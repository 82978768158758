import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import ExecutiveAccordions from "./ExecutiveAccordion";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Button } from "@mui/material";
import SimpleAccordion from "./Accordion2";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const TransportFlow = () => {
  const classes = useStyles();

  const [points, setPoints] = React.useState([{}]);
  const [sd, setSd] = React.useState(0);

  const addPoint = () => {
   
    setPoints(prevState => [...prevState, {}])
  };
//   console.log("p", points);
const removePoint=(index)=>{
  // console.log("index",index)
  // setPoints(points.splice(index,1))
  // console.log("ponts",points)
  let temp = [...points]
  temp.splice(index, 1)
  setPoints(temp);
}
  return (

    <div  className="flow">
        <div class="m-1">
         <Button style={{zIndex:1000 ,marginTop:"-4%"}}variant ="outlined"  className="add-waypoint float-right mb-4"  startIcon={< AddBoxOutlinedIcon />} onClick={() => addPoint()}>
        Add
      </Button>
      </div>
        <div class="mt-4 ">
      {points.map((point,index) => (
        // <ExecutiveAccordions  remove={removePoint} index={index} />
        <SimpleAccordion remove={removePoint} index={index}/>

      ))}

      </div>
    </div>
  );
};

export default TransportFlow;
