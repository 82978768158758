import { UPDATE_SINGLE_DOCUMENT_REQUEST,UPDATE_SINGLE_DOCUMENT_SUCCESS,UPDATE_SINGLE_DOCUMENT_FAILURE } from "../../../actions/Documents/projectDocuments/DocumentsTypes";

const initialState={
    loading:false,
    error:"",
    projectDocument:{}
}

const projectOwnerReducer=(state=initialState,action)=>{
    switch(action.type){
        case UPDATE_SINGLE_DOCUMENT_REQUEST:
            return {...state,
                loading:true
            }
        case UPDATE_SINGLE_DOCUMENT_SUCCESS:
            return {...state,
                projectDocumentReducer:action.payload
            }
        case UPDATE_SINGLE_DOCUMENT_FAILURE:
            return {...state,
                loading:false,
                error:action.payload
            }
            default:
                return state;
        }
}
export default projectOwnerReducer;