import { FETCH_SECTOR_FAILURE, FETCH_SECTOR_REQUEST, FETCH_SECTOR_SUCCESS } from "../../actions/sector/sectorTypes"

const initialState = {
    loading: false,
    sectors: [],
    error: ''
}

const sectorsReducer = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_SECTOR_REQUEST:
            return {
                loading: true,
                sectors: [],
                error: ''
            }
        case FETCH_SECTOR_SUCCESS:
            return {
                loading: false,
                sectors: action.payload,
                error: ''
            }
        case FETCH_SECTOR_FAILURE:
            return {
                loading: false,
                sectors: [],
                error: action.payload
            }
        default: return state
    }
}

export default sectorsReducer