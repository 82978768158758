import { useState } from 'react'
import { Link } from "react-router-dom";
import ReactCardFlip from 'react-card-flip';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import Map from './location';
import { Button, Dropdown, DropdownItem, DropdownMenu, Modal } from 'reactstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import "./style.css"
import DeleteCompany from './DeleteCompany';
import {imageurl} from "../../../services/Api"

function Card({ company ,handleDelete}) {
    const [flip, setFlip] = useState(false)
    const [modal,setModal]=useState(false)
    const [doc,setDoc]=useState(false)
    const [dropdownOpen,setDropdownOpen]=useState(false)
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const toggle_2=(e)=>{
        if (modal===false){
            setModal(true)
        }else{
            setModal(false)
        }
    }

    

    // const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    function createMarkup(value) {
        return { __html: value };
    }
    return (
        <>
            <ReactCardFlip isFlipped={flip} flipDirection="vertical">
                <div class="property__list__wrapper property__grid m-4">
                    <div class="row d-flex align-items-center">
                        <div class="property__grid__area__wrapper__inner__two">
                            <div class="property__item__image column__space--secondary">
                                <div class="img__effect " style={{ maxHeight: "250px" }}>
                                    <Link to={`/company/${company.id}`}>
                                        <img src={`${imageurl}${company.company_logo}`} alt="" />

                                        {/* <Map/> */}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="property__grid__area__wrapper__inner__three  position-relative" style={{zIndex:0}}>
                            <div class="property__item__content">
                                <div class="item__head">
                                    <div class="item__head__left">
                                        <h4>{company.company_name}</h4>
                                        <p><i class="fa-solid fa-location-dot"></i> {company.company_city + " , " + company.company_state}
                                        </p>
                                    </div>

                                </div>
                                <div class="progress__type progress__type--two">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="25"
                                            aria-valuemin="0" aria-valuemax="100"
                                            style={{
                                                width: `${parseInt(((company.company_share_volume - company.company_available_shares) / company.company_share_volume) * 100)}%`
                                            }}
                                        ></div>
                                    </div>
                                    <div class="project__info">
                                        <p class="project__has"><span class="project__has__investors">{`${company.status}`}</span> | <span class="project__has__investors__amount">{`SHARES ${parseInt(company.company_share_volume - company.company_available_shares)}`}</span> <span
                                            class="project__has__investors__percent">{`(${parseInt(((company.company_share_volume - company.company_available_shares) / company.company_share_volume) * 100)}%)`}</span></p>
                                        <p class="project__goal">
                                            {`SHARES ${company.company_share_volume}`}
                                        </p>
                                    </div>
                                </div>
                                <div class="item__info">
                                    <div class="item__info__single">
                                        <p>Total Shares</p>
                                        <h6>{company.company_share_volume}</h6>
                                    </div>
                                    <div class="item__info__single">
                                        <p>Total Shares</p>
                                        <h6>{company.company_share_volume}</h6>
                                    </div>
                                    <div class="item__info__single">
                                        <p>Available shares</p>
                                        <h6>{company.company_available_shares}</h6>
                                    </div>
                                    <div class="item__info__single">
                                        <p>Sector</p>
                                        <h6>{company.company_available_shares}</h6>
                                    </div>
                                </div>
                                <div className='d-flex flex-row position-relative'>
                                   
                                    <Button className="button button--effect" onClick={() => setFlip(prev => !prev)}>flip</Button>
                                    {/* <div className="position-absolute end-0 rounded-circle bg-light text-dark"><BsThreeDotsVertical className='text-center m-1 try' style={{ height: "1.8em", width: "1.8em" }} /></div> */}
                                    <Dropdown className="position-absolute end-0 mx-4 rounded-circle bg-light text-dark" isOpen={dropdownOpen} toggle={toggle} style={{zIndex:5}}>
                                        <a href="" caret><BsThreeDotsVertical className='text-center m-1 try' style={{ height: "1.8em", width: "1.8em" }} /></a>
                                        <DropdownMenu >
                                            <Link to={`/company/${company.id}`}>
                                                <DropdownItem >  <i className="ri-eye-fill  me-2 align-middle text-muted" />View</DropdownItem>
                                                </Link>
                                            <Link to ={`/update-company/${company.id}`}><DropdownItem >
                                                <i className="ri-edit-fill  me-2 align-middle text-muted" />edit</DropdownItem>
                                            </Link>
                                            
                                            
                                          
                                            {/* delete */}
                                            <DropdownItem className='remove-item-btn' onClick={() => {
                                                toggle_2()
                                                setDoc(company.id)
                                            }}
                                            >
                                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
                                            <Modal
                                                isOpen={modal}
                                                toggle={() => {
                                                    toggle_2();
                                                }}
                                                centered
                                            >
                                                <DeleteCompany   hancleClose={toggle_2} id={doc} handleDelete={handleDelete} />
                                            </Modal>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="property__list__wrapper property__grid m-4">
                    <div class="row d-flex align-items-center">
                        <div class="property__grid__area__wrapper__inner__two">
                            <div class="property__item__image column__space--secondary">
                                <div class="img__effect" style={{ width: "100%", height: "400px" }}>
                                    <Link to={`/company/${company.id}`}>
                                        <Map center={{ "lat": parseFloat(company.company_lat), "lng": parseFloat(company.company_long) }} zoom={15} />
                                        {/* <img src="assets/images/property/los.png" alt="Los Angeles" /> */}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div class="property__grid__area__wrapper__inner__three">
                            <div class="property__item__content">
                                <div class="item__head">
                                    <div class="item__head__left" id="add" >
                                        <h4>{`${company.company_name}`} Description</h4>

                                        {console.log("desc", typeof (company.company_description))}
                                        {/* <p id="add"> {_.truncate(company.company_description, { length: 100,'omission': '...' })}</p> */}
                                        {/* <div dangerouslySetInnerHTML={{ __html: company.company_description}} /> */}
                                        <div style={{ width: '100%', maxHeight: '100px', minHeight: '100px', overflow: 'hidden' }}>
                                            <p dangerouslySetInnerHTML={createMarkup(company.company_description)} />
                                        </div>


                                        {/* <p id="demo">{add()}</p> */}
                                    </div>

                                </div>


                                <div className='d-flex flex-row position-relative'>
                                    {/* <a href={`${company.company_website}`} target="_blank" class="button button--effect ">Visit Website</a> */}
                                    <button className="button button--effect" onClick={() => setFlip(!flip)}>flip</button>
                                    <div className="position-absolute end-0"><BsThreeDotsVertical /></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </ReactCardFlip>
        </>
    )
}

export default Card;