import { UPDATE_PROJECT_REQUEST,UPDATE_PROJECT_SUCCESS,UPDATE_PROJECT_FAILURE } from "../../actions/project-details/projectTypes";

const initialState={
    loading:false,
    project:{},
    error:""
}

const updateProjectReducer=(state=initialState,action)=>{
    switch(action.type){
        case UPDATE_PROJECT_REQUEST:
            return{
                ...state,
                loading:true

            }
            break;
        case UPDATE_PROJECT_SUCCESS:
            return{
                ...state,
                loading:false,
                project:action.payload

            }
            break;
        case UPDATE_PROJECT_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.payload
            }
            break;
        default:
            return state;
            
    }
}
export default updateProjectReducer;