import Swal from "sweetalert2"
import { requests } from "../../../services/Api"
import {DELETE_SINGLE_COMPANIES_REQUEST,DELETE_SINGLE_COMPANIES_FAILURE,DELETE_SINGLE_COMPANIES_SUCCESS} from "./companyTypes"

const DeleteCompanyAct=(id,token)=>async(dispatch)=>{
    try {
        dispatch({type:DELETE_SINGLE_COMPANIES_REQUEST})
        const response =await requests.delete(`/company/delete-company/${id}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        dispatch({type:DELETE_SINGLE_COMPANIES_SUCCESS,
        payload:response.data})
        Swal.fire({
            icon:"success",
            title:"Success",
            text:"Company Deleted Successfully",
            confirmButtonText:"ok"

        })

    } catch (error) {
        dispatch({type:DELETE_SINGLE_COMPANIES_FAILURE, payload:error.response.data.message})
        Swal.fire({
            icon:"error",
            title:"Error",
            text:error.response.data.message,
            confirmButtonColor:"red",
            confirmButtonText:"OK",
        })
        
    }

}
export default DeleteCompanyAct