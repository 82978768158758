import { Grid } from '@mui/material'
import React from 'react'
import ProjectedChart from '../../components/Charts/ComposedChart'

export default function MyAnalytics() {
  return (
    <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ProjectedChart/>
            </Grid>
        </Grid>
    </div>
  )
}
