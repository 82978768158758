import Swal from "sweetalert2";
import { requests } from "../../../../services/Api";
import { getToken } from "../../../../services/UseTokens";
import { CREATE_INVESTOR_REQUEST,CREATE_INVESTOR_SUCCESS,CREATE_INVESTOR_FAILURE } from "./InvestorTypes";


export const createInvestor =(data)=> async(dispatch) => {
    const token=getToken()
    try {
        dispatch({type: CREATE_INVESTOR_REQUEST})

        const response = await requests.post("documents/investors-bulk",data,{
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        dispatch({type:CREATE_INVESTOR_SUCCESS,
        payload:response.data})
        Swal.fire(
            'Good job!',
            'investor Document Created Successfully',
            'success'
        )
        console.log("responseee",response.data)
    } catch (error) {
        dispatch({
            type: CREATE_INVESTOR_FAILURE,
            payload: error.response
        })
            Swal.fire({
                icon: "error",
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
                showCancelButton: true,               
                cancelButtonColor: '#d33',
                
            
        })
        
    }
}