import React from 'react'
import {RESEND_LINK_SUCCESS,RESEND_LINK_REQUEST,RESEND_LINK_FAILURE} from "../../actions/auth/Twofactor/TwofactorAct"


const initialState={
    loading:false,
    link:"",
    error:""
}
function ResendLinkReducer(state=initialState, action) {
    switch(action.type){
        case RESEND_LINK_REQUEST:
            return{
                ...state,
                loading:true
            }
        case RESEND_LINK_SUCCESS:
            return{
                ...state,
                link:action.payload
            }
        case RESEND_LINK_FAILURE:
            return{
                ...state,
                error:action.payload
            }
        default:
            return state;

    }
}

export default ResendLinkReducer