import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { fetchProjects } from '../../../redux/actions/projects/fetchProjects';
import moment from 'moment';
import _ from 'lodash';
import Dropdown from '../../home/filter/dropdown/dropdown.component';
import { getUserId } from '../../../services/UseTokens';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import {BiEditAlt } from "react-icons/bi"
import styled from 'styled-components';
import "./style.css"
import { imageurl } from '../../../services/Api';

const Main = styled("div")`
  font-family: sans-serif;
  background: #f0f0f0;
  height: 100vh;
`;

 const DropDownContainer = styled("div")`
  width: 10.5em;
  margin: 0 auto;
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
  background: #ffffff;
`;

 const DropDownListContainer = styled("div")``;

 const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

 const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
`;


function List({ allProjects }) {
    const [perPage, setPerPage] = useState(9)
    const [currentPage, setCurrentPage] = useState(1)
    const [modal2,setModal2] = useState(false)
    const [isOpen,setIsOpen]=useState(false)
    const dispatch = useDispatch();
    const userId = getUserId()
    // const allProjects = useSelector(state => state.projects.projects);
    // const projects = allProjects.slice(0,perPage.value);
    //check number of pages
    const userProjects = _.filter(allProjects, { created_by: userId })

    const numPages = Math.ceil(userProjects.length / perPage.value);
    const paginate = (array, pageSize, pageNumber) => {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }
    const projects = paginate(userProjects, perPage.value || 9, currentPage);

    //get minutes to project end date
    const minutesToEnd = (closing) => {
        const today = moment();
        const end = moment(closing);
        const diff = end.diff(today, 'minutes');
        return diff;
    }

    useEffect(() => {
        dispatch(fetchProjects())
    }, [currentPage])

    const options = [
        { name: '9', value: 9 },
        { name: '18', value: 18 },
        { name: '27', value: 27 },
        { name: '36', value: 36 }
    ]
    function onButtonClick(){
setIsOpen(!isOpen)
    }
    function toggle_3() {
        if (modal2 === false) {
            setModal2(true)
        } else {
            setModal2(false)
        }
    }

    return (
        <div>
            <section class="properties__filter section__space__bottom">
                <div class="container wow fadeInUp">
                    <div class="properties__filter__wrapper">
                        <h6>Showing <span>{userProjects && userProjects.length}</span> projects</h6>
                        <div class="grid__wrapper">
                            <label>Per Page</label>
                            <Dropdown
                                options={
                                    options
                                }
                                prompt="Per Page"
                                value={perPage}
                                onChange={(val) => setPerPage(val)}
                            />
                        </div>
                    </div>
                    <div class="row property__grid__area__wrapper">
                        {
                            userProjects && userProjects.map((project, index) => (
                                <Link to={`/my-projects/${project.id}`}>
                                    <div class="col-xl-4 col-md-6 property__grid__area__wrapper__inner" key={index}>
                                        <div class="property__list__wrapper property__grid">
                                            <div class="row d-flex align-items-center">
                                                <div class="property__grid__area__wrapper__inner__two">
                                                    <div class="property__item__image column__space--secondary">
                                                        <div class="img__effect">
                                                            <Link to={`/my-projects/${project.id}`}>
                                                                <img src={`${imageurl}${project.project_header_image}`} alt="Los Angeles" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="property__grid__area__wrapper__inner__three">
                                                    <div class="property__item__content">
                                                        <div class="item__head">
                                                            <div class="item__head__left">
                                                                <h4>{project.project_name}</h4>
                                                                <p><i class="fa-solid fa-location-dot"></i> {project.location}
                                                                </p>
                                                            </div>
                                                            <div class="item__head__right">
                                                                <div class="countdown__wrapper">
                                                                    <p class="secondary"><i class="fa-solid fa-clock"></i> Left to invest
                                                                    </p>
                                                                    <div class="countdown">
                                                                        <h5>
                                                                            <span class="days">{parseInt(minutesToEnd(project.closing_date) / (60 * 24))}</span><span class="ref">d</span>
                                                                            <span class="seperator">:</span>
                                                                        </h5>
                                                                        <h5>
                                                                            <span class="hours">{parseInt(parseInt(minutesToEnd(project.closing_date) % (60 * 24)) / 60)}</span><span class="ref">h</span>
                                                                            <span class="seperator">:</span>
                                                                        </h5>
                                                                        <h5>
                                                                            <span class="minutes">{parseInt(parseInt(parseInt(minutesToEnd(project.closing_date) % (60 * 24)) % 60))}</span><span class="ref">m</span>
                                                                            <span class="seperator"></span>
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="progress__type progress__type--two">
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar" aria-valuenow="25"
                                                                    aria-valuemin="0" aria-valuemax="100"
                                                                    style={{
                                                                        width: `${parseInt(((project?.financing.project_share_volume - project?.financing.project_available_shares) / project?.financing.project_share_volume) * 100)}%`
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <div class="project__info">
                                                                <p class="project__has"><span class="project__has__investors">{`${project.no_of_investors} Investors`}</span> | <span class="project__has__investors__amount">{`SHARES ${parseInt(project.financing.project_share_volume - project.financing.project_available_shares)}`}</span> <span
                                                                    class="project__has__investors__percent">{`(${parseInt(((project.financing.project_share_volume - project.financing.project_available_shares) / project.financing.project_share_volume) * 100)}%)`}</span></p>
                                                                <p class="project__goal">
                                                                    {`SHARES ${project.financing.project_share_volume}`}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="item__info">
                                                            <div class="item__info__single">
                                                                <p>Max Investment</p>
                                                                <h6>{project.financing.maximum_investment}</h6>
                                                            </div>
                                                            <div class="item__info__single">
                                                                <p>Available Shares</p>
                                                                <h6>{project.financing.project_available_shares}</h6>
                                                            </div>
                                                            <div class="item__info__single">
                                                                <p>Min Investment</p>
                                                                <h6>{project.financing.minimum_investment}</h6>
                                                            </div>
                                                            <div class="item__info__single">
                                                                <p>Sector</p>
                                                                <h6>{project?.sector?.name}</h6>
                                                            </div>
                                                        </div>
                                                        <div class="item__footer">
                                                            <div class="item__security">
                                                                <div class="icon__box">
                                                                    <img src="assets/images/home.png" alt="Security" />
                                                                </div>
                                                                <div class="item__security__content">
                                                                    <p class="secondary">Security</p>
                                                                    <h6>{project.financing.finance_method}</h6>
                                                                </div>
                                                            </div>
                                                            <div class="item__cta__group">
                                                                <Link to={`/project/${project.id}`} class="button button--effect">Invest Now</Link>
                                                                <Link to={`/projects/${project.id}`}
                                                                    class="button button--secondary button--effect">Details</Link>
                                                            </div>
                                                        </div>
                                                        <div class="invest__cta__wrapper">
                                                            <div class="countdown__wrapper">
                                                                <p class="secondary"><i class="fa-solid fa-clock"></i> Left to invest</p>
                                                                <div class="countdown">
                                                                    <h5>
                                                                        <span class="days">{parseInt(minutesToEnd(project.closing_date) / (60 * 24))}</span><span class="ref">d</span>
                                                                        <span class="seperator">:</span>
                                                                    </h5>
                                                                    <h5>
                                                                        <span class="hours">{parseInt(parseInt(minutesToEnd(project.closing_date) % (60 * 24)) / 60)}</span><span class="ref">h</span>
                                                                        <span class="seperator">:</span>
                                                                    </h5>
                                                                    <h5>
                                                                        <span class="minutes">{parseInt(parseInt(parseInt(minutesToEnd(project.closing_date) % (60 * 24)) % 60))}</span><span class="ref">m</span>
                                                                        <span class="seperator"></span>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <div class=" invest__cta  position-relative">
                                                                <Link to={`/projects/${project.id}`} class="button button--effect ">
                                                                    Invest Now
                                                                </Link>

                                                                <div class="btn-group p-4  float-end">
                                                                <button class="btn btn-light btn-sm dropdown-toggle try"   type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <BsThreeDotsVertical />

                                                                </button>
                                                                <ul class="dropdown-menu">
                                                                    <li><Link to={`/update-project/${project.id}`}><div className='border-bottom text-center' ><span class="mx-1"><BiEditAlt/></span>edit</div></Link></li>

                                                                    <li><div  className="border-bottom text-center" onClick={toggle_3}><span class="mx-1"><MdOutlineDeleteOutline/></span>Delete</div></li>
                                                                </ul>
                                                            </div>


                                                                  
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>

                    {
                        currentPage === 1 && numPages === 1 ? null :
                            currentPage === 1 ? (
                                <div class="cta__btn">
                                    <button
                                        class="button button--effect m-4"
                                        onClick={() => {
                                            setCurrentPage(currentPage + 1)
                                        }}>Load More</button>
                                </div>

                            ) : numPages === currentPage || numPages < currentPage ? (
                                <div class="cta__btn">
                                    <button
                                        class="button button--effect m-4"
                                        onClick={() => {
                                            setCurrentPage(currentPage - 1)
                                        }}>Load Previous</button>
                                </div>
                            ) : (
                                <div class="cta__btn">
                                    <button
                                        class="button button--effect m-4"
                                        onClick={() => {
                                            setCurrentPage(currentPage - 1)
                                        }}>Load Previous</button>
                                    <button
                                        class="button button--effect m-4"
                                        onClick={() => {
                                            setCurrentPage(currentPage + 1)
                                        }}>Load More</button>
                                </div>

                            )
                    }

                </div>
            </section>
        </div>
    );
}

export default List;
