// import "./styles.css";
import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Page D",
    uv: 80,
    pv: 3908,
    amt: 2000
  },
  {
    name: "Page E",
    uv: 189,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Page F",
    uv: 239,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Page G",
    uv: 49,
    pv: 4300,
    amt: 2100
  }
];

const colors = [
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#ff7300",
]

export default function InvestorsChart({ data, projects }) {
  return (
    <ResponsiveContainer width="100%" height={300}>
    <AreaChart
      width={400}
      height={300}
      data={data}
      margin={{
        top: 10,
        right: 0,
        left: 0,
        bottom: 0
      }}
    >
      {/* <CartesianGrid strokeDasharray="3 3" /> */}
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      {
        projects && projects.map((project, index) => (
          <>
          <Area type="monotone" dataKey={`${project?.project_name}`} stroke={colors[index]} fill={colors[index]} />
          </>
        ))
      }
    </AreaChart>
    </ResponsiveContainer>
  );
}
