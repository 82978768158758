// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Rating from '@mui/material/Rating'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import NoSetup from './NoSetUp'

// Styled Grid component
const StyledGrid1 = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    paddingTop: '0 !important'
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(3, 4.75),
    [theme.breakpoints.down('md')]: {
      paddingTop: 0
    }
  }
}))

// Styled Grid component
const StyledGrid2 = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    paddingLeft: '0 !important'
  },
  [theme.breakpoints.down('md')]: {
    order: -1
  }
}))

// Styled component for the image
const Img = styled('img')(({ theme }) => ({
  height: '11rem',
  borderRadius: theme.shape.borderRadius
}))

const Overview = ({ company }) => {
  function createMarkup(value) {
    return { __html: value };
}
  return (
    <div>
      {company?.company_description === null && (
        <NoSetup />
      )}
      {company?.company_description !== null && (
        <Card>
          <Grid container spacing={12}>
            <StyledGrid1 item xs={12} md={12} lg={12}>

              <Box sx={{ padding: "15px" }}>
              <Typography variant='h6' sx={{ marginBottom: 2 }}>
                  Description
                </Typography>

                <Typography variant='body2' sx={{ marginBottom: 4 }}>
                  {/* {company?.company_description} */}
                  <p dangerouslySetInnerHTML={createMarkup(company?.company_description)} />
                </Typography>
              </Box>

            </StyledGrid1>
          </Grid>
          <Grid container spacing={5}>
            <StyledGrid2 item xs={6} md={3} lg={3}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <LanguageIcon />
                <Typography variant="caption">{company?.website_url === null ? "Not Available": `${company?.website_url}`}</Typography>
              </Box>
            </StyledGrid2>
            <StyledGrid2 item xs={6} md={3} lg={3}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <PublicIcon />
                <Typography variant="caption">{company?.company_nationality === null ? "Not Available": `${company?.company_nationality}`}</Typography>
              </Box>
            </StyledGrid2>
            <StyledGrid2 item xs={6} md={3} lg={3}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <TwitterIcon />
                <Typography variant="caption">{company?.twitter_url === null ? "Not Available": `${company?.twitter_url}`}</Typography>
              </Box>
            </StyledGrid2>
            <StyledGrid2 item xs={6} md={3} lg={3}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                < FacebookIcon />
                <Typography variant="caption">{company?.facebook_url === null ? "Not Available": `${company?.facebook_url}`}</Typography>
              </Box>
            </StyledGrid2>


          </Grid>
        </Card>
      )}

    </div>
  )
}

export default Overview
