import { CREATE_OWNERDOCS_REQUEST,CREATE_OWNERDOCS_SUCCESS,CREATE_OWNERDOCS_FAILURE } from "../../../actions/Documents/projectsowner/OwnerTypes";

const initialState = {
    loading: false,
    error: "",
    document: []
}

export default function createOwnerDocumentReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_OWNERDOCS_REQUEST:
        return {
            ...state,
            loading: true,
            document: [],
            error: ""   
        }
        case CREATE_OWNERDOCS_SUCCESS:
        return {
            ...state,
            loading: false,
            document: action.payload,
            error: ""
        }
        case CREATE_OWNERDOCS_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            document: []
        }
        default:
        return state
    }
}
