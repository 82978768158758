import React from "react";
import Carousel from "better-react-carousel";
import { Box, Stack, styled, Switch, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { imageurl, requests } from "../../../services/Api";

const Gallery = (data) => {
  // get where media_type is image
  const images = [];
  for (let i = 0; i < data.data.length; i++) {
    if (data.data[i].media_type === "image") {
      images.push(data.data[i]);
    }
  }
  console.log('data', images);
  return (
    <Box>
      {images.length > 0 ? (
        <Carousel cols={2} rows={1} gap={10} loop autoPlay={100}>
          {images.map((image, index) => (
            <Carousel.Item key={index}>
              <img style={{maxHeight: '50%', maxWidth: '70%'}} src={`${imageurl}/${image?.image_path}`} />
            </Carousel.Item>
          ))}
          {/* ... */}
        </Carousel>
      ) : (
        <Typography variant="h6" color="text.secondary">
          No images found
        </Typography>
      )}
    </Box>
  );
};

export default Gallery;
