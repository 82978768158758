import Swal from "sweetalert2";
import { requests } from "../../../../services/Api";
import { getToken } from "../../../../services/UseTokens";
import { FETCH_DOCUMENT_FAILURE, FETCH_DOCUMENT_REQUEST, FETCH_DOCUMENT_SUCCESS } from "./documentTypes";


 const fetchdocumentstypes=() => async (dispatch) =>{
    const token=getToken()
 
    try {
        dispatch({type: FETCH_DOCUMENT_REQUEST})
        await requests.get(`/documents/types`,  {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        .then((response) => {
            
            dispatch({ 
                type: FETCH_DOCUMENT_SUCCESS, 
                payload: response.data 
            });
            
        })
        
    } catch (error) {
       dispatch({type:FETCH_DOCUMENT_FAILURE,
       payload:error.response}) 
      
    }

}
export default fetchdocumentstypes;