import { Link } from "react-router-dom";

function Marketing() {
  return (
    <div>
   <section class="market section__space over__hi">
        <div class="container">
            <div class="market__area">
                <div class="row d-flex align-items-center" style={{display: 'flex', flexDirection: 'row'}}>
                    <div class="col-lg-6 col-xl-5">
                        <div class="market__thumb thumb__rtl column__space">
                            <img src="assets/images/market-illustration.png" alt="Explore the Market" />
                        </div>
                    </div>
                    {/* <div class="col-lg-6 col-xl-6 " >
                        weeew
                    </div> */}
                    <div class="col-lg-6 col-xl-6 ">
                        <div class="content">
                            <h5 class="neutral-top"><span>Real exposure to the investment market</span></h5>
                            <h2>You Invest. Mkuzaji
                                Does the Rest</h2>
                            <p>Transparent  Investing Through Mkuzaji.Join us and
                                experience a smarter,better way to invest</p>
                            <Link to="/projects" class="button button--effect" style={{color: 'white'}}>Start Exploring</Link>
                            <img src="assets/images/arrow.png" alt="Go to" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Marketing;
