import { useEffect, useLayoutEffect ,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects } from '../../redux/actions/projects/fetchProjects';
import _ from 'lodash'

import FeaturedProjects from "./featured";
import ProjectFilter from "./filter";
import HeroHome from "./hero";
import Marketing from "./marketing";
import Numbers from "./numbers";
import Profit from "./profit";
import Projects from "./projects";
import Start from "./start";
import Video from "./video";

function Home() {
 
  const dispatch = useDispatch();
  const allProjects = useSelector(state => state.projects.projects);
  const projects = _.filter(allProjects, {featured: "1"})
  useEffect(() => {
    dispatch(fetchProjects())
  }, [])
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>
      <HeroHome />
      {/* <ProjectFilter allProjects={allProjects} /> */}
      {
        projects && projects.length > 0 && <FeaturedProjects allProjects={projects}/>
      }
      <Projects allProjects={allProjects}/>
      <Profit />
      <Start />
      <Video />
      <Numbers />
      <Marketing />
    </div>
  );
}

export default Home;
