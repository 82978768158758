import { FETCH_PROJECT_FAILURE, FETCH_PROJECT_REQUEST, FETCH_PROJECT_SUCCESS } from "../../actions/project-details/projectTypes"

const initialState = {
    loading: false,
    project: {},
    error: ''
}

const projectReducer = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_PROJECT_REQUEST:
            return {
                loading: true,
                project: {},
                error: ''
            }
        case FETCH_PROJECT_SUCCESS:
            return {
                loading: false,
                project: action.payload,
                error: ''
            }
        case FETCH_PROJECT_FAILURE:
            return {
                loading: false,
                project: {},
                error: action.payload
            }
        default: return state
    }
}

export default projectReducer