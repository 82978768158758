import { DELETE_DIRECTORS_FAILURE,DELETE_DIRECTORS_SUCCESS,DELETE_DIRECTORS_REQUEST } from "../../actions/company/companyDirectors/directorTypes";

const initialState={
    loading: false,
    director:{},
    error:""
}
export default function DeleteDirectorsReducer(state=initialState,action){
    switch (action.type) {
        case DELETE_DIRECTORS_REQUEST:
            return{
                ...state,
                loading:true
            }
            
            break;
            case DELETE_DIRECTORS_SUCCESS:
                return{
                    ...state,
                    director:action.payload

                }
                
                break;
                case DELETE_DIRECTORS_FAILURE:
                    return{
                        ...state,
                        error:action.payload
                    }
                    
                    break;
    
        default:
            return state;
            break;
    }
}