import React, { useState } from 'react'
import PlacesAutoComplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { getUserId } from '../../services/UseTokens';
import "./style.css"

function AutoComplete({ addressData,handleLoc,locationdata}) {
  console.log("locationData",locationdata)
  const [address, setAddress] = React.useState("");
  const [location,setLocation]=useState("")
  const [latitude, setLatitude] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({ lat: null, lng: null });
  const user = getUserId()
  const generalData = JSON.parse(localStorage.getItem(`${user}-generalinfo`))
  var generalDataCompany={}

  const handleSelect = async value => {
    console.log("value", value)
    handleLoc(value)
    const results = await geocodeByAddress(value);
   if (generalData != null) {
     generalData.location = value
   }else{
    generalDataCompany.location = value
    localStorage.setItem(`${user}-company`, JSON.stringify(generalDataCompany))
   }
    setAddress(value);
    const latLng = await getLatLng(results[0]).then((latLng, value) => {
      setCoordinates(latLng);

      addressData(coordinates)
      
      if (generalData != null) {
      generalData.latitude = latLng.lat
      generalData.longitude = latLng.lng
      }else{
        
        generalDataCompany.latitude = latLng.lat
        generalDataCompany.longitude = latLng.lng
        localStorage.setItem(`${user}-company`, JSON.stringify(generalDataCompany))
       
      }

  
      localStorage.setItem(`${user}-generalinfo`, JSON.stringify(generalData))
      
      console.log("addressdata",coordinates)
    })
  }


  return (
    <div class="col-xl-12  mb-3">

      <PlacesAutoComplete
        value={
          address}
        onChange={setAddress}

        // onChange={(e)=>{console.log("latitude",coordinates.lat)}} 
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (

       
          
          <div className='row'>
             <div className='col-lg-12'>
             
              <input {...getInputProps({
                type: "text",
                placeholder: !locationdata?"Type address":locationdata,
                class: "form-control",
                className: 'location-search-input',
                
                // value:{address},
                // id:"address",
                // name:"address"
              })} />
               <div className="autocomplete-dropdown-container">
              {loading ? <div>...loading</div> : null}

              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                };

                return (
                  <div {...getSuggestionItemProps(suggestion, { className, style })}>
                    <span >{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
            </div>
            {/* {address && coordinates.lat && (
              <div className='col-lg-6'>
                <label class="form-label">latitude</label>
                <input
                  id="latitude"
                  name="latitude"
                  class="form-control invisible"
                  onChange={(e) => {
                    setLatitude(coordinates.lat)
                    generalData.latitude = coordinates.lat
                    console.log("generalData loc", generalData)

                  }}
                
                  onBlur={(e) => {
                    localStorage.setItem(`${user}-generalinfo`, JSON.stringify(generalData))
                  }}
                  value={coordinates.lat}
                />
              </div>

            )} */}

            {/* {address && coordinates.lng && (
              <div className='col-lg-6'>
                
                <input
                  id="longitude"
                  name="longitude"
                  class="form-control invisible"
                  onChange={(e) => {
                    setLongitude(coordinates.lng)
                    generalData.longitude = coordinates.lng
                    console.log("generalData loc", generalData)
                  }
                  }
                  onBlur={(e) => {
                    localStorage.setItem(`${user}-generalinfo`, JSON.stringify(generalData))
                  }}
                  value={coordinates.lng}
                />
              </div>

            )} */}
          
          </div>
        )}
      </PlacesAutoComplete>
    </div>
  )
}

export default AutoComplete