export const FETCH_OWNERDOCS_REQUEST = "FETCH_OWNERDOCS_REQUEST";
export const FETCH_OWNERDOCS_SUCCESS = "FETCH_OWNERDOCS_SUCCESS";
export const FETCH_OWNERDOCS_FAILURE = "FETCH_OWNERDOCS_FAILURE";

export const CREATE_OWNERDOCS_REQUEST = "CREATE_OWNERDOCS_REQUEST";
export const CREATE_OWNERDOCS_SUCCESS = "CREATE_OWNERDOCS_SUCCESS";
export const CREATE_OWNERDOCS_FAILURE = "CREATE_OWNERDOCS_FAILURE";

export const UPDATE_OWNERDOCS_REQUEST = "UPDATE_OWNERDOCS_REQUEST";
export const UPDATE_OWNERDOCS_SUCCESS = "UPDATE_OWNERDOCS_SUCCESS";
export const UPDATE_OWNERDOCS_FAILURE = "UPDATE_OWNERDOCS_FAILURE";

export const DELETE_OWNERDOCS_REQUEST = "DELETE_OWNERDOCS_REQUEST";
export const DELETE_OWNERDOCS_SUCCESS = "DELETE_OWNERDOCS_SUCCESS";
export const DELETE_OWNERDOCS_FAILURE = "DELETE_OWNERDOCS_FAILURE";