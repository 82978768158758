import _ from 'lodash';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import OnBoard from '.'
import OnBoardMobile from './OnboardMobile'

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export default function OnboardCompanyCombine() {
  const [windowWidth, setWindowWidth] = useState(0);
    const [ismobile,setIsMobile]=useState(window.innerWidth)

    const checkmobile=()=>{
      setWindowWidth(window.innerWidth)
    }
    // useLayoutEffect(() => {
    //  checkmobile()
    // }, [ismobile])
    useEffect(() => {

      // // }
     checkmobile()
      // window.addEventListener("resize",checkmobile)
      // return()=>window.removeEventListener('resize', checkmobile);
      console.log("windo2",windowWidth)

    }, [window.innerWidth])
  
    
  return (
    <div style={{}}>
        {  windowWidth <=600 &&<OnBoardMobile/>}
        {  windowWidth >600 &&<OnBoard/>}
    </div>
  )
}
