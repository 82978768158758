
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Companycard from './Card';
import NoProjects from '../../myProjects/NoProjects';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function CompanyProfile({ allCompanies }) {
  return (
    <Box sx={{ flexGrow: 1, marginY: "7%", marginX: "4%" }}>
      <Grid container spacing={2}>

        {
          allCompanies?.length > 0 && allCompanies?.map((company, index) => (
            <Grid item xs={12} sm={6} md={3} lg={4} xl={4} key={index}>
              <Item><Companycard company={company} /></Item>
            </Grid>
          ))
        }

        {
          allCompanies?.length === 0 && (
            <NoProjects />
          )
        }
      </Grid>
    </Box>
  );
}
