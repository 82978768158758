import Footer from "../footer";
import TopNav from "../topnav";

function Main({ children }) {
  return (
    <div className="">
      <TopNav />
      {children}
      <Footer />
    </div>
  );
}

export default Main;
