import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fetchdocumentstypes from '../../../redux/actions/Documents/documentTypes/fetchdocumentstypes'
import { getToken, getUserId } from '../../../services/UseTokens'
import _ from 'lodash'
import { Formik } from 'formik'
import myimg from './image/image.png'
import avif from "./image/avif.webp"
import jpgimg from "./image/jpg.png"
import webpimg from "./image/webp.png"
import pngimg from "./image/png.png"
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Input, Label, Modal, Row, Spinner } from 'reactstrap'
import { AiOutlineFileJpg, AiOutlineFilePdf, AiOutlineMore } from 'react-icons/ai'
import fetchprojectOwnerAction from '../../../redux/actions/Documents/projectsowner/fetchOwnerDocs'
import createOwnerDocumentAction from '../../../redux/actions/Documents/projectsowner/postOwnerDocument'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import ViewSingle from './ViewSingle'
import ViewDocument from './ViewDocument'
import DeleteProjectOwner from './DeleteProjectOwner'

export default function ProjectOwnerDocument() {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [modal_center, setmodal_center] = useState(false);
  const [doc, setDoc] = useState()
  const [saveData, setSaveData] = React.useState([{ document: "", document_id: "", user_id: "" }])
  const [modal, setModal] = useState(false)
  const [indObj, setIndObj] = React.useState({})
  const [image, setImage] = React.useState(null)
  const [indexvalue, setIndexvalue] = React.useState()
  const token = getToken()
  const userId = getUserId()
  const dispatch = useDispatch()
  var testArr = []
  const documenttype = useSelector(state => state.documenttype.documents)
  const ownerDocuments = useSelector(state => state.ownerDocuments.documents)
  const isLoading = useSelector(state => state.createOwnerDocument.loading)
  const ownerdoc = _.filter(documenttype, { isOwner: 1 })


  function tog_center2() {
    if (modal == false) {
      setModal(true)
    } else {
      setModal(false)
    }
  }
  function tog_center() {
    if (modal_center == false) {
        setmodal_center(true)
    } else {
        setmodal_center(false)
    }
}


  const newcreatedprojectDocuments = userId && _.filter(ownerDocuments, { user_id: userId })
  const b = ownerdoc && ownerdoc.filter(function (o1) {
    return !newcreatedprojectDocuments.some(function (o2) {
      return o1.id === o2.document_id;
    })
  })
  console.log("project owner document", newcreatedprojectDocuments)
  const newdocs = () => {
    newcreatedprojectDocuments.map((item, index) => {
      for (let i = 0 + 1; i < newcreatedprojectDocuments.length; i++) {
        let temp = { document: "", document_id: "", user_id: "" }
        setSaveData([...saveData, temp])
        console.log("save data links", saveData)
        return saveData
      }
    })
  }

  const handleActualChange = (id, e, index) => {
    console.log("event", e.target.name)
    setImage(e.target.files[0])
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    let temp = [...saveData]
    console.log("saveData", saveData)
    console.log("temp empty", temp)
    newdocs()
    console.log("saveData", saveData)
    reader.onloadend = function () {
      console.log("reader", index)
      console.log("temp index", temp[index])
      temp[index][e.target.name] = reader.result
      temp[index]["document_id"] = id
      temp[index]["user_id"] = userId
      setSaveData(temp)

    }

    // let temp = [ ...saveData ]
    console.log("save data", saveData)
    console.log("temp", temp)
  }
  const handleDelete=(childData)=>{
    console.log("childData",childData)
    var index = newcreatedprojectDocuments.findIndex(function(o){
      return o.id === childData
 })
    newcreatedprojectDocuments.splice(index,1)
    return newcreatedprojectDocuments

    console.log("newcreatedprojectDocuments minus one",newcreatedprojectDocuments)
    
  }

  useEffect(() => {
    dispatch(fetchdocumentstypes())
    dispatch(fetchprojectOwnerAction(token))
  }, [])

  return (
    <div>
      {b.length != 0 && (
        <Card className="p-2" >
          <Formik
            initialValues={{
              "document": "",
              "document_id": "",
              "user_id": "",

            }}
            onSubmit={(values, { resetForm }) => {
              // PostProjectOwnerDocument()
              console.log("data to submit", saveData)
              resetForm({ values: "" })
            }} >
            {({ values, isSubmitting, errors, handleSubmit, handleChange, dirty, isValid }) => (
              <Form>
                <CardHeader className='position-relative'>
                  <h5 className="card-title mb-0">Upload Documents below</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    {b.map((projectdoc, index) => (
                      <Col md={4} lg={4} sm={12} xs={12} key={index}>
                        {() => testArr.push({ document: {}, document_id: "", user_id: "" })}

                        <div className=" mb-3">
                          <Label className="ValidationInput text-capitalize fs-15 " for="validationFormCheck3">{projectdoc.name}</Label>
                          <Input type="file"
                            className="form-control"
                            id="name"
                            name="document"
                            placeholder='Enter document '
                            values={image}
                            onChange={(e) => {
                              setImage(e.target.files[0])
                              console.log("index", index)
                              handleActualChange(projectdoc.id, e, index)
                            }}
                            required />

                          <div className="invalid-feedback">upload file</div>
                        </div>

                      </Col>
                    )

                    )}

                  </Row>
                  <CardFooter >
                    {isLoading === false && (
                      <div className="text-right position-absolute  end-0 m-2">
                        <div className='position-relative'>

                          <button className="btn btn-success m-1" type="button" onClick={(e) => {
                            console.log("save data", saveData)
                            dispatch(createOwnerDocumentAction(saveData, token))
                          }}>Submit form</button>
                        </div>

                      </div>
                    )}
                    {isLoading === true && (
                      <div className="text-right position-absolute  end-0 m-2">
                        <div className="m-1 position-relative">
                          <Button color="primary" className="btn-load" outline disabled>
                            <span className="d-flex align-items-center">
                              <span className="flex-grow-1 me-2">
                                Loading...
                              </span>
                              <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                            </span>
                          </Button>
                        </div>
                      </div>
                    )}
                  </CardFooter>
                </CardBody>

              </Form>
            )}
          </Formik>
        </Card>
      )}

      <div class="table-wrapper m-4 ">
        <Row >

          {newcreatedprojectDocuments &&
            newcreatedprojectDocuments.map((projectdoc, index) => (
              <Col xs={6} sm={4} xl={3} >

                <div className="investment-table-row   m-1 border" key={index}>
                  {projectdoc.documents.type === "pdf" && (
                    <div><AiOutlineFilePdf style={{ height: "85%", width: "85%", color: "red" }} /></div>
                  )}
                  {projectdoc.documents.type === "jpg" && (
                    <div><AiOutlineFileJpg style={{ height: "100px", width: "100px", color: "" }} /></div>
                  )}
                  {projectdoc.documents.type === "jpeg" && (
                    <div><img src={myimg} alt="jpeg" style={{ height: "85%", width: "85%" }} /></div>
                  )}
                  {projectdoc.documents.type === "png" && (
                    <div><img src={pngimg} alt="jpeg" style={{ height: "45%", width: "45%" }} /></div>
                  )}
                  {projectdoc.documents.type === "avif" && (
                    <div><img src={avif} alt="avif" style={{ height: "45%", width: "45%" }} /></div>
                  )}
                  {projectdoc.documents.type === "webp" && (
                    <div><img src={webpimg} alt="webp" style={{ height: "45%", width: "45%" }} /></div>
                  )}
                  <div className='d-flex position-relative m-4' >
                    <p class="position-absolute start-0 text-muted">{projectdoc.documents.name}</p>
                    <Dropdown className="position-absolute end-0 " isOpen={dropdownOpen} toggle={toggle}>
                      <a href="#" caret><AiOutlineMore /></a>
                      <DropdownMenu>
                        <DropdownItem onClick={(e) => {
                          tog_center2(e)
                          setDoc(projectdoc.id)

                        }}><i className="ri-eye-fill me-2 align-middle text-muted" />View</DropdownItem>
                        { }
                        <Modal
                          isOpen={modal}
                          toggle={() => {
                            tog_center2();
                          }}
                          centered
                        >{
                            projectdoc.documents.type === "pdf" ?
                              <ViewSingle handleClose={tog_center2} documentId={doc} /> :
                              <ViewDocument handleClose={tog_center2} documentId={doc} />
                          }

                        </Modal>
                        {/* delete */}
                        <DropdownItem className='remove-item-btn' onClick={() =>{
                          tog_center()
                          setDoc(projectdoc.id)
                        }}
                        >
                          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
                        <Modal
                          isOpen={modal_center}
                          toggle={() => {
                            tog_center();
                          }}
                          centered
                        >
                          <DeleteProjectOwner handleClose={tog_center} documentId={doc} handleDelete={handleDelete} />
                        </Modal>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>

              </Col>
            ))}
        </Row>

      </div>
    </div>
  )
}
