import { requests } from "../../../services/Api";
import { FETCH_SUMMARY_FAILURE, FETCH_SUMMARY_REQUEST, FETCH_SUMMARY_SUCCESS } from "./summaryTypes";

export const fetchSummary = () => async (dispatch) => {
    try {
        dispatch({ 
            type: FETCH_SUMMARY_REQUEST 
        });
        const response = await requests.get("/summary")
        dispatch({ 
            type: FETCH_SUMMARY_SUCCESS, 
            payload: response.data
        });
    } catch (error) {
        dispatch({ 
            type: FETCH_SUMMARY_FAILURE, 
            payload: error 
        });
    }
}