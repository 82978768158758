import React from 'react'
import Banner from './banner'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image from "../../assets/images/NoProjects.png"
import { Link } from 'react-router-dom';

function NoProjects() {
  return (
    <div>
       
        <Card sx={{ maxWidth: 345,marginX:"auto" ,marginY:"9%"}}>
      <CardMedia
        component="img"
        height="140"
        image={image}
        alt="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          No Companies created
        </Typography>
        {/* <AddCompany/> */}
        <Link to="/onboard-company"><Button variant="contained" 
        >Add Company</Button></Link>
      </CardContent>
    
    </Card>
    </div>
  )
}

export default NoProjects