import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import classnames from 'classnames';
import AllSectors from './AllSectors';
import TopNav from '../../layout/topnav';
import Footer from '../../layout/footer';
import AddSector from './CreateSectors';
// import AllSectors from './AllSectors'


export default function Settings(){
SwiperCore.use([Autoplay]);
    const [activeTab, setActiveTab] = useState('1');
    const [activityTab, setActivityTab] = useState('1')
const toggleTab = (tab) => {
    if (activeTab !== tab) {
        setActiveTab(tab);
    }
};


  return (
    <div>
        <TopNav/>
       <div class="dashboard section__space__bottom">
        <div class="container">
            <div class="dashboard__area">
                <div class="row">
                   
                    <div class="col-xxl-12">
                        <div class="main__content">
                            <div class="collapse__sidebar">
                                <h4>Settings</h4>
                                <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                    <i class="fa-solid fa-bars-staggered"></i>
                                </a>
                            </div>
                            <div class="main__content-dashboard">
                                <div class="breadcrumb-dashboard">
                                    <h5>Settings</h5>
                                    <div>
                                        <Link to={`/settimgs`}> Settings</Link>
                                        <i class="fa-solid fa-arrow-right-long"></i>
                                        <Link to={`/settings`}>Set Up below</Link>
                                    </div>
                                </div>
                                <div class="account-info">
                                <div className="d-flex">
                                    <Nav pills className=" account-info__btn-wrapper"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                href="#overview-tab"
                                                className={classnames({ active: activeTab === '1' }) }
                                                onClick={() => { toggleTab('1'); }}
                                            >
                                                <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Sectors</span>
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink
                                                href="#directors"
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => { toggleTab('2'); }}
                                            >
                                                <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block text-capitalize">Company Directors</span>
                                            </NavLink>
                                        </NavItem> */}
                                        {/* <NavItem>
                                            <NavLink
                                                href="#executives"
                                                className={classnames({ active: activeTab === '3' })}
                                                onClick={() => { toggleTab('3'); }}
                                            >
                                                <i className="ri-price-tag-line d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Company Executives</span>
                                            </NavLink>
                                        </NavItem> */}
                                        {/* <NavItem>
                                            <NavLink
                                                href="#documents"
                                                className={classnames({ active: activeTab === '4' })}
                                                onClick={() => { toggleTab('4'); }}
                                            >
                                                <i className="ri-folder-4-line d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block"> Company Members</span> 
                                            </NavLink>
                                        </NavItem> */}
                                    </Nav>
                                  
                                </div>
 
                                    <div class="account-content_wrapper">
                                    <TabContent activeTab={activeTab} className="pt-4 text-muted">
                                    <TabPane tabId="1">
                                    <AllSectors/>
                                    </TabPane>
                                   
                                    </TabContent>
{/* 
                                        <div class="account-content" id="general">
                                           <CreateCompany/>
                                        </div>
                                        <div class="account-content" id="billing">
                                            <CreateDirectors/>
                                        </div>
                                        <div class="account-content" id="security">
                                          <CreateExcecutives/>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </div>
  )
}
