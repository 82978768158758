import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getUser, getUserId, removeUserSession } from "../../services/UseTokens";
import Logo from "./images/logo.png";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/actions/auth/logoutUser";
import Avatar from "react-avatar";

function TopNav(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = getUserId();
  const user = getUser();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const logout = () => {
    removeUserSession()
    dispatch(logoutUser(navigate));
  };
  return (
    <div>
      <header class="header position-fixed">
        <nav class="navbar navbar-expand-xl">
          <div class="container">
            <Link class="navbar-brand" to="/">
              <img src={Logo} alt="Logo" class="logo" />
            </Link>
            <div class="navbar__out order-2 order-xl-3">
              {user != null || user != undefined ? (
                <>
                  <div class="nav__group__btn">
                    {/* <Avatar {...stringAvatar(`${user.first_name} ${user.last_name}`)} /> */}

                    <Dropdown isOpen={dropdownOpen} toggle={toggle} {...props}>
                      <DropdownToggle caret size="lg" color="blue">
                        {`${user.first_name} ${user.last_name}`}
                        <a class="button button--effect d-none d-md-block">
                          {" "}
                          {`${user.first_name} ${user.last_name}`}{" "}
                        </a>
                      </DropdownToggle>
                      <DropdownMenu>
                        {/* <DropdownItem header><p>hello {`${user.first_name} ${user.last_name}`} ...</p></DropdownItem> */}
                        {/* <DropdownItem onClick={() => navigate("/my-projects")}>My Projects</DropdownItem> */}
                        <DropdownItem
                          className="dropdown"
                          onClick={() => navigate("/my-company")}
                        >
                          My Companies
                        </DropdownItem>
                        {/* <DropdownItem>Profile</DropdownItem> */}
                        <DropdownItem
                          className="dropdown"
                          onClick={() => navigate("/my-dashboard")}
                        >
                          My DashBoard
                        </DropdownItem>
                        {/* <DropdownItem onClick={()=>navigate(`/project-owner-documents/${id}`)}>My Documents</DropdownItem> */}

                        <DropdownItem
                          className="dropdown"
                          onClick={() => logout()}
                        >
                          Logout
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </>
              ) : (
                <div class="nav__group__btn">
                  <Link to="/login" class="">
                    {" "}
                    Login{" "}
                  </Link>
                  <Link
                    to="/register"
                    class="button button--effect button--last"
                  >
                    <span style={{ color: "white" }}>
                      Join Now<i class="fa-solid fa-arrow-right-long"></i>
                    </span>
                  </Link>
                </div>
              )}

              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#primaryNav"
                aria-controls="primaryNav"
                aria-expanded="false"
                aria-label="Toggle Primary Nav"
              >
                <span class="icon-bar top-bar"></span>
                <span class="icon-bar middle-bar"></span>
                <span class="icon-bar bottom-bar"></span>
              </button>
            </div>
            <div
              class="collapse navbar-collapse order-3 order-xl-2"
              id="primaryNav"
            >
              <ul class="navbar-nav">
                <li class="nav-item">
                  <Link class="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/projects">
                    Projects
                  </Link>
                </li>
                {user != null || user != undefined ? (
                  <li class="nav-item">
                    <Link class="nav-link" to="/list-your-project">
                      List your project
                    </Link>
                  </li>
                ) : null}
                <li class="nav-item">
                  <Link class="nav-link" to="/learn">
                    Learn
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default TopNav;
