import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import DotsVertical from "mdi-material-ui/DotsVertical";
import MenuUp from "mdi-material-ui/MenuUp";
import React from "react";
const data = [
  {
    progress: 75,
    imgHeight: 20,
    title: "Zipcar",
    color: "primary",
    amount: "$24,895.65",
    subtitle: "Vuejs, React & HTML",
    imgSrc: "/images/cards/logo-zipcar.png",
  },
  {
    progress: 50,
    color: "info",
    imgHeight: 27,
    title: "Bitbank",
    amount: "$8,650.20",
    subtitle: "Sketch, Figma & XD",
    imgSrc: "/images/cards/logo-bitbank.png",
  },
  {
    progress: 20,
    imgHeight: 20,
    title: "Aviato",
    color: "secondary",
    amount: "$1,245.80",
    subtitle: "HTML & Angular",
    imgSrc: "/images/cards/logo-aviato.png",
  },
];
function NewMyInvestmentCard({ project }) {
  return (
    <Card
      style={{
        marginRight: "5px !important",
        height: "170px",
        padding: "15px",
        marginTop: "5px !important",
        minWidth: { sx: "100%" },
      }}
    >
      <CardHeader
        subheader={
          project?.financing?.finance_method === "revenue_share"
            ? "Revenue Share"
            : project?.financing?.finance_method === "equity"
            ? "Equity"
            : "Debt"
        }
        titleTypographyProps={{
          sx: {
            lineHeight: "1.6 !important",
            letterSpacing: "0.15px !important",
            color: "#5927e3 !important",
            fontSize: "20px !important",
          },
        }}
      />
      <CardContent sx={{ pt: (theme) => `${theme.spacing(1.25)} !important` }}>
        <Tooltip title="Current Investment  Value " placement="top-start">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: 600, fontSize: "1.4rem !important" }}
            >
              {project?.financing?.finance_method === "revenue_share"
                ? project?.financing?.revenue_repayment_cap
                : project?.financing?.finance_method === "debt"
                ? project?.financing?.debt_interest
                : project?.financing?.project_share_value}
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "success.main",
              }}
            ></Box>
          </Box>
        </Tooltip>
      </CardContent>
    </Card>
  );
}

export default NewMyInvestmentCard;
