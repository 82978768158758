import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Badge, Button } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import image from "../../assets/images/NoProjects.png"

const columns = [
  // { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'project_name',
    headerName: 'Project Name',
    width: 150,
    editable: true,
  },
  {
    field: 'location',
    headerName: 'Project Location',
    width: 150,
    editable: true,
  },
  {
    field: 'closing_date',
    headerName: 'Deadline',
    type: 'number',
    width: 150,
    editable: true,
  },
  {

    field: 'status',
    headerName: 'Status',

    width: 80,
    renderCell: (params) => {
      return (
        <>

          <Badge badgeContent={params.row.status} color={params.row.status === "active" ? "success" :
            params.row.status === "pending" ? "info" : "secondary"} style={{ margin: "15px" }}></Badge>
        </>
      )
    }
  },
  {

    field: 'Actions',
    headerName: 'Actions',

    width: 160,
    renderCell: (params) => {
      return (
        <>
          {console.log("params id", params.row.id)}
          <Link to={`/project-profile/${params.row.id}`}>
            <Button variant="outlined" size="small" style={{ color: "#5927e3" }} startIcon={<RemoveRedEyeOutlinedIcon />}>
              view
            </Button>
          </Link>
        </>
      )
    }
  },
];

export default function CompanyProjects({ projects }) {
  const navigate=useNavigate()
  const { id } = useParams();
  return (
    <>
    {
      projects.length > 0 ? (
        <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={projects}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
      ): (
        <Card sx={{ maxWidth: 345, marginX: "auto", marginY: "9%" }}>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            You have no projects yet
          </Typography>
          <Button variant="contained" onClick={() => navigate(`/onboard-project/${id}`)}
          >Create a project</Button>
        </CardContent>

      </Card>
      )
    }
      
    </>
  );
}
