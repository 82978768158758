import React, { useState } from 'react'
import { sellShares } from '../../redux/actions/sell-shares/SellShares.actions'
import SideBar from '../configurables/SideBar'
import Graph from './Graph'
import InvestmentTable from './InvestmentTable'
import SellShares from './SellShares'
import Statistics from './Statistics'

export default function MyInvestment() {
    const [mystate,setMyState]=useState(false)
    const handleState=(childData)=>{
        setMyState(childData)
    }
    
  return (
    <div> <div class="dashboard section__space__bottom">
    <div class="container">
        <div class="dashboard__area">
            <div class="row">
                <div class="col-xxl-3">
                    <SideBar/>
                </div>
                <div class="col-xxl-9">
                    <div class="main__content">
                        <div class="collapse__sidebar">
                            <h4>Dashboard</h4>
                            <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                <i class="fa-solid fa-bars-staggered"></i>
                            </a>
                        </div>
                        <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5>Investments</h5>
                                <div>
                                    <a href="index.html">Home</a>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                    <a href="javascript:void(0)">Investments</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-8">
                                   <InvestmentTable handleState={handleState}/>
                                </div>
                                {!mystate &&(
                                     <div class="col-lg-4">
                                     <Statistics/>
                                 </div>
                                )}
                                {mystate && (
                                    <div class="col-lg-4">
                                        <SellShares/>
                                    </div>
                                )}
                               
                            </div>
                            {/* <Graph/> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div></div>
  )
}
