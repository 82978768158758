import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import image from "../../assets/table.jpg";
import ProjectDetails from "./ProjectDetails";
import ProjectTab from "./ProjectTab";
import FinancialDetails from "./Financial";
import ActivityLog from "./Activity";
import { fetchProject } from "../../redux/actions/project-details/fetchProject";
import { useParams } from "react-router-dom";
import { fetchProjectMilestones } from "../../redux/actions/milestones/projectMilestone";
import { getToken } from "../../services/UseTokens";
import { Card, CardHeader, Fab, Modal } from "@mui/material";
import { AddCircleOutlineRounded } from "@mui/icons-material";
import Invest from "./investment/Index";
import ExistInvestProject from "./investment/ExistingInvestor";
import ProjectTab2 from "./ProjectTab2";
import Main from "./mainTab";
import { requests } from "../../services/Api";

const style = {
  margin: 0,
  top: { lg: "auto", xs: 50 },
  right: 70,
  bottom: { lg: 50, xs: "auto" },
  left: "auto",
  position: "fixed",
  zIndex: 1000,
  borderRadius: "25px",
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ProjectProfile() {
  const [open, setOpen] = React.useState(false);
  const token = getToken();
  const dispatch = useDispatch();
  const { project_id } = useParams();
  const project = useSelector((state) => state.single);
  // const fetchedmilestones = useSelector((state) => state.projectMilestones);
  // const { loading, milestones, error } = fetchedmilestones;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [activities, setActivities] = React.useState([]);
  const projectLogs = async () => {
    await requests
      .get(`/activity-logs/project-activity-logs/${project_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setActivities(response.data);
      })
      .catch((error) => {
        console.log("error-log", error);
      });
  };

  useEffect(() => {
    projectLogs();
    dispatch(fetchProject(project_id));
    // dispatch(fetchProjectMilestones(project_id, token))
  }, []);
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), #5e5ee2),url(${image}) !important`,
      }}
    >
      <Box
        sx={{
          marginTop: "10%",
          marginX: { lg: "3%", xs: "15px" },
          minWidth: { sx: "100% !important" },
          paddingBottom: "10%",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Item style={{ margin: "15px" }}>
              <ProjectDetails project={project?.project} />
            </Item>
            <Item style={{ margin: "15px" }}>
              <FinancialDetails
                project={project?.project}
                financing={project?.project?.financing}
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={8} xl={8} spacing={2}>
            <Item style={{ margin: "15px" }}>
              <ProjectTab2
                project={project?.project}
                milestones={project?.project_milestones}
                financing={project?.project?.financing}
              />
            </Item>

            <Item style={{ margin: "15px" }}>
              <ActivityLog activities={activities} />
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Box style={style}>
        <Fab
          onClick={handleOpen}
          style={{ backgroundColor: "#5927e3", color: "#fff", flex: 1 }}
          variant="extended"
        >
          <AddCircleOutlineRounded sx={{ mr: 1 }} />
          Invest
        </Fab>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Card style={{ padding: "15px" }}>
              {/* <Invest/> */}
              <CardHeader title="Invest" />
              <ExistInvestProject />
            </Card>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}
