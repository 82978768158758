import Swal from "sweetalert2";
import { requests } from "../../../services/Api";
import { getToken } from "../../../services/UseTokens";
import  {UPDATE_PROJECT_REQUEST,UPDATE_PROJECT_SUCCESS,UPDATE_PROJECT_FAILURE} from "./projectTypes";

const updateProjectsAction = (id,project) => async(dispatch) => {
    const token=getToken()
    try {
        dispatch({type:UPDATE_PROJECT_REQUEST});
        const response=await requests.put(`projects/update-project/${id}`,
        project,
        {headers:{Authorization:`Bearer ${token}`}
    });
        dispatch({type:UPDATE_PROJECT_SUCCESS,payload:response.data});
        Swal.fire({
            title: 'Success',
            text: 'Project updated successfully',
            icon: 'success',
            confirmButtonText: 'Ok'
        })
        
    } catch (error) {
        dispatch({type:UPDATE_PROJECT_FAILURE,payload:error.response.data.message})
        Swal.fire({
            icon: 'error',  
            title: 'Oops...',
            text: error.response.data.message,
            confirmButtonAriaLabel:"ok",
            confirmButtonColor:"#00a8ff"
        })
        
    }
}

export default updateProjectsAction;