import React, { useLayoutEffect } from 'react'
import AmountInvested from './Amount Invested'
import Faq from './Faq'
import Hero from './Hero'
import Process from './Process'

export default function ListofProjects() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
   <div>
    {/* <Hero/> */}
    {/* <AmountInvested/> */}
    {/* <Process/> */}
    <Faq/>
   </div>
  )
}
