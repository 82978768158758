import React from 'react'

export default function DashBoardTopNav({ toggleSidebar }) {
    return (
        <div class="collapse__sidebar">
            <h4>Dashboard</h4>
            <a href="javascript:void(0)" onClick={() => toggleSidebar()}>
                <i class="fa-solid fa-bars-staggered"
                ></i>
            </a>
        </div>
    )
}
