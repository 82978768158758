import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import GeneralAnalytics from "./GeneralAnalytics";
import FinanceAnalytics from "./FinancialCard";
import { requests } from "../../../services/Api";
import { useParams } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Analytics({ financing }) {
  const [analytics, setAnalytics] = React.useState([]);
  const { project_id } = useParams();
  const fetchData = async () => {
    await requests
      .get(`projects/analytics/${project_id}`)
      .then((response) => {
        setAnalytics(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <GeneralAnalytics analytics={analytics} />
        </Grid>
        <Grid item xs={12}>
          <FinanceAnalytics financing={financing} analytics={analytics} />
        </Grid>
      </Grid>
    </Box>
  );
}
