import { useEffect, useState } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import "./style.css";
// ** Icons Imports
import TrendingUp from "mdi-material-ui/TrendingUp";
import CurrencyUsd from "mdi-material-ui/CurrencyUsd";
import DotsVertical from "mdi-material-ui/DotsVertical";
import CellphoneLink from "mdi-material-ui/CellphoneLink";
import AccountOutline from "mdi-material-ui/AccountOutline";
import Doughnut from "./Donught";
import DoughnutChart from "./Donught";
import { green } from "@mui/material/colors";
import { requests } from "../../../services/Api";

const salesData = [
  {
    stats: "24k",
    title: "Raised Amount",
    color: "primary",
    icon: <TrendingUp sx={{ fontSize: "1.75rem" }} />,
  },
  {
    stats: "10 ",
    title: "Investors",
    color: "success",
    icon: <AccountOutline sx={{ fontSize: "1.75rem" }} />,
  },

  {
    stats: "$88k",
    color: "secondary",
    title: "Goal Amount",
    icon: <CurrencyUsd sx={{ fontSize: "1.75rem" }} />,
  },
];

const renderStats = () => {
  return salesData.map((item, index) => (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} key={index}>
      <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          variant="rounded"
          sx={{
            mr: 3,
            width: 44,
            height: 44,
            boxShadow: 3,
            color: "common.white",
            backgroundColor: `${item.color}.main`,
          }}
        >
          {item.icon}
        </Avatar>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption" style={{ fontSize: "16px" }}>
            {item.title}
          </Typography>
          <Typography variant="h6">{item.stats}</Typography>
        </Box>
      </Box>
    </Grid>
  ));
};

const GeneralAnalytics = ({ analytics }) => {
  return (
    <Card>
      <CardHeader
        // title='Statistics Card'

        subheader={
          <Typography variant="body2">
            <Grid container spacing={[1, 0]}>
              <Grid item xs={12} style={{ fontSize: "16px" }}>
                <Box
                  component="span"
                  sx={{
                    fontWeight: 600,
                    fontSize: "24px",
                    color: "text.primary",
                  }}
                >
                  Total growth
                  <span
                    style={{ fontSize: "24px", color: green[350] }}
                  >{`${analytics?.total_growth}%`}</span>
                </Box>
                😎 this month
              </Grid>
            </Grid>
          </Typography>
        }
        titleTypographyProps={{
          sx: {
            mb: 2.5,
            lineHeight: "2rem !important",
            letterSpacing: "0.15px !important",
          },
        }}
      />
      <CardContent sx={{ pt: (theme) => `${theme.spacing(1)} !important` }}>
        <Grid container spacing={[2, 0]}>
          {/* {renderStats()} */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="rounded"
                sx={{
                  mr: 3,
                  width: 44,
                  height: 44,
                  boxShadow: 3,
                  color: "common.white",
                  backgroundColor: `primary.main`,
                }}
              >
                <TrendingUp sx={{ fontSize: "1.75rem" }} />
              </Avatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption" style={{ fontSize: "16px" }}>
                  Raised Amount
                </Typography>
                <Typography variant="h6">{analytics?.total_amount_invested}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="rounded"
                sx={{
                  mr: 3,
                  width: 44,
                  height: 44,
                  boxShadow: 3,
                  color: "common.white",
                  backgroundColor: `success.main`,
                }}
              >
                <AccountOutline sx={{ fontSize: "1.75rem" }} />
              </Avatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption" style={{ fontSize: "16px" }}>
                  Investors
                </Typography>
                <Typography variant="h6">{analytics?.total_investors}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="rounded"
                sx={{
                  mr: 3,
                  width: 44,
                  height: 44,
                  boxShadow: 3,
                  color: "common.white",
                  backgroundColor: `secondary.main`,
                }}
              >
                <CurrencyUsd sx={{ fontSize: "1.75rem" }} />
              </Avatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption" style={{ fontSize: "16px" }}>
                  Goal Amount
                </Typography>
                <Typography variant="h6">{analytics?.goal_amount}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GeneralAnalytics;
