import Swal from "sweetalert2";
import { requests } from "../../../services/Api";
import { getToken, getUserId } from "../../../services/UseTokens";
import { UPDATE_PROFILE_SUCCESS,UPDATE_PROFILE_REQUEST,UPDATE_PROFILE_FAILURE } from "./updateProfileType";


const updateProfileAction = (data) => async (dispatch) =>   {
    const token=getToken();
    const userId=getUserId()
 try {
    dispatch({
        type: UPDATE_PROFILE_REQUEST
    })

    const response = await requests.put(`profile/update-profile/${userId}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }).then((response) => {
        dispatch({ 
            type: UPDATE_PROFILE_SUCCESS,
            payload: response.data 
        });
        Swal.fire(
            'Good job!',
            'Profile Updated Successfully',
            'success'
        )
    })

 } catch (error) {
    dispatch({ 
        type: UPDATE_PROFILE_FAILURE,
        payload: error
    });
    Swal.fire(
        'fail',
        'error occured',
      
    )
 }
}
export default updateProfileAction;