import React from 'react'
import { Link } from 'react-router-dom'
import InvestorDocumentTable from './InvestorDocumentTable'
import Statistics from './Statistics'

export default function InvestorDocument() {
  return (
    <div>
    <div class="main__content-dashboard">
                       <div class="breadcrumb-dashboard">
                           <h5>Investments</h5>
                           <div>
                               <Link to="/">Home</Link>
                               <i class="fa-solid fa-arrow-right-long"></i>
                               <Link to="#">Investmentor Documents</Link>
                           </div>
                       </div>
                       <div class="row p-4">
                           <div class="col-lg-8">
                              <InvestorDocumentTable/>
                           </div>
                           <div class="col-lg-4">
                               <Statistics/>
                           </div>
                       </div>
                       {/* <Graph/> */}
                   </div>
</div>
  )
}
