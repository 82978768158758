import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { imageurl } from "../../../services/Api";
import ProjectFilter from "../filter";
import _ from "lodash";
import NoProjectYet from "./NoProjectsYet";

function Projects({ allProjects }) {
  //get minutes to project end date
  const minutesToEnd = (closing) => {
    const today = moment();
    const end = moment(closing);
    const diff = end.diff(today, "minutes");
    return diff;
  };
  // return the projects that the due date has not passed
  const projects = allProjects
    .filter((project) => minutesToEnd(project.closing_date) > 0)
    .slice(0, 6);
  const [searchResult, setSearchResult] = useState([]);

  const getSearchResult = (childdata) => {
    var searchresult = _.filter(allProjects, function (p) {
      return _.includes(
        [childdata.toLowerCase()],
        p.project_name.toLowerCase()
      );
    });
    console.log("search Value", searchresult);
    setSearchResult(searchresult);
  };

  // using a parent component to pass data to child component filter by sector
  const filterBySector = (sector) => {
    console.log("filter by sector", sector);
    let result = _.filter(allProjects, function (p) {
      return _.includes([sector], p.sector_id);
    });
    console.log("search Value", result);
    setSearchResult(result);
  };

  console.log("allprojects", allProjects);

  useEffect(() => {}, []);

  return (
    <div>
      <section class="properties__grid section__space">
        <ProjectFilter
          allProjects={allProjects}
          getSearchResult={getSearchResult}
          filterBySector={filterBySector}
        />
        <div class="container">
          <div class="properties__grid__area wow fadeInUp">
            <div class="title__with__cta">
              <div class="row d-flex align-items-center">
                <div class="col-lg-8 col-xxl-8 mt-4">
                  <h2> Projects</h2>
                </div>
                <div class="">
                  <div class="text-start text-lg-end">
                    <Link
                      to="/projects"
                      class="button button--secondary button--effect"
                    >
                      Browse All Projects
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {projects && projects.length > 0 ? (
              <div class="property__grid__wrapper">
                {/* <NoProjectYet/> */}
                {searchResult.length === 0 && (
                  <div class="row">
                    {projects.length > 0 &&
                      projects.map((project, index) => (
                        <div
                          class="col-lg-4 col-xs-12 col-xl-3 col-xxl-12 col-md-6 col-sm-12"
                          key={index}
                        >
                          <div class="property__grid__single ">
                            <div class="img__effect">
                              <Link to={`/project-profile/${project.id}`}>
                                <img
                                  src={`${imageurl}${project?.project_header_image}`}
                                  alt="Property"
                                />
                              </Link>
                            </div>
                            <div class="property__grid__single__inner">
                              <h4>{project?.project_name}</h4>
                              <p class="sub__info">
                                <i class="fa-solid fa-location-dot"></i>
                                {project?.location}
                              </p>
                              <div class="progress__type">
                                <div class="progress">
                                  <div
                                    class="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{
                                      width: `${project?.percentage_completed}%`,
                                    }}
                                  ></div>
                                </div>
                                {/* <p class="project__has">
                                                 <span class="project__has__investors">{`${project?.no_of_investors} Investors`}</span> |
                                                 <span class="project__has__investors__amount">{`SHARES ${parseInt(project?.financing?.project_share_volume - project?.financing?.project_available_shares)}`}</span> <span
                                                     class="project__has__investors__percent">{`(${parseInt(((project?.financing?.project_share_volume - project?.financing.project_available_shares)/project?.financing?.project_share_volume)*100)})%`}</span>
                                             </p> */}
                              </div>
                              <div class="item__info">
                                <div class="item__info__single">
                                  <p>Max Investment</p>
                                  <h6>
                                    {project?.financing?.maximum_investment}
                                  </h6>
                                </div>
                                <div class="item__info__single">
                                  <p>Min Investment</p>
                                  <h6>
                                    {project?.financing?.minimum_investment}
                                  </h6>
                                </div>
                              </div>
                              <div class="invest__cta__wrapper">
                                <div class="countdown__wrapper">
                                  <p class="secondary">
                                    <i class="fa-solid fa-clock"></i> Left to
                                    invest
                                  </p>
                                  <div class="countdown">
                                    <h5>
                                      <span class="days">
                                        {parseInt(
                                          minutesToEnd(project?.closing_date) /
                                            (60 * 24)
                                        )}
                                      </span>
                                      <span class="ref">d</span>
                                      <span class="seperator">:</span>
                                    </h5>
                                    <h5>
                                      <span class="hours">
                                        {parseInt(
                                          parseInt(
                                            minutesToEnd(
                                              project?.closing_date
                                            ) %
                                              (60 * 24)
                                          ) / 60
                                        )}
                                      </span>
                                      <span class="ref">h</span>
                                      <span class="seperator">:</span>
                                    </h5>
                                    <h5>
                                      <span class="minutes">
                                        {parseInt(
                                          parseInt(
                                            parseInt(
                                              minutesToEnd(
                                                project?.closing_date
                                              ) %
                                                (60 * 24)
                                            ) % 60
                                          )
                                        )}
                                      </span>
                                      <span class="ref">m</span>
                                      <span class="seperator"></span>
                                    </h5>
                                  </div>
                                </div>
                                <div class="invest__cta">
                                  <Link
                                    to={`/project-profile/${project.id}`}
                                    class="button button--effect"
                                    style={{ color: "white" }}
                                  >
                                    Invest Now
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
                {searchResult.length > 0 && (
                  <div class="row">
                    {searchResult.map((project, index) => (
                      <div
                        class="col-lg-4 col-xs-12 col-xl-3 col-xxl-12 col-md-6 col-sm-12"
                        key={index}
                      >
                        <div class="property__grid__single ">
                          <div class="img__effect">
                            <Link to={`/projects/${project.id}`}>
                              <img
                                src={`${imageurl}${project?.project_header_image}`}
                                alt="Property"
                              />
                            </Link>
                          </div>
                          <div class="property__grid__single__inner">
                            <h4>{project?.project_name}</h4>
                            <p class="sub__info">
                              <i class="fa-solid fa-location-dot"></i>
                              {project?.location}
                            </p>
                            <div class="progress__type">
                              <div class="progress">
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{
                                    width: `${project?.percentage_completed}%`,
                                  }}
                                ></div>
                              </div>
                              {/* <p class="project__has">
                                                  <span class="project__has__investors">{`${project?.no_of_investors} Investors`}</span> |
                                                  <span class="project__has__investors__amount">{`SHARES ${parseInt(project?.financing?.project_share_volume - project?.financing?.project_available_shares)}`}</span> <span
                                                      class="project__has__investors__percent">{`(${parseInt(((project?.financing?.project_share_volume - project?.financing.project_available_shares)/project?.financing?.project_share_volume)*100)})%`}</span>
                                              </p> */}
                            </div>
                            <div class="item__info">
                              <div class="item__info__single">
                                <p>Max Investment</p>
                                <h6>
                                  {project?.financing?.maximum_investment}
                                </h6>
                              </div>
                              <div class="item__info__single">
                                <p>Min Investment</p>
                                <h6>
                                  {project?.financing?.minimum_investment}
                                </h6>
                              </div>
                            </div>
                            <div class="invest__cta__wrapper">
                              <div class="countdown__wrapper">
                                <p class="secondary">
                                  <i class="fa-solid fa-clock"></i> Left to
                                  invest
                                </p>
                                <div class="countdown">
                                  <h5>
                                    <span class="days">
                                      {parseInt(
                                        minutesToEnd(project?.closing_date) /
                                          (60 * 24)
                                      )}
                                    </span>
                                    <span class="ref">d</span>
                                    <span class="seperator">:</span>
                                  </h5>
                                  <h5>
                                    <span class="hours">
                                      {parseInt(
                                        parseInt(
                                          minutesToEnd(project?.closing_date) %
                                            (60 * 24)
                                        ) / 60
                                      )}
                                    </span>
                                    <span class="ref">h</span>
                                    <span class="seperator">:</span>
                                  </h5>
                                  <h5>
                                    <span class="minutes">
                                      {parseInt(
                                        parseInt(
                                          parseInt(
                                            minutesToEnd(
                                              project?.closing_date
                                            ) %
                                              (60 * 24)
                                          ) % 60
                                        )
                                      )}
                                    </span>
                                    <span class="ref">m</span>
                                    <span class="seperator"></span>
                                  </h5>
                                </div>
                              </div>
                              <div class="invest__cta">
                                <Link
                                  to={`/project-profile/${project.id}`}
                                  class="button button--effect"
                                  style={{ color: "white" }}
                                >
                                  Invest Now
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <NoProjectYet />
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Projects;
