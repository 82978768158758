import React, { useEffect, useLayoutEffect, useState } from 'react'
import OnBoardProject from '.';
import OnBoardMobile from './OnboardProjectMobile'

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export default function OnboardProjectCombine() {
  const [windowWidth, setWindowWidth] = useState(0);
    const [ismobile,setIsMobile]=useState(window.innerWidth)

    const checkmobile=()=>{
      setWindowWidth(window.innerWidth)
      
    }
    // useLayoutEffect(() => {
    //  checkmobile()
    // }, [ismobile])
    useEffect(() => {
      checkmobile()
      // setTimeout(function(){ checkmobile() }, 5000);
      // const debouncedResizeHandler=debounce(()=>{
      //   setWindowWidth([window.innerWidth])
      //   console.log("window project",windowWidth)
      // },100)
      // window.addEventListener("resize",debouncedResizeHandler)
      // window.addEventListener("resize",checkmobile)
      // return()=>window.removeEventListener('resize', checkmobile);
      // return()=>window.removeEventListener('resize', debouncedResizeHandler);
      // console.log("windo2",windowWidth)

    }, [windowWidth])
  
    
  return (
    <div style={{}}>
        {/* {windowWidth <=600 &&<OnBoardMobile/>} */}
        {/* {windowWidth >700 &&<OnBoardMobile/>} */}
        <OnBoardProject/>
    </div>
  )
}
