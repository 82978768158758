import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row, Spinner } from 'reactstrap'
import * as Yup from "yup"
import { requests } from '../../services/Api'
import { getToken, getUserId } from '../../services/UseTokens'
import AutoComplete from './Autocomplete'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Swal from 'sweetalert2'
import { fetchcompany } from '../../redux/actions/company/companydetails/fetchcompany'
import { useDispatch, useSelector } from 'react-redux'
import updateCompanyAction from '../../redux/actions/company/companydetails/UpdateCompany'

export default function UpdateCompany() {
    const [company, setCompany] = useState({})
    const [dealType, setDealType] = useState("")
    const [location, setLocation] = useState("")
    const [sectorId, setSectorId] = useState("")
    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState("")
    const [image, setImage] = useState({})
    const [selectedFile, setSelectedFile] = useState(null)
    const [successResponse, setSuccessResponse] = useState("")
    const [serverError, setServerError] = useState("")
    const [address, setAddress] = useState([])
    const userId = getUserId()
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const {id}=useParams()
    console.log("id",id)
    const token = getToken()
    const locations = JSON.parse(localStorage.getItem(`${userId}-company`))
    const companydet=useSelector(state=>state.singleCompany.company)

    console.log("company details",companydet)

    const onFileChange = event => {
        //    setSelectedFile((event)=> event.target.files[0] );
        console.log("selected image", event.target)
    };

    const createCompany = async (values) => {
        console.log("values",values)
        const formData = new FormData();
        formData.append('company_logo', values.company_logo, values.company_logo.name);
        formData.append('company_name', values.company_name||companydet.company_name);
        formData.append('company_nationality', values.company_nationality);
        formData.append('company_description', description);
        formData.append('company_state', values.company_state);
        formData.append('company_city', values.company_city);
        formData.append('company_postal_code', values.company_postal_code);
        formData.append('company_address_1', values.company_address_1);
        formData.append('company_address_2', values.company_address_2);
        formData.append('website_url', values.website_url);
        formData.append('company_phone_number', values.company_phone_number);
        formData.append('company_share_value', values.company_share_value);
        formData.append('company_share_volume', values.company_share_volume);
        formData.append('company_lat', values.company_lat);
        formData.append('company_location', values.location);
        formData.append('user_id', values.user_id);
        formData.append('company_long', values.company_long);

        const Data={
            
            'company_name': values.company_name||companydet.company_name,
            'company_nationality': values.company_nationality,
            'company_description': description,
            'company_state': values.company_state,
            'company_city':values.company_city,
            'website_url': values.website_url,
            'company_address_2': values.company_address_2,
            'company_address_1': values.company_address_1,
            'company_postal_code': values.company_postal_code,
            'company_long': values.company_long,
            'user_id': values.user_id,
            'company_location': values.location,
            'company_lat': values.company_lat,
            'company_share_volume': values.company_share_volume,
            'company_share_value': values.company_share_value,
            'company_phone_number': values.company_phone_number,
            'company_logo': values.company_logo,

        }
        // Data.append('company_logo', values.company_logo, values.company_logo.name)
        dispatch(updateCompanyAction(id, Data))
    }
    const handleMapsData = (childData) => {
        setAddress(childData)
        console.log("add", address.lat)
    }
    const handleLocationsData = (childData) => {
        setLocation(childData)
        console.log("locationn", location)
    }
    useEffect(() => {
     dispatch(fetchcompany(id))
    }, [])
    

    return (
        <div className='mx-4' >

            <Formik
                initialValues={companydet||{
                    "company_name": "",
                    "company_no": "",
                    "system_company_number": "",
                    "company_phone_number": "",
                    "company_lat": "",
                    "company_long": "",
                    "website_url": "",
                    "company_state": "",
                    "company_city": "",
                    "company_postal_code": "",
                    "company_address_2": "",
                    "company_address_1": "",
                    "company_nationality": "",
                    "company_no": "",
                    "location": "",
                    "company_share_value": "",
                    "company_share_volume": "",
                    "company_description": "",
                    "company_logo": {},
                    "net_worth": "",
                    "company_id": "",
                    "user_id": "",

                }}





                onSubmit={(values) => {
                    values["user_id"] = userId
                    values["company_logo"] = selectedFile
                    values["company_lat"] = locations.latitude
                    values["company_long"] = locations.longitude
                    values["location"] = locations.location
                    createCompany(values)
                    console.log("submitted values", values)

                }}
                enableReinitialize>
                {({ values, isSubmitting, errors, handleSubmit, handleChange }) => (
                    <Form >
                        {serverError && (
                            <div style={{ color: "red", fontSize: "25px" }}>
                                {serverError}</div>
                        )}

                        {successResponse && (
                            <div
                                style={{ color: "green", fontSize: "25px", height: "30px", width: "auto", right: "0" }}>
                                {successResponse}
                            </div>

                        )}
                        <Row>
                            <Col lg={8}>
                                <Card className='mb-4'>
                                    <CardHeader className='m-2 text-capitalize card-header'>
                                        <h5 className='text-capitalize'>General company info</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row'>
                                            <div className="mb-4 col-md-4 col-sm-12 ">
                                                <Label className="form-label text-capitalize " htmlFor="project-title-input">company Title</Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    id="company-title-input"
                                                    placeholder="Enter company title"
                                                    onChange={handleChange}
                                                    name="company_name"
                                                    value={values.company_name} />
                                                <div className="text-danger">{errors.company_name}</div>
                                            </div>
                                            <div className="mb-4 col-md-4 col-sm-12 ">
                                                <Label className="form-label text-capitalize " htmlFor="project-title-input">company Number</Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    id="company-title-input"
                                                    placeholder="Enter company number"
                                                    onChange={handleChange}
                                                    name="company_no"
                                                    value={values.company_no} />
                                            </div>
                                            <div className="mb-3 col-md-4 col-sm-12 ">
                                                <Label className="form-label text-capitalize " htmlFor="project-title-input">company nationality</Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    id="company-nationality"
                                                    placeholder="Enter company nationality"
                                                    onChange={handleChange}
                                                    name="company_nationality"
                                                    value={values.company_nationality} />
                                            </div>
                                         

                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className='mb-4'>
                                    <CardHeader>
                                        <h5>company Description</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={companydet?.company_description}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDescription(data)
                                                console.log({ event, editor, data });
                                            }}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                        />
                                    </CardBody>
                                </Card>

                                <div className='mb-4 card'>
                                    <div className=' card-header'>
                                        <h5 className='text-capitalize'>company location info</h5>
                                    </div>
                                    <CardBody>
                                        <div className='row'>
                                            <div className="mb-4 col-md-4 col-sm-12 ">
                                                <Label className="form-label text-capitalize " htmlFor="project-title-input">company state / County</Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    id="company-state"
                                                    placeholder="Enter company state"
                                                    onChange={handleChange}
                                                    name="company_state"
                                                    value={values.company_state} />

                                            </div>
                                            <div className="mb-4 col-md-4 col-sm-12 ">
                                                <Label className="form-label text-capitalize " htmlFor="project-title-input">company city</Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    id="company-tcity"
                                                    placeholder="Enter company city"
                                                    onChange={handleChange}
                                                    name="company_city"
                                                    value={values.company_city} />
                                            </div>
                                            <div className="mb-4 col-md-4 col-sm-12 ">
                                                <Label className="form-label text-capitalize " htmlFor="project-title-input">company postal code</Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    id="company_postal_code"
                                                    placeholder="Enter company postal code"
                                                    onChange={handleChange}
                                                    name="company_postal_code"
                                                    value={values.company_postal_code} />
                                            </div>
                                            <div className="mb-4 col-md-6 col-sm-12 ">
                                                <Label className="form-label text-capitalize " htmlFor="project-title-input">company address 1</Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    id="company_address_1"
                                                    placeholder="Enter address 1"
                                                    onChange={handleChange}
                                                    name="company_address_1"
                                                    value={values.company_address_1} />
                                            </div>
                                            <div className="mb-4 col-md-6 col-sm-12 ">
                                                <Label className="form-label text-capitalize " htmlFor="project-title-input">company address 2</Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    id="company_address_2"
                                                    placeholder="Enter address 2"
                                                    onChange={handleChange}
                                                    name="company_address_2"
                                                    value={values.company_address_2} />
                                            </div>
                                            <AutoComplete addressData={handleMapsData} handleLoc={handleLocationsData} locationdata={companydet.company_location}/>


                                        </div>
                                    </CardBody>
                                </div>
                            </Col>

                            <Col lg={4}>
                                <div className="card" style={{ marginBottom: "82px" }}>
                                    <div className="card-header">
                                        <h5 className="card-title mb-0 text-capitalize">contact info</h5>
                                    </div>
                                    <CardBody>

                                        <div className="m-2">
                                            <Label htmlFor="choices-privacy-status-input" className="form-label text-capitalize">Company website url</Label>
                                            <Input type="text"
                                                className="form-control"
                                                id="website_url"
                                                placeholder="Enter company website url"
                                                onChange={handleChange}
                                                name="website_url"
                                                value={values.website_url} />
                                        </div>
                                        <div className="m-2">
                                            <Label htmlFor="choices-privacy-status-input" className="form-label text capitalize">Company phone number</Label>
                                            <Input type="number"
                                                className="form-control"
                                                id="company_phone_number"
                                                placeholder="Enter company phone number"
                                                onChange={handleChange}
                                                name="company_phone_number"
                                                value={values.comapny_phone_number} />
                                        </div>
                                        <div className="mb-3 col-md-12 col-sm-12 ">
                                            <Label className="form-label text-capitalize " htmlFor="project-title-input">company logo</Label>
                                            <Input type="file"
                                                className="form-control"
                                                placeholder='Enter company logo '
                                                onChange={(e) => {
                                                    setSelectedFile(e.target.files[0])
                                                    console.log("selected file", selectedFile)
                                                }}

                                                required />
                                        </div>
                                    </CardBody>
                                </div>

                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0 text-capitalize">shares info</h5>
                                    </div>
                                    <CardBody>


                                        <div className="mb-3">
                                            <Label htmlFor="choices-categories-input text-capitalize" className="form-label">company share value
                                            </Label>
                                            <input type='number'
                                                className="form-control"
                                                onChange={handleChange}
                                                value={values.company_share_value}
                                                name="company_share_value"

                                            />
                                            <div className='text-danger'>{errors.company_share_value}</div>
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="choices-categories-input text-capitalize" className="form-label">company share volume
                                            </Label>
                                            <input type='number'
                                                className="form-control"
                                                onChange={handleChange}
                                                value={values.company_share_volume}
                                                name="company_share_volume"

                                            />
                                            <div className='text-danger'>{errors.company_share_volume}</div>
                                        </div>
                                    </CardBody>
                                </div>
                                {!loading && (
                                    <div className="text-end m-4">

                                        <button type="submit" className="button button-input w-100">update</button>
                                    </div>
                                )}

                                {
                                    loading && (
                                        <div className="m-4">
                                            <Button color="success" className="btn-load w-100" outline disabled>
                                                <span className="d-flex align-items-center">
                                                    <span className="flex-grow-1 me-2">
                                                        Loading...
                                                    </span>
                                                    <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                                                </span>
                                            </Button>
                                        </div>

                                    )
                                }

                            </Col>
                        </Row>

                    </Form>
                )}
            </Formik>
        </div>
    )
}
