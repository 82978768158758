import { UPDATE_EXECUTIVES_FAILURE, UPDATE_EXECUTIVES_SUCCESS, UPDATE_EXECUTIVES_REQUEST, DELETE_EXECUTIVES_SUCCESS } from "../../actions/company/CompanyExecutives/ExecutivesTypes";

const initialState = {
    loading: false,
    executive: {},
    error: ""
}

export default function updateExecutiveReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_EXECUTIVES_REQUEST:
            return {
                ...state,
                loading: true
            }

            break;
        case UPDATE_EXECUTIVES_SUCCESS:
            return {
                ...state,
                executive:action.payload
            }

            break;
        case UPDATE_EXECUTIVES_REQUEST:
            return {
                ...state,
                error:action.payload
            }

            break;

        default:
            return state;
            break;
    }
}