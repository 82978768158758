import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fetchdocumentstypes from '../../../redux/actions/Documents/documentTypes/fetchdocumentstypes'
import { getToken, getUserId } from '../../../services/UseTokens'
import _ from "lodash"
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, Form, Input, Label, Modal, Row, Spinner } from 'reactstrap'
import { Formik } from 'formik'
import { postprojectdocuments } from '../../../redux/actions/Documents/projectDocuments/Postdocuments'
import { useParams } from 'react-router-dom'
import { AiOutlineFilePdf,AiOutlineFileJpg, AiOutlineMore} from 'react-icons/ai';
import myimg from './image/image/image.png'
import avif from "./image/image/avif.webp"
import jpgimg from "./image/image/jpg.png"
import webpimg from "./image/image/webp.png"
import pngimg from "./image/image/png.png"
import fetchprojectDocumentAction from '../../../redux/actions/Documents/projectDocuments/FetchDocuments'
import DeleteProjectDocument from './DeleteProjectDocument'
import ViewSingle from './ViewSingle'
import ViewDocument from './ViewDocument'

export default function ProjectDocumentTable(props) {
    const [projects,setProjects]=useState([])
    const [saveData, setSaveData] = useState([{ document: "", document_id: "", user_id: "" }])
    const [loading, setLoading] = useState(false)
    const [doc,setDoc]=useState()
    const [successResponse, setSuccessResponse] = useState()
    const [serverError, setServerError] = useState("")
    const [image, setImage] = useState(null)
    const [modal_center, setmodal_center] = useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [modal, setModal] = useState(false)
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const testArr = []
    const userId=getUserId()
    const id=useParams()
    const projectid=id.id
    const token=getToken()
    const dispatch=useDispatch()
    const documents = useSelector(state => state.documenttype.documents);
    const createdprojectDocuments=useSelector(state=>state.allprojectdocuments.documents)
    const isLoading=useSelector(state=>state.allprojectdocuments.loading)
    const response=useSelector(state=>state.projectdocumentss.documents)
    const projectDocuments=_.filter(documents,{isProject:1})

    function tog_center2() {
      if (modal == false) {
        setModal(true)
      } else {
        setModal(false)
      }
    }
    function tog_center() {
      if (modal_center == false) {
          setmodal_center(true)
      } else {
          setmodal_center(false)
      }
  }
     
   
    const newcreatedprojectDocuments=projectid && _.filter(createdprojectDocuments,{project_id:parseInt(projectid)})
    const b = projectDocuments && projectDocuments.filter(function (o1) {
      return !newcreatedprojectDocuments.some(function (o2) {
        return o1.id === o2.document_id;
      })
    })

    const newdocs = () => {
      newcreatedprojectDocuments.map((item, index) => {
        for (let i = 0 + 1; i < newcreatedprojectDocuments.length; i++) {
          let temp = { document: "", document_id: "", user_id: "" }
          setSaveData([...saveData, temp])
          console.log("save data links", saveData)
          return saveData
        }
      })
    }

    const handleActualChange = (id, e, index) => {
      console.log("event", e.target.name)
      setImage(e.target.files[0])
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let temp = [...saveData]
      console.log("saveData", saveData)
      console.log("temp empty", temp)
      newdocs()
      console.log("saveData", saveData)
      reader.onloadend = function () {
        console.log("reader", index)
        console.log("temp index", temp[index])
        temp[index][e.target.name] = reader.result
        temp[index]["document_id"] = id
        temp[index]["project_id"] = projectid
        setSaveData(temp)
  
      }
      // let temp = [ ...saveData ]
      console.log("save data", saveData)
      console.log("temp", temp)
    }
  
   
        
        const handleSubmitbb = (e,saveData,token) => {
          e.preventDefault()
          setLoading(true)
          dispatch(postprojectdocuments(saveData,token))
          .then(() => {
            
            console.log("response", response)
            setSuccessResponse(response.data.message)
            setLoading(false)
            var index = b.findIndex(function(o){
              return o.id === response.data.document_id;
         })
         b.splice(index,1)
         return b;
          })
        }
    
        const handleDelete=(childData)=>{
          console.log("childData",childData)
          var index = newcreatedprojectDocuments.findIndex(function(o){
            return o.id === childData
       })
          newcreatedprojectDocuments.splice(index,1)
          return newcreatedprojectDocuments
      
          console.log("newcreatedprojectDocuments minus one",newcreatedprojectDocuments)
          
        }

    useEffect(() => {
      dispatch(fetchdocumentstypes(token))
      // ProjectDocumentsToPost()
      dispatch(fetchprojectDocumentAction(token))
    }, [])
    
  return (
    <div>
       <div class="investment-table">
        <Row>
         
        <Col md={12} lg={12} sm={12} xs={12}>
          {b && b.length > 0 && (
            <Card className="p-2" >
              <Formik
                 initialValues={{
                  "document": "",
                  "document_id": "",
                  "user_id": "",
              }}
                onSubmit={(values, { resetForm }) => {
                //   PostProjectOwnerDocument()
                console.log("submit data",saveData)
                dispatch(postprojectdocuments (saveData,token))
                //     console.log("valuees",saveData)
                  resetForm({ values: "" })
              
               console.log("post  data",saveData)
                }}
                 >
                {({ values, isSubmitting, errors, handleSubmit, handleChange, dirty, isValid }) => (

                  <Form>
                    {serverError && (
                      <div style={{ color: "red", fontSize: "15px" }}>{serverError && (
                        <div>{serverError}</div>
                      )}
                      </div>
                    )}

                    {successResponse && (
                      <div
                        style={{ color: "green", fontSize: "15px", height: "30px", width: "auto", right: "0" }}>
                        {successResponse}
                      </div>

                    )}

                    <CardHeader className='position-relative'>
                      <h5 className="card-title mb-0">Upload Documents below</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {b.length > 0 && b.map((projectdoc, index) => (
                          <Col md={4} lg={4} sm={12} xs={12} key={index}>
                            {() => testArr.push({ document: {}, document_id: "", user_id: "" })}

                            <div className=" mb-3">
                              <Label className="ValidationInput text-capitalize fs-15 " for="validationFormCheck3">{projectdoc.name}</Label>
                              <Input type="file"
                                className="form-control"
                                id="name"
                                name="document"
                                placeholder='Enter document '
                                values={image|| " "}
                                onChange={(e) => {
                                
                                  handleActualChange(projectdoc.id, e, index)
                                }}
                                required />

                              <div className="invalid-feedback">upload file</div>
                            </div>

                          </Col>
                        )

                        )}

                      </Row>
                      {isLoading ===false &&(
                          <div className="text-right position-absolute  end-0 m-2">
                          <div className="m-1 position-relative">
                        <button type="button" onClick={(e)=>
                      handleSubmitbb(saveData,token)
                      
                        } className="button button--input">Submit</button>
                        </div>
                        </div>
                      )}
                      {/* {isLoading ===true &&(
                           <div className="text-right position-absolute  end-0 m-2">
                           <div className="m-1 position-relative">
                             <Button color="primary" className="btn-load" outline disabled>
                               <span className="d-flex align-items-center">
                                 <span className="flex-grow-1 me-2">
                                   Loading...
                                 </span>
                                 <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                               </span>
                             </Button>
                           </div>
                         </div>
                      )} */}

                    </CardBody>
                 
                  </Form>
                )}
              </Formik>
            </Card>
          )}
        </Col>
        </Row>
    <div class="intro">
        <h5>My Documents</h5>
    </div>
    <div class="table-wrapper ">
      <Row className='d-flex'>
      
      {newcreatedprojectDocuments&& 
          newcreatedprojectDocuments.map((projectdoc, index) => (
        <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 border-right'>
          
            <div className="investment-table-row" key={index}>
            {projectdoc.documents.type === "pdf" && (
                    <div><AiOutlineFilePdf style={{ height: "30%", width: "30%", color: "red" }} /></div>
                  )}
                  {projectdoc.documents.type === "jpg" && (
                    <div><AiOutlineFileJpg style={{ height: "100px", width: "100px", color: "" }} /></div>
                  )}
                  {projectdoc.documents.type === "jpeg" && (
                    <div><img src={myimg} alt="jpeg" style={{ height: "85%", width: "85%" }} /></div>
                  )}
                  {projectdoc.documents.type === "png" && (
                    <div><img src={pngimg} alt="jpeg" style={{ height: "45%", width: "45%" }} /></div>
                  )}
                  {projectdoc.documents.type === "avif" && (
                    <div><img src={avif} alt="avif" style={{ height: "45%", width: "45%" }} /></div>
                  )}
                  {projectdoc.documents.type === "webp" && (
                    <div><img src={webpimg} alt="webp" style={{ height: "45%", width: "45%" }} /></div>
                  )}
               <div className='d-flex position-relative m-4' >
                    <p class="position-absolute start-0 text-muted">{projectdoc.documents.name}</p>
                    <Dropdown className="position-absolute end-0 " isOpen={dropdownOpen} toggle={toggle}>
                      <a href="#" caret><AiOutlineMore /></a>
                      <DropdownMenu>
                        <DropdownItem onClick={(e) => {
                          tog_center2(e)
                          setDoc(projectdoc.id)

                        }}><i className="ri-eye-fill me-2 align-middle text-muted" />View</DropdownItem>
                        { }
                        <Modal
                          isOpen={modal}
                          toggle={() => {
                            tog_center2();
                          }}
                          centered
                        >{
                            projectdoc.documents.type === "pdf" ?
                              <ViewSingle handleClose={tog_center2} documentId={doc} /> :
                              <ViewDocument handleClose={tog_center2} documentId={doc} />
                          }

                        </Modal>
                        {/* delete */}
                        <DropdownItem className='remove-item-btn' onClick={() =>{
                          tog_center()
                          setDoc(projectdoc.id)
                        }}
                        >
                          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
                        <Modal
                          isOpen={modal_center}
                          toggle={() => {
                            tog_center();
                          }}
                          centered
                        >
                          <DeleteProjectDocument handleClose={tog_center} documentId={doc} handleDelete={handleDelete} />
                        </Modal>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
            </div>
          
        </div>
        ))}
      </Row>
       
    </div>
</div></div>
  )
}
