import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { getToken } from '../../../services/UseTokens'



function DeleteInvestorDoc(props) {
    const token=getToken()
    const dispatch=useDispatch()
    const handleSubmit=()=>{
        // dispatch(deleteMilestoneAct(props.milestones.id,token))
    }
  return (
    <div class="text-center">
    <div class="icon-box">
    <i className={`fa red-circle fa-trash`}></i>
    </div>
    <h2>Are you sure?</h2>
   <div>
    <p>
    Do you really want to delete these records? This process cannot be undone.
    </p>
   </div>

         <Button color="danger" onClick={()=>{handleSubmit()}}  >Delete</Button>
   
  </div>
  )
}

export default DeleteInvestorDoc;