import Swal from "sweetalert2";
import { requests } from "../../../../services/Api";
import { getToken, getUser, getUserId } from "../../../../services/UseTokens";
import { RESET_PASSWORD_REQUEST,RESET_PASSWORD_SUCCESS,RESET_PASSWORD_FAILURE } from "./ResetPassType";

const ResetPasswordAct=(data)=>async (dispatch)=>{
    const token=getToken()
    const user=getUser()
    const userId=getUserId()

    try {
        dispatch({type:RESET_PASSWORD_REQUEST})
        const response=await requests.post(`auth/password/reset`,data,{
            headers:{
                Authorization:`Bearer ${token}`
            }
        })
        dispatch({
            type:RESET_PASSWORD_SUCCESS,
            payload:response.data
        })
        Swal.fire(
            'Good job!',
            'Password Reset Successfully',
            'success'
        )
        
        
    } catch (error) {
        dispatch({type:RESET_PASSWORD_FAILURE,
            payload:error.response.data.message})
        Swal.fire(
            "error",
            error.response.data.message,
            "error"
        )

        
    }
}
export default ResetPasswordAct;