import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchUserShares } from '../../redux/actions/user-shares/fetchUserShares'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Badge } from 'reactstrap'
import { imageurl } from '../../services/Api'
import NoInvestment from './NoInvestment'

const user = JSON.parse(localStorage.getItem('authuser'))


export default function MyInvestment() {
    const dispatch = useDispatch()
    const usershare = useSelector(state => state.usershares)
    
    console.log("userShare",usershare)
    let loading=usershare?.loading

    //randomly display one of the investments
    const randomInvestment = () => {
        const random = Math.floor(Math.random() * usershare?.usershares.length)
        return usershare.usershares[random]
    }

    const project = randomInvestment()
    console.log('random-project', project?.project?.project_header_image);

    useEffect(() => {
        dispatch(fetchUserShares(user?.id, user?.token))
    }, [])
  return (
    <div>
        <div class="dashboard-single__box header-margin">
           { !loading &&(
            
                <div class="intro">
                <h5 style={{fontSize:"20px"}}>
                  My Invested Projects{' '}
                  <Badge color='success' pill>
                    {usershare?.usershares.length}
                  </Badge>
                </h5>
                <Link style={{fontSize:"20px"}} to="/my-investments">Show All<i
                        class="fa-solid fa-arrow-right-long"></i></Link>
            </div>
            
  )}
          {loading &&(
            <Skeleton
            
            width="100%"
            containerClassName="avatar-skeleton"
        />
          )}
          {!loading && usershare?.usershares?.length > 0 && (
            <div class="property-wrap">
            <div class="poster">
                <Link to={`/projects/${project?.project_id}`}>
                    <img src={`${imageurl}${project?.project?.project_header_image}`} alt="" />
                </Link>
            </div>
            <h4><Link to={`/projects/${project?.project_id}`}>{project?.project?.project_name}</Link></h4>
            <p><i class="fa-solid fa-location-dot"></i>{project?.project?.location}</p>
        </div>
          )}  

          {!loading && usershare?.usershares?.length === 0 && (
            <div class="property-wrap">
            {/* <p>No Invested projects click below to make an investment.</p> <Link to='/projects'>Invest</Link> */}
            {/* <p><i class="fa-solid fa-location-dot"></i>{project?.project?.location}</p> */}
            <NoInvestment/>
        </div>
          )} 
            
        </div>
    </div>
  )
}
