import React, { useState } from 'react'
import DashBoardTopNav from '../../layout/Settings/DashBoardTopNav'
// import CreateProject from '../ListProperty/CreateProject'
// import CreateProject2 from '../ListProperty/createProject2'
import SideBar from './SideBar'

export default function Test2() {
    const [sidebarIsOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  return (
    <div>
       <div class="dashboard section__space__bottom " >
<div class="container">
    <div class="dashboard__area">
        <div class="row">
            <div class="col-xxl-3">
               <SideBar  opensidebar={toggleSidebar}  sidebarIsOpen={sidebarIsOpen}/>
            </div>
            <div class="col-xxl-9">
                <div class="main__content">
                  <DashBoardTopNav toggleSidebar={toggleSidebar}/>
                    <div class="">
                        <div class="row">
                            {/* <CreateProject2/> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
    </div>
  )
}
