import { requests } from "../../../services/Api"
import { FETCH_PROJECT_FAILURE, FETCH_PROJECT_REQUEST, FETCH_PROJECT_SUCCESS } from "./projectTypes"

export const fetchProject = (id) => async (dispatch) =>{
    try {
        dispatch({
            type: FETCH_PROJECT_REQUEST
        })

        const response = await requests.get(`projects/show-project/${id}`)

        dispatch({
            type: FETCH_PROJECT_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: FETCH_PROJECT_FAILURE,
            payload: error
        })
    }
}