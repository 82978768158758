import React from 'react'
import { Link } from 'react-router-dom'
import { getUser } from '../../services/UseTokens'

const user = getUser()


export default function Statistics(props) {
   
  return (
    <div>
        <div class="investment-sidebar">
            <div class="statistics">
                <h5>Statistics</h5>
                <hr />
                <div class="group">
                    <img src="assets/images/icons/wallet.png" alt="Wallet" />
                    <div>
                        <h4>{user.totalAmountInvested}</h4>
                        <p>Total Investments</p>
                    </div>
                </div>
            </div>
            <div class="explore">
                <img src="assets/images/icons/explore.png" alt="Explore" />
                <div class="group">
                    <h6>Explore More</h6>
                    <p class="secondary">Investment opportunities</p>
                    <Link to="/projects"
                        class="button button--effect">Explore</Link>
                </div>
            </div>
        </div>
    </div>
  )
}
