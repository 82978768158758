import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import CreateCompany from './CreateCompany'
import CreateDirectors from './CreateDirectors'
import CreateExcecutives from './CreateExcecutives'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import classnames from 'classnames';

export default function () {
    SwiperCore.use([Autoplay]);
    const [activeTab, setActiveTab] = useState('1');
    const [activityTab, setActivityTab] = useState('1')
    const navigate=useNavigate()
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };


    return (
        <div>
            <div class="dashboard section__space__bottom">
                <div class="container">
                    <div class="dashboard__area">
                        <div class="row">

                            <div class="col-xxl-12">
                                <div class="main__content">
                                    <div class="collapse__sidebar">
                                        <h4>Company</h4>
                                        <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                            <i class="fa-solid fa-bars-staggered"></i>
                                        </a>
                                    </div>
                                    <div class="main__content-dashboard">
                                        <div class="breadcrumb-dashboard">
                                            <h5>Company</h5>
                                            <div>
                                                <Link to={`/company`}> Company</Link>
                                                <i class="fa-solid fa-arrow-right-long"></i>
                                                <Link to={`/company`}>Create Account</Link>
                                            </div>
                                        </div>
                                        <div class="account-info">
                                            <div className='card-header d-flex'>
                                                
                                                <Button className="bg-secondary m-1 text-light" onClick={() => navigate(-1)}>Back</Button>
                                            </div>
                                            <CreateCompany />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
