import { FETCH_EXECUTIVES_REQUEST,FETCH_EXECUTIVES_SUCCESS,FETCH_EXECUTIVES_FAILURE } from "./ExecutivesTypes";
import {getToken} from "../../../../services/UseTokens"
import Swal from "sweetalert2";
import { requests } from "../../../../services/Api";
const fetchexecutivesAction=(id,token)=> async (dispatch) => {
    
    // const token=getToken(toke
    try {
        
    
        dispatch({
            type:FETCH_EXECUTIVES_REQUEST 
        })
        console.log("tokennn",token)
        console.log("requestsss")
        const response=await requests.get(`company/get-executives/${id}`,{
            headers:{
                Authorization: `Bearer ${token}` 
            }
        })
        console.log(response)
        dispatch({type:FETCH_EXECUTIVES_SUCCESS, payload:response.data})
        console.log(" res.daata",response)
     
        
    } catch (error) {
        dispatch({type:FETCH_EXECUTIVES_FAILURE,payload:error.response.message})
   
        console.log("error: " + error.response)
    }
}
export default fetchexecutivesAction;