import { UPDATE_PROFILE_FAILURE,UPDATE_PROFILE_SUCCESS,UPDATE_PROFILE_REQUEST } from "../../actions/profile/updateProfileType";

const initialState={
    loading:false,
    error:"",
    profile:[]
}

const updateProfileReducer=(state=initialState,action)=>{
    switch (action.type) {
        case UPDATE_PROFILE_REQUEST:
            return {
                loading:true,
                error:"",
                profile:[]
            }
            
            break;  
        case UPDATE_PROFILE_SUCCESS:
            return {
                loading:false,
                profile:action.payload,
                error:""
            }
            break;
        case UPDATE_PROFILE_FAILURE:
            return {
                loading:false,
                profile:[],
                error:action.payload
            }
            break;
    
        default:
            return state;
            break;
    }
}
export default updateProfileReducer