import React from 'react'
import ListofProjects from '../../pages/ListProperty/Index'
import Footer from '../footer'
import TopNav from '../topnav'

function ListYourProject() {
  return (
    <div>
        
        <ListofProjects/>

    </div>
  )
}

export default ListYourProject