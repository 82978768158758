import { requests } from "../../../services/Api"
import { FETCH_TRANSACTIONS_FAILURE, FETCH_TRANSACTIONS_REQUEST, FETCH_TRANSACTIONS_SUCCESS } from "./transactionTypes"

export const fetchTransactions = (id, token) => async (dispatch) => {
    dispatch({
        type: FETCH_TRANSACTIONS_REQUEST
    })
    try {
        const response = await requests.get(`/transactions/user-transactions/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        dispatch({
            type: FETCH_TRANSACTIONS_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: FETCH_TRANSACTIONS_FAILURE,
            payload: error
        })
    }
}