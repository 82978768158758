import Swal from "sweetalert2";
import { requests } from "../../../../services/Api";
import { getToken } from "../../../../services/UseTokens";
import {CREATE_OWNERDOCS_REQUEST,CREATE_OWNERDOCS_SUCCESS,CREATE_OWNERDOCS_FAILURE} from "./OwnerTypes";

const createOwnerDocumentAction=(saveData,token)=>async(dispatch)=>{

    try {
        dispatch({type:CREATE_OWNERDOCS_REQUEST})
        console.log("token",token)
        const response = await requests.post("documents/owners-bulk",saveData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }})
        dispatch({type:CREATE_OWNERDOCS_SUCCESS,

        payload:response.data}).then(()=>{
            Swal.fire(
                'Good job!',
                'Your document has been  created successfully',
                'success'
            )
            
        })
        
    } catch (error) {
        dispatch({ TYPE:CREATE_OWNERDOCS_FAILURE, payload: error })
        .then((error)=>{
            Swal.fire(
                'Oops...',
                error.response.data.message,
                'error'
            )
        })
        
    }
}
export default createOwnerDocumentAction;