import React from 'react'
import { Link } from 'react-router-dom'
import ProjectOwnerDocument from './ProjectOwnerDocument'
import Statistics from './Statistics'

export default function ProjectOwnerDocuments() {
  return (
    <div>
         <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5>Investments</h5>
                                <div>
                                    <Link to="/">Home</Link>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                    <Link to="#">Investments</Link>
                                </div>
                            </div>
                            <div class="row p-4">
                                <div class="col-lg-8">
                                   <ProjectOwnerDocument/>
                                </div>
                                <div class="col-lg-4">
                                    <Statistics/>
                                </div>
                            </div>
                            {/* <Graph/> */}
                        </div>
    </div>
  )
}
