import { UPDATE_INVESTOR_REQUEST,UPDATE_INVESTOR_FAILURE,UPDATE_INVESTOR_SUCCESS } from "../../../actions/Documents/InvestorDocs/InvestorTypes";

const initialState={
    loading:false,
    error:"",
    data:{},
}

const updateIvestorDocReducer=(state=initialState,action)=>{
    switch (action.type) {
        case UPDATE_INVESTOR_REQUEST:
            return{
                ...state,
                loading:true,
            }
            
            break;
        case UPDATE_INVESTOR_SUCCESS:
            return{
                ...state,
                loading:false,
                data:action.payload,
                error:""

            }
            break;
        case UPDATE_INVESTOR_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.payload
            }
            break;
        default:
            return state;
            break;
    }
}
export default updateIvestorDocReducer