function Video() {
  return (
    <div>
    <div class="video">
        <div class="container">
            <div class="video__area">
                <img src="assets/images/video-illustration.png" alt="Video Illustration" />
                <div class="video__btn">
                    <a class="video__popup" href="https://www.youtube.com/watch?v=j8z7UjET1Is" target="_blank"
                        title="YouTube video player">
                        <i class="fa-solid fa-play"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    </div>
  );
}

export default Video;
