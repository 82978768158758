// ** React Imports
import { useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import CardHeader from '@mui/material/CardHeader'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { getToken } from '../../services/UseTokens'
import { requests } from '../../services/Api'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { LoadingButton } from '@mui/lab'



const CompleteSetup = ({company, handleClose}) => {
  console.log("company",company)
  const token = getToken();
  // ** States
  const [website, setWebsite] = useState("")
  const [nationality, setNationality] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [vision, setVision] = useState("");
  const [mission, setMission] = useState("");
  const [description, setDescription] = useState("");
  const [logo, setLogo] = useState("");
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  // ** Handle Submit
  const handleSubmit = async () => {
    const data = {
      company_name: company?.company_name,
      website_url: website,
      company_nationality: nationality,
      linkedin_url: linkedIn,
      facebook_url: facebook,
      twitter_url: twitter,
      vision,
      mission,
      company_description:description,
      company_logo: logo,
    }
      setLoading(true)
      await requests.put(`/company/update-company/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        setLoading(false)
        Swal.fire('Company?', 'Updated successfully?', 'success',);
        setWebsite("")
        setNationality("")
        setLinkedIn("")
        setFacebook("")
        setTwitter("")
        setVision("")
        setMission("")
        setDescription("")
        setLogo("")
      }).catch((error) => {
        console.log({error})
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${error.response.data.message}`,
        });
      });
      
  }

  return (
    <Card>
      <CardHeader title='complete setting up your company' titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>
        <form onSubmit={e => e.preventDefault()}>
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label='Website url'
                placeholder='info.company.com'
                onChange={e => setWebsite(e.target.value)}
                value={website}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <TextField
                fullWidth
                type='text'
                label='Nationality'
                placeholder='Enter Your Nationality'
                onChange={(e) => setNationality(e.target.value)}
                value={nationality}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <TextField
                fullWidth
                type='file'
                helperText='update Company Logo'
                placeholder='Enter Your Company Logo'
                onChange={(e) => setLogo(e.target.value)}
                value={logo}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <TextField
                fullWidth
                type='text'
                label='linkedIn'
                placeholder='Enter Your Company linkedin account'
                helperText="optional"
                onChange={(e) => setLinkedIn(e.target.value)}
                value={linkedIn}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <TextField
                fullWidth
                type='text'
                label='twitter'
                placeholder='Enter Your Company twitter handle'
                helperText="optional"
                onChange={(e) => setTwitter(e.target.value)}
                value={twitter}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
              <TextField
                fullWidth
                type='text'
                label='Facebook'
                placeholder='Enter Your Company facebook link'
                helperText="optional"
                onChange={(e) => setFacebook(e.target.value)}
                value={facebook}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-multiline-static"
                label="Vision"
                multiline
                rows={4}
                onChange={(e) => setVision(e.target.value)}
                value={vision}
              // defaultValue="Default Value"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-multiline-static"
                label="Mission"
                multiline
                rows={4}
                onChange={(e) => setMission(e.target.value)}
                value={mission}
              // defaultValue="Default Value"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="paragraph">Description</Typography>
              <CKEditor

                editor={ClassicEditor}

                data=""
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data)
                  console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor);
                }}
              />
            </Grid>


            <Grid item xs={12}>
              <Box
                sx={{
                  gap: 5,
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                {/* {!loading && (
                  <Button type='submit' variant='contained' size='large' style={{ backgroundColor: "#5927e3" }} onClick={() => handleSubmit()}>
                  Submit
                </Button>
                )} */}
                  <LoadingButton
                    onClick={() => handleSubmit()}
                    loading={loading}
                    loadingIndicator="Loading…"
                    variant="contained"
                    style={{ backgroundColor: "#5927e3" }}
                    size='large'
                  >
                    Submit
                  </LoadingButton>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}

export default CompleteSetup
