import { DELETE_FAILURE,DELETE_SUCCESS,DELETE_REQUEST } from "../../actions/milestones/MilestoneType";
const inittialState={
    loading:false,
    error:"",
    milestone:{}
}

export default function DeleteMilestoneReducer(state=inittialState,action){
    switch (action.type) {
        case DELETE_REQUEST:
            return{
                ...state,
                loading:true
            }
            
            break;
        case DELETE_SUCCESS:
            return{
                ...state,
                milestone:action.payload
            }
            break;
        case DELETE_FAILURE:
            return{
                ...state,
                error:action.payload
            }
            break
    
        default:
            return state;
            break;
    }
}