import Swal from "sweetalert2";
import { removeUserSession } from "../../../services/UseTokens";
import { LOGOUT_FAILURE, LOGOUT_REQUEST } from "./logoutTypes";

export const logoutUser = (navigate) => async (dispatch) => {
    try {
        dispatch({
            type: LOGOUT_REQUEST
        })
        Swal.fire(
            'Logout Successful',
            'success'
          ).then((result) => {
            navigate("/");
          })
    } catch (e) {
        dispatch({
            type: LOGOUT_FAILURE,
            payload: e
        })
    }
}