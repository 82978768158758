import { PAYMENT_FAILURE,PAYMENT_SUCCESS,PAYMENT_REQUEST } from "../../actions/Payment/paymentTypes";

const initialState={
    loading:false,
    payment:"",
    console:""
}

export default function PaymentReducer(state=initialState, action){
    switch (action.type){ 
        case PAYMENT_REQUEST:
            return{
                ...state,
                loading:true,
            }
            break;
        case PAYMENT_REQUEST:
            return{
                ...state,
                payment:action.payload,
            }
            break;
            case PAYMENT_REQUEST:
                return{
                    ...state,
                    error:action.payload,
                }
                break;
    
        default:
            return state;
            break;
    }
}