export const FETCH_PROJECTDOCUMENT_REQUEST = "FETCH_PROJECTDOCUMENT_REQUEST";
export const FETCH_PROJECTDOCUMENT_SUCCESS = "FETCH_PROJECTDOCUMENT_SUCCESS";
export const FETCH_PROJECTDOCUMENT_FAILURE = "FETCH_PROJECTDOCUMENT_FAILURE";

export const CREATE_PROJECTDOCUMENT_REQUEST = "CREATE_PROJECTDOCUMENT_REQUEST";
export const CREATE_PROJECTDOCUMENT_SUCCESS = "CREATE_PROJECTDOCUMENT_SUCCESS";
export const CREATE_PROJECTDOCUMENT_FAILURE = "CREATE_PROJECTS_FAILURE";

export const FETCH_SINGLE_DOCUMENT_REQUEST = "FETCH_SINGLE_DOCUMENT_REQUEST";
export const FETCH_SINGLE_DOCUMENT_SUCCESS = "FETCH_SINGLE_DOCUMENT_SUCCESS";
export const FETCH_SINGLE_DOCUMENT_FAILURE = "FETCH_SINGLE_DOCUMENT_FAILURE";

export const UPDATE_SINGLE_DOCUMENT_REQUEST = "UPDATE_SINGLE_DOCUMENT_REQUEST";
export const UPDATE_SINGLE_DOCUMENT_SUCCESS = "UPDATE_SINGLE_DOCUMENT_SUCCESS";
export const UPDATE_SINGLE_DOCUMENT_FAILURE = "UPDATE_SINGLE_DOCUMENT_FAILURE";