import { FETCH_INVESTOR_REQUEST,FETCH_INVESTOR_SUCCESS,FETCH_INVESTOR_FAILURE } from "../../../actions/Documents/InvestorDocs/InvestorTypes";

const initialState = {
    loading: false,
    error: "",
    data: []
}

export default function fetchInvestorDocreducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_INVESTOR_REQUEST:
        return {
            ...state,
            loading: true,
            data: [],
            error: ""   
        }
        case FETCH_INVESTOR_SUCCESS:
        return {
            ...state,
            loading: false,
            data: action.payload,
            error: ""
        }
        case FETCH_INVESTOR_FAILURE:
        return {
            ...state,
            loading: false,
            error: action.payload,
            data: []
        }
        default:
        return state
    }
}

