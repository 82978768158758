import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import DeleteDirectorAct from '../../redux/actions/company/companyDirectors/DeleteDirectors'
// import { getToken } from '../../../services/UseTokens'
import DeleteExecutiveAct from '../../redux/actions/company/CompanyExecutives/DeleteExecutive'
import { getToken } from '../../services/UseTokens'

function DeleteDirector(props) {
    console.log("props",props)
    const token = getToken()
    const dispatch = useDispatch()
    const handleSubmit = () => {
        dispatch(DeleteDirectorAct(props.director.id, token))
    }
    return (
        <div class="text-center p-4">

            <div class="icon-box">
                <i className={`fa red-circle fa-trash`}></i>
            </div>
            <h2 class="text-center">Are you sure?</h2>
            <div>
                <p class= "text-center m-1">
                    Do you really want to delete these records? 
                    This process cannot be undone.
                </p>
            </div>
        <div class="float-end">
            <Button color="danger" onClick={() => { handleSubmit() }}  >Delete</Button>
            {/* <Button color="primary" onClick={() => { props.handleClose() }}  >Delete</Button> */}
        </div>

        </div>
    )
}

export default DeleteDirector