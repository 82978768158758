import BannerImage from '../banner/images/banner.png'
function Banner() {
    return (
      <div>
        <section class="support__banner contact__banner bg__img clear__top"
        style={{ backgroundImage:`url(${BannerImage})` }}>
        <div class="container">
            <div class="support__banner__area">
                <div class="support__banner__inner">
                    <h1 class="neutral-top">How can we help?</h1>
                    <h5 class="neutral-top">Got a question?</h5>
                </div>
            </div>
        </div>
    </section>
      </div>
    );
  }
  
  export default Banner;
  