import Swal from "sweetalert2";
import { requests } from "../../../services/Api";
import { DELETE_FAILURE,DELETE_SUCCESS,DELETE_REQUEST } from "./MilestoneType";

const deleteMilestoneAct=(id,token)=> async (dispatch) => { 
    try {
        dispatch({ type:DELETE_REQUEST})
        const response = await requests.delete(`projects/delete-project-milestone/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        dispatch({
            type:DELETE_SUCCESS,
            payload: response.data
        })
        Swal.fire({
            icon:"success",
            title:"success",
            text:"milestone successfully deleted",
            confirmButtonText:"Ok"
        })
        
    } catch (error) {
        dispatch({type:DELETE_FAILURE, payload:error.response.message})
        Swal.fire({
            icon:"error",
            title:"error",
            text:error.response.message,
            confirmButtonText:"Ok"

        })
        
    }
 }  
export default deleteMilestoneAct
    