import {useState, useRef, useEffect} from 'react'

import './dropdown.styles.css'

function Dropdown({options, prompt, value, onChange}) {

    const [open, setOpen] = useState(false)
    const [query, setQuery] = useState("")

    const ref = useRef(null)

    useEffect(() => {
        ["click", "touchend"].forEach(e => {
            document.addEventListener(e, toogle)
        })
        return () => 
        {
            ["click", "touchend"].forEach(e => {
                document.removeEventListener(e, toogle)
            })
        }
    }, [])

    function toogle(e) {
        setOpen(e && e.target === ref.current)
    }

    function filter(options) {
        return options.filter(option => option.name.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    }

    function displayValue() {
        if(query.length > 0) return query
        if(value) return value.name
        return ""
    }

    function selectOption (option) {
        setQuery("");
        onChange(option);
        setOpen(false);
    }

    return (
        <div className='dropdown'>
            <div className="control">
                <div className="selected-value">
                   <input 
                        type="text"
                        ref={ref}
                        placeholder =  { value ? value.name : prompt}
                        value = {displayValue()}
                        onChange = {
                            (e) => { 
                                setQuery(e.target.value)
                                onChange(null)
                            }
                        }
                        onClick = {toogle}
                        onTouchEnd = {toogle}
                    /> 
                </div>
                <div className ={`arrow ${open ? "open" : null}`} />
            </div>
            <div className={`options ${open ? "open" : null}`}>
                {
                    (filter(options).map((option, index) => 
                    <div 
                        className={`option ${value === option ? "selected" : null}`} 
                        onClick = {() => selectOption(option)}
                        onTouchEnd = {() => selectOption(option)}
                        key = {index}>{option.name}</div>
                    ))
                    
                }
            </div>
        </div>
    )
}

export default Dropdown