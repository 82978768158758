import { useState, useEffect } from 'react';
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import LanguageIcon from "@mui/icons-material/Language";
import PublicIcon from "@mui/icons-material/Public";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import GeneralAnalytics from "./Analytics/GeneralAnalytics";
import TotalEarning from "./Analytics/InvestmentCard";
import NewMyInvestmentCard from "./Analytics/NewMyInvestmentCard";
import { requests } from '../../services/Api';
import { useParams } from 'react-router-dom';

// Styled Grid component
const StyledGrid1 = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  [theme.breakpoints.down("md")]: {
    paddingTop: "0 !important",
  },
  "& .MuiCardContent-root": {
    padding: theme.spacing(3, 4.75),
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },
}));

// Styled Grid component
const StyledGrid2 = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    paddingLeft: "0 !important",
  },
  [theme.breakpoints.down("md")]: {
    order: -1,
  },
}));

// Styled component for the image
const Img = styled("img")(({ theme }) => ({
  height: "11rem",
  borderRadius: theme.shape.borderRadius,
}));

const ProjectAdditonal = ({ project }) => {
  function createMarkup(value) {
    return { __html: value };
  }
  const [analytics, setAnalytics] = useState([])
  const { project_id } = useParams();
  const fetchData = async() => {
    await requests.get(`projects/analytics/${project_id}`).then((response) => {
      setAnalytics(response.data)
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Card style={{ xs: { minWidth: "100%" } }}>
      {/* GeneralAnalytics */}
      <Grid container spacing={2}>
        <StyledGrid1 item xs={12} sm={12} md={6} lg={3}>
          <CardContent>
            <Box>
              <Card style={{ marginTop: { xs: "5px" } }}>
                <NewMyInvestmentCard project={project}/>
              </Card>
              {/* <TotalEarning/> */}
            </Box>
          </CardContent>
        </StyledGrid1>

        <StyledGrid1 item xs={12} sm={12} md={6} lg={9}>
          <CardContent>
            <Box>
              <GeneralAnalytics analytics={analytics}/>
            </Box>
          </CardContent>
        </StyledGrid1>
        <StyledGrid1 item xs={12} md={12} lg={12}>
          <CardContent>
            <Typography
              variant="h6"
              sx={{ marginBottom: 2, fontSize: "20px", color: "#5927e3" }}
            >
              Description
            </Typography>

            <Typography
              variant="body2"
              sx={{ marginBottom: 2, fontSize: "16px" }}
            >
              {/* {project?.project_description} */}
              <p
                dangerouslySetInnerHTML={createMarkup(
                  project?.project_description
                )}
              />
            </Typography>
            <Box sx={{ padding: "15px" }}>
              <Typography
                variant="h6"
                sx={{ marginBottom: 2, fontSize: "20px", color: "#5927e3" }}
              >
                Why Invest
              </Typography>

              <Typography
                variant="body2"
                sx={{ marginBottom: 4, fontSize: "16px" }}
              >
                <p
                  dangerouslySetInnerHTML={createMarkup(project?.why_invest)}
                />
              </Typography>
            </Box>
          </CardContent>
        </StyledGrid1>
      </Grid>
    </Card>
  );
};

export default ProjectAdditonal;
