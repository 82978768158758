import { requests } from "../../../../services/Api"
import { FETCH_SINGLE_DOCUMENT_FAILURE, FETCH_SINGLE_DOCUMENT_REQUEST, FETCH_SINGLE_DOCUMENT_SUCCESS } from "./DocumentsTypes"

export const fetchSingleDocument = (id, token) => async (dispatch) => {
    try {
        dispatch({
            type: FETCH_SINGLE_DOCUMENT_REQUEST
        })

        const response = await requests.get(`/documents/projects/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`            }
        })

        const { data } = response
        const documentUrl = `${process.env.REACT_APP_BACKEND_URL}${data.document}`
        data['documentUrl'] = documentUrl
        
        dispatch({
            type: FETCH_SINGLE_DOCUMENT_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: FETCH_SINGLE_DOCUMENT_FAILURE,
            payload: error.response.data.message
        })
    }
}