import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { requests } from "../../../services/Api";
import { getToken } from "../../../services/UseTokens";
import Swal from "sweetalert2";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#5927e3",
    },
  },
});
const StyledButton = styled(Button)`
  ${({ theme }) => `
    cursor: pointer;
    transition: ${theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.standard,
    })};
    &:hover {
        color:"fff";
      background-color:#8d6bec;
      transform: scale(1.3);
    }
    `}
`;
export default function InvestProject() {
  const [payment, setPayment] = React.useState("");
  const { project_id } = useParams();
  const token = getToken();
  const navigate = useNavigate();
  const [amount, setAmount] = React.useState("");


  const handleChange = (event) => {
    setPayment(event.target.value);
  };

  const handleInvest = async() => {
    const data = {
      payment_method: payment,
      project_id: project_id,
      amount: amount,
    }
    await requests.post(`/shares/invest`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      Swal.fire({
        icon: 'success',
        title: `Investment successful`,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = `/project-profile/${project_id}`;
        }
      })
    }).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: `Investment failed`,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        showConfirmButton: true,
      })
    })
  }


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Payment Method
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={payment}
              label="Payment Method"
              onChange={handleChange}
            >
              <MenuItem value={10}>Cash</MenuItem>
              <MenuItem value={20}>Mpesa</MenuItem>
              <MenuItem value={30}>Visa</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <TextField
            id="outlined-basic"
            label="Amount  "
            variant="outlined"
            helperText="Enter The amount of money you would like to invest"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <ThemeProvider theme={customTheme}>
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <StyledButton
                variant="outlined"
                style={{
                  color: "#5927e3",
                  marginLeft: "15px",
                  marginBottom: "0px",
                }}
                onClick={() => handleInvest()}
              >
                Invest
              </StyledButton>
            </Grid>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Box>
  );
}
