import { Alert, Button, Stack, Typography } from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { requests } from "../../services/Api";
import { getToken } from "../../services/UseTokens";
import { useNavigate } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { OnboardCompanyContext } from "./OnboardContext";
import { Item } from "better-react-carousel";

export default function Payment() {
  const { activeStep, setActiveStep, paymentComplete, setPaymentComplete } = useContext(OnboardCompanyContext);
  const [loading, setLoading] = useState(false);
  const [moveNext, setMoveNext] = useState(false);
  // theme.transitions.create(['background-color', 'transform']);

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#5927e3",
      },
    },
  });
  const StyledButton = styled(Button)`
    ${({ theme }) => `
    cursor: pointer;
    transition: ${theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.standard,
    })};
    &:hover {
        color:"fff";
      background-color:#8d6bec;
      transform: scale(1.3);
    }
    `}
  `;

  const [isLoadingStk, setIsLoadingStk] = useState(false);
  const [phone, setPhone] = useState("");
  var confirmInterval = null;
  const lt = getToken();
  const navigate = useNavigate();

  const mpesaStkPush = async (values) => {
    setIsLoadingStk(true);
    await requests
      .post(
        "/mpesa/stkpush",
        {
          amount: 1,
          phone: phone,
          AccountReference: "OAKNET",
          TransactionDescription: "INVESTMENT",
        },
        {
          headers: {
            Authorization: `Bearer ${lt}`,
          },
        }
      )
      .then((response) => {
        const { data } = response;

        confirmInterval = setInterval(() => {
          confirmPayment(data.MerchantRequestID, data.CheckoutRequestID);
        }, 2000);
        Swal.fire({
          icon: "question",
          title: "Processing payment",
          text: "Dont close the pop up before completing the payment",
          allowOutsideClick: false,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log("mpesaStkPushError", error);
        Swal.fire({
          icon: "error",
          title: "Error paying for investment",
          text: `${error.response.data.message}`,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setIsLoadingStk(false);
        setMoveNext(true);
      });
  };

  const confirmPayment = async (merchant, checkout) => {
    const formattedPhone = phone.substring(1);
    const completePhone = `254${formattedPhone}`;
    await requests
      .get(`/mpesa/confirm/payment/${completePhone}/${merchant}/${checkout}`)
      .then((response) => {
        if (response.data.data !== null) {
          clearInterval(confirmInterval);
          try {
            Swal.fire({
              title: "Payment Successful",
              text: "BRS Payment Successfull proceed to search for company",
              icon: "success",
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Okay",
            }).then((result) => {
              if (result.isConfirmed) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
            });
          } catch (error) {
            console.log("save object error", error);
          }
          setTimeout(() => {
            clearInterval(confirmInterval);
            if (response.data.data === null) {
              Swal.fire({
                title: "Payment Failed",
                text: "Payment could not be confirmed",
                icon: "error",
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Okay",
              });
            }
          }, 30 * 1000);
        }
      })
      .catch((error) => {
        console.log("payment-confirmation-error", error);
      });
  };

  const simulate = (values) => {
    if (lt === null) {
      Swal.fire({
        icon: "error",
        title: "You need to be logged in to simulate, logout then try again..",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showConfirmButton: true,
        timer: 2000,
      });
      // history.push('/signin')
      navigate("/login");
      return null;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Pay for BRS!",
    }).then((result) => {
      if (result.isConfirmed) {
        mpesaStkPush(values);
      }
    });
  };

  useEffect(() => {
    // setPaymentComplete(true);
  })
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: { xs: "column", md: "column", lg: "row" },
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Item>
          <Typography variant="h4" gutterBottom>
            <Alert severity="info">
              Before registering for your company, you are required to pay a
              service fee of Ksh 650.00 to search for your company in the government database.
              You can pay via Mpesa by inputing your phone number and an
              stk-push will be sent to your mobile phone.
            </Alert>
          </Typography>
        </Item>
        <Item>
          <div class="row">
            <ValidatorForm
              style={{ display: "flex" }}
              // ref="form"
              onSubmit={() => simulate()}
              onError={(errors) => console.log(errors)}
            >
              <TextValidator
                label="phone number"
                onChange={(e) => setPhone(e.target.value)}
                name="phone"
                type="number"
                validators={["required", "minNumber:8"]}
                errorMessages={[
                  "this field is required",
                  "phone number cant be less than 8 values",
                ]}
                value={phone}
              />
              <ThemeProvider theme={customTheme}>
                <StyledButton
                  variant="outlined"
                  type="submit"
                  style={{
                    color: "#5927e3",
                    marginLeft: "15px",
                    marginBottom: "0px",
                  }}
                  // onClick={() => simulate()}
                >
                  Pay
                </StyledButton>
              </ThemeProvider>
            </ValidatorForm>
          </div>
        </Item>
      </Stack>
    </Box>
  );
}
