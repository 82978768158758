import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { requests } from "../../services/Api";
import { getToken } from "../../services/UseTokens";
import { LoadingButton } from "@mui/lab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Item } from "better-react-carousel";
import { OnboardCompanyContext } from "./OnboardContext";
// import BootstrapInput from '../components/BootstrapInput';

export default function NewDirectors() {
  const { setDirectorComplete } = useContext(OnboardCompanyContext);
  const [executivearr, setExecutivearr] = React.useState([]);

  const saveData = (director) => {
    setExecutivearr(director);
  };

  const token = getToken();
  const [savedDirectors, setSavedDirectors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [directorID, setDirectorID] = useState("");
  const [directorName, setDirectorName] = useState("");
  const [directorEmail, setDirectorEmail] = useState("");
  const [directorPhone, setDirectorPhone] = useState("");

  const inviteDirector = async (name, id) => {
    setIsLoading(true);

    await requests
      .post(
        "/director-invites",
        {
          id_number: directorID,
          name: directorName,
          email: directorEmail,
          phone_number: directorPhone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const { data } = response;
        setIsLoading(false);
        const director = {
          name: data.name,
          id_no: directorID,
          email: data.email,
          mobile: data.phone_number,
        };

        Swal.fire({
          icon: "success",
          title: `Director ${data.name} successfully invited`,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showConfirmButton: true,
        });

        setSavedDirectors((prevState) => [...prevState, director]);

        setDirectorID("");
        setDirectorName("");
        setDirectorEmail("");
        setDirectorPhone("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: `${error?.response?.data?.message}`,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showConfirmButton: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box
      component="form"
      sx={{ flexGrow: 1, flexDirection: "column", justifyContent: "center" }}
      Validate
      autoComplete="off"
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card style={{ width: { lg: "60%", xs: "100%" } }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack spacing={2} justifyContent="center" alignItems={"center"}>
              <Item>
                <Typography variant="h4" gutterBottom>
                  <Alert severity="info">
                    This section is for inviting directors of your company to
                    verify they approve the company being registered on the
                    platform. If your company has more than one director, you
                    need at least two directors to verify the company to
                    activate the company account. If you are the only director,
                    you can verify the company yourself. Once you are done
                    inviting directors, click on the finish button to complete
                    the registration process.
                  </Alert>
                </Typography>
              </Item>
              <Item>
                <Typography variant="h6" style={{ color: "#5927e3" }}>
                  Invite Director
                </Typography>
              </Item>
            </Stack>
          </Box>
          <CardContent>
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    type="text"
                    onChange={(e) => {
                      setDirectorName(e.target.value);
                    }}
                    value={directorName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="email"
                    variant="outlined"
                    type="text"
                    onChange={(e) => setDirectorEmail(e.target.value)}
                    value={directorEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="id_number"
                    variant="outlined"
                    type="number"
                    onChange={(e) => setDirectorID(e.target.value)}
                    value={directorID}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    sx={6}
                    id="outlined-basic"
                    label="mobile"
                    variant="outlined"
                    type="text"
                    onChange={(e) => setDirectorPhone(e.target.value)}
                    value={directorPhone}
                  />
                </Grid>
              </Grid>

              <div class="m-4">
                <LoadingButton
                  onClick={() => inviteDirector()}
                  loading={isLoading}
                  style={{ color: "#5927e3", borderColor: "#5927e3" }}
                  loadingIndicator="Loading…"
                  variant="outlined"
                  size="large"
                >
                  Invite Directors
                </LoadingButton>
              </div>
            </form>
          </CardContent>
        </Card>
      </Box>

      {/* <div>
                <TableExecutives executives={executivearr} />
            </div> */}
      <div>
        {savedDirectors.length > 0 && (
          <TableContainer style={{ margin: { lg: "15px", xs: "5px" } }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Email</TableCell>
                  <TableCell align="right">Mobile</TableCell>
                  <TableCell align="right">id number</TableCell>
                  {/* <TableCell align="right">Job Title</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {savedDirectors.length > 0 &&
                  savedDirectors.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{row.mobile}</TableCell>
                      <TableCell align="right">{row.id_no}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Box>
  );
}
