import { Card } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap'
import { fetchTransactions } from '../../redux/actions/transactions/fetchTransactions';
import { imageurl } from '../../services/Api';
import { getToken, getUser } from '../../services/UseTokens';

const user = getUser();
const token = getToken();
export default function NewInvestment() {
    const dispatch = useDispatch();
    const transactions = useSelector(state => state.transactions);

    useEffect(() => {
        dispatch(fetchTransactions(user?.id, token))
    }, [])
  return (
    
    <div className='header-margin'>
         {transactions.transactions.length !==0 &&(
            <Card style={{margin:"15px"}}>
             <div class="dashboard-single__box">
             <div class="intro">
                 <h5 style={{fontSize:"20px"}}>
                     My Recent Transactions{' '}
                     <Badge color='success' pill>
                         {transactions.transactions.length}
                   </Badge>
                 </h5>
                 <Link style={{fontSize:"20px"}} to="/transactions">Show All<i
                         class="fa-solid fa-arrow-right-long"></i></Link>
             </div>
            
                  <div class="investment-table investment-table-two">
                  <div class="table-wrapper">
                      <table>
                          <tr>
                              <th>Project</th>
                              <th>Amount Invested</th>
                              <th>Status</th>
                          </tr>
                          {
                              transactions && transactions.transactions.map((transaction, index) => (
                                  <tr>
                                      <td>
                                          <img src={`${imageurl}${transaction?.project?.project_header_image}`} alt="Investment" />
                                          {transaction.project.project_name}
                                      </td>
                                      <td >{`Ksh ${transaction.amount}`}</td>
                                      <td>{transaction.status == 'complete' ? (<Badge color='success' pill>complete</Badge>) : transaction.status == 'pending' ? (<Badge color='warning'>pending</Badge>) : (<Badge>failed</Badge>)}</td>
                                  </tr>
                              ))
                          }
                      </table>
                  </div>
              </div>
            
            
         </div>
         </Card>
         )}
       
    </div>
    
  )
}
