import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Excecutives from './Executives';
import { FormHelperText } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary 
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }}  />}
    {...props}
    RemoveIcon={<RemoveIcon sx={{ fontSize: '0.9rem' }} />}
  />
))(({ theme }) => ({
 
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    float:"left",
    margin:0
  },
  '& .MuiAccordionSummary-RemoveIconWrapper': {
    transform: 'rotate(90deg)',
    float:"right",
    right:0
  },
  '& .MuiAccordionSummary-expandIcon': {
    position: 'absolute',
    right: '5%',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function ExecutiveAccordions(props) {
    console.log("propss",props)
  const [expanded, setExpanded] = React.useState('panel1');
 


  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : true);
  };
  

  return (
    <div>
      <Accordion   class="invoice-repeater" expanded={expanded === "panel1"} onChange={handleChange(props.sd)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{border:1}} >
      <div>
          <Typography >First Executive</Typography>
          
          <FormHelperText>Click to open form</FormHelperText>
        </div>
       <div>
          <Typography className="float-right"> <RemoveIcon onClick={()=>props.remove(props.index)}/></Typography>
          </div>
     
          
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           <Excecutives/>
          </Typography>
        </AccordionDetails>
      </Accordion>
     
    </div>
  );
}
