import Banner from '../banner/images/banner.png'
function Companies() {
    return (
      <div>
        <section class="banner__alt bg__img" style={{ backgroundImage:`url(${Banner})` }}>
            <div class="container">
                <div class="banner__alt__area">
                    <h1 class="neutral-top neutral-bottom">Browse Companies</h1>
                </div>
            </div>
        </section>
      </div>
    );
  }
  
  export default Companies;
  
  
