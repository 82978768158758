// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import AvatarGroup from "@mui/material/AvatarGroup";
import Badge from "@mui/material/Badge";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AddTaskIcon from "@mui/icons-material/AddTask";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Modal, Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { imageurl } from "../../services/Api";
import Invest from "./investment/Index";
import React from "react";
import Moment from "react-moment";
import { getUser } from "../../services/UseTokens";
import ExistInvestProject from "./investment/ExistingInvestor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid ##5927e3",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const commonStyles = {
  bgcolor: "background.paper",
  m: 1,
  borderColor: "text.primary",
  width: "5rem",
  height: "5rem",
};
const ProjectDetails = ({ project }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = getUser();
  return (
    <div style={{ margin: "15px" }}>
      <Card sx={{ position: "relative" }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          p={2}
        >
          <Avatar
            alt={project?.project_name}
            src={`${imageurl}${project?.project_header_image}`}
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
            variant="rounded"
            sx={{
              height: 105,
              width: 105,
              bgcolor: "#5927e3",
            }}
          />
          <Typography
            variant="h6"
            sx={{ m: 1, fontSize: "32px", fontWeight: "500", color: "#5927e3" }}
          >
            {project?.project_name}
          </Typography>
          <Badge
            badgeContent={project?.status}
            sx={{
              "& .MuiBadge-badge": { fontSize: 20, height: 25, minWidth: 26 },
            }}
            // sx={{ margin: "10px",fontSize:"24px" }}
            color={project?.status === "inactive" ? "error" : "success"}
          ></Badge>
        </Box>
        <CardContent>
          <Box
            sx={{
              marginX: "10px",
              mb: 8.75,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{ mr: 2, mb: 1, display: "flex", flexDirection: "column" }}
            >
              <Typography>
                <PeopleOutlineIcon sx={{ height: 40, width: 40 }} />
              </Typography>
              <Typography
                variant="caption"
                style={{ fontSize: "20px" }}
              >{`${project?.no_of_investors} Investors`}</Typography>
            </Box>
            <Box
              sx={{ mr: 2, mb: 1, display: "flex", flexDirection: "column" }}
            >
              <Typography>
                <AddTaskIcon sx={{ height: 40, width: 40 }} />
              </Typography>
              <Typography
                variant="caption"
                style={{ fontSize: "20px" }}
              >{`${project?.project_milestones?.length} Milestones`}</Typography>
            </Box>

            <Box>
              <Box
                sx={{
                  borderBottom: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  color="#5927e3"
                  variant="h6"
                  style={{ fontSize: "24px", fontWeight: 450 }}
                >
                  Details
                </Typography>
              </Box>

              <Paper elevation={0}>
                {/* <Typography variant="h5">Details</Typography> */}
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                  // alignItems="center"
                >
                  <Grid item xs={5}>
                    <Box
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography
                        variant="paragraph"
                        noWrapstyle={{ fontSize: "20px" }}
                      >
                        Name{" "}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Box
                      xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography
                        variant="caption"
                        noWrap
                        style={{ fontSize: "18px" }}
                      >
                        {project?.project_name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Box
                      xs={6}
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography
                        variant="paragraph"
                        noWrap
                        style={{ fontSize: "20px" }}
                      >
                        Sector{" "}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Box
                      xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography
                        variant="caption"
                        noWrap
                        style={{ fontSize: "18px" }}
                      >
                        {project?.sector?.name}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Box
                      xs={6}
                      style={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      <Typography
                        variant="paragraph"
                        noWrap
                        style={{ fontSize: "20px" }}
                      >
                        Deadline{" "}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Box
                      xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Typography variant="caption" noWrap>
                        <Moment
                          format="YYYY-MM-DD"
                          style={{ fontSize: "18px" }}
                        >
                          {project?.closing_date}
                        </Moment>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "15px",
            }}
          >
            {project && project?.created_by !== user?.id ? (
              <Button
                onClick={handleOpen}
                variant="outlined"
                style={{ color: "#5927e3" }}
              >
                Invest Now
              </Button>
            ) : null}
          </Box>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              {user?.iprs_verified === 1 ? <ExistInvestProject /> : <Invest />}
              {/* <Invest/> */}
            </Box>
          </Modal>
        </CardContent>
      </Card>
    </div>
  );
};

export default ProjectDetails;
