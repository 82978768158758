import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import { Button, Card, CardContent, Modal } from '@mui/material';
import AddRisk from './AddRisk';
import { AddCircleOutline } from '@material-ui/icons';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  border: '2px solid #5927e3',
  boxShadow: 24,
}

function Risk ({risks}) {
  console.log({risks})
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Box style={{display:"flex",justifyContent:"flex-end"}}>
        <Button variant="outlined" onClick={handleClickOpen} sx={{color:"#5927e3"}} startIcon={<AddCircleOutline />}
        >Add Risks</Button>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
          <Card>
            <Box sx={style}>
               <CardContent> <AddRisk handleClose={handleClose}/></CardContent> 
            </Box>
            </Card>
        </Modal>
     
        </Box>
        {
          risks.map((risk, index) => (
            <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" style={{fontWeight: "600", textTransform: "Capitalize"}}>{risk.risk_title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography variant="h6">Risk Description</Typography>
          <Typography variant="paragraph">
            {risk.risk_description}
          </Typography>

          <Typography variant="h6">Risk Mitigation</Typography>
          <Typography variant="paragraph">
            {risk.mitigation_description}
          </Typography>
        </AccordionDetails>
      </Accordion>
          ))
        }    
    </div>
  );
}

export default Risk;
