import React from 'react'
import Company from '../../pages/Company/Index'
import Footer from '../footer'
import TopNav from '../topnav'
export default function CompanyLayout() {
  return (
    <div>
        <TopNav />
        <Company />
        <Footer />
    </div>
  )
}
