import React from 'react'
import ProjectDocumentTable from './ProjectDocumentTable'
import Statistics from './Statistics'

export default function ProjectDocuments() {
  return (
    <div>
         <div class="main__content-dashboard">
                            <div class="breadcrumb-dashboard">
                                <h5>Investments</h5>
                                <div>
                                    <a href="index.html">Home</a>
                                    <i class="fa-solid fa-arrow-right-long"></i>
                                    <a href="javascript:void(0)">Investments</a>
                                </div>
                            </div>
                            <div class="row p-4">
                                <div class="col-lg-8">
                                   <ProjectDocumentTable/>
                                </div>
                                <div class="col-lg-4">
                                    <Statistics/>
                                </div>
                            </div>
                            {/* <Graph/> */}
                        </div>
    </div>
  )
}
