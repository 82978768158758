// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

// ** Icons Imports
import TrendingUp from "mdi-material-ui/TrendingUp";
import CurrencyUsd from "mdi-material-ui/CurrencyUsd";
import DotsVertical from "mdi-material-ui/DotsVertical";
import CellphoneLink from "mdi-material-ui/CellphoneLink";
import AccountOutline from "mdi-material-ui/AccountOutline";

const salesData = [
  {
    stats: "245k",
    title: "Projects listed",
    color: "#5927e3",
    icon: (
      <TrendingUp sx={{ fontSize: "1.75rem", backgroundColor: "#5927e3" }} />
    ),
  },
  {
    stats: "12.5k",
    title: "Investors",
    color: "success",
    icon: <AccountOutline sx={{ fontSize: "1.75rem" }} />,
  },
  {
    stats: "1.54k",
    color: "secondary",
    title: "Shares Remaining",
    icon: <CellphoneLink sx={{ fontSize: "1.75rem" }} />,
  },
  {
    stats: "$88k",
    color: "warning",
    title: "Invested",
    icon: <CurrencyUsd sx={{ fontSize: "1.75rem" }} />,
  },
];

const renderStats = () => {
  return salesData.map((item, index) => (
    <Grid item xs={12} md={6} lg={3} key={index}>
      <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          variant="rounded"
          sx={{
            mr: 3,
            width: 44,
            height: 44,
            boxShadow: 3,
            color: "common.white",
            backgroundColor: `${item.color}.main`,
          }}
        >
          {item.icon}
        </Avatar>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption">{item.title}</Typography>
          <Typography variant="h6">{item.stats}</Typography>
        </Box>
      </Box>
    </Grid>
  ));
};

const TopSummaries = ({ totalProjects, totalInvestors, totalInvested, remainingInvestment, percentageChange }) => {
  return (
    <Card>
      <CardHeader
        title="Statistics Card"
        action={
          <IconButton
            size="small"
            aria-label="settings"
            className="card-more-options"
            sx={{ color: "text.secondary" }}
          >
            <DotsVertical />
          </IconButton>
        }
        subheader={
          <Typography variant="body2">
            <Box
              component="span"
              sx={{ fontWeight: 600, color: "text.primary" }}
            >
              {`Total ${percentageChange}% growth`}
            </Box>{" "}
            😎 this month
          </Typography>
        }
        titleTypographyProps={{
          sx: {
            mb: 2.5,
            lineHeight: "2rem !important",
            letterSpacing: "0.15px !important",
          },
        }}
      />
      <CardContent sx={{ pt: (theme) => `${theme.spacing(3)} !important` }}>
        <Grid container spacing={[5, 0]}>
          {/* {renderStats()} */}
          <Grid item xs={12} md={6} lg={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="rounded"
                sx={{
                  mr: 3,
                  width: 44,
                  height: 44,
                  boxShadow: 3,
                  color: "common.white",
                  backgroundColor: `#5927e3.main`,
                }}
              >
                <TrendingUp
                  sx={{ fontSize: "1.75rem", backgroundColor: "#5927e3" }}
                />
              </Avatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">Projects Listed</Typography>
                <Typography variant="h6">{totalProjects}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="rounded"
                sx={{
                  mr: 3,
                  width: 44,
                  height: 44,
                  boxShadow: 3,
                  color: "common.white",
                  backgroundColor: `success.main`,
                }}
              >
                <AccountOutline sx={{ fontSize: "1.75rem" }} />
              </Avatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">Investors</Typography>
                <Typography variant="h6">{totalInvestors}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="rounded"
                sx={{
                  mr: 3,
                  width: 44,
                  height: 44,
                  boxShadow: 3,
                  color: "common.white",
                  backgroundColor: `secondary.main`,
                }}
              >
                <CellphoneLink sx={{ fontSize: "1.75rem" }} />
              </Avatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">Investment Remaining</Typography>
                <Typography variant="h6">{remainingInvestment}</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="rounded"
                sx={{
                  mr: 3,
                  width: 44,
                  height: 44,
                  boxShadow: 3,
                  color: "common.white",
                  backgroundColor: `warning.main`,
                }}
              >
                <CurrencyUsd sx={{ fontSize: "1.75rem" }} />
              </Avatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">Invested</Typography>
                <Typography variant="h6">{totalInvested}</Typography>
              </Box>
            </Box>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
};

export default TopSummaries;
