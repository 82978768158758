import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { requests } from "../../services/Api";
import { Formik, Form } from "formik";
import ReactPhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import { Button, Input, InputGroup, Spinner } from "reactstrap";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import image from "../../assets/images/registration-bg.png";
import { responsiveFontSizes } from "@mui/material";
import { setUserToken } from "../../services/UseTokens";

function Register() {
  const [phone_number, setPhone_number] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");
  const [successResponse, setSuccessResponse] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [terms, setTerms] = useState(false);
  const navigate = useNavigate();
  const RegisterUser = async (values) => {
    try {
      setLoading(true);
      await requests.post(`/auth/register`, values).then((response) => {
        setLoading(false);
        const { data } = response;

        setUserToken(response.token);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "User registered successfully",
          confirmButtonText: "Ok",
        });
        setSuccessResponse(
          "you have been registered successfully.checkyour phone and enter the code you have been sent"
        );
        setTimeout(() => {
          setSuccessResponse("");
          navigate("/verify-email");
        }, 5000);
      });
    } catch (error) {
      setLoading(false);
      setServerError(error.response.data.message);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
        confirmButtonText: "Ok",
      });
      setTimeout(() => {
        setServerError("");
      }, 5000);
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <div class="wrapper bg__img" data-background={image}>
        <section class="registration ">
          <div class="container">
            <div class="registration__area text-center">
              <h4 class="neutral-top mt-1 text-center">Registration</h4>

              <Formik
                initialValues={{
                  email: "",
                  name: "",
                  password: "",
                  password_confirmation: "",
                  remeber__pass: false,
                }}
                validationSchema={Yup.object({
                  first_name: Yup.string().required("Required"),
                  middle_name: Yup.string().required("Required"),
                  last_name: Yup.string().required("Required"),
                  // phone_number:Yup.string().min(10,"number too short").required("required"),
                  email: Yup.string()
                    .email("invalid email")
                    .required("required"),
                  password: Yup.string()
                    .min(6, "Password too short")
                    .required("Required"),
                  password_confirmation: Yup.string().oneOf(
                    [Yup.ref("password"), null],
                    "Passwords do not match"
                  ),
                })}
                onSubmit={(values, { resetForm }) => {
                  values["phone_number"] = phone_number;
                  if (terms === false) {
                    Swal.fire({
                      icon: "error",
                      title: "Error",
                      text: "Please accept terms and conditions",
                      confirmButtonText: "Ok",
                    });
                    return;
                  } else {
                    RegisterUser(values);
                    resetForm({ values: "" });
                  }
                }}
              >
                {({
                  values,
                  isSubmitting,
                  errors,
                  handleSubmit,
                  handleChange,
                  dirty,
                  isValid,
                }) => (
                  <Form>
                    <div class="regi__type"></div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="input input--secondary">
                          <label for="firstName">First Name*</label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter your first name"
                            id="first_name"
                            name="first_name"
                            onChange={handleChange}
                            value={values.first_name}
                          />
                          <div
                            class="text-capitalize"
                            style={{ color: "red", marginTop: "12px" }}
                          >
                            {errors.first_name}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="input input--secondary">
                          <label for="middleName">Middle Name*</label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter your middle name"
                            id="middle_name"
                            name="middle_name"
                            onChange={handleChange}
                            value={values.middle_name}
                          />
                          <div
                            class="text-capitalize"
                            style={{ color: "red", marginTop: "12px" }}
                          >
                            {errors.middle_name}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="input input--secondary">
                          <label for="lastName">Last Name*</label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter last name"
                            id="last_name"
                            name="last_name"
                            onChange={handleChange}
                            values={values.last_name}
                          />
                          <div
                            class="text-capitalize"
                            style={{ color: "red", marginTop: "12px" }}
                          >
                            {errors.last_name}
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="input input--secondary ">
                          <label for="lastName">Phone Number*</label>
                          <div
                            class="input input--secondary"
                            style={{ maxWidth: "350px" }}
                          >
                            <ReactPhoneInput
                              defaultCountry="ke"
                              country="ke"
                              inputClass="form-control input input--secondary"
                              inputStyle={{ width: "250px", height: "24px" }}
                              required
                              containerStyle={{ marginRight: "35px" }}
                              searchClass="search-class"
                              searchStyle={{
                                margin: "0",
                                width: "85%",
                                height: "30px",
                              }}
                              enableSearchField
                              disableSearchIcon
                              value={phone_number}
                              onChange={(e) => setPhone_number(e)}
                            />
                          </div>

                          <div
                            class="text-capitalize"
                            style={{ color: "red", marginTop: "12px" }}
                          >
                            {errors.phone}
                          </div>
                        </div>
                      </div>
                      <div class="input input--secondary col-sm-12">
                        <label for="registrationMail">Email*</label>
                        <Input
                          type="email"
                          className="form-control"
                          placeholder="example@gamil.com"
                          id="email"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                        />
                        <div
                          class="text-capitalize"
                          style={{ color: "red", marginTop: "12px" }}
                        >
                          {errors.email}
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <div class="input input--secondary">
                          <label for="regiPass">Password*</label>
                          <div class="input-group">
                            <InputGroup>
                              <Input
                                type={
                                  showPassword2 === true ? "text" : "password"
                                }
                                className="form-control pe-5"
                                placeholder="Password"
                                id="password-input"
                                required
                                name="password"
                                onChange={handleChange}
                                value={values.password}
                              />
                              <Button
                                color="secondary"
                                onClick={() => setShowPassword2(!showPassword2)}
                              >
                                {showPassword2 === true ? "Hide" : "Show"}
                              </Button>
                              <div
                                className="text-capitalize"
                                style={{ color: "red", marginTop: "12px" }}
                              >
                                {errors.password}
                              </div>
                            </InputGroup>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="   input input--secondary ">
                          <label for="passCon">Password Confirmation*</label>
                          <div className="input-group">
                            <InputGroup>
                              <Input
                                type={
                                  showPassword === true ? "text" : "password"
                                }
                                className="form-control pe-5"
                                placeholder="Password Confirmation"
                                id="password-input"
                                required
                                name="password_confirmation"
                                onChange={handleChange}
                                value={values.password_confirmation}
                              />
                              <Button
                                color="secondary"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword === true ? "Hide" : "Show"}
                              </Button>
                              <div
                                className="text-capitalize"
                                style={{ color: "red", marginTop: "12px" }}
                              >
                                {errors.password_confirmation}
                              </div>
                            </InputGroup>
                          </div>
                        </div>
                      </div>
                      <div class="checkbox login__checkbox">
                        <label>
                          <input
                            type="checkbox"
                            id="remeberPass"
                            name="remeber__pass"
                            value="remember"
                            onChange={(e) => setTerms(true)}
                          />
                          <span class="checkmark"></span>
                          Accept terms and conditions
                        </label>
                      </div>
                      {!terms && (
                        <div style={{ color: "red", marginTop: "12px" }}>
                          Accept terms and conditions
                        </div>
                      )}
                    </div>

                    {!loading && (
                      <div className="mt-4">
                        <Button
                          className="button button--effect w-100"
                          type="submit"
                        >
                          Sign Up
                        </Button>
                      </div>
                    )}
                    {loading && (
                      <div className="mt-4">
                        <Button
                          color="secondary"
                          className="btn-load w-100"
                          outline
                          disabled
                        >
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">Loading...</span>
                            <Spinner
                              size="sm"
                              type="grow"
                              className="flex-shrink-0"
                              role="status"
                            >
                              {" "}
                              Loading...{" "}
                            </Spinner>
                          </span>
                        </Button>
                      </div>
                    )}
                    <p>
                      Already Registered? <a href="/login">Login</a>
                    </p>
                    <p class="fw-bold mt-4">
                      <a href="/">Back to home</a>
                    </p>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Register;
