import { TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Input, Spinner } from 'reactstrap'
import image from "../../assets/images/registration-bg.png"
import ResetPasswordAct from '../../redux/actions/auth/ResetPassword/ResetPassAct'

function ChangePassword() {
    const [successResponse,setSuccessResponse] = React.useState("")
  const [serverError,setServerError] = React.useState("")
  const [loading,setLoading]=React.useState(false)
  const [password,setPassword]=React.useState("")
  const [confirmPassword,setConfirmPassword]=React.useState("")
  const dispatch=useDispatch()
  const {token,email}=useParams()

    const data = {
      password: password,
      password_confirmation: confirmPassword,
      token: token,
      email:email
    }


  return (
    <div>
    <div class="wrapper bg__img" data-background={image}>


      <section class="registration ">
        <div class="container">

          <div class="registration__area">

            <h4 class="neutral-top text-center">Set a new password</h4>
            {successResponse && (
              <div style={{ color: "green", fontSize: "25px" }}>
                {successResponse && (<p style={{ color: "green" }}>{successResponse}</p>)}
              </div>

            )}
            {serverError && (
              <div style={{ color: "red", fontSize: "25px" }}>
                {serverError}
              </div>
            )}

            <Formik
              initialValues={{
                "password_confirmation": "",
                "password":""
                
              }}
            
             >
              {({ values, isSubmitting, errors, handleSubmit, handleChange, isValid,
                dirty }) => (
                <Form >
                  <div class="input input--secondary">
                    {/* <label for="loginMail">Password *</label> */}
                    {/* <Input  */}
                    <TextField  variant="outlined"
                    label="Current Password"
                    type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter your default password"
                      required
                      name="password"
                      onChange={(e)=>setPassword(e.target.value)}
                      value={password} />
                  </div>
                  <div class="input input--secondary">
                    {/* <label for="loginMail">Password *</label> */}
                    {/* <Input  */}
                    <TextField  variant="outlined"
                    label="New Password"
                    type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter your New password"
                      required
                      name="password"
                      onChange={(e)=>setPassword(e.target.value)}
                      value={password} />
                  </div>
                  <div class="input input--secondary">
                    {/* <label for="loginMail">Password *</label> */}
                    {/* <Input  */}
                    <TextField variant='outlined'
                    type="password"
                    label="Confirm Password"
                      className="form-control"
                      id="password_confirmation"
                      placeholder="confirm your password"
                      required
                      name="password_confirmation"
                      onChange={(e)=>setConfirmPassword(e.target.value)}
                      value={confirmPassword} />
                  </div>               
                 
                  {
                    loading && (
                      <div className="mt-4">

                        <Button color="secondary" className="btn-load w-100" outline disabled>
                          <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">
                              Loading...
                            </span>
                            <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                          </span>
                        </Button>
                      </div>

                    )
                  }

                  {!loading && (
                    <div className="mt-4">
                      <Button type="button" onClick={(e)=>{
                        dispatch(ResetPasswordAct(data))
                        }}  color="secondary" className="button button--effect w-100" >Submit</Button>
                    </div>
                  )}
                 
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </div>
  </div>
  )
}

export default ChangePassword