import { DELETE_OWNERDOCS_FAILURE,DELETE_OWNERDOCS_REQUEST,DELETE_OWNERDOCS_SUCCESS } from "../../../actions/Documents/projectsowner/OwnerTypes";

const initialState={
    loading:false,
    error:"",
    documents:{}
}

const DeleteOwnerDocReducer=(state=initialState,action)=>{
    switch (action.type) {
        case DELETE_OWNERDOCS_REQUEST:
            return {
                ...state,
                loading:true,
            
            }
            
            break;  
        case DELETE_OWNERDOCS_SUCCESS:
            return {
                ...state,
                documents:action.payload,
                
            }
            break;
        case DELETE_OWNERDOCS_FAILURE:
            return {
                ...state,
                error:action.payload
            }
            break;
    
        default:
            return state;
            break;
    }
}
export default DeleteOwnerDocReducer;