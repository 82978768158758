import Banner from '../banner/images/projects.jpg'
function Projects() {
    return (
      <div style={{background:"linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgb(	0, 172, 223))",}}>
        <section class="banner__alt bg__img" style={{ backgroundImage:`linear-gradient(to bottom, rgba(245, 246, 252, 0.52), #5e5ee2),url(${Banner})`,height:"70vh" }}>
            <div class="container">
                <div class="banner__alt__area">
                    <h1 class="neutral-top neutral-bottom ">My projects</h1>
                </div>
            </div>
        </section>
      </div>
    );
  }
  
  export default Projects;
  