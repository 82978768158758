import { UPDATE_COMPANY_REQUEST, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE } from "./companyTypes";
import { requests} from  "../../../../services/Api"
import { getToken} from  "../../../../services/UseTokens"
import Swal from "sweetalert2";
const updateCompanyAction = (id,company) => async (dispatch) => {
    console.log("update Company",company)
    const token=getToken();
    dispatch({ type: UPDATE_COMPANY_REQUEST });
    try {
        const response = await requests.put(`company/update-company/${id}`,company, {
            
            headers: {
                Authorization : `Bearer ${token}`
                // "Content-Type": "application/json"
            },
            
        });
        const data = await response.data();
        dispatch({ type: UPDATE_COMPANY_SUCCESS, payload: data });
        Swal.fire({
            "title": "Success",
            "text": "Company updated successfully",
            "type": "success",
            "confirmButtonText": "OK"
        })
    } catch (error) {
        dispatch({ type: UPDATE_COMPANY_FAILURE, payload: error.message });
        Swal.fire({
            "title": "Error",
            "text": error,
            "type": "error",
            "confirmButtonText": "OK"
        })
    }
}

export default updateCompanyAction;