export const FETCH_INVESTOR_REQUEST = "FETCH_INVESTOR_REQUEST";
export const FETCH_INVESTOR_SUCCESS = "FETCH_INVESTOR_SUCCESS";
export const FETCH_INVESTOR_FAILURE = "FETCH_INVESTOR_FAILURE";

export const CREATE_INVESTOR_REQUEST = "CREATE_INVESTOR_REQUEST";
export const CREATE_INVESTOR_SUCCESS = "CREATE_INVESTOR_SUCCESS";
export const CREATE_INVESTOR_FAILURE = "CREATE_iNVESTOR_FAILURE";

export const UPDATE_INVESTOR_REQUEST = "UPDATE_INVESTOR_REQUEST";
export const UPDATE_INVESTOR_SUCCESS = "UPDATE_INVESTOR_SUCCESS";
export const UPDATE_INVESTOR_FAILURE = "UPDATE_iNVESTOR_FAILURE";

export const DELETE_INVESTOR_REQUEST = "DELETE_INVESTOR_REQUEST";
export const DELETE_INVESTOR_SUCCESS = "DELETE_INVESTOR_SUCCESS";
export const DELETE_INVESTOR_FAILURE = "DELETE_iNVESTOR_FAILURE";