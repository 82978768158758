import Test from "../../pages/configurables/test";
import Home from "../../pages/home";
import ListofProjects from "../../pages/ListProperty/Index";
import Footer from "../footer";
import TopNav from "../topnav";

function CreateProjectsLayout() {
  return (
    <div className="">
         <TopNav/>
      <ListofProjects />
     
      <Footer />
    </div>
  );
}

export default CreateProjectsLayout;