import { FETCH_COMPANY_FAILURE, FETCH_COMPANY_REQUEST, FETCH_COMPANY_SUCCESS } from "./companyTypes"
import _ from 'lodash'
import { requests } from "../../../services/Api";


export const fetchCompany = (id, token) => async (dispatch) => {
    console.log("id", id)
    try {
        dispatch({
            type: FETCH_COMPANY_REQUEST
        })
        
        const response = await requests.get("/company/get-companies", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        
        const companies = _.filter(response.data, {user_id: id})
        //restructure companies to have name and id
        const companiesWithName = companies.map(company => {
            return {
                id: company.id,
                name: company.company_name
            }
        })
        dispatch({
            type: FETCH_COMPANY_SUCCESS,
            payload: companiesWithName
        })
    } catch (error) {
        dispatch({
            type: FETCH_COMPANY_FAILURE,
            payload: error
        })
    }
}