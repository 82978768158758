export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";

export const FETCH_USERS_COMPANIES_REQUEST = "FETCH_USERS_COMPANIES_REQUEST";
export const FETCH_USERS_COMPANIES_SUCCESS = "FETCH_USERS_COMPANIES_SUCCESS";
export const FETCH_USERS_COMPANIES_FAILURE = "FETCH_USERS_COMPANIES_FAILURE";

export const DELETE_SINGLE_COMPANIES_REQUEST = "DELETE_SINGLE_COMPANIES_REQUEST";
export const DELETE_SINGLE_COMPANIES_SUCCESS = "DELETE_SINGLE_COMPANIES_SUCCESS";
export const DELETE_SINGLE_COMPANIES_FAILURE = "DELETE_SINGLE_COMPANIES_FAILURE";