import { UPDATE_SINGLE_DOCUMENT_REQUEST,UPDATE_SINGLE_DOCUMENT_SUCCESS,UPDATE_SINGLE_DOCUMENT_FAILURE } from "../../../actions/Documents/projectDocuments/DocumentsTypes";

const initialState={
    loading:false,
    error:"",
    projectDocument:{}
}
const projectDocumentReducer=(state=initialState,action)=>{
    switch (action.type) {
        case UPDATE_SINGLE_DOCUMENT_REQUEST:
            return {...state,
                loading:true
            }
            break;
        case UPDATE_SINGLE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading:false,
                projectDocument:action.payload
            }
            break;
        case UPDATE_SINGLE_DOCUMENT_FAILURE:
            return{
                ...state,
                error:action.payload
            }
            
            break;
    
        default:
            return state;
            break;
    }
}
export default projectDocumentReducer;