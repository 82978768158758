import React, { Component } from "react";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';


function Map ( {center}) { 
    console.log(typeof(center.lat),"center")
    const containerStyle = {
        width: '400px',
        height: '400px'
      };

    return (
       <div>
         
          <GoogleMap
         
          
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
      >
  <Marker
      icon={{
        path:
          "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
        fillColor: "yellow",
        fillOpacity: 0.9,
        scale: 2,
        strokeColor: "gold",
        strokeWeight: 2,
      }}
      position={center}/>
      </GoogleMap>
          {/* </LoadScript> */}
       </div>
    );
    }

export default Map;
