import React, { useEffect, useState } from 'react'
import "./style.css";
import { DataGrid } from '@material-ui/data-grid'
import { Badge } from '@mui/material';


function CompanyDirectors({ directors }) {
    const [userExists, setUserExists] = useState(false)

    useEffect(() => {
        directors.forEach(object => { object.user_id === null ? setUserExists(false) : setUserExists(true) })
        if (userExists) {
            directors.forEach(object => {
                object.status = "active"
            })
        } else {
            directors.forEach(object => {
                object.status = "inactive"
            })
        }
    }, [directors])


    const columns = [
        // { field: 'id', headerName: 'ID', width: 90 },
        {

            field: 'name',
            headerName: 'Directors Name',
            width: 200,
        },
        // {
        //     field: 'identification_document_type',
        //     headerName: 'ID type',
        //     width: 120,
        // },
        // {
        //     field: 'identification_number',
        //     headerName: 'ID No ',
        //     width: 120,
        // },
        // {
        //     field: 'director_email',
        //     headerName: 'Email',
        //     width: 180,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 {params?.row?.user?.email}
        //             </>
        //         )
        //     }
        // },
        {
            field: 'status',
            headerName: 'Status',

            width: 120,
            renderCell: (params) => {
                return (
                    <>

                        <Badge badgeContent={params.row.user_id !== null ? "active" : "inactive"} color={params.row.user_id !== null ? "success" : "secondary"} style={{ margin: "15px" }}></Badge>
                    </>
                )
            }
        }
    ];



    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                disableSelectionOnClick
                rows={directors}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
            />
        </div>
    )
}

export default CompanyDirectors