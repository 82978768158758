import { requests } from "../../../services/Api";
import { USER_SHARES_FAILURE, USER_SHARES_REQUEST, USER_SHARES_SUCCESS } from "./userSharesTypes";

export const fetchUserShares = (id, token) => async (dispatch) => {
    try {
        dispatch({ 
            type: USER_SHARES_REQUEST 
        });

        const response = await requests.get(`/shares/user-shares/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        
        dispatch({
            type: USER_SHARES_SUCCESS,
            payload: response.data
        })

    } catch(error) {
        dispatch({
            type: USER_SHARES_FAILURE,
            payload: error.response.data.message
        })
    }
}