function Profit() {
  return (
    <div>
    <section class="profit section__space">
        <div class="container">
            <div class="profit__area wow fadeInUp">
                <div class="section__header">
                    <h5 class="neutral-top"><span>Built to help smart investors invest smarter</span></h5>
                    <h2>We handle the heavy lifting so you
                        can sit back, relax, and profit.</h2>
                    <p class="neutral-bottom">We make institutional quality real estate accessible to investors, in a
                        simple
                        and transparent way.</p>
                </div>
                <div class="profit__item__wrapper">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="profit__single__item alt shadow__effect">
                                <div class="img__box">
                                    <img src="assets/images/overview/passive-income.png" alt="Passive Income" />
                                </div>
                                <div class="item__content">
                                    <h4>Passive Income</h4>
                                    <p>Earn rental income and receive deposits quarterly</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="profit__single__item shadow__effect">
                                <div class="img__box">
                                    <img src="assets/images/overview/secure.png" alt="secure" />
                                </div>
                                <div class="item__content">
                                    <h4>Secure & Cost-efficient</h4>
                                    <p>Digital security is legally compliant and tangible for qualified investors</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="profit__item__wrapper">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="profit__single__item alt shadow__effect">
                                <div class="img__box">
                                    <img src="assets/images/overview/transparency.png" alt="Transparency" />
                                </div>
                                <div class="item__content">
                                    <h4>Transparency</h4>
                                    <p>Easily consult the full documentation for each property.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="profit__single__item shadow__effect">
                                <div class="img__box">
                                    <img src="assets/images/overview/support.png" alt="Support" />
                                </div>
                                <div class="item__content">
                                    <h4>Support</h4>
                                    <p>Earn rental income and receive deposits quarterly</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Profit;
