// get  the user data from session storage
export const getUser = () => {
  const userStr = localStorage.getItem("@investment.authuser");
  if (userStr) return JSON.parse(userStr);
  else return null;
};
// get user id
export const getUserId = () => {
  const userStr = localStorage.getItem("@investment.authuser");
  if (userStr) {
    return JSON.parse(userStr).id;
  } else return console.log("no active user id found");
};

// return tocken from session storage
export const getToken = () => {
  const userStr = localStorage.getItem("@investment.token");
  if (userStr) {
    return userStr || null;
    // return JSON.parse(localStorage.getItem("token"))|| null;
  }
};

// remove the token and user from session
export const removeUserSession = () => {
  localStorage.removeItem("@investment.authuser");
  localStorage.removeItem("@investment.token");
};

// set the tocken and user from session
export const setUserToken = (token) => {
  localStorage.setItem("@investment.token", token);
};

export const setUser = (user) => {
  localStorage.setItem("@investment.authuser", JSON.stringify(user));
};
