import React from 'react'

function HeroHome() {
  return (
    <div>
           <section class="hero hero--two pos__rel over__hi bg__img" data-background="assets/images/hero/hero-two-bg.png">
        <div class="container">
            <div class="hero__area">
                <div class="row">
                    <div class="col-lg-7 col-xxl-6">
                        <div class="hero__content">
                            <h5  style={{fontSize:"18px",lineHeight:"24px" ,marginTop:"-120px"}}><span>Smart. Simple. Accessible.</span></h5>
                            <h1><span>Investing Made </span>
                                 <span>Simple &</span> 
                                <span>Transparent</span>
                            </h1>
                            <p class="primary neutral-bottom">
                                Start growing your passive income today. Invest from as low as 100 EUR in real estate
                                opportunities worldwide.
                            </p>
                            <div class="hero__cta__group">
                                <a href="/projects" class="button button--effect" style={{color: 'white'}}>Start Exploring</a>
                                <a href="/list-your-project" class="button button--secondary button--effect">Get
                                    Funding</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xxl-6">
                        <div class="hero__illustration d-none d-lg-block">
                            <img src="assets/images/hero/hero-two-illustration.png" alt="Hero Illustration" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default HeroHome;
