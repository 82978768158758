import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { OnboardProjectContext } from './ProjectContext';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { requests } from '../../services/Api';
import Swal from 'sweetalert2';
import { getToken } from '../../services/UseTokens';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function Equity() {
  const { projectFinance, setProjectFinance, checked, setChecked } = useContext(OnboardProjectContext);

  return (
    <React.Fragment>
      <Grid item xs={12} md={2} lg={2} xl={2}>
        <FormGroup>
          <FormControlLabel control={
            <Checkbox onChange={(e) => {
              setChecked(1)
              setProjectFinance({ ...projectFinance, finance_method: 'equity' })
              }} checked={checked === 1 ? true : false }/>}
            label="Equity" />

        </FormGroup>
      </Grid>

      <Grid item xs={12} md={3} lg={3} xl={3}>
        {checked === 1 && (
          <TextField
            variant="outlined"
            label="Goal Amount"
            name="goal_amount"
            onChange={(e) => setProjectFinance({ ...projectFinance, goal_amount: e.target.value })}
            fullWidth required />
        )}
      </Grid>

      <Grid item xs={12} md={3} lg={3} xl={3}>
        {checked === 1 && (
          <TextField
            variant="outlined"
            label="Maximum Investment"
            name="maximum_investment"
            onChange={(e) => setProjectFinance({ ...projectFinance, maximum_investment: e.target.value })}
            fullWidth required />
        )}
      </Grid>

      <Grid item xs={12} md={3} lg={3} xl={3}>
        {checked === 1 && (
          <TextField
            variant="outlined"
            label="Minimum Investment"
            name="minimum_investment"
            onChange={(e) => setProjectFinance({ ...projectFinance, minimum_investment: e.target.value })}
            fullWidth required />
        )}
      </Grid>
    </React.Fragment>
  );
}
function Debt() {
  const { projectFinance, setProjectFinance, checked, setChecked } = useContext(OnboardProjectContext);
  return (
    <React.Fragment>
      <Grid item xs={12} md={2} lg={2} xl={2} >
        <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={(e) => {
              setChecked(2)
              setProjectFinance({ ...projectFinance, finance_method: 'debt' })
              }} checked={checked === 2 ? true : false } />}
            label="Debt" />
        </FormGroup>
      </Grid>


      <Grid item xs={12} md={4} lg={3} xl={3} >
        {checked === 2 && (
          <TextField
            variant="outlined"
            name="goal_amount"
            label="Goal Amount"
            onChange={(e) => setProjectFinance({ ...projectFinance, goal_amount: e.target.value })}
            fullWidth required />
        )}
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3} >
        {checked === 2 && (
          <TextField
            variant="outlined"
            name='maximum_investment'
            label="Maximum Investment"
            onChange={(e) => setProjectFinance({ ...projectFinance, maximum_investment: e.target.value })}
            fullWidth required />
        )}
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3} >
        {checked === 2 && (
          <TextField
            variant="outlined"
            label="Minimum Investment"
            name="minimum_investment"
            onChange={(e) => setProjectFinance({ ...projectFinance, minimum_investment: e.target.value })}
            fullWidth required />
        )}
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3} >
        {checked === 2 && (
          <TextField
            variant="outlined"
            label="Debt Interest"
            name='debt_interest'
            onChange={(e) => setProjectFinance({ ...projectFinance, debt_interest: e.target.value })}
            fullWidth required />
        )}
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3} >
        {checked === 2 && (
          <TextField
            variant="outlined"
            label="Repayment Period"
            name='debt_repayment_period'
            onChange={(e) => setProjectFinance({ ...projectFinance, debt_repayment_period: e.target.value })}
            fullWidth required />
        )}
      </Grid>
    </React.Fragment>
  );
}
function RevenueShare() {
  const { projectFinance, setProjectFinance, checked, setChecked } = useContext(OnboardProjectContext);
  return (
    <React.Fragment>
      <Grid item xs={12} md={2} lg={2} xl={2} container spacing={1}>
        <FormGroup>
          <FormControlLabel control={<Checkbox onChange={
            (e) => {
              setChecked(3)
              setProjectFinance({ ...projectFinance, finance_method: 'revenue_share' })
              }
        
            } checked={checked === 3 ? true : false } />} label="revenue share" />

        </FormGroup>
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3} >
        {checked === 3 && (
          <TextField 
          variant="outlined" 
          label="Goal Amount" 
          onChange={(e) => setProjectFinance({ ...projectFinance, goal_amount: e.target.value })}
          fullWidth required />
        )}
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3} >
        {checked === 3 && (
          <TextField 
          variant="outlined" 
          label="Maximum Investment" 
          onChange={(e) => setProjectFinance({ ...projectFinance, maximum_investment: e.target.value })}
          fullWidth required />
        )}
      </Grid>
      <Grid item xs={12} md={3} lg={3} xl={3} >
        {checked === 3 && (

          <TextField 
          variant="outlined" 
          label="Minimum Investment" 
          onChange={(e) => setProjectFinance({ ...projectFinance, minimum_investment: e.target.value })}
          fullWidth required />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6} >
        {checked === 3 && (

          <TextField 
          variant="outlined" 
          label="Revenue Cap" 
          onChange={(e) => setProjectFinance({ ...projectFinance, revenue_repayment_cap: e.target.value })}
          fullWidth required />
        )}
      </Grid>
    </React.Fragment>
  );
}
export default function FinancialDetails() {
  const [loading, setLoading] = useState(false)
  const { company_id } = useParams();
  const navigate = useNavigate();
  const token = getToken()

  const {
    projectName,
    openingDate,
    closingDate,
    sector,
    address,
    projectHeader,
    projectDescription,
    whyInvest,
    projectFinance,
    lat,
    lng
  } = useContext(OnboardProjectContext);


  const addProject = async () => {
    const data = {
      project_name: projectName,
      company_id,
      created_by: 1,
      sector_id: sector,
      project_description: projectDescription,
      opening_date: openingDate,
      closing_date: closingDate,
      location: address,
      location_lat: lat,
      location_long: lng,
      project_header_image: projectHeader,
      project_finance: projectFinance,
      why_invest: whyInvest,

    }

    try {
      console.log('project-data', data)
      setLoading(true);
      const response = await requests.post(`projects/create-project`, data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })


      setLoading(false);


      Swal.fire({
        icon: 'success',
        title: 'Project created Successfully',
        confirmButtonText: 'Okay',
      })

      navigate(`/project-profile/${response.data.id}`)


    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: error.response.data.message,
        showConfirmButton: true,
      }).then((result) => {
        //check if error status is 401
        if (error.response.status === 401) {
          //remove token
          localStorage.clear();
          //redirect to login
          navigate('/login')
        }
      })
    }
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid container item spacing={1} sx={{ margin: "15px" }}>
          <Equity />
        </Grid>
        <Grid container item spacing={1} sx={{ margin: "15px" }}>
          <RevenueShare />
        </Grid>
        <Grid container item spacing={1} sx={{ margin: "15px" }}>
          <Debt />
        </Grid>
        <Button
          style={{
            backgroundColor: "#5927e3"
          }}
          variant="contained"
          onClick={() => addProject()}
          sx={{ mt: 3, ml: 1, textTransform: 'Capitalize' }}
        >
          Submit
        </Button>
      </Grid>
    </Box>
  );
}
