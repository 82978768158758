import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { Monitor, Tablet, ArrowDown, ArrowUp } from "react-feather";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Card, CardHeader, CardTitle, CardBody } from "reactstrap";

const RaisedAmount = ({
  tooltipShadow,
  successColorShade,
  warningLightColor,
  primary,
  cdata,
}) => {
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  const labelss = ["red", "blue", "relow", "green", "blue", "pink", "maroon"];

  const options = {
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      cutoutPercentage: 60,
      legend: { display: false },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            const label = data.datasets[0].labels[tooltipItem.index] || "",
              value = data.datasets[0].data[tooltipItem.index];
            const output = ` ${label} : ${value} %`;
            return output;
          },
        },
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: tooltipShadow,
        backgroundColor: "#fff",
        titleFontColor: "#000",
        bodyFontColor: "#000",
      },
    },
    data = {
      datasets: [
        {
          labels: cdata?.investments_label,
          data: cdata?.investments_value,
          backgroundColor: [
            "#ebac23",
            "#b80058",
            "#008cf9",
            "#006e00",
            "#d163e6",
            "#b24502",
            "#ff9287",
            "#5964d6",
            "#00c6f8",
          ],
          //   [ "#5927e3","#D3D3D3",],
          borderWidth: 0,
          pointStyle: "rectRounded",
        },
      ],
    };

  return (
    <Card>
      {/* <CardHeader className='d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column'>
        <CardTitle tag='h4'>Raised Amount Progress</CardTitle>
      </CardHeader> */}
      <CardBody>
        <div style={{ height: "275px" }}>
          <Doughnut data={data} options={options} height={275} />
        </div>
        {/* <div className="d-flex justify-content-between mt-3 mb-1">
          <div className="d-flex align-items-center">
            <HorizontalRuleIcon size={17} style={{ color: "#ebac23" }} />
            <span className="font-weight-bold ml-75 mr-25">Raised Amount</span>
            <span>- 80%</span>
          </div>
          <div>
            <span>2%</span>
            <ArrowUp className="text-success" size={14} />
          </div>
        </div> */}

        {/* {cdata && cdata?.investments && cdata?.investments.map((item, index) => (
          <div className="d-flex justify-content-between mb-1" key={index}>
            <div className="d-flex align-items-center">
              <HorizontalRuleIcon size={17} style={{ color: "#d163e6" }} />
              <span className="font-weight-bold ml-75 mr-25">{item}</span>
              <span>{
                cdata && cdata?.investments_value && cdata?.investments_value[index]
              }</span>
            </div>
          </div>
        ))} */}
      </CardBody>
    </Card>
  );
};

export default RaisedAmount;
