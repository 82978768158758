// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import AvatarGroup from "@mui/material/AvatarGroup";
import Badge from "@mui/material/Badge";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { Paper } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const commonStyles = {
  bgcolor: "background.paper",
  m: 1,
  borderColor: "text.primary",
  width: "5rem",
  height: "5rem",
};
const CompanyDetails = ({ company }) => {
  console.log("company", company);
  return (
    <div>
      <Card sx={{ position: "relative" }}>
        {/* <CardMedia sx={{ height: '12.625rem' }} image='/images/cards/background-user.png' /> */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          p={2}
        >
          <Avatar
            alt={`${company?.company_name}`}
            src="/images/avatars/1.png"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
            variant="rounded"
            sx={{
              height: 75,
              width: 75,
              bgcolor: "#5927e3",
            }}
          />
          <Typography variant="h6" style={{ fontSize: "24px" }} sx={{ m: 2 }}>
            {company?.company_name}
          </Typography>
          <Badge
            badgeContent={company?.status}
            style={{ fontSize: "16px" }}
            sx={{ marginX: "15px" }}
            color={company?.status === "inactive" ? "error" : "success"}
          ></Badge>
        </Box>
        <CardContent>
          <Box
            sx={{
              marginX: "15%",
              // mb: 8.75,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <Box
                  sx={{
                    mr: 2,
                    mb: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography style={{ fontSize: "20px" }}>
                    <PlaylistAddCheckIcon sx={{ height: 50, width: 50 }} />
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ fontSize: "16px" }}
                  >{`${company?.projects?.length} projects`}</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Box
                  sx={{
                    mr: 2,
                    mb: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography style={{ fontSize: "20px" }}>
                    <AddTaskIcon sx={{ height: 50, width: 50 }} />
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ fontSize: "16px" }}
                  >{`${company?.directors?.length} directors`}</Typography>
                </Box>
              </Grid>
            </Grid>

            <Box>
              <Box
                sx={{
                  borderBottom: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  color="#5927e3"
                  variant="h6"
                  style={{ fontSize: "24px", marginBottom: "20px", marginLeft: "-120px" }}
                >
                  Details
                </Typography>
              </Box>
              <Paper elevation={0}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={10}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Box
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="paragraph"
                          noWrap
                          style={{ fontSize: "16px" }}
                        >
                          Name{" "}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Box
                        xs={6}
                        style={{
                          marginLeft: "0px",
                        }}
                      >
                        <Typography variant="caption" noWrap style={{fontSize: "16px"}}>
                          {company?.company_name}
                        </Typography>
                      </Box>
                    </Grid>
                  </Stack>

                  <Stack direction="row" spacing={10}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Box
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="paragraph"
                          noWrap
                          style={{ fontSize: "16px" }}
                        >
                          Address 
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Box
                        xs={6}
                        style={{
                          marginLeft: "-80px",
                        }}
                      >
                        <Typography variant="caption" noWrap style={{ fontSize: "16px" }}>
                          {company?.company_location}
                        </Typography>
                      </Box>
                    </Grid>
                  </Stack>

                  <Stack direction="row" spacing={10}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Box
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="paragraph"
                          noWrap
                          style={{ fontSize: "16px" }}
                        >
                          Email{" "}
                        </Typography>
                      </Box>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box
                        xs={6}
                        style={{
                          marginLeft: "-36.5px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          noWrap
                          style={{ fontSize: "16px" }}
                        >
                          {company?.company_email}
                        </Typography>
                      </Box>
                    </Grid>
                  </Stack>

                  <Stack direction="row" spacing={10}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Box
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="paragraph"
                          noWrap
                          style={{ fontSize: "16px" }}
                        >
                          Mobile{" "}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Box
                        xs={6}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Typography
                          variant="caption"
                          noWrap
                          style={{ fontSize: "16px" }}
                        >
                          {company?.company_phone_number}
                        </Typography>
                      </Box>
                    </Grid>
                  </Stack>

                  <Stack direction="row" spacing={10}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="paragraph"
                          noWrap
                          style={{ fontSize: "16px" }}
                        >
                          Status{" "}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Box
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="caption"
                          noWrap
                          style={{ fontSize: "16px" }}
                        >
                          {company?.approved_status}
                        </Typography>
                      </Box>
                    </Grid>
                  </Stack>
                </Grid>
              </Paper>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default CompanyDetails;
