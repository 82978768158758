import { useEffect, useState } from 'react'
import React from 'react'
import Dropdown from '../../../../components/dropdown/dropdown.component';
import CompanyProfile from '../Index';

function List({ allCompanies }) {
    const [perPage, setPerPage] = useState({ name: "9", value: 9 })
    const [currentPage, setCurrentPage] = useState(1)


    //check number of pages
    const numPages = Math.ceil(allCompanies?.length / perPage?.value);
    const paginate = (array, pageSize, pageNumber) => {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }
    const companies = paginate(allCompanies, perPage?.value || 9, currentPage);

    useEffect(() => {
    }, [currentPage])

    const options = [
        { name: '9', value: 9 },
        { name: '18', value: 18 },
        { name: '27', value: 27 },
        { name: '36', value: 36 }
    ]


    return (
        <>
            <section class="properties__filter section__space__bottom">
                <div class="container wow fadeInUp">
                    <div class="properties__filter__wrapper">
                        <h6>Showing <span>{companies?.length}</span>  companies</h6>
                        <div class="grid__wrapper">
                            <label>Per Page</label>
                            <Dropdown
                                options={
                                    options
                                }
                                prompt="Per Page"
                                value={perPage}
                                onChange={(val) => setPerPage(val)}
                            />
                        </div>
                    </div>

                    <div class="row property__grid__area__wrapper">
                        <CompanyProfile allCompanies={companies}/>

                    </div>

                    {
                        currentPage === 1 && numPages === 1 ? null :
                            currentPage === 1 ? (
                                <div class="cta__btn">
                                    <button
                                        class="button button--effect m-4"
                                        onClick={() => {
                                            setCurrentPage(currentPage + 1)
                                        }}>Load More</button>
                                </div>

                            ) : numPages === currentPage || numPages < currentPage ? (
                                <div class="cta__btn">
                                    <button
                                        class="button button--effect m-4"
                                        onClick={() => {
                                            setCurrentPage(currentPage - 1)
                                        }}>Load Previous</button>
                                </div>
                            ) : (
                                <div class="cta__btn">
                                    <button
                                        class="button button--effect m-4"
                                        onClick={() => {
                                            setCurrentPage(currentPage - 1)
                                        }}>Load Previous</button>
                                    <button
                                        class="button button--effect m-4"
                                        onClick={() => {
                                            setCurrentPage(currentPage + 1)
                                        }}>Load More</button>
                                </div>

                            )
                    }

                </div>



            </section>
        </>
    );
}

export default List;
