import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import image from "../../assets/images/email2.png";
import { createTheme, makeStyles } from "@material-ui/core";
import { Box, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import ResendLinkAct from "../../redux/actions/auth/Twofactor/resendLinkAct";
import { getToken } from "../../services/UseTokens";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { requests } from "../../services/Api";

const useStyles = makeStyles((theme) => ({
  CardContent: {
    top: "auto",
    bottom: 0,
    textAlign: "center",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));
export default function VerifyEmail() {
  const token = getToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const resendLink = async() => {
    await requests.post(`/email/verify/resend`, {}, { 
      headers: { Authorization: `Bearer ${token}` } 
    }).then((response) => {
      Swal.fire({
        title: "Success!",
        text: "Verification link sent",
        icon: "success",
        confirmButtonText: "Ok",
      })
    }).catch((error) => {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Ok",
      })
    })
  };

  return (
    <div style={{ margin: "auto", minHeight: "100vh", padding: "3%" }}>
      <Card
        className={classes.CardContent}
        sx={{
          padding: "3%",
          boxShadow: 3,
          maxWidth: "75% ",
          marginX: "auto",
          marginY: "10%",
          width: "45%",
          height: "70%",
          padding: "auto",
        }}
      >
        <CardMedia
          class="float-center"
          style={{
            padding: "10px",
            height: "45%",
            width: "45%",
          }}
          component="img"
          image={image}
          alt="Paella dish"
        />
        <div class="">
          <CardContent className={classes.footer}>
            <Typography
              className={classes.footer}
              variant="h5"
              color="#5927e3"
              align="center"
            >
              Verify your Email
            </Typography>
            <br />
            <Typography
              style={{ fontSize: "0.8rem" }}
              variant="body2"
              color="text.secondary"
              align="center"
            >
              Hello..
              <br />
              Thank you for successfully registering your Company.
              <br />
              Check your email to verify your email <br />
              Link not recieved?
              <br />
              click resend button below.
            </Typography>
            <Box textAlign="center">
              <Button
                onClick={() => resendLink()}
                style={{
                  backgroundColor: "#5927e3",
                  color: "#fff",
                  margin: "15px",
                }}
                variant="contained"
              >
                Resend Link
              </Button>
            </Box>
            {/* <Button style={{backgroundColor:"#5927e3",color:"#fff",maxWidth:"60%",marginX:"80px"}} justify="center" variant="filled" align="center" >Verify Email</Button> */}
          </CardContent>
        </div>
        <Box style={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            variant="outline"
            sx={{ color: "#5927e3" }}
            onClick={navigate("/login")}
          >
            Back to Login
          </Button>
          <Button
            variant="outline"
            sx={{ backgroundColor: "#5927e3" }}
            onClick={navigate("/")}
          >
            Go to Home
          </Button>
        </Box>
      </Card>
    </div>
  );
}
