import { useEffect } from 'react'

import { Link } from 'react-router-dom';
import _ from 'lodash'
import moment from 'moment'
import { imageurl } from '../../../services/Api';
function FeaturedProjects({ allProjects }) {
    const projects = _.filter(allProjects, { featured: "1" })
    //get minutes to project end date
    const minutesToEnd = (closing) => {
        const today = moment();
        const end = moment(closing);
        const diff = end.diff(today, 'minutes');
        return diff;
    }
    useEffect(() => {
    }, [])
    return (
        <div>
            <section class="featured__properties section__space">
                <div class="container">
                    <div class="featured__properties__area wow fadeInUp">
                        <div class="title__with__cta">
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <h2>Featured Projects</h2>
                                </div>
                                <div class="col-lg-4">
                                    <div class="text-start text-lg-end">
                                        <Link to='/projects' class="button button--secondary button--effect">Browse All
                                            Projects</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            projects && projects.map((project, index) => (
                                <div class="property__list__wrapper" key={index}>
                                    <div class="row d-flex align-items-center">
                                        <div class="col-lg-5">
                                            <div class="property__item__image column__space--secondary">
                                                <div class="img__effect">
                                                    <Link to={`/projects/${project?.id}`}>
                                                        <img src={`${imageurl}${project?.project_header_image}`} alt={`${project?.project_name}`} />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7">
                                            <div class="property__item__content">
                                                <div class="item__head">
                                                    <div class="item__head__left">
                                                        <h4>{project?.project_name}</h4>
                                                        <p><i class="fa-solid fa-location-dot"></i>{project?.location}</p>
                                                    </div>
                                                    <div class="item__head__right">
                                                        <div class="countdown__wrapper">
                                                            <p class="secondary"><i class="fa-solid fa-clock"></i> Left to invest</p>
                                                            <div class="countdown">
                                                                <h5>
                                                                    <span class="days">{parseInt(minutesToEnd(project?.closing_date) / (60 * 24))}</span><span class="ref">d</span>
                                                                    <span class="seperator">:</span>
                                                                </h5>
                                                                <h5>
                                                                    <span class="hours">{parseInt(parseInt(minutesToEnd(project?.closing_date) % (60 * 24)) / 60)}</span><span class="ref">h</span>
                                                                    <span class="seperator">:</span>
                                                                </h5>
                                                                <h5>
                                                                    <span class="minutes">{parseInt(parseInt(parseInt(minutesToEnd(project?.closing_date) % (60 * 24)) % 60))}</span><span class="ref">m</span>
                                                                    <span class="seperator"></span>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="progress__type progress__type--two">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="25"
                                                            aria-valuemin="0" aria-valuemax="100"
                                                            style={{
                                                                width: `${project?.percentage_completed}%`,
                                                            }}

                                                        ></div>
                                                    </div>
                                                    <div class="project__info">
                                                        <p class="project__has"><span class="project__has__investors">{`${project?.no_of_investors} Investors`}</span> | <span class="project__has__investors__amount">{`INVESTED ${project?.invested_amount}`}</span> <span
                                                            class="project__has__investors__percent">{`(${project?.percentage_completed}%)`}</span></p>
                                                        <p class="project__goal">
                                                            {`INVESTED ${project?.invested_amount}`}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="item__info">
                                                    <div class="item__info__single">
                                                        <p>Max Investment</p>
                                                        <h6>{project?.financing?.maximum_investment}</h6>
                                                    </div>
                                                    <div class="item__info__single">
                                                        <p>Remaining Investment</p>
                                                        <h6>{project?.financing?.goal_amount - project?.invested_amount}</h6>
                                                    </div>
                                                    <div class="item__info__single">
                                                        <p>Min Investment</p>
                                                        <h6>{project?.financing?.minimum_investment}</h6>
                                                    </div>
                                                    <div class="item__info__single">
                                                        <p>Sector</p>
                                                        <h6>{project?.sector?.name}</h6>
                                                    </div>
                                                </div>
                                                <div class="item__footer">
                                                    <div class="item__security">
                                                        <div class="icon__box">
                                                            <img src="assets/images/home.png" alt="Security" />
                                                        </div>
                                                        <div class="item__security__content">
                                                            <p class="secondary">Investment Type</p>
                                                            <h6>{project?.financing?.finance_method}</h6>
                                                        </div>
                                                    </div>
                                                    <div class="item__cta__group">
                                                        <Link to={`/project-profile/${project?.id}`} class="button button--effect" style={{ color: "white" }}>Invest Now</Link>
                                                        <Link to={`/project-profile/${project?.id}`}
                                                            class="button button--secondary button--effect">Details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}

export default FeaturedProjects;
