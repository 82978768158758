function Overview () {
    return (
        <div>
            <section class="contact__overview">
                <div class="container">
                    <div class="contact__overview__area">
                        <div class="row">
                            <div class="col-md-6 col-xl-4">
                                <div class="contact__overview__single column__space--secondary shadow__effect">
                                    <img src="assets/images/icons/email.png" alt="email" />
                                    <h5>Send Us an Email</h5>
                                    {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p> */}
                                    <hr />
                                    <p class="neutral-bottom">
                                        <a href="mailto:info@oaknetbusiness.com">info@oaknetbusiness.com</a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="contact__overview__single column__space--secondary shadow__effect">
                                    <img src="assets/images/icons/phone.png" alt="Call" />
                                    <h5>Give Us a Call</h5>
                                    {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p> */}
                                    <hr />
                                    <p class="neutral-bottom">
                                        <a href="tel:+17087362094">[+254] 722 496 816</a>
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="contact__overview__single shadow__effect">
                                    <img src="assets/images/icons/chat.png" alt="Chat" />
                                    <h5>Chat with us</h5>
                                    {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing.</p> */}
                                    <hr />
                                    <p class="neutral-bottom">
                                        <a href="#0">Open live chat</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Overview;