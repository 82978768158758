import React from 'react'
import Content from './content'

export default function Company
() {
  return (
    <div>
        <Content/>
    </div>
  )
}
