import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';


const customTheme = createTheme({
    palette: {
      primary: {
        main: "#5927e3",
      },
    },
  });
  const StyledButton = styled(Button)`
    ${({ theme }) => `
    cursor: pointer;
    transition: ${theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard,
      })};
    &:hover {
        color:"fff";
      background-color:#8d6bec;
      transform: scale(1.3);
    }
    `}
  `;
export default function ExistInvestProject() {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} >
        <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Payment Method</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={age}
    label="Age"
    onChange={handleChange}
  >
    <MenuItem value={10}>Cash</MenuItem>
    <MenuItem value={20}>Mpesa</MenuItem>
    <MenuItem value={30}>Visa</MenuItem>
  </Select>
</FormControl>
        </Grid>
        <Grid item xs={12} >
          <TextField fullWidth id="outlined-basic" label="Amount  " variant="outlined"  helperText="Enter The amount of money you would like to invest" />

        </Grid>
        <Grid item xs={12} >
        <ThemeProvider theme={customTheme}>
        <Grid item xs={12} >
<StyledButton 
  variant="outlined" 
  style={{ color: "#5927e3", marginLeft: "15px", marginBottom: "0px" }} 
  // onClick={() => simulate()}
  >Invest</StyledButton>
  </Grid>
</ThemeProvider>
        </Grid>
       
      </Grid>
    </Box>
  );
}
