import { requests } from "../../../../services/Api"
// import { getToken } from "../../../../services/UseTokens"
import { FETCH_COMPANY_FAILURE, FETCH_COMPANY_REQUEST, FETCH_COMPANY_SUCCESS } from "../companyTypes"


export const fetchcompany= (token, id, navigate) => async (dispatch) =>{
    // const token=getToken()
    try {
        dispatch({
            type: FETCH_COMPANY_REQUEST
        })

        const response = await requests.get(`company/show-company/${id}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        dispatch({
            type: FETCH_COMPANY_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: FETCH_COMPANY_FAILURE,
            payload: error
        })
        if(error.response.status === 401){
            localStorage.clear()
            navigate('/login')
        }
    }
}