import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Avatar, Card, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { requests } from "../../services/Api";
import { useParams } from "react-router-dom";
import { getToken } from "../../services/UseTokens";

export default function ActivityLog({activities}) {
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  return (
    <React.Fragment style={{ margin: "15px" }}>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h6" style={{ color: "#5927e3", fontSize: "24px" }}>
          Project Interaction Log
        </Typography>
      </Box>
      <div>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {activities.map((activity, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot color="secondary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="paragraph" style={{ fontSize: "20px" }}>
                  {`${activity?.milestone?.milestone_name} ${activity?.type}`}
                </Typography>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  <Stack direction="row" spacing={2}>
                    <Avatar {...stringAvatar(`${activity?.user?.first_name} ${activity?.user?.last_name}`)} />
                    <Typography variant="caption" style={{ fontSize: "16px" }}>
                    {`${activity?.description}`}
                    </Typography>
                  </Stack>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </React.Fragment>
  );
}
