import { requests } from "../../../services/Api";
import { FETCH_PROJECT_MILESTONE_FAILURE, FETCH_PROJECT_MILESTONE_REQUEST, FETCH_PROJECT_MILESTONE_SUCCESS } from "./MilestoneType";

export const fetchProjectMilestones = (id, token) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_PROJECT_MILESTONE_REQUEST });
        const response = await requests.get(`projects/get-project-milestones/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: FETCH_PROJECT_MILESTONE_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_PROJECT_MILESTONE_FAILURE,
            payload: error.response.message,
        });
    }
}