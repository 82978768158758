import { FETCH_RISKS_FAILURE, FETCH_RISKS_REQUEST, FETCH_RISKS_SUCCESS } from "../../actions/risks/riskTypes"

const initialState = {
    loading: false,
    risk: [],
    error: ""
}

const fetchRiskReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RISKS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_RISKS_SUCCESS:
            return {
                ...state,
                risk: action.payload
            }
        case FETCH_RISKS_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}

export default fetchRiskReducer