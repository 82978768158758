import { FETCH_DIRECTORS_FAILURE,FETCH_DIRECTORS_REQUEST,FETCH_DIRECTORS_SUCCESS} from "../../actions/company/companyDirectors/directorTypes";

const initialState={
    loading: false,
    error:"",
    directors:[]
}

export default function fetchDirectors(state=initialState, action){
    console.log(action.type,action.payload)
    switch (action.type) {
        case FETCH_DIRECTORS_REQUEST:
            return{
                 ...state,
            loading: true,}
            
            break;
        case FETCH_DIRECTORS_SUCCESS:
            return{
                ...state,
                directors:action.payload
            }  
        case FETCH_DIRECTORS_FAILURE:
            return{
                ...state,
                error:action.payload
            }  
    
        default:
            return state
            break;
    }
}