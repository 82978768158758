import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../components/dropdown/dropdown.component';
import {FetchUserCompany} from "../../redux/actions/company/FetchUserCompany";
import { fetchSectors } from '../../redux/actions/sector/fetchSectors';
import Companies from './banner';
import Filter from './filter';
import List from './companies'
import { fetchProjects } from '../../redux/actions/projects/fetchProjects';
import NoProjects from '../myProjects/NoProjects';

function MyCompany() {

const dispatch=useDispatch();
const allCompanies = useSelector(state => state.userCompanies.company);
const sectors = useSelector(state => state.sectors);
const [search, setSearch] = useState('');
const [sec, setSec] = useState("");
const [filterSector, setFilterSector] = useState('');

const companies =  allCompanies;
console.log("companies",companies)
useEffect(() => {
//   dispatch(fetchSectors())
  console.log('useEffect runs');
  dispatch(FetchUserCompany())
}, [])


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
      <div>
        <Companies />
        {/* <Filter allProjects = {allProjects}/> */}
        <div class="property__filter">
            <div class="container">
                <div class="property__filter__area">
                    <div class="row d-flex align-items-center">
                    {companies.length >0 &&( <div class="col-lg-12 col-xl-11">
                            <div class="property__search__wrapper">
                                <form>
                                    <div class="input m-auto" style={{margin:"auto"}}>
                                        <input 
                                        className='m-auto'
                                            type="search" 
                                            name="property__search" 
                                            id="propertySearch"
                                            placeholder="Search for properties" 
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                    <button type="submit" class="button button--effect">Search</button> 
                                </form> 
                            </div>
                        </div>)}
                        
                    </div>
                </div>
            </div>
        </div>
        {companies.length >0 && (<List companies={companies}/>)}
        {companies.length ==0 && (
            <NoProjects/>
        )}
      </div>
    );
  }
  
export default MyCompany;
  