import { FETCH_DIRECTORS_REQUEST,FETCH_DIRECTORS_SUCCESS,FETCH_DIRECTORS_FAILURE } from "./directorTypes";
import { requests } from "../../../../services/Api";
const fetchDirectors=(id, token)=> async (dispatch) => {
   
    // const token=getToken(token)

    try {
        dispatch({
            type:FETCH_DIRECTORS_REQUEST 
        })
        const response=await requests.get(`company/get-directors/${id}`,{
            headers:{
                Authorization: `Bearer ${token}` 
            }
        })
        dispatch({type:FETCH_DIRECTORS_SUCCESS, payload:response.data})
     
        
    } catch (error) {
        dispatch({type:FETCH_DIRECTORS_FAILURE,payload:error.response.message})

    }
}
export default fetchDirectors;