import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Modal, ModalBody, Row } from 'reactstrap'

import { Link } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';

import {SectorList} from './SectorCol';
import AddSector from './CreateSectors';
// import AddSector from './CreateSectors';

export default function AllSectors() {
  const [modal, setModal] = React.useState(false);
  const [sectors,setSectors]=React.useState([])
  const newDocs=[]

  const realtime=(childData)=>{
    newDocs.push(childData)
  }
  // Toggle for Modal
  const toggle = () =>{if (modal===true) {
    setModal(false)
  } else {
    setModal(true)
  }}
  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className='position-relative'>
              <h5 className="card-title mb-0">All Projects</h5>
             
                 <Button className="button--effect position-absolute top-0 end-0 text-capitalize m-2 card-title" 
                 onClick={toggle} > 
                 ADD SECTOR
                 </Button>
                 <Modal isOpen={modal}
                toggle={toggle}
                modalTransition={{ timeout: 1000 }}>
                <ModalBody>
                    <AddSector toggle={toggle}/>
                </ModalBody>
            </Modal>
              
            </CardHeader>
            <div className="card-body border border-dashed border-end-0 border-start-0">
              <form>
                <div className="row g-3">
                  <div className="col-xxl-5 col-sm-12">
                    <div className="search-box">
                      <input type="text" className="form-control search bg-light border-light" placeholder="Search for tasks or something..." />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>

              

             
                
                </div>
              </form>
            </div>
            <CardBody>
              <SectorList style={{ width: "100%" }} parentCallBack={sectors}/>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
