import Swal from "sweetalert2";
import requests from "../../../services/Api"
import { FETCH_SINGLE_MILESTONE_REQUEST,FETCH_SINGLE_MILESTONE_SUCCESS,FETCH_SINGLE_MILESTONE_FAILURE } from "./MilestoneType";

export const GetSingleMilestoneAction=(id,token)=>async(dispatch)=>{
    try {
        dispatch({type:FETCH_SINGLE_MILESTONE_REQUEST})
        const response = await requests.get(`projects/show-project-milestone/${id}`,  {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        dispatch({type:FETCH_SINGLE_MILESTONE_SUCCESS,
        payload:response.data})
       
        
    } catch (error) {
        dispatch({type:FETCH_SINGLE_MILESTONE_FAILURE,
            payload:error.response.message})
        
    }
}