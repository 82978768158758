import { DELETE_SINGLE_COMPANIES_FAILURE,DELETE_SINGLE_COMPANIES_REQUEST,DELETE_SINGLE_COMPANIES_SUCCESS } from "../../actions/company/companyTypes";

const initialState = {
    loading:false,
    company: {},
    error:""
}
export default function DeleteCompanyReducer(state=initialState, action){
    switch(action.type){
        case DELETE_SINGLE_COMPANIES_REQUEST:
        return {
            ...state,
            loading: true,
            error: ""
        }
        case DELETE_SINGLE_COMPANIES_SUCCESS:
            return{
                ...state,
                company: action.payload
            }
        case DELETE_SINGLE_COMPANIES_FAILURE:
            return{
                ...state,
                errors: action.payload
            }
         default:
            return state
               
    }

}