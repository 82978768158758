export const FETCH_EXECUTIVES_REQUEST = "FETCH_EXECUTIVES_REQUEST";
export const FETCH_EXECUTIVES_SUCCESS = "FETCH_EXECUTIVES_SUCCESS";
export const FETCH_EXECUTIVES_FAILURE = "FETCH_EXECUTIVES_FAILURE";

export const DELETE_EXECUTIVES_REQUEST = "DELETE_EXECUTIVES_REQUEST";
export const DELETE_EXECUTIVES_SUCCESS = "DELETE_EXECUTIVES_SUCCESS";
export const DELETE_EXECUTIVES_FAILURE = "FETCH_EXECUTIVES_FAILURE";

export const UPDATE_EXECUTIVES_REQUEST = "UPDATE_EXECUTIVES_REQUEST";
export const UPDATE_EXECUTIVES_SUCCESS = "UPDATE_EXECUTIVES_SUCCESS";
export const UPDATE_EXECUTIVES_FAILURE = "UPDATE_EXECUTIVES_FAILURE";