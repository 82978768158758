import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Input, Spinner } from 'reactstrap'
import * as Yup from "yup";
import ForgotPasswordAct from '../../redux/actions/auth/forgotPassword/forgotPass';
import image from "../../assets/images/registration-bg.png"


export default function ForgotPass() {
  const [successResponse,setSuccessResponse] = React.useState("")
  const [serverError,setServerError] = React.useState("")
  // const [loading,setLoading]=React.useState(false)
  const [email,setEmail]=React.useState("")
  const dispatch=useDispatch()
 const data={
    email:email
 }

 const {loading, error } = useSelector((state) => state.forgotpassword)
  
  return (
    <div style={{marginTop: "50px"}}>
      <div class="wrapper bg__img" data-background={image}>


        <section class="registration ">
          <div class="container">

            <div class="registration__area">

              <h4 class="neutral-top text-center">Forgot password</h4>
              {successResponse && (
                <div style={{ color: "green", fontSize: "25px" }}>
                  {successResponse && (<p style={{ color: "green" }}>{successResponse}</p>)}
                </div>

              )}
              {serverError && (
                <div style={{ color: "red", fontSize: "25px" }}>
                  {serverError}
                </div>
              )}

              <Formik
                initialValues={{
                  "email": ""
                  
                }}
              
               >
                {({ values, isSubmitting, errors, handleSubmit, handleChange, isValid,
                  dirty }) => (
                  <Form >
                    <div class="input input--secondary">
                      <label for="loginMail">Email*</label>
                      <Input type="text"
                        className="form-control"
                        id="email"
                        placeholder="Enter email"
                        required
                        name="email"
                        onChange={(e)=>setEmail(e.target.value)}
                        value={email} />
                    </div>
                  
                   
                    {
                      loading && (
                        <div className="mt-4">

                          <Button color="secondary" className="btn-load w-100" outline disabled>
                            <span className="d-flex align-items-center">
                              <span className="flex-grow-1 me-2">
                                Loading...
                              </span>
                              <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                            </span>
                          </Button>
                        </div>

                      )
                    }

                    {!loading && (
                      <div className="mt-4">
                        <Button type="button" onClick={(e)=>{
                          //check if email is empty
                          dispatch(ForgotPasswordAct(data))
                        }}  color="secondary" className="button button--effect w-100" >Submit</Button>
                      </div>
                    )}
                    <p> I remember my password? <a href="/login">Back  to login</a></p>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
