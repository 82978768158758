import { UPDATE_DIRECTORS_FAILURE,UPDATE_DIRECTORS_SUCCESS,UPDATE_DIRECTORS_REQUEST } from "../../actions/company/companyDirectors/directorTypes";

const initialState={
    loading:false,
    director:{},
    error:""
}

export default function UpdateDirectorReducer (state=initialState,action){
    switch (action.type) {
        case UPDATE_DIRECTORS_FAILURE:
            return{
                ...state,
                error:action.payload
            }
            
            
            break;
            case UPDATE_DIRECTORS_SUCCESS:
                return{
                    ...state,
                    director:action.payload
                }
                
                
                break;
                case UPDATE_DIRECTORS_REQUEST:
                    return{
                        ...state,
                        loading:true
                    }
                    
                    
                    break;
    
        default:
            return state;
            break;
    }
}