import React, { useEffect, useState } from "react";
import { imageurl, requests } from "../../../services/Api";
import ViewDocument from "./ViewDocument";
import Viewpdf from "./ViewPdf";
import _ from "lodash";
import { fetchSingleDocument } from "../../../redux/actions/Documents/projectDocuments/singleDocument";
import { useDispatch, useSelector } from "react-redux";


export default function ViewSingle({documentId}) {
    const token=JSON.parse(localStorage.getItem('authuser')).token

    const dispatch = useDispatch()
    const singleDocument = useSelector((state) => state.singledocument.document)
    const url=`${imageurl}${singleDocument.document}`
    console.log("singleDocument",url)

    useEffect(() => {
        dispatch(fetchSingleDocument(documentId, token))
        // getSingleDocument()
      
    }, [])
  return (
    <div className="">
eeee
     {
        singleDocument && singleDocument.loading && (
            <div>loading</div>
        )
     }

     {
        singleDocument && singleDocument.document && (
            <Viewpdf pdf={url} />
        )
     }

    </div>
  );
}
