import { requests } from "../../../services/Api";
import { FETCH_SECTOR_FAILURE, FETCH_SECTOR_REQUEST, FETCH_SECTOR_SUCCESS } from "./sectorTypes";

export const fetchSectors = () => async (dispatch) => {
    try {
        dispatch({ 
            type: FETCH_SECTOR_REQUEST 
        });
        const response = await requests.get("/sectors");
        dispatch({ 
            type: FETCH_SECTOR_SUCCESS, 
            payload: response.data
        });
    } catch (error) {
        dispatch({ 
            type: FETCH_SECTOR_FAILURE, 
            payload: error
        });
    }
}