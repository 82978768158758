import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getToken, getUserId } from '../../services/UseTokens'
import { requests } from '../../services/Api'
import * as Yup from "yup"
import { Form, Formik } from 'formik'
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row, Spinner } from 'reactstrap'
import Select from 'react-select'
import AutoComplete from '../Company/Autocomplete'
import AsyncSelect from 'react-select/async'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../../components/dropdown/dropdown.component'
import { fetchSectors } from '../../redux/actions/sector/fetchSectors'
import Swal from 'sweetalert2'
import { fetchCompany } from '../../redux/actions/company/fetchCompany'
import TopNav from '../../layout/topnav'
import fetchprojectDocuments from '../../redux/reducers/documents/projectDocuments/fetchprojectDocuments'
import { fetchProject } from '../../redux/actions/project-details/fetchProject'
import updateProjectsAction from '../../redux/actions/project-details/updateProject'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'


export default function CreateProject2() {

  const [projectname, setProjectName] = useState("")
  const [description, setDescription] = useState("")
  const [goalAmount, setGoalAmount] = useState("")
  const [maxInvestment, setMaxInvestment] = useState("")
  const [minInvestment, setMinInvestment] = useState("")
  const [dealType, setDealType] = useState([])
  const [openingDate, setOpeningDate] = useState("")
  const [closingDate, setClosingDate] = useState("")
  const [sectorId, setSectorId] = useState("")
  const [loading, setLoading] = useState(false)
  const [debtInterest, setDebtInterest] = useState("")
  const [debtGoalAmount, setDebtGoalAmount] = useState("")
  const [debtMaxInvestment, setDebtMaxInvestment] = useState("")
  const [debtMinInvestment, setDebtMinInvestment] = useState("")
  const [debtRepaymentMethod, setDebtRepaymentMethod] = useState("")
  const [revenueGoalAmount, setRevenueGoalAmount] = useState("")
  const [revenueMaxInvestment, setRevenueMaxInvestment] = useState("")
  const [revenueMinInvestment, setRevenueMinInvestment] = useState("")
  const [revenueRepaymentCap, setRevenueRepaymentCap] = useState("")
  const [successResponse, setSuccessResponse] = useState("")
  const [serverError, setServerError] = useState("")
  const [companies, setCompanies] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [companyId, setCompanyId] = useState("")
  const [financingMethod, setFinancingMethod] = useState({})
  const [location, setLocation] = useState("")
  const [address, setAddress] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [risk,setRisk]=useState("")
  const [investmentOpportunity,setInvestmentOpportunity]=useState("")
  const [whyInvest,setWhyInvest]=useState("")
  const user = getUserId()
  const navigate = useNavigate()
  const finance_method = []
  const financing_meth_value = []
  const loations = JSON.parse(localStorage.getItem(`${user}-company`))
  const dispatch = useDispatch();
  const {id}=useParams()
  const sectorsList = useSelector(state => state.sectors);
  const companyList = useSelector(state => state.companies)
  const projects=useSelector(state=>state.single.project)
  const financing = projects?.financing?.finance_method;
  console.log("projects",projects)
  const options = [
    { value: 'equity', label: 'equity', color: '#00B8D9' },
  ];
  const [sectors, setSectors] = useState([]);
  const token = getToken()
  const projectLocation = JSON.parse(localStorage.getItem(`${user}-company`))
  const [bigScreen,setBigScreen] = useState(false)

  // const data = {
  //   created_by: JSON.parse(localStorage.getItem('authuser')).id,
  //   project_name: values.project_name,
  //   sector_id: values.sector_id,
  //   project_description: values.project_description,
  //   opening_date: values.opening_date,
  //   closing_date: values.closing_date,
  //   company_id: companyId.id,
  //   company_name: companyId.name,
  //   project_finance: dealType,
  //   location_lat: projectLocation.latitude,
  //   location_long: projectLocation.longitude,
  //   location: projectLocation.location,
  //   project_header_image: selectedFile,
  //   why_invest:values.why_invest

  // }

  useEffect(() => {
    dispatch(fetchSectors())
    dispatch(fetchCompany(user, token))
    dispatch(fetchProject(id))
  }, [])

// set bigscreen state true when screen width  isgreater or equal to 991
const setBigScreenFunction = () => {
  if (window.screen.availWidth > 991) {
    setBigScreen(true)
    return bigScreen
  }else{
    setBigScreen(false)
    return bigScreen
  }
}

const updateProjectfunct = async (values) => {

  const data = {
    created_by: JSON.parse(localStorage.getItem('authuser')).id,
    project_name: values.project_name,
    sector_id: values.sector_id?values.sector_id:projects?.sector?.id,
    project_description: values.project_description,
    opening_date: values.opening_date,
    closing_date: values.closing_date,
    company_id: companyId.id?companyId.id:projects?.company_id,
    company_name: companyId.name?companyId.name:projects?.company?.company_name,
    project_finance: dealType.length !== 0 ?dealType : projects?.financing,
    location_lat: projectLocation.latitude,
    location_long: projectLocation.longitude,
    location: projectLocation.location,
    project_header_image: selectedFile,
    why_invest:values.why_invest,
    

  }
console.log("data",data)
  
    dispatch(updateProjectsAction(id,data))

 
}
// {projects.project_header_image &&(
//   <div style={{height:"50%}",width:"50%"}}>
//   <img src={`http://18.116.46.98:8001${projects?.project_header_image}`} alt="" style={{height:"100%",width:"100%"}}/>

// </div>
// )}

useLayoutEffect(() => {
  setBigScreenFunction()
});
  const handleActualChange = (e) => {
    var file = e.target.files[0];
    console.log("file", file)
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = function () {
      setSelectedFile(reader.result)
      console.log("selected file", typeof (reader.result))
    }
  }


  const handleMapsData = (childData) => {
    setAddress(childData)
    console.log("add", address.lat)
  }
  const handleLocationsData = (childData) => {
    setLocation(childData)
    console.log("locationn", location)
  }

  var item = financing && options.find(item => item.value === financing);
  console.log("item",item)
  return (
    <div>
      
      <Formik
        initialValues={projects||{
          "project_name": "",
          "sector_id": "",
          "project_description": "",
          "opening_date": "",
          "closing_date": "",
          "company_id": "",
          "created_by": "",
          "company_name": "",
          "project_finance": [],
          "latitude": "",
          "longitude": "",
          "location": "",

          "why_invest": ""
        }}
      
        onSubmit={(values) => {
          values["company_id"] = id
          values["project_header_image"] = selectedFile
          values["company_name"] = companyId.name
          values["sector_id"] = sectorId.id
          values["created_by"] = JSON.parse(localStorage.getItem('authuser')).id
          values["project_finance"] = dealType
          
          updateProjectfunct (values)
          console.log("submitted values", values)

        }}
        enableReinitialize
        >
        {({ values, isSubmitting, errors, handleSubmit, handleChange }) => (
           <Form onSubmit={handleSubmit}>
           {serverError && (
             <div style={{ color: "red", fontSize: "15px" }}>{serverError && (
               <div>{serverError}</div>

             )}
             </div>
           )}

           {successResponse && (
             <div
               style={{ color: "green", fontSize: "15px", height: "30px", width: "auto", right: "0" }}>
               {successResponse}
             </div>

           )}
           <Row>
             <Col lg={8} >

               <Card className='mb-4'>
                 <div className='card-header'>
                   <h3 className='card-title'>General Project Info</h3>
                 </div>
                 <CardBody>
                   <div className='row'>
                     <div className="col-md-4 col-sm-12 ">
                       <h5 className="form-label text-capitalize text-start" htmlFor="project-title-input">Project Title<span className='text-danger'>*</span></h5>
                       <Input type="text"
                         className="form-control"
                         id="project-title-input"
                         placeholder="Enter project title"
                         onChange={handleChange}
                         name="project_name"
                         value={values.project_name} />
                       <div className="text-danger mt-2">{errors.project_name}</div>
                     </div>
                     <div className="mb-4 col-md-4 col-sm-12 ">
                       <h5 className="form-label text-capitalize text-start" htmlFor="project-title-input">Select company</h5>
                     
                       <Dropdown
                       className="form-label text-capitalize text-start mb-4"
                         options={
                           companyList.loading
                             ? []
                             : companyList.company.length === 0
                               ? []
                               : companyList.company
                         }
                         prompt="Choose a Company"
                         value={companyId}
                         onChange={(val) => setCompanyId(val)}
                       />
                     </div>

                     <div className="mb-4 col-md-4 col-sm-12 ">
                       <h5 className="form-label text-capitalize text-start" >Select Sector</h5>
                       {/* <Select onChange={(e) => setSectorId(e.value)} options={sectors} /> */}
                       <Dropdown
                         options={
                           sectorsList.loading
                             ? []
                             : sectorsList.sectors.length === 0
                               ? []
                               : sectorsList.sectors
                         }
                         prompt="Choose a Sector"
                         value={sectorId}
                         onChange={(val) => setSectorId(val)}
                       />
                     </div>
                   </div>
                        
                   <Row>
                     <CardBody className='row'  >
                      
                      
                       <Col lg={12}>
                         <AutoComplete addressData={handleMapsData} handleLoc={handleLocationsData} />
                       </Col>
                     </CardBody>



                   </Row>
                 </CardBody>

                 
               
                

               </Card>
               <Card className='mb-4'>
                                   <CardHeader>
                                       <h5>Description</h5>
                                   </CardHeader>
                                   <CardBody>
                                   {/* <CKEditorContext context={ Context }> */}
                                       <CKEditor
                                       
                                           editor={ClassicEditor}
                                           
                                           // config={ {
                                           //   plugins: [ Indent ,Subscript,Superscript,Code,Strikethrough ,Autoformat,Paragraph, Bold, Italic,Underline, Essentials ],
                                           //   toolbar: [ 
                                           //     'heading', '|', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ,
                                           //     "Subscript","SuperScript","Code","Strikethrough ",'Bold', 'italic',"Autoformat","Underline" 
                                           //   ]
                                         // } }
                                           data={projects.project_description?projects.project_description:"enter Description"}
                                           onReady={editor => {
                                               // You can store the "editor" and use when it is needed.
                                               console.log('Editor is ready to use!', editor);
                                           }}
                                           onChange={(event, editor) => {
                                               const data = editor.getData();
                                               setDescription(data)
                                               console.log({ event, editor, data });
                                           }}
                                           onBlur={(event, editor) => {
                                               console.log('Blur.', editor);
                                           }}
                                           onFocus={(event, editor) => {
                                               console.log('Focus.', editor);
                                           }}
                                       />
                                     {/* </CKEditorContext> */}
                                   </CardBody>
                               </Card>
                               <Card className='mb-4'>
                                   <CardHeader>
                                       <h5>Project Risks</h5>
                                   </CardHeader>
                                   <CardBody>
                                       <CKEditor
                                           editor={ClassicEditor}
                                       
                                           data={projects.project_risk?projects.project_risk:"State the project risks"}
                                           onReady={editor => {
                                               // You can store the "editor" and use when it is needed.
                                               console.log('Editor is ready to use!', editor);
                                           }}
                                           onChange={(event, editor) => {
                                               const data = editor.getData();
                                               setRisk(data)
                                               console.log({ event, editor, data });
                                           }}
                                           onBlur={(event, editor) => {
                                               console.log('Blur.', editor);
                                           }}
                                           onFocus={(event, editor) => {
                                               console.log('Focus.', editor);
                                           }}
                                       />
                                   </CardBody>
                               </Card>
                               <Card className='mb-4'>
                                   <CardHeader>
                                       <h5>Why Invest</h5>
                                   </CardHeader>
                                   <CardBody>
                                       <CKEditor
                                           editor={ClassicEditor}
                                           data={projects.why_invest?projects.why_invest:"Justift why I should invest"}
                                           onReady={editor => {
                                               // You can store the "editor" and use when it is needed.
                                               console.log('Editor is ready to use!', editor);
                                           }}
                                           onChange={(event, editor) => {
                                               const data = editor.getData();
                                               setWhyInvest(data)
                                               console.log({ event, editor, data });
                                           }}
                                           onBlur={(event, editor) => {
                                               console.log('Blur.', editor);
                                           }}
                                           onFocus={(event, editor) => {
                                               console.log('Focus.', editor);
                                           }}
                                       />
                                   </CardBody>
                               </Card>
                               <Card className='mb-4'>
                                   <CardHeader>
                                       <h3>Investment Opportunity</h3>
                                   </CardHeader>
                                   <CardBody>
                                       <CKEditor
                                           editor={ClassicEditor}
                                           data={projects.investment_opportunity?projects.investment_opportunity:"State investment opportunity"}
                                           onReady={editor => {
                                               // You can store the "editor" and use when it is needed.
                                               console.log('Editor is ready to use!', editor);
                                           }}
                                           onChange={(event, editor) => {
                                               const data = editor.getData();
                                               setInvestmentOpportunity(data)
                                               console.log({ event, editor, data });
                                           }}
                                           onBlur={(event, editor) => {
                                               console.log('Blur.', editor);
                                           }}
                                           onFocus={(event, editor) => {
                                               console.log('Focus.', editor);
                                           }}
                                       />
                                   </CardBody>
                               </Card>
         

             </Col>

             <Col lg={4}>
               <Card className="mb-4 col-md-12 col-sm-6 col-xsm-12 ">
                   <CardHeader className="text-center">
                       <h3 className='text-center'>Dates</h3>
                   </CardHeader>
                   <CardBody>
                   <Col lg={12}>
                         <div className="mb-3">
                           <h5 htmlFor="choices-categories-input text-capitalize" className="form-label">opening
                             Date</h5>
                           <input type="date"
                             className="form-control"

                             onChange={handleChange}
                             value={values.opening_date}
                             name="opening_date"

                           />
                           <div className='text-danger'>{errors.opening_date}</div>
                         </div>
                       </Col>
                       <Col lg={12}>
                         <div className="mb-3">
                           <h5 htmlFor="choices-categories-input text-capitalize" className="form-label">closing
                             Date</h5>
                           <input type='date'
                             className="form-control"
                             onChange={handleChange}
                             value={values.closing_date}
                             name="closing_date"

                           />
                           <div className='text-danger'>{errors.closing_date}</div>
                         </div>
                       </Col>
                   </CardBody>
               </Card>

               <Card className='mb-4 '>
                  
                   <CardBody>
                   <Col lg={12} >
                         <div className="mb-3 ">
                          
                           <input type='file'
                             className="form-control"

                             onChange={(e) => {
                               handleActualChange(e)
                             }}


                           />

                         </div>
                       </Col>
                   </CardBody>
               </Card>
               <div className="card mb-4 ">
                 <div className="card-header">
                   <h3 className="card-title mb-0 text-capitalize">Finance Method</h3>
                 </div>

                 <CardBody>
                   <div>
                     <h5 htmlFor="choices-privacy-status-input" className="form-label">Project finance option</h5>
                     <Select
                       // value={selectedMulti}
                       isMulti={true}
                       onChange={(e) => {
                         e.map((d) => {
                           finance_method.push({ finance_method: d.value })
                           financing_meth_value.push({ finance_method: d.value })
                           financing_meth_value.map((q) => { })

                         })

                         setDealType(finance_method)


                       }}
                       options={options}
                     />
                   </div>

                 </CardBody>

               
                 
                 {dealType != 0 && (
                   <>
                     {dealType.map((d, index) => (
                       <div key={index}>
                         {d.finance_method == "equity" && (
                           <div className="card mt-2">
                             <div className="card-header">
                               <h3 className="card-title mb-0 text-capitalize text-center">Equity </h3>
                             </div>
                             <CardBody>
                                 <Row>
                               <Col lg={12}>
                                 <div className="mb-3 col-lg-12">
                                   <h5 htmlFor="choices-status-input" className="form-label text-capitalize">goal amount</h5>
                                   <Input type="text"
                                     className="form-control"
                                     id="project-goal_amount"
                                     placeholder="Enter project goal"
                                     onChange={(e) => {
                                       setGoalAmount(e.target.value)
                                       dealType.forEach(obj => (
                                         obj.finance_method === "equity" && (
                                           obj.goal_amount = e.target.value
                                         )
                                       ))
                                     }}
                                     name="goal_amount"
                                     value={goalAmount} />
                                 </div>
                                 {console.log("goalAmount", goalAmount)}
                               </Col>
                               <Col lg={12}>
                                 <div className="mb-3 mb-lg-0">
                                   <h5 htmlFor="choices-status-input" className="form-label text-capitalize">maximum investment</h5>
                                   <Input type="text"
                                     className="form-control"
                                     id="project-minimum_investment"
                                     placeholder="Enter project minimum investment"
                                     onChange={(e) => {
                                       setMaxInvestment(e.target.value)
                                       dealType.forEach(obj => (
                                         obj.finance_method == "equity" && (
                                           obj.maximum_investment = e.target.value
                                         )
                                       ))
                                     }}
                                     name="maximum_investment"
                                     value={maxInvestment} />
                                   {console.log("maxInvestment", maxInvestment)}
                                 </div>
                               </Col>
                               <Col lg={12}>
                                 <div className="mb-3 mb-lg-0">
                                   <h5 htmlFor="choices-status-input" className="form-label text-capitalize">minimum investment</h5>
                                   <Input type="text"
                                     className="form-control"
                                     id="project-maximum_investment"
                                     placeholder="Enter project minimum investment"
                                     onChange={(e) => {
                                       setMinInvestment(e.target.value)
                                       dealType.forEach(obj => (
                                         obj.finance_method === "equity" && (
                                           obj.minimum_investment = minInvestment
                                         )
                                       ))
                                     }}
                                     name="minimum_investment"
                                     value={values.minInvestment} />
 
 
                                 </div>
                               </Col>
                               </Row>
                             </CardBody>
                           </div>
                         )}
                       </div>
                     ))}
 
                   </>
                 )}
                 
             
               </div>
             

               {!loading && (
             <div className="text-end mb-4">
               {/* <button type="submit" className="btn btn-danger w-sm me-1">Delete</button> */}
               {/* <button type="submit" className="btn btn-secondary w-sm me-1" >Draft</button> */}
               <button type="submit" color="secondary" className=" button button--effect  w-100 m-4">Create</button>
             </div>
           )}

           {
             loading && (
               <div className="text-end mt-4">
                 <Button color="success" className="btn-load  w-100" outline disabled>
                   <span className="d-flex align-items-center">
                     <span className="flex-grow-1 me-2">
                       Loading...
                     </span>
                     <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                   </span>
                 </Button>
               </div>

             )
           }

             </Col>
           </Row>
          
         </Form>
        )}
      </Formik>
    </div >
  )
}
