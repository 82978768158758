import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'
import { getTTFB } from 'web-vitals'
import DeleteCompanyAct from '../../../redux/actions/company/deleteCompany'
import "./index.css"
import {getToken} from "../../../services/UseTokens"

export default function DeleteCompany(props) {
 
 const token=getToken()
  const dispatch=useDispatch()
  const handleSubmit=()=>{
    dispatch(DeleteCompanyAct(props.id,token))
  
  }

  return (
    <div class="text-center">
    <div class="icon-box">
    <i className={`fa red-circle fa-trash`}></i>
    </div>
    <h2>Are you sure?</h2>
   <div>
    <p>
    Do you really want to delete these records? This process cannot be undone.
    </p>
   </div>
    
      
      
         <Button color="danger" onClick={()=>{handleSubmit()}}  >Delete</Button>

       
     
   
  </div>
  )
}
