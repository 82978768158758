import { UPDATE_COMPANY_REQUEST, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE} from "../../actions/company/companydetails/companyTypes"

const initialState = {
    loading: false,
    company: {},
    error: ""
}

const updateCompany = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_COMPANY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                company: action.payload
            }
        case UPDATE_COMPANY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}
export default updateCompany