import React, { useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Card, CardContent, Modal } from '@mui/material';
import AddMilestone from '../project-details/AddMiletone';
import { AddCircleOutline } from '@material-ui/icons';
import { ProjectContext } from './ProjectContext';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as dayjs from "dayjs"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  border: '2px solid #5927e3',
  boxShadow: 24,
}

export default function Milestone() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { milestones } = useContext(ProjectContext);
  function createMarkup(value) {
    return { __html: value };
}
  return (
    <div>
        <Box style={{display:"flex",justifyContent:"flex-end"}}>
        <Button variant="outlined" onClick={handleClickOpen} sx={{color:"#5927e3"}} startIcon={<AddCircleOutline />}
        >Add Milestones</Button>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
          <Card>
            <Box sx={style}>
               <CardContent> <AddMilestone handleClose={handleClose}/></CardContent> 
            </Box>
            </Card>
        </Modal>
     
        </Box>
      {
        milestones.map((milestone, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box style={{display:"flex",flexDirection:"row"}}>
            
              <Typography variant="h6" style={{fontWeight: "600", textTransform: "Capitalize"}}>{milestone?.milestone_name}</Typography>
              
              </Box>
              
            </AccordionSummary>
            <AccordionDetails style={{display:"flex",flexDirection:"column"}}>
            {/* <Box style={{display:"flex",JustifyContent:"space-around"}}>
              <Typography> Name</Typography>
              <Typography>{milestone?.milestone_name}</Typography>
              </Box> */}
              <Box style={{ display: 'flex',
          justifyContent: 'space-between',marginX:"10%"}}>
              <Typography variant="h6"> Start Date</Typography>
              <Typography variant="paragraph">{milestone?.milestone_start_date}</Typography>
              </Box>
              <Box style={{ display: 'flex',
          justifyContent: 'space-between',marginX:"10%" }}>
              <Typography variant="h6">Deadline</Typography>
              <Typography variant="paragraph">{milestone?.milestone_end_date}</Typography>
              </Box>
              <Typography variant="h6">Milestone Description</Typography>
              <Typography variant="paragraph">
                {/* {milestone?.milestone_description} */}
                <p dangerouslySetInnerHTML={createMarkup(milestone?.milestone_description)} />
              </Typography>


            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  );
}
