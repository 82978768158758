import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import LinearProgress from '@mui/material/LinearProgress'

// ** Icons Imports
import MenuUp from 'mdi-material-ui/MenuUp'
import DotsVertical from 'mdi-material-ui/DotsVertical'
import { createTheme, ThemeProvider } from '@mui/material'
import { purple } from '@mui/material/colors'
import logoAvianto from "../../assets/images/cards/logo-aviato.png"
import Zipcar from "../../assets/images/cards/logo-zipcar.png"
import bitbank from "../../assets/images/cards/logo-bitbank.png"
import { fetchProjects } from '../../redux/actions/projects/fetchProjects'
import { getUser } from '../../services/UseTokens'
import { imageurl } from '../../services/Api'
// import { purple } from '@mui/material/colors'

const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: purple[300],
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
  });

const data = [
  {
    progress: 75,
    imgHeight: 20,
    title: 'Zipcar',
    color: 'primary',
    amount: '$24,895.65',
    subtitle: 'Vuejs, React & HTML',
    imgSrc: Zipcar
  },
  {
    progress: 50,
    color: 'info',
    imgHeight: 27,
    title: 'Bitbank',
    amount: '$8,650.20',
    subtitle: 'Sketch, Figma & XD',
    imgSrc: bitbank
  },
  {
    progress: 20,
    imgHeight: 20,
    title: 'Aviato',
    color: 'secondary',
    amount: '$1,245.80',
    subtitle: 'HTML & Angular',
    imgSrc: logoAvianto
  }
]

// const theme = createTheme({
//     palette: {
//       primary: {
//         // Purple and green play nicely together.
//         main: purple[300],
//       },
//       secondary: {
//         // This is green.A700 as hex.
//         main: '#11cb5f',
//       },
//     },
//   });

const MyInvestments = ({analytics}) => {
  const dispatch = useDispatch()
  const projects = useSelector(state => state.projects.projects)
  const user = getUser();

  //get only the projects that the user has created
  const myProjects = projects.filter(project => project.created_by === user?.id)
  console.log("myProjects", myProjects)
  useEffect(() => {
    dispatch(fetchProjects())
  }, [])
  return (
    <ThemeProvider theme={theme}>
    <Card>
      <CardHeader
        title='My Projects'
        titleTypographyProps={{ sx: { lineHeight: '1.6 !important', letterSpacing: '0.15px !important' } }}
        action={
          <IconButton size='small' aria-label='settings' className='card-more-options' sx={{ color: 'text.secondary' }}>
            <DotsVertical />
          </IconButton>
        }
      />
      <CardContent sx={{ pt: theme => `${theme.spacing(2.25)} !important` }}>
        <Box sx={{ mb: 1.5, display: 'flex', alignItems: 'center' }}>
          <Typography variant='h4' sx={{ fontWeight: 600, fontSize: '2.125rem !important' }}>
            {analytics?.totalAmountRaised || 0}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'success.main' }}>
            <MenuUp sx={{ fontSize: '1.875rem', verticalAlign: 'middle' }} />
            {/* <Typography variant='body2' sx={{ fontWeight: 600, color: 'success.main' }}>
              10%
            </Typography> */}
          </Box>
        </Box>

        {/* <Typography component='p' variant='caption' sx={{ mb: 10 }}>
          Compared to $84,325 last year
        </Typography> */}

        {myProjects && myProjects.length > 0 && myProjects.map((item, index) => {
          return (
            <Box
              key={item.title}
              sx={{
                display: 'flex',
                alignItems: 'center',
                ...(index !== myProjects.length - 1 ? { mb: 8.5 } : {})
              }}
            >
              <Avatar
                variant='rounded'
                sx={{
                  mr: 3,
                  width: 40,
                  height: 40,
                  backgroundColor: `primary`
                }}
              >
                <img src={`${imageurl}${item?.project_header_image}`} alt={item.project_name} height={item.imgHeight} />
              </Avatar>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box sx={{ marginRight: 2, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='body2' sx={{ mb: 0.5, fontWeight: 600, color: 'text.primary' }}>
                    {item.project_name}
                  </Typography>
                  <Typography variant='caption'>{item.sector.name}</Typography>
                </Box>

                <Box sx={{ minWidth: 85, display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='body2' sx={{ mb: 2, fontWeight: 600, color: 'text.primary' }}>
                    {item.financing.goal_amount}
                  </Typography>
                  <LinearProgress color={item.color} value={item.progress} variant='determinate' />
                </Box>
              </Box>
            </Box>
          )
        })}

        {
          myProjects && myProjects.length === 0 && (
            <h3>No current projects</h3>
          )
        }
      </CardContent>
    </Card>
    </ThemeProvider>
  )
}

export default MyInvestments
