import { FETCH_OWNERDOCS_FAILURE,FETCH_OWNERDOCS_REQUEST,FETCH_OWNERDOCS_SUCCESS } from "../../../actions/Documents/projectsowner/OwnerTypes";

const initialState={
    loading:false,
    error:"",
    documents:[]
}

const fetchOwnerDocReducer=(state=initialState,action)=>{
    switch (action.type) {
        case FETCH_OWNERDOCS_REQUEST:
            return {
                loading:true,
                error:"",
                documents:[]
            }
            
            break;  
        case FETCH_OWNERDOCS_SUCCESS:
            return {
                loading:false,
                documents:action.payload,
                error:""
            }
            break;
        case FETCH_OWNERDOCS_FAILURE:
            return {
                loading:false,
                documents:[],
                error:action.payload
            }
            break;
    
        default:
            return state;
            break;
    }
}
export default fetchOwnerDocReducer;