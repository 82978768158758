import * as React from 'react';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Excecutives from './Executives';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, styled } from '@material-ui/core';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary': {
        position: 'relative',
        minWidth: '100%',
      
      },
      '& .MuiAccordionSummary.Mui-expanded': {
        position: 'relative',
        minWidth: '100%',
       
      },
    '& .MuiAccordionSummary-expandIcon': {
        position: 'absolute',
        start: 0,
      },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
      start: 0,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        minWidth:"100vh",
        start: 0,
      },
      '& .MuiAccordionSummary-expandIconWrapper.MUI-expanded': {
        minWidth:"100vh",
        start: 0,
      },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

export default function SimpleAccordion(props) {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    }
  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary class={expanded ? "float-left position-relative":"float-left position-relative"}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Executives</Typography>
          {/* <Button style={{ }}variant ="outlined"  className={!expanded?"add-waypoint  position-absolute end-0 mt-1  mb-0": "add-waypoint  position-absolute end-0 mt-1  mb-0"} startIcon={< RemoveIcon />} onClick={()=>props.remove(props.index)}>
        remove
      </Button> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <Excecutives/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      
    </div>
  );
}
