import { Outlet, Navigate } from 'react-router-dom'
import { getToken } from '../services/UseTokens';

export function PrivateOutlet() {
  const token = getToken()
  return token ? <Outlet /> : <Navigate to="/login" />
}

export function PrivateRoute({ children }) {
  const token = getToken()
  return token ? children : <Navigate to="/login" />;
}