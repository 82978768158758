import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Select, TextField } from '@material-ui/core';
import AutoComplete from '../Company/Autocomplete';
import { FormControl, InputLabel, MenuItem, Stack } from '@mui/material';
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as dayjs from "dayjs"
import { OnboardProjectContext } from './ProjectContext';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSectors } from '../../redux/actions/sector/fetchSectors';
import { useFocusableInput } from './AutofocusInput';


export default function GeneralProjectInfo() {
  const dispatch = useDispatch();
  const allSectors = useSelector(state => state.sectors.sectors);
  // const [sector, setSector] = React.useState()
  const [val, setVal] = React.useState(1);
  const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

  const {
    projectName,
    setProjectName,
    address,
    setAddress,
    sector,
    setSector,
    projectHeader,
    setProjectHeader,
    openingDate,
    setOpeningDate,
    closingDate,
    setClosingDate,
    lat,
    setLat,
    lng,
    setLng,
  } = useContext(OnboardProjectContext);

  const [sectorLabel, setSectorLabel] = useState('Sector');

  const handleBorderChange = (event) => {
    setVal(event.target.value);
  }

  const borderSelectClasses = useBorderSelectStyles();

  // moves the menu below the select input
  const menuProps = {
    classes: {
      list: borderSelectClasses.list
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };


const handleMapsData = (childData) => {
  console.log({childData})
  const { lat, long } = childData;
  setLat(lat)
  setLng(long)
}
const handleLocationsData = (childData) => {
  setAddress(childData)
}
const inputFormat = "yyyy-MM-dd";
const handleActualChange = (e) => {
  var file = e.target.files[0];
  console.log("file", file)
  var reader = new FileReader();
  reader.readAsDataURL(e.target.files[0]);
  reader.onloadend = function () {
    setProjectHeader(reader.result)
    console.log("selected file", typeof (reader.result))
  }
}
useEffect(() => {
  dispatch(fetchSectors());
}, [])

return (
  <Box sx={{ flexGrow: 1 }}>
    <form>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Box>
            <TextField
              variant="outlined"
              autoFocus={useFocusableInput}
              fullWidth
              label="Project Name"
              onChange={(e) => setProjectName(e.target.value)}
              value={projectName}
              required />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Box>
            <AutoComplete addressData={handleMapsData} handleLoc={handleLocationsData} locationdata={address}/>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Box>

            <FormControl fullWidth style={{ marginRight: "5px" }}>
              <InputLabel id="demo-simple-select-label">{sectorLabel}</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                classes={{ root: borderSelectClasses.select, }}
                id="demo-simple-select"
                value={sector}
                onChange={(e) => {
                  setSectorLabel("")
                  setSector(e.target.value)
                  console.log("sector", e.target.value)
                  }}
              >
                {
                  allSectors.map((sector, index) => (
                    <MenuItem value={sector.id}>{sector.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Box>
            <TextField 
              variant="outlined" 
              fullWidth type="file" 
              onChange={(e) => handleActualChange(e)}
              required helperText="set project header image" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker

                  label="Opening Date"
                  inputFormat="MM/DD/YYYY"
                  value={openingDate}
                  format={inputFormat}
                  onChange={(value) => setOpeningDate(value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} xxl={3}>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker

                  label="Closing Date"
                  inputFormat="MM/DD/YYYY"
                  value={closingDate}
                  onChange={(value) => setClosingDate(value)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
        </Grid>

      </Grid>
    </form>
  </Box>
);
}
