import { Form, Formik } from 'formik'
import React, { useState, useContext } from 'react'
import { CardFooter, Label } from 'reactstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Card, CardActions, CardContent, CardHeader, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useNavigate, useParams } from 'react-router-dom';
import { ProjectContext } from '../ProjectProfile.jsx/ProjectContext';
import { requests } from '../../services/Api';
import { getToken } from '../../services/UseTokens';
import Swal from 'sweetalert2';

export default function AddMilestone(props) {
    const { milestones, setMilestones } = useContext(ProjectContext);
    const { project_id } = useParams();
    const token = getToken()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState("")
    const [title, setTitle] = useState("")
    const [dueDate, setDueDate] = useState("12/13/2022")
    const [startDate, setStartDate] = useState("12/13/2022")


    const addMilestone = async () => {
        setLoading(true)
        const formData = {
            project_id: project_id,
            milestone_name: title,
            milestone_description: description,
            milestone_start_date: startDate,
            milestone_end_date: dueDate,

        }
        await requests.post(`projects/create-project-milestone`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            setLoading(false)
            setMilestones((prevState) => [...milestones, response.data])
            props.handleClose()
            Swal.fire({
                title: 'Success',
                text: 'Milestone added successfully',
                icon: 'success',
                confirmButtonText: 'Ok'
            })
        }).catch((error) => {
            setLoading(false)
            Swal.fire({
                title: 'Error',
                text: Object.values(error.response.data.message)[0][0],
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (error.response.status === 401) {
                    navigate('/login')
                    props.handleClose()
                }
            })
        })
    }

    return (
        <div className='card'>
            <Card>
                <CardHeader title="Add Milestone" />
                <Formik
                    initialValues={{
                        "milestone_name": "",
                        "milestone_start_date": "",
                        "milestone_end_date": "",
                        "project_id": "",
                    }}

                    onSubmit={(values, { resetForm }) => {

                        values["project_id"] = props.id
                        values["milestone_description"] = description
                        // PostProjectDocument(values)
                        resetForm({ values: "" })
                        console.log("submit values", values)
                    }}
                >
                    {({ values, isSubmitting, errors, handleSubmit, handleChange, dirty, isValid }) => (


                        <Form className="was-validated">

                            <CardContent>
                                <div className="row">
                                    <div className=" mb-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        {/* <Label className="ValidationInput txt-capitalize" for="validationFormCheck3">milestone Name</Label> */}
                                        <TextField
                                            id="outlined-basic"
                                            label="Milestone Name"
                                            variant="outlined"
                                            onChange={(e) => setTitle(e.target.value)}
                                            required />

                                        <div className="invalid-feedback">Enter milestone name</div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className=" mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        {/* <Label className="ValidationInput text-capitalize" for="validationFormCheck3">milestone Name</Label> */}
                                        <TextField
                                        type="date"
                                            id="outlined-basic"
                                            label="Start Date"
                                            variant="outlined"
                                            onChange={(e) => setStartDate(e.target.value)}
                                            value={startDate}
                                            required />


                                        <div className="invalid-feedback">Enter milestone  start date</div>
                                    </div>
                                    <div className=" mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        {/* <Label className="ValidationInput text-capitalize" for="validationFormCheck3">milestone end date</Label> */}
                                        <TextField
                                        type="date"
                                            id="outlined-basic"
                                            label="End Date"
                                            variant="outlined"
                                            onChange={(e) => setDueDate(e.target.value)}
                                            value={dueDate}
                                            required />

                                        <div className="invalid-feedback">select milestone closing date</div>
                                    </div>
                                </div>
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Date desktop"
                                        inputFormat="MM/DD/YYYY"
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField {...params} />}
                                        />
                                </LocalizationProvider> */}
                                <div className=" mb-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">milestone description</Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data=""
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setDescription(data)
                                        }}
                                        onBlur={(event, editor) => {
                                        }}
                                        onFocus={(event, editor) => {
                                        }}
                                    />
                                    <div className="invalid-feedback">Enter milestone description</div>
                                </div>

                            </CardContent>
                            {!loading && (
                                <CardActions disableSpacing style={{ display: "flex", float: "left" }}>
                                    <div className=''>
                                        <Button variant="outlined" style={{ backgroundColor: "#5927e3", margin: "2px", color: "white" }} onClick={() => addMilestone()}>Submit</Button>
                                        <Button style={{ margin: "2px" }} variant="outlined" color="error" onClick={() => props.handleClose()}>Cancel</Button>
                                    </div>

                                </CardActions>
                            )}


                        </Form>
                    )}
                </Formik>
            </Card>
        </div>
    )
}
