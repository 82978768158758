import Swal from "sweetalert2";
import { requests } from "../../../../services/Api";
import { getToken } from "../../../../services/UseTokens";
import { UPDATE_SINGLE_DOCUMENT_REQUEST,UPDATE_SINGLE_DOCUMENT_SUCCESS,UPDATE_SINGLE_DOCUMENT_FAILURE } from "./DocumentsTypes";

const UpdateProjectDocumentAct= (id,documents,token) =>async(dispatch)=> {
    console.log("token",token)
    try {
        dispatch({type: UPDATE_SINGLE_DOCUMENT_REQUEST})
        const response=await requests.put(`/documents/projects/${id}`,documents,
       { headers: {
            Authorization: `Bearer ${token}`,
        }
    })
        dispatch({
            type:UPDATE_SINGLE_DOCUMENT_SUCCESS,
            payload:response.data
        })
        Swal.fire({
            icon: "success",
            title: "Document updated",
            text: "Project document updated successfully",
            confirmButton:"ok"
        })
    } catch (error) {
        dispatch({type: UPDATE_SINGLE_DOCUMENT_FAILURE, payload: error.response.data.message})
        Swal.fire({
            icon: "error",
            title: error.response.data.message,
            confirmButtonAriaLabel: "OK",
        })
    }
}
export default UpdateProjectDocumentAct;