// ** MUI Imports
import Box from '@mui/material/Box'
import * as React from "react"
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import AvatarGroup from '@mui/material/AvatarGroup'
import Badge from '@mui/material/Badge';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AddTaskIcon from '@mui/icons-material/AddTask';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Modal, Paper, Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Progress } from 'reactstrap';
import Invest from './investment/Index'
import { getUser } from '../../services/UseTokens'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #5927e3',
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  borderColor: 'text.primary',
  width: '5rem',
  height: '5rem',
};
const FinancialDetails = ({ project, financing }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user = getUser();
  return (
    <div style={{ margin: "15px" }}>
      <Card sx={{ position: 'relative', }} style={{ border: "2px solid #5927e3" }}>
        <Box >
          <Grid container spacing={2}>
            <Grid item xs={6} lg={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Badge badgeContent={financing?.finance_method} color="success" style={{ margin: "15px", padding: "15px" }} variant="outlined"></Badge>

            </Grid>

            <Grid item xs={6} lg={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="goal  amount">
                <Typography variant="h6" sx={{ color: "#5927e3", fontSize: "24px" }}>{financing?.goal_amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  <span><Typography variant="caption" style={{ marginLeft: "2px", fontSize: "28px" }}>ksh</Typography></span>
                </Typography>
              </Tooltip>
            </Grid>

          </Grid>


        </Box>
        <Box>

          <Grid container spacing={2}
            style={{ padding: "15px" }}
          >
            <Grid item xs={6} sm={6} lg={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="paragraph" style={{ fontSize: "20px" }}> Maximum Investment</Typography>
            </Grid>
            <Grid item xs={6} sm={6} lg={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography variant="caption" style={{ fontSize: "16px" }}>{`ksh ${financing?.maximum_investment.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} lg={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="paragraph" style={{ fontSize: "20px" }}> Minimum Investment</Typography>
            </Grid>
            <Grid item xs={6} sm={6} lg={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography variant="caption" style={{ fontSize: "16px" }}>{` Ksh ${financing?.minimum_investment.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`}</Typography>
            </Grid>
          </Grid>
          {
            financing?.finance_method === "revenue_share" ? (
              <>       <Grid container spacing={2}
                style={{ padding: "15px" }}
              >
                <Grid item xs={6} sm={6} lg={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography variant="paragraph" style={{ fontSize: "20px" }}> Repayment Cap</Typography>
                </Grid>
                <Grid item xs={6} sm={6} lg={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Typography variant="caption" style={{ fontSize: "16px" }}>{financing?.revenue_repayment_cap}</Typography>
                </Grid>
              </Grid>
              </>
            ) : financing?.finance_method === "debt" ? (
              <>
                <Grid container spacing={2}
                  style={{ padding: "15px" }}
                >
                  <Grid item xs={6} sm={6} lg={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography variant="paragraph" style={{ fontSize: "20px" }}>Interest(%)</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="caption" style={{ fontSize: "16px" }}>{`${financing?.debt_interest} %`}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography variant="paragraph" style={{ fontSize: "20px" }}>Repayment period(m)</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="caption" style={{ fontSize: "16px" }}>{`${financing?.debt_repayment_period} months`}</Typography>
                  </Grid>
                </Grid>
              </>
            ) : null
          }

        </Box>
        {/* <Box xs={12} sm={12} lg={12} md={12} style={{ padding: "15px" }}>
          <Typography variant="caption">75 out of 100 shares invested </Typography>
          <Progress color="#5927e3" value={75} style={{ height: "15px" }}>75%</Progress>

        </Box> */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", padding: "15px" }}>
        {
          project && project?.created_by !== user?.id ? (
            <Button onClick={handleOpen} variant="outlined" style={{ color: "#5927e3" }}>Invest Now</Button>
          ): null
        }
          
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Invest />
          </Box>
        </Modal>
      </Card>
    </div>

  )
}

export default FinancialDetails
