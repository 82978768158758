import axios from "axios";
import { removeUserSession } from './UseTokens';

const instance = axios.create({
    // baseURL: `${process.env.REACT_APP_BACKEND_URL}/api`,
    baseURL: 'https://investmentsbackend.oaknetbusiness.com/api',
    // baseURL: 'http://localhost:8000/api',
    timeout: 15000,
});

export default instance;

// export const imageurl=`${process.env.REACT_APP_BACKEND_URL}/storage`
export const imageurl= 'https://investmentsbackend.oaknetbusiness.com/storage'
// export const imageurl='http://localhost:8000/storage'

const responseBody = (response) => response.data;

const responseError = (error) => {
    return Promise.reject(error)
}

export const requests = {
    get: (url, headers) => instance.get(url, headers).then(responseBody).catch(responseError),
    post: (url, body, headers) => instance.post(url, body, headers).then(responseBody).catch(responseError),
    put: (url, body,headers) => instance.put(url, body,headers).then(responseBody).catch(responseError),
    delete: (url, headers) => instance.delete(url, headers).then(responseBody).catch(responseError),
};