import { requests } from "../../../services/Api";
import { BUYSHARES_FAILURE, BUYSHARES_REQUEST, BUYSHARES_SUCCESS } from "./buysharesTypes";
import Swal from 'sweetalert2'
import { Navigate, useNavigate } from "react-router-dom";

export const buyShares = (data, token) => async (dispatch) => {
   
        dispatch({ 
            type: BUYSHARES_REQUEST 
        });
        await requests.post(`/shares/temp-buy`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response) => {
            dispatch({ 
                type: BUYSHARES_SUCCESS, 
                payload: response.data 
            })
            Swal.fire(
                'Good job!',
                'Shares Bought Successfully',
                'success'
            )
           
        }).catch((error) => {
            dispatch({ 
                type: BUYSHARES_FAILURE, 
                payload: error.response.data.message 
            });
        })
}