import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import image from "../../assets/images/NoProjects.png";

import { Link } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
} from "@mui/material";
import AddRisk from "./AddRisk";
import { getUser } from "../../services/UseTokens";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

function NoRisk({ project }) {
  const [open, setOpen] = React.useState(false);

  const user = getUser();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Card sx={{ maxWidth: 345, marginX: "auto", marginY: "9%" }}>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            No risks created
          </Typography>
          {/* <AddCompany/> */}
          {project && project.created_by == user.id ? (
            <Button
              variant="contained"
              onClick={handleClickOpen}
              sx={{ backgroundColor: "#5927e3" }}
            >
              Add Risks
            </Button>
          ) : null}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <AddRisk handleClose={handleClose} />
            </Box>
          </Modal>
        </CardContent>
      </Card>
    </div>
  );
}

export default NoRisk;
