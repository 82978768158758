import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image from "../../assets/images/NoProjects.png"
import { Box, Modal } from '@mui/material';
import CompleteSetup from './CompleteSetUp';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #5927e3',
  margin: "25px",
  boxShadow: 24,
}
function NoSetup({company}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>

      <Card sx={{ maxWidth: 345, marginX: "auto", marginY: "9%" }}>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            You have not completed setting up your company
          </Typography>
          {/* <AddCompany/> */}
          <Button variant="contained" onClick={handleClickOpen}
          >Complete setup</Button>
          <Modal
            size="lg"
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
              <CompleteSetup company={company} handleClose={handleClose} />
            </Box>
          </Modal>
        </CardContent>

      </Card>
    </div>
  )
}

export default NoSetup