import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSectors } from "../../../redux/actions/sector/fetchSectors";
import Dropdown from "./dropdown/dropdown.component";

function ProjectFilter({ allProjects,getSearchResult, filterBySector }) {
    const [sec, setSec] = useState("");
    const [searchQuery, setSearchQuery] = useState("")

    const dispatch = useDispatch();
    const sectors = useSelector(state => state.sectors);

    function handleSearch(e) {
        const searchword=e.target.value
        getSearchResult(searchword)
    }

    function handleSector(value) {
        setSec(value)
        filterBySector(value.id)
    }

    useEffect(() => {
        dispatch(fetchSectors())
    }, [])


    return (
        <div class="property__filter">
            <div class="container">
                <div class="property__filter__area">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-12 col-xl-6">
                            <div class="property__search__wrapper">
                                <form action="#" method="post">
                                    <div class="input">
                                        <input type="search"
                                        aria-label="search"
                                            name="property__search"
                                            id="propertySearch"
                                            placeholder="Search for projects"
                                            // value={searchQuery}
                                            onChange={(e) => handleSearch(e)}

                                        />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                    <button type="button" onClick={() => { handleSearch() }} class="button button--effect">Search</button>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-3">
                            <div class="property__select__wrapper input">
                                <Dropdown
                                    options={
                                        sectors.loading
                                            ? []
                                            : sectors.sectors.length === 0
                                                ? []
                                                : sectors.sectors
                                    }
                                    prompt="Choose a Sector"
                                    class="input"
                                    value={sec}
                                    onChange={(val) => { handleSector(val)}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectFilter;
