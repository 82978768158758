import { useEffect, useRef } from "react";

export function useFocusableInput(shouldFocus) {
    const inputRef = useRef(null);
    const setInputRef = (instance) => {
      inputRef.current = instance;
    };

    useEffect(() => {
      let timeout;
      if (shouldFocus) {
        timeout = setTimeout(() => {
          inputRef.current?.focus();
        });
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [shouldFocus]);

    return {
      setInputRef,
    };
  }