import React, { Component } from "react";
import { Collapse } from "react-collapse";
import { ListGroup, ListGroupItem } from "reactstrap"
import "./style.css";

class ProjDocDropDown extends Component {
  state = {
    isOpened: this.props.isOpen
  };


  onItemClick = id => {
    this.setState({ isOpened: !this.state.isOpened });
  };

  render() {
    const { isOpened } = this.state;
    const { title, startDate, endDate,Description } = this.props;
    console.log("milestone", this.props);
    return (
      <div>
        <ListGroup>
        <ListGroupItem  className=" listg">
        <div onClick={this.onItemClick} className="title">
          <span>{title}</span>
        </div>
        </ListGroupItem>
        <Collapse isOpened={isOpened} className="content">
          {isOpened && (
            <div>
              <h5> Start Date : <span class="textl">{startDate}</span></h5>
              <h5> End Date : <span class="textl">{endDate}</span></h5>
                <h5> Description: <span class="textl">{Description}</span></h5>
              
            </div>
          )}
        </Collapse>
        
        </ListGroup>
      </div>
    );
  }
}

export default ProjDocDropDown;
