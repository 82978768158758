import { FETCH_USERS_COMPANIES_REQUEST, FETCH_USERS_COMPANIES_FAILURE, FETCH_USERS_COMPANIES_SUCCESS } from "./companyTypes"
import _ from 'lodash'
import { requests } from "../../../services/Api";
import { getToken, getUserId } from "../../../services/UseTokens";


export const FetchUserCompany = (navigate) => async (dispatch) => {
    const id = getUserId()
    const token = getToken()

    try {
        dispatch({
            type: FETCH_USERS_COMPANIES_REQUEST,
        })
        const response = await requests.get("/company/get-all-companies", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })



        const data = await response.data
        const userCompanies = _.filter(data, { user_id: id })
        const shuffleData = _.shuffle(userCompanies)

        dispatch({
            type: FETCH_USERS_COMPANIES_SUCCESS,
            payload: shuffleData
        })

    } catch (error) {
        dispatch({
            type: FETCH_USERS_COMPANIES_FAILURE,
            payload: error
        })

        if (error.response.status === 401) {
            localStorage.clear()
            navigate('/login')
            // window.location.href = "/login"
        }
    }

}