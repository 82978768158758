import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { logoutUser } from '../../redux/actions/auth/logoutUser'
import { getUser } from '../../services/UseTokens'
import "./style.css"
import classNames from "classnames";


export default function SideBar({ sidebarIsOpen, toggle, opensidebar }) {
    console.log(sidebarIsOpen)
   
 
    const [x, setX] = useState(false)
    const ref = useRef(null);
    const dispatch = useDispatch()
    const navigate = useNavigate("/login")
    var current = new Date();
    const user = getUser()
    const valX=()=>{
        setX(sidebarIsOpen)
        return x
    }

    useEffect(()=>{
        valX()
    },[sidebarIsOpen])
    
    const logout = () => {
        dispatch(logoutUser(navigate))
    }
 
    
        console.log('classNamenc 👉️', ref);
  
    
    
    return (
        // className={classNames("sidebar", {  "sidebar__active":isOpen })}
       
        <div className={x== true ? "sidebar__active sidebar" : "sidebar"}>
                {console.log("x",x)}
            <Link to="" class="close__sidebar">
                {/* <SideBar openSidebar="openSidebar"  isOpen={sidebarIsOpen}/> */}
                <i class="fa-solid fa-xmark" onClick={() => opensidebar()}></i>
            </Link>
            <div class="sidenav__wrapper">
                <ul>
                    <li>
                        <Link to="/my-dashboard" class="sidenav__active">
                            <img src="assets/images/dashboard.png" alt="Dashboard" style={{ height: "32px", width: "32px" }} /> Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to="/onboard-company" class="">
                            <img src="assets/images/company.png" alt="companies" style={{ height: "32px", width: "32px" }} />Onboard
                            Company
                        </Link>
                    </li>

                    <li>
                        <Link to="/my-company">
                            <img src="assets/images/project-management.png" alt="projects" style={{ height: "32px", width: "32px" }} />My
                            Companies
                        </Link>
                    </li>
                </ul>
                <hr />
                <ul class="logout">
                    <li>
                        <Link to="/login">
                            <img src="assets/images/icons/logout.png" alt="Logout" onClick={() => logout()} /> Logout
                        </Link>
                    </li>
                </ul>
            </div>
            <div class="sidenav__wrapper sidenav__footer">
                <h6 className="text-capitalize">My Name</h6>
                <hr />
                <div class="sidenav__time">
                    <p class="secondary"><img src="assets/images/user.png" alt="Calendar" style={{ height: "32px", width: "32px" }} />
                        {user?.first_name + " " + user?.last_name}</p>

                </div>
            </div>
        </div>
    )
}
