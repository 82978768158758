import React, { useState } from 'react'
import Footer from '../../layout/footer'
import DashBoardTopNav from '../../layout/Settings/DashBoardTopNav'
import TopNav from '../../layout/topnav'
import CreateCompany from '../Company/CreateCompany'
import SideBar from './SideBar'
import SideBar2 from './SideBar2'

function Dash() {
    const [sidebarIsOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  return (
    <div>
       
         <div class="dashboard section__space__bottom " >
        <div class="container">
            <div class="dashboard__area">
                <div class="row">
                    <div class="col-xxl-3">
                       <SideBar opensidebar={toggleSidebar}  sidebarIsOpen={sidebarIsOpen}/>
                    </div>
                    <div class="col-xxl-9">
                        <div class="main__content">
                           <DashBoardTopNav toggleSidebar={toggleSidebar}/>
                            <div class="main__content-dashboard">
                               <CreateCompany />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      
    
    </div>
  )
}

export default Dash