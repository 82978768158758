import { FETCH_SUMMARY_FAILURE, FETCH_SUMMARY_REQUEST, FETCH_SUMMARY_SUCCESS } from "../../actions/summary/summaryTypes"

const initialState = {
    loading: false,
    summary: {},
    error: ''
}

const summaryReducer = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_SUMMARY_REQUEST:
            return {
                loading: true,
                summary: {},
                error: ''
            }
        case FETCH_SUMMARY_SUCCESS:
            return {
                loading: false,
                summary: action.payload,
                error: ''
            }
        case FETCH_SUMMARY_FAILURE:
            return {
                loading: false,
                summary: {},
                error: action.payload
            }
        default: return state
    }
}

export default summaryReducer