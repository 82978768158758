import React, { useState, useEffect } from "react";
import SideBar from "../configurables/SideBar";
import BalanceReportCard from "./BalanceReportCard";
import Cardcred from "./Cardcred";
import Graph from "./graph";
import LastIncome from "./LastIncome";
import MyInvestment from "./MyInvestment";
import NewInvestment from "./NewInvestment";
import classNames from "classnames";
import "./dashboard.style.css";
import DashBoardTopNav from "../../layout/Settings/DashBoardTopNav";
import { Card, CardHeader, Grid } from "@mui/material";
import InvestmentSummary from "./InvestMentSummaryCard";
import StatisticsCard from "./StatisticsCard";
import Investors from "../../components/Charts/ProjectInvestors";
import RaisedAmount from "../../components/Charts/RaisedAmountChart";
import MyInvestments from "./MyInvestments";
import MyProjects from "./MyProjects";
import ProjectedChart from "../../components/Charts/ComposedChart";
import { requests } from "../../services/Api";
import { getToken } from "../../services/UseTokens";

export default function Dashboard() {
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
  const [data, setData] = useState([]);

  const opensidebar = () => {
    if (sidebarIsOpen === false) {
      setSidebarOpen(true);
    } else setSidebarOpen(true);
  };

  const token = getToken();

  const fetchUserSummary = async () => {
    await requests
      .get("user-summary", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUserSummary();
  }, []);

  return (
    <div>
      {" "}
      <div class="dashboard section__space__bottom">
        <div class="container">
          <div class="dashboard__area">
            <div class="row">
              <div class="col-xxl-3">
                <SideBar
                  opensidebar={toggleSidebar}
                  sidebarIsOpen={sidebarIsOpen}
                />
              </div>
              <div class="col-xxl-9 ">
                <div className="main__content" I>
                  {/* <DashBoardTopNav toggleSidebar={toggleSidebar} /> */}
                  <div class="main__content-dashboard">
                    <div class="row">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          <InvestmentSummary data={data}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                          <StatisticsCard data={data}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                          {/* <BalanceReportCard /> */}
                          {/* <Card style={{ margin: "15px" }}>
                            <CardHeader title="Investor" />
                            <Investors />
                          </Card> */}
                          <Card style={{ margin: "15px" }}>
                            <CardHeader title="Investment Amounts Distribution" />
                            <RaisedAmount cdata={data}/>
                          </Card>
                          <Card style={{ margin: "15px", padding: "15px" }}>
                            <CardHeader title="Monthly Investment" />
                            <ProjectedChart data={data?.monthlyInvestmentGraph}/>
                          </Card>
                          {/* <MyInvestment /> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                          <Card style={{ margin: "15px" }}>
                            <MyInvestments analytics={data} />
                          </Card>
                          <MyProjects />
                          {/* <LastIncome /> */}

                          <NewInvestment />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
