import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../components/dropdown/dropdown.component';
import { fetchProjects } from '../../redux/actions/projects/fetchProjects';
import { fetchSectors } from '../../redux/actions/sector/fetchSectors';
import Banner from './banner'
import Filter from './filter';
import List from './projects'
function MyProjects() {
    const dispatch = useDispatch();
    const allProjects = useSelector(state => state.projects.projects);
    const sectors = useSelector(state => state.sectors);
    const [search, setSearch] = useState('');
    const [sec, setSec] = useState("");
    const [filterSector, setFilterSector] = useState('');
    const filteredData = () => {
        return allProjects.filter(project => {
            //check if the project name contains the search text
            if(filterSector !== '') {
                return project.sector_id == filterSector && project.project_name.toLowerCase().includes(search.toLowerCase());
            }
            return project?.project_name.toLowerCase().includes(search.toLowerCase());
        })
    }
    const projects = filteredData() || allProjects;
    useEffect(() => {
      dispatch(fetchSectors())
      dispatch(fetchProjects())
    }, [])

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
      <div>
        <Banner />
        {/* <Filter allProjects = {allProjects}/> */}
        <div class="property__filter">
            <div class="container">
                <div class="property__filter__area">
                    <div class="row d-flex align-items-center position-relative">
                        <div class="col-lg-12 col-xl-6">
                            <div class="property__search__wrapper">
                                <form>
                                    <div class="input">
                                        <input 
                                            type="search" 
                                            name="property__search" 
                                            id="propertySearch"
                                            placeholder="Search for properties" 
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                    {/* <button type="submit" class="button button--effect">Search</button> */}
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-3 position-absolute end-0 input w-30">
                            <div class="property__select__wrapper input">
                              
                                <Dropdown
                                options={
                                    sectors.loading
                                    ? []
                                    : sectors.sectors.length === 0
                                    ? []
                                    : sectors.sectors
                                }
                                prompt="sort by sector"
                                className="m-2 input"
                                value={sec}
                                onChange={(val) => setSec(val)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <List allProjects = {projects}/>
      </div>
    );
  }
  
export default MyProjects;
  