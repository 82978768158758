import { USER_SHARES_FAILURE, USER_SHARES_REQUEST, USER_SHARES_SUCCESS } from "../../actions/user-shares/userSharesTypes"

const initialState = {
    loading: false,
    usershares: [],
    error: ''
}

const usersharesReducer = (state=initialState, action) => {
    switch (action.type) {
        case USER_SHARES_REQUEST:
            return {
                loading: true,
                usershares: [],
                error: ''
            }
        case USER_SHARES_SUCCESS:
            return {
                loading: false,
                usershares: action.payload,
                error: ''
            }
        case USER_SHARES_FAILURE:
            return {
                loading: false,
                usershares: [],
                error: action.payload
            }
        default: return state
    }
}

export default usersharesReducer