import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { TextField, Typography } from '@mui/material';
import { OnboardProjectContext } from './ProjectContext';
import { useFocusableInput } from './AutofocusInput';



export default function AdditionalInfo() {
    // const [Description, setDescription] = React.useState()
    const {
        projectDescription,
        setProjectDescription,
        whyInvest,
        setWhyInvest
    } = useContext(OnboardProjectContext);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Typography variant="paragraph">Description</Typography>
                    <CKEditor

                        editor={ClassicEditor}
                        autofocus={useFocusableInput}
                        data={projectDescription}
                        onReady={(editorr) => { console.log(editorr.editing.view.document.isFocused(true))}}
                        // onReady={editor => {
                        //     editor.editing.view.focus()
                        //   }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setProjectDescription(data)
                        }}
                    />
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                    <Typography variant="paragraph">Why Invest</Typography>
                    <Box>
                        <CKEditor

                            editor={ClassicEditor}

                            data={whyInvest}
                            onReady={(editor) => { }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setWhyInvest(data)
                            }}
                        />
                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
}
