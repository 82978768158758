import { DELETE_INVESTOR_REQUEST,DELETE_INVESTOR_SUCCESS,DELETE_INVESTOR_FAILURE } from "../../../actions/Documents/InvestorDocs/InvestorTypes";

const initialState = {
    loading: false,
    error: "",
    data: {}
}

export default function DeleteInvestorDocreducer(state = initialState, action) {
    switch (action.type) {
        case DELETE_INVESTOR_REQUEST:
        return {
            ...state,
            loading: true,
             
        }
        case DELETE_INVESTOR_SUCCESS:
        return {
            ...state,
            data: action.payload,
           
        }
        case DELETE_INVESTOR_FAILURE:
        return {
            ...state,
            error: action.payload,
        }
        default:
        return state
    }
}

