// import "./styles.css";
import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";

// const data = [
//   {
//     name: "Aug",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400
//   },
//   {
//     name: "Oct",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210
//   },
//   {
//     name: "Nov",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290
//   },
//   {
//     name: "Dec",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000
//   },
//   {
//     name: "Jan",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181
//   },
//   {
//     name: "Feb",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500
//   },
//   {
//     name: "March",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100
//   }
// ];

export default function Investors({data}) {
  return (
    <ResponsiveContainer width="100%" height={300}>
    <AreaChart
      width={380}
      height={400}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0
      }}
    >
      <CartesianGrid horizontal={false} vertical={false}  />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey="data" stroke="#8884d8" fill="#8cfab5" />
    </AreaChart>
    </ResponsiveContainer>
  );
}
