import { DELETE_EXECUTIVES_FAILURE,DELETE_EXECUTIVES_SUCCESS, DELETE_EXECUTIVES_REQUEST} from "../../actions/company/CompanyExecutives/ExecutivesTypes";

const initialState={
    loading:false,
    executive:{},
    error:""
}

export default function DeleteExecutiveReducer(state=initialState,action){
    switch (action.type) {
        case DELETE_EXECUTIVES_REQUEST:
            return{
                ...state,
                loading:true,
            }
            
            break;
            case DELETE_EXECUTIVES_SUCCESS:
            return{
                ...state,
                executive:action.payload,
            }
            
            break;
            case DELETE_EXECUTIVES_FAILURE:
            return{
                ...state,
                error:action.payload,
            }
            
            break;
    
        default:
            return state
            break;
    }
}