import { useState, useEffect } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import MuiDivider from "@mui/material/Divider";
import grumdart from "../../assets/images/logos/gumroad.png";
import mastercard from "../../assets/images/logos/mastercard-label.png";
import stripe from "../../assets/images/logos/stripe.png";
import americanbank from "../../assets/images/logos/american-bank.png";
import citibank from "../../assets/images/logos/citi-bank.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../redux/actions/transactions/fetchTransactions";
import { getToken, getUser } from '../../services/UseTokens';
import { imageurl, requests } from "../../services/Api";

const user = getUser();
const token = getToken();
const depositData = [
  {
    logoWidth: 28,
    logoHeight: 29,
    amount: "+$4,650",
    subtitle: "Sell UI Kit",
    title: "Gumroad Account",
    logo: grumdart,
  },
  {
    logoWidth: 38,
    logoHeight: 38,
    amount: "+$92,705",
    title: "Mastercard",
    subtitle: "Wallet deposit",
    logo: mastercard,
  },
  {
    logoWidth: 20,
    logoHeight: 28,
    amount: "+$957",
    title: "Stripe Account",
    subtitle: "iOS Application",
    logo: stripe,
  },
  {
    logoWidth: 34,
    logoHeight: 32,
    amount: "+$6,837",
    title: "American Bank",
    subtitle: "Bank Transfer",
    logo: americanbank,
  },
  {
    logoWidth: 33,
    logoHeight: 22,
    amount: "+$446",
    title: "Bank Account",
    subtitle: "Wallet deposit",
    logo: citibank,
  },
];

// Styled Divider component
const Divider = styled(MuiDivider)(({ theme }) => ({
  margin: theme.spacing(5, 0),
  borderRight: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down("md")]: {
    borderRight: "none",
    margin: theme.spacing(0, 5),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const MyProjects = () => {

  const [transactions, setTransactions] = useState([]);
  const fetchUserTransactions = async() => {
    await requests.get(`/transactions/user-transactions/${user.id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setTransactions(response.data)
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    fetchUserTransactions()
  }, []);
  return (
    <Card
      sx={{
        margin: "15px",
        display: "flex",
        flexDirection: ["column", "column", "row"],
      }}
    >
      <Box sx={{ width: "100%" }}>
        <CardHeader
          title="My Transactions"
          sx={{
            pt: 5.5,
            alignItems: "center",
            "& .MuiCardHeader-action": { mt: 0.6 },
          }}
          action={<Typography variant="caption">View All</Typography>}
          titleTypographyProps={{
            variant: "h6",
            sx: {
              lineHeight: "1.6 !important",
              letterSpacing: "0.15px !important",
            },
          }}
        />
        <CardContent sx={{ pb: (theme) => `${theme.spacing(5.5)} !important` }}>
          {transactions && transactions.length > 0 && transactions.map((item, index) => {
            return (
              <Box
                key={item.title}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: index !== depositData.length - 1 ? 6 : 0,
                }}
              >
                <Box
                  sx={{
                    minWidth: 38,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={`${imageurl}${item?.project?.project_header_image}`}
                    alt={item?.project?.project_name}
                    width={34}
                    height={32}
                  />
                </Box>
                <Box
                  sx={{
                    ml: 4,
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      marginRight: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem" }}>
                      {item?.project?.project_name}
                    </Typography>
                    <Typography variant="caption">{item?.financing_method}</Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 600, color: "success.main" }}
                  >
                    {item.amount}
                  </Typography>
                </Box>
              </Box>
            );
          })}

          {
            transactions && transactions.length === 0 && (
              <h3>No current transaction</h3>                
            )
          }
        </CardContent>
      </Box>
    </Card>
  );
};

export default MyProjects;
