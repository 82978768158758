import { FETCH_EXECUTIVES_FAILURE, FETCH_EXECUTIVES_REQUEST,FETCH_EXECUTIVES_SUCCESS} from "../../actions/company/CompanyExecutives/ExecutivesTypes";

const initialState = {
    loading: false,
    executives: [],
    error:""
}

export default function fetchexecutives(state=initialState,action) {
    
    switch (action.type) {
     
        case FETCH_EXECUTIVES_REQUEST:
            return {
                ...state,
                loading: true,
                error: ""
            }
        case FETCH_EXECUTIVES_SUCCESS:
            return {
                ...state,
                loading: false,
                executives: action.payload,
                error: ""
            }
            case FETCH_EXECUTIVES_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                    executives:""
                }
            default:
                return state;
        }        

}