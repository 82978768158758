import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Dropdown from "../../home/filter/dropdown/dropdown.component";
import { imageurl } from "../../../services/Api";
import "./style.css";
import NoProjectYet from "./NoProjectsYet";
import PropagateLoader from "react-spinners/PropagateLoader";

//get minutes to project end date
const minutesToEnd = (closing) => {
  const today = moment();
  const end = moment(closing);
  const diff = end.diff(today, "minutes");
  return diff;
};

function List({ allProjects }) {
  const [perPage, setPerPage] = useState({ name: "9", value: 9 });
  const [currentPage, setCurrentPage] = useState(1);

  //check number of pages
  const numPages = Math.ceil(allProjects?.length / perPage?.value);
  const paginate = (array, pageSize, pageNumber) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };
  const aprojects = allProjects.filter(
    (project) => minutesToEnd(project.closing_date) > 0
  );

  const projects = paginate(aprojects, perPage?.value || 9, currentPage);

  useEffect(() => {}, [currentPage]);

  const options = [
    { name: "9", value: 9 },
    { name: "18", value: 18 },
    { name: "27", value: 27 },
    { name: "36", value: 36 },
  ];

  return (
    <div>
      <section class="properties__filter section__space__bottom">
        <div class="container wow fadeInUp">
          <div class="properties__filter__wrapper">
            <h4>
              Showing{" "}
              <span style={{ color: "blue" }}>
                <strong>{projects?.length}</strong>
              </span>{" "}
              projects
            </h4>
            <div class="grid__wrapper">
              <label>Per Page</label>
              <Dropdown
                options={options}
                prompt="Per Page"
                value={perPage}
                onChange={(val) => setPerPage(val)}
              />
            </div>
          </div>
        </div>
        {projects && projects.length > 0 ? (
          <div class="row property__grid__area__wrapper">
          <PropagateLoader color="#36d7b7" loading={true}/>
            {projects &&
              projects.map((project, index) => (
                <div
                  class="property__grid__area__wrapper__inner   col-xl-4 col-md-6 col-xxl-2 col-sm-12 col-lg-4 col xl-3 "
                  key={index}
                >
                  <div class="property__list__wrapper property__grid">
                    <div class="row d-flex align-items-center">
                      <div class="property__grid__area__wrapper__inner__two">
                        <div class="property__item__image column__space--secondary">
                          <div class="img__effect">
                            <Link to={`/project-profile/${project?.id}`}>
                              <img
                                src={`${imageurl}${project?.project_header_image}`}
                                alt="Los Angeles"
                              />
                            </Link>
                          </div>
                        </div>
                        <div class="property__grid__area__wrapper__inner__three">
                          <div class="property__item__content">
                            <div class="item__head">
                              <div class="item__head__left">
                                <h4>{project?.project_name}</h4>
                                <p>
                                  <i class="fa-solid fa-location-dot"></i>{" "}
                                  {project?.location}
                                </p>
                              </div>
                              <div class="item__head__right">
                                <div class="countdown__wrapper">
                                  <p class="secondary">
                                    <i class="fa-solid fa-clock"></i> Left to
                                    invest
                                  </p>
                                  <div class="countdown">
                                    <h5>
                                      <span class="days">
                                        {parseInt(
                                          minutesToEnd(project?.closing_date) /
                                            (60 * 24)
                                        )}
                                      </span>
                                      <span class="ref">d</span>
                                      <span class="seperator">:</span>
                                    </h5>
                                    <h5>
                                      <span class="hours">
                                        {parseInt(
                                          parseInt(
                                            minutesToEnd(
                                              project?.closing_date
                                            ) %
                                              (60 * 24)
                                          ) / 60
                                        )}
                                      </span>
                                      <span class="ref">h</span>
                                      <span class="seperator">:</span>
                                    </h5>
                                    <h5>
                                      <span class="minutes">
                                        {parseInt(
                                          parseInt(
                                            parseInt(
                                              minutesToEnd(
                                                project?.closing_date
                                              ) %
                                                (60 * 24)
                                            ) % 60
                                          )
                                        )}
                                      </span>
                                      <span class="ref">m</span>
                                      <span class="seperator"></span>
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="progress__type progress__type--two">
                              <div class="progress">
                                <div
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{
                                    width: `${project?.percentage_completed}%`,
                                  }}
                                ></div>
                              </div>
                              <div class="project__info">
                                <p class="project__has">
                                  <span class="project__has__investors">{`${project?.no_of_investors} Investors`}</span>{" "}
                                  |{" "}
                                  <span class="project__has__investors__amount">{`INVESTED ${project?.invested_amount}`}</span>{" "}
                                  <span class="project__has__investors__percent">{`(${project?.percentage_completed}%)`}</span>
                                </p>
                                <p class="project__goal">
                                  {`INVESTED ${project?.invested_amount}`}
                                </p>
                              </div>
                            </div>
                            <div class="item__info">
                              <div class="item__info__single">
                                <p>Max Investment</p>
                                <h6>
                                  {project?.financing?.maximum_investment}
                                </h6>
                              </div>
                              <div class="item__info__single">
                                <p>Available Shares</p>
                                <h6>
                                  {project?.financing?.project_available_shares}
                                </h6>
                              </div>
                              <div class="item__info__single">
                                <p>Min Investment</p>
                                <h6>
                                  {project?.financing?.minimum_investment}
                                </h6>
                              </div>
                              <div class="item__info__single">
                                <p>Sector</p>
                                <h6>{project?.sector?.name}</h6>
                              </div>
                            </div>
                            <div class="item__footer">
                              <div class="item__security">
                                <div class="icon__box">
                                  <img
                                    src="assets/images/home.png"
                                    alt="Security"
                                  />
                                </div>
                                <div class="item__security__content">
                                  <p class="secondary">Security</p>
                                  <h6>{project?.financing?.finance_method}</h6>
                                </div>
                              </div>
                              <div class="item__cta__group">
                                <Link
                                  to={`/project/${project?.id}`}
                                  class="button button--effect"
                                >
                                  Invest Now
                                </Link>
                                <Link
                                  to={`/projects/${project?.id}`}
                                  class="button button--secondary button--effect"
                                >
                                  Details
                                </Link>
                              </div>
                            </div>
                            <div class="invest__cta__wrapper">
                              <div class="countdown__wrapper">
                                <p class="secondary">
                                  <i class="fa-solid fa-clock"></i> Left to
                                  invest
                                </p>
                                <div class="countdown">
                                  <h5>
                                    <span class="days">
                                      {parseInt(
                                        minutesToEnd(project?.closing_date) /
                                          (60 * 24)
                                      )}
                                    </span>
                                    <span class="ref">d</span>
                                    <span class="seperator">:</span>
                                  </h5>
                                  <h5>
                                    <span class="hours">
                                      {parseInt(
                                        parseInt(
                                          minutesToEnd(project?.closing_date) %
                                            (60 * 24)
                                        ) / 60
                                      )}
                                    </span>
                                    <span class="ref">h</span>
                                    <span class="seperator">:</span>
                                  </h5>
                                  <h5>
                                    <span class="minutes">
                                      {parseInt(
                                        parseInt(
                                          parseInt(
                                            minutesToEnd(
                                              project?.closing_date
                                            ) %
                                              (60 * 24)
                                          ) % 60
                                        )
                                      )}
                                    </span>
                                    <span class="ref">m</span>
                                    <span class="seperator"></span>
                                  </h5>
                                </div>
                              </div>
                              <div class="invest__cta d-flex position-relative">
                                <Link
                                  to={`/project-profile/${project?.id}`}
                                  class="button button--effect start-0"
                                  style={{ color: "white" }}
                                >
                                  Invest Now
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <NoProjectYet />
        )}

        {currentPage === 1 && numPages === 1 ? null : currentPage === 1 ? (
          <div class="cta__btn">
            <button
              class="button button--effect m-4"
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              Load More
            </button>
          </div>
        ) : numPages === currentPage || numPages < currentPage ? (
          <div class="cta__btn">
            <button
              class="button button--effect m-4"
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            >
              Load Previous
            </button>
          </div>
        ) : (
          <div class="cta__btn">
            <button
              class="button button--effect m-4"
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
            >
              Load Previous
            </button>
            <button
              class="button button--effect m-4"
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              Load More
            </button>
          </div>
        )}
      </section>
    </div>
  );
}

export default List;
