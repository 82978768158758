import Swal from "sweetalert2";
import { requests } from "../../../../services/Api";
import { DELETE_PROJECTS_FAILURE } from "../../projects/projectTypes";
import { DELETE_DIRECTORS_REQUEST,DELETE_DIRECTORS_SUCCESS,DELETE_DIRECTORS_FAILURE } from "./directorTypes";

const DeleteDirectorAct=(id,token)=> async (dispatch) => {
    try {
        dispatch({type:DELETE_DIRECTORS_REQUEST})
        const response=await requests.delete(`company/delete-director/${id}`,{
            headers:{
                Authorization: `Bearer ${token}` 
            }
        })
        dispatch({
            type:DELETE_DIRECTORS_SUCCESS,
            payload:response.data
        })
        Swal.fire({
            icon:"success",
            title:"success",
            text:"director deleted successfully",
            confirmButtonText:"Ok"
        })
        
    } catch (error) {
        dispatch({
            type:DELETE_PROJECTS_FAILURE,
            payload:error.response.data
        })
        Swal.fire({
            icon:"error",
            title:"error",
            text:error.response.message,
            confirmButtonText:"Ok"
        })
        
    }
}
export default DeleteDirectorAct