import BackgroundImage from './images/background.png'
import ReactPhoneInput from "react-phone-input-2";
function FormPage () {
    return (
        <div>
            <section class="ask section__space bg__img" style={{ backgroundImage:`url(${BackgroundImage})` }}>
                <div class="container">
                    <div class="ask__area">
                        <div class="alert__newsletter__area">
                            <div class="section__header">
                                <h2 class="neutral-top">Ask a Question</h2>
                            </div>
                            <form action="#" name="ask__from" method="post">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="input input--secondary">
                                            <label for="askFirstName">First Name*</label>
                                            <input type="text" name="ask__first__name" id="askFirstName"
                                                placeholder="Enter Your First Name" required="required" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="input input--secondary">
                                            <label for="askLastName">Last Name*</label>
                                            <input type="text" name="ask__last__name" id="askLastName"
                                                placeholder="Enter Your Last Name" required="required" />
                                        </div>
                                    </div>
                                </div>
                                <div class="input input--secondary">
                                    <label for="askRegistrationMail">Email*</label>
                                    <input type="email" name="ask__registration__email" id="askRegistrationMail"
                                        placeholder="Enter your email" required="required" />
                                </div>
                                <div class="input input--secondary input__alt">
                                    <label for="askNumber">Phone*</label>
                                    <div class="input-group">
                                        <ReactPhoneInput
                                            defaultCountry="ke"
                                            country="ke"
                                            inputClass="form-control input input--secondary"
                                            inputStyle={{width:"250px",height:"60px"}}
                                            required
                                            containerStyle={{ marginRight: "35px",}}
                                            searchClass="search-class"
                                            searchStyle={{ margin: "0", width: "85%", height: "100px" }}
                                            enableSearchField
                                        />
                                    </div>
                                </div>
                                <div class="input input--secondary">
                                    <label for="askSubject">Subject*</label>
                                    <input type="text" name="ask__subject" id="askSubject" placeholder="Write Subject"
                                        required="required" />
                                </div>
                                <div class="input input--secondary">
                                    <label for="askMessage">Message*</label>
                                    <textarea name="ask_message" id="askMessage" required="required"
                                        placeholder="Write Message"></textarea>
                                </div>
                                <div class="input__button">
                                    <button type="submit" class="button button--effect">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FormPage