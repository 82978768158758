import React, { useRef, useState } from 'react'
import { getUser } from '../../services/UseTokens'
import "./style.css"
import moment from 'moment';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button, Input } from 'reactstrap';
import updateProfileAction from '../../redux/actions/profile/updateProfile';
import { useDispatch } from 'react-redux';
import { BsCashCoin } from 'react-icons/bs';


export default function UserProfile() {
  const [updateState,setUpdateState] = useState(false)
  const dispatch = useDispatch();
  const user = getUser()
  const [nationality, setNationality] = useState("")
  const [gender, setGender] = useState()
  const [selectedFile, setSelectedFile] = useState("")
  const [dob, setDob] = useState()
  const [docno, setDocno] = useState()
  const [docType, setDocType] = useState()
  const [image, setImage] = useState(null)
  var years = moment().diff(dob, 'years');
  console.log("age", years)

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()    
  // run this function from an event handler or an effect to execute scroll 


  const updateProff=()=>{
    if(updateState===false){
      setUpdateState(true)
    }else{
      setUpdateState(false)
    }
  }

  const handleActualChange = (e) => {
    var file = e.target.files[0];
    console.log("file", file)
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = function () {
      setSelectedFile(reader.result)
      console.log("selected file", typeof (reader.result))
    }
  }

  const data={
    first_name:user.first_name,
    middle_name:user.middle_name,
    last_name:user.last_name,
    phone_number:user.phone_number,
    email:user.email,
    avatar:selectedFile,
    date_of_birth:dob,
    gender:gender,
    national_document_type:docType,
    national_document_number:docno,
    nationality:nationality
  }
  var options = [
    { value: 'one', label: 'One' },
    { value: 'two', label: 'Two' }
  ];
  var genderopts = [
    { value: "female", label: "female" },
    { value: "male", label: "male" },
    { value: "prefer_not_to_say", label: "prefer not to say" }
  ]
  return (
    <div class="main-content">
      <div class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{ minHeight: "600px", backgroundImage: "url(https://raw.githubusercontent.com/creativetimofficial/argon-dashboard/gh-pages/assets-old/img/theme/profile-cover.jpg)", backgroundSize: "cover", backgroundPosition: "center top" }}>
        {/* <!-- Mask --> */}
        <span class="mask bg-gradient-default opacity-8"></span>
        {/* <!-- Header container --> */}
        <div class="container-fluid d-flex align-items-center">
          <div class="row">
            <div class="col-lg-7 col-md-10">
              <h1 class="display-2 text-white">Hello {user.first_name}</h1>
              <p class="text-white mt-0 mb-5">This is your profile page. You can view the your geneneral info here</p>
              {/* <a href="#upp" onClick={executeScroll}> <button onClick={(e)=>updateProff()} class="btn btn-info">Complete profile</button></a> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Page content --> */}
      <div class="container-fluid mt--7">
        <div class="row">
          <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
            <div class="card card-profile shadow">
              <div class="row justify-content-center">
                <div class="col-lg-3 order-lg-2">
                  <div class="card-profile-image">
                    <a href="#">
                      <img src="https://demos.creative-tim.com/argon-dashboard/assets-old/img/theme/team-4.jpg" class="rounded-circle" />
                    </a>
                  </div>

                </div>
              </div>
              <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">

              </div>
              <div class="card-body pt-0 pt-md-4">

                <div class="row">
                  <div class="col">
                    {/* <h5 class="text-center " style={{ marginTop: "12%" }}>{user.first_name + " " + user.last_name}</h5> */}
                    <div class="card-profile-stats d-flex justify-content-center row  mt-4">

                      <div className='col-sm-6 col xs-6 col-md-6 '>
                        <span class="heading  "  >{user.totalAmountInvested}</span>
                        <span class="description"> Invested</span>
                      </div>
                      <div className='col-sm-6 col xs-6 col-md-6'>
                        <span class="heading">{user.totalCompanies}</span>
                        <span class="description">Companies</span>
                      </div>
                      <div className='col-sm-6 col xs-6 col-md-6'>
                        <span class="heading">{user.totalProjectsInvested}</span>
                        <span class="description">Invested Projects</span>
                      </div>
                      <div className='col-sm-6 col xs-6 col-md-6'>
                        <span class="heading">{user.totalShares}</span>
                        <span class="description">Shares</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <h3 className='text-center '>
                    <h3 className='text-center'> {user.first_name + " " + user.last_name} ,</h3>
                    {/* <span class="font-weight-light">{" " + years} years old</span> */}
                  </h3>
                  <div class="h5 font-weight-300">
                    <i class="ni location_pin mr-2"></i>{user.email}
                  </div>

                  <div class="h5 mt-4">
                    <i class="ni business_briefcase-24 mr-2"></i>{user.phone_number}

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8 order-xl-1">
            <div class="card bg-secondary shadow">
              <div class="card-header bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">My Information</h3>
                  </div>
                  <div class="col-4 text-right">
                    <Link to="/my-dashboard" class="btn btn-sm btn-primary">Go to Settings</Link>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <form>
                  <h6 class="heading-small text-muted mb-4">User information</h6>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-username">First Name</label>
                          <input type="text" id="input-username" name="first_name" class="form-control form-control-alternative" placeholder="Username" value={user.first_name} />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-username">Middle Name</label>
                          <input type="text" id="input-middlename" name="middle_name" class="form-control form-control-alternative" placeholder="Username" value={user.middle_name} />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-username">Last Name</label>
                          <input type="text" id="input-middlename" name="last_name" class="form-control form-control-alternative" placeholder="Username" value={user.last_name} />
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-first-name">Email</label>
                          <input type="text" id="input-email" name="email" class="form-control form-control-alternative" placeholder="First name" value={user.email} />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-last-name">Phone Number</label>
                          <input type="text" id="input-last-name" name="phone_number" class="form-control form-control-alternative" placeholder="Last name" value={user.phone_number} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4" />
                  {/* <!-- Address --> */}
                  {updateState===true &&(
                    <div>
                       <h6 class="heading-small text-muted mb-4">Additional Information</h6>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-address">Nationality</label>
                          <input id="input-address"
                            class="form-control form-control-alternative"
                            placeholder="Home Address"
                            name="nationality"
                            onChange={(e) => { setNationality(e.target.value) }}
                            value={nationality}
                            type="text" />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-city">Date of Birth</label>
                          <input type="date"
                            id="input-city"
                            class="form-control form-control-alternative"
                            placeholder="enter your date of birth"
                            onChange={(e) => {
                              setDob(e.target.value)
                              console.log("dob", e.target.value)
                            }}
                            value={dob} />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-country">Document type</label>
                          <Select

                            name="form-field-name"
                            value={docType}
                            options={options}
                            onChange={(e) => { setDocType(e.value) }}
                          />
                        </div>
                      </div>

                    </div>
                    <div class="row">

                    {/* <div class="col-lg-4">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-city">Document Number</label>
                          <Input type="text"
                          name="document_number"
                          onChange={(e) => { setDocno(e.target.value) 
                            console.log(e.target.value) }}
                          value=""
                          />
                        </div>
                      </div> */}
                      <div class="col-lg-4">
                        <div class="form-group focused">
                          <label class="form-control-label" for="input-country">Gender</label>
                          <Select
                            name="form-field-name"
                            value={gender}
                            options={genderopts}
                            onChange={(e) => { setGender(e.value) }}
                          />
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="form-group">
                          <label class="form-control-label" for="input-country">file</label>
                          <input type="file"
                            id="input-postal-codekk"
                            class="form-control form-control-alternative"
                            name="avatar"
                            onChange={(e) => { handleActualChange(e) }}
                            value={docno}
                            placeholder="select image" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4" />
                  {/* <!-- Description --> */}
                  <Button type="button" onClick={(e)=>{
                    e.preventDefault();
                    dispatch(updateProfileAction(data))
                    console.log("submitted values",data)}}  className="button button--input" >Submit</Button>

                    </div>
                  )}
                 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  )
}
