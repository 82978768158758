import React, { useState } from 'react'
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { requests } from '../../services/Api';
import { Form, Formik } from 'formik';
import * as Yup from "yup"
import { Button, Input, Spinner, InputGroup } from 'reactstrap';
import Swal from 'sweetalert2';
import image from "../../assets/images/registration-bg.png"
import { setUserToken } from '../../services/UseTokens';

export default function Login() {
    const location = useLocation();
    document.title = "Login|Mapato";
    const [loading, setLoading] = useState(false)
    const [serverError, setServerError] = useState("")
    const [successResponse, setSuccessResponse] = useState("")
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)

    const LoginUser = async (values) => {
        try {
            setLoading(true)
            await requests.post(`auth/login`, values).then((response) => {
                setLoading(false)
                const { data } = response
                console.log("userdata", data)
                // localStorage.setItem("authuser", JSON.stringify(response))
                setUserToken(response.token);
                setSuccessResponse("Login Successful")

                Swal.fire({
                    icon: "success",
                    text: "Login Successful",
                    confirmButtonText: "Ok"
                })
                setTimeout(() => {
                    setSuccessResponse("")
                    navigate(location.state && location.state.from ? location.state.from : '/two-factor')
                }, 1000)

            })


        }
        catch (error) {
            setLoading(false)
            console.log('error login', error.response.data.message)
            setServerError(error.response.data.message)
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response.data.message,
                confirmButtonText: "Ok"
            })
            setTimeout(() => {
                setServerError("")
            }, 5000)
        }
    }


    return (
        <div style={{marginTop: "50px"}}>
            <div class="wrapper bg__img" data-background={image}>


                <section class="registration ">
                    <div class="container">

                        <div class="registration__area">

                            <h4 class="neutral-top text-center">Log in</h4>
                            {successResponse && (
                                <div style={{ color: "green", fontSize: "25px" }}>
                                    {successResponse && (<p style={{ color: "green" }}>{successResponse}</p>)}
                                </div>

                            )}
                            {serverError && (
                                <div style={{ color: "red", fontSize: "25px" }}>
                                    {serverError}
                                </div>
                            )}

                            <Formik
                                initialValues={{
                                    "email": "",
                                    "password": ""
                                }}
                                validationSchema={Yup.object({
                                    email: Yup.string().email('Invalid email address').required('Required'),
                                    password: Yup.string().required('Required'),

                                })}
                                onSubmit={(values) => {
                                    LoginUser(values)
                                }}>
                                {({ values, isSubmitting, errors, handleSubmit, handleChange, isValid,
                                    dirty }) => (

                                    <Form >



                                        <div class="input input--secondary">
                                            <label for="loginMail">Email*</label>
                                            <Input type="text"
                                                className="form-control"
                                                id="username"
                                                placeholder="Enter username"
                                                required
                                                name="email"
                                                onChange={handleChange}
                                                value={values.email} />
                                        </div>
                                        <div class="input input--secondary">
                                            <label for="loginPass">Password*</label>
                                            <div class="input-group">
                                                <InputGroup>
                                                    <Input type={showPassword === true ? "text" : "password"}
                                                           className="form-control pe-5"
                                                           placeholder="Enter password"
                                                           id="password-input"
                                                           required
                                                           name="password"
                                                           onChange={handleChange}
                                                           value={values.password} />
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword === true ? "Hide" : "Show"}
                                                    </Button>
                                                    {/*<button className="input-group-btn    bg-opacity-10   text-decoration-none text-muted" type="button" id="password-addon" >Show</button>*/}
                                                </InputGroup>

                                            </div>

                                            <div class="text-capitalize" style={{ color: "red", marginTop: "12px" }}>
                                                {errors.password}
                                            </div>
                                        </div>
                                        <div class="checkbox login__checkbox">
                                            <label>
                                                <input type="checkbox" id="remeberPass" name="remeber__pass" value="remember" />
                                                <span class="checkmark"></span>
                                                Remember Me
                                            </label>
                                            <a href="/forgot-password">Forget Password</a>
                                        </div>
                                        {
                                            loading && (
                                                <div className="mt-4">

                                                    <Button color="secondary" className="btn-load w-100" outline disabled>
                                                        <span className="d-flex align-items-center">
                                                            <span className="flex-grow-1 me-2">
                                                                Loading...
                                                            </span>
                                                            <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                                                        </span>
                                                    </Button>
                                                </div>

                                            )
                                        }

                                        {!loading && (
                                            <div className="mt-4">
                                                <Button type="submit" color="secondary" className="button button--effect w-100" >Sign In</Button>
                                            </div>
                                        )}
                                        <p>Don't have an account? <a href="/register">Register here.</a></p>
                                        <p class="fw-bold mt-4"><a href="/">Back to home</a></p>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </section>



            </div>
        </div>
    )
}
