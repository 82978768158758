import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getUserId } from '../../../services/UseTokens';
import _ from 'lodash';
import fetchdocumentstypes from '../../../redux/actions/Documents/documentTypes/fetchdocumentstypes';
import fetchInvestorAction from '../../../redux/actions/Documents/InvestorDocs/fetchInvestorDocuments';
import { Card, CardBody, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, Form, Input, Label, Modal, Row } from 'reactstrap';
import { Formik } from 'formik';
import myimg from './image/image/image.png'
import avif from "./image/image/avif.webp"
import jpgimg from "./image/image/jpg.png"
import webpimg from "./image/image/webp.png"
import pngimg from "./image/image/png.png"
import { createInvestor } from '../../../redux/actions/Documents/InvestorDocs/PostInvestorDocAction';
import DeleteInvestorDocument from './DeleteUnvestorDocument';
import ViewSingle from '../ProjectOwner/ViewSingle';
import ViewDocument from '../ProjectOwner/ViewDocument';
import { AiOutlineFileJpg, AiOutlineFilePdf, AiOutlineMore } from 'react-icons/ai';
 

export default function InvestorDocumentTable() {
const [saveData,setSaveData]=React.useState([{document:"",document_id:"",user_id:""},{document:"",document_id:"",user_id:""}]);
const [serverError,setServerError]=React.useState("");
const [successResponse,setSuccessResponse]=React.useState("");
const [loading,setLoading]=React.useState(false);
const [image,setImage]=React.useState(null);
const [doc ,setDoc]=React.useState("");
const [modal_center, setmodal_center] = useState(false);
const [dropdownOpen, setDropdownOpen] = React.useState(false);
const [modal, setModal] = useState(false)
const toggle = () => setDropdownOpen(prevState => !prevState);

var testArr=[]

  const userId=getUserId()
  const token=getToken()
  const dispatch = useDispatch();
  const documents = useSelector(state => state.documenttype.documents)
  const investorDocuments= useSelector(state => state.investorDocuments.data);
  const myInvestorDocuments=_.filter(documents,{isInvestor:1})
  const isLoading=useSelector(state => state.createInvestorDocument.loading);
  const response=useSelector(state=>state.createInvestorDocument.data)
console.log("userId",userId)
  // filter docs to display form

  function tog_center2() {
    if (modal == false) {
      setModal(true)
    } else {
      setModal(false)
    }
  }
  function tog_center() {
    if (modal_center == false) {
        setmodal_center(true)
    } else {
        setmodal_center(false)
    }
}

  const newcreatedprojectDocuments=userId && _.filter(investorDocuments,{user_id:2})
  const b = myInvestorDocuments && myInvestorDocuments.filter(function (o1) {
    return !newcreatedprojectDocuments.some(function (o2) {
      return o1.id === o2.document_id;
    })
  })
  console.log("investorDocuments ttr",newcreatedprojectDocuments);
  const newdocs = () => {
    newcreatedprojectDocuments.map((item, index) => {
      for (let i = 0 ; i < newcreatedprojectDocuments.length; i++) {
        let temp = { document: "", document_id: "", user_id: "" }
        setSaveData([...saveData, temp])
        console.log("save data links", saveData)
        return saveData
      }
    })
  }
  const handleActualChange = (id, e, index) => {
    newdocs()
    console.log("event", e.target.name)
    setImage(e.target.files[0])
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    let temp = [...saveData]
    console.log("saveData", saveData)
    console.log("temp empty", temp)
    
    console.log("saveData", saveData)
    reader.onloadend = function () {
      console.log("reader", index)
      console.log("temp index", temp[index])
      temp[index][e.target.name] = reader.result
      temp[index]["document_id"] = id
      temp[index]["user_id"] =userId
      setSaveData(temp)

    }
    // let temp = [ ...saveData ]
    console.log("save data", saveData)
    console.log("temp", temp)
  }
  const handleSubmitbb = (e,saveData,token) => {
    
    setLoading(true)
    dispatch(createInvestor(saveData,token))
    .then(() => {
      
      console.log("response for investors", response)
      setSuccessResponse(response.data.message)
      setLoading(false)
      var index = b.findIndex(function(o){
        return o.id === response.data.document_id;
   })
   console.log("index",index)
   b.splice(index,1)
   return b;
    })
  }

  const handleDelete=(childData)=>{
    console.log("childData",childData)
    var index = newcreatedprojectDocuments.findIndex(function(o){
      return o.id === childData
 })
    newcreatedprojectDocuments.splice(index,1)
    return newcreatedprojectDocuments

    console.log("newcreatedprojectDocuments minus one",newcreatedprojectDocuments)
    
  }

  useEffect(() => {
    dispatch(fetchdocumentstypes(token))
    dispatch(fetchInvestorAction(token))
  }, [])
  
  return (
    <div>
       <Row>
         
         <Col md={12} lg={12} sm={12} xs={12}>
           {b && b.length > 0 && (
             <Card className="p-2" >
               <Formik
                  initialValues={{
                   "document": "",
                   "document_id": "",
                   "user_id": "",
               }}
                 onSubmit={(values, { resetForm }) => {
                 //   PostProjectOwnerDocument()
                 console.log("submit data",saveData)
                 dispatch(createInvestor (saveData,token))
                 //     console.log("valuees",saveData)
                   resetForm({ values: "" })
               
                console.log("post  data",saveData)
                 }}
                  >
                 {({ values, isSubmitting, errors, handleSubmit, handleChange, dirty, isValid }) => (
 
                   <Form>
                     {serverError && (
                       <div style={{ color: "red", fontSize: "15px" }}>{serverError && (
                         <div>{serverError}</div>
                       )}
                       </div>
                     )}
 
                     {successResponse && (
                       <div
                         style={{ color: "green", fontSize: "15px", height: "30px", width: "auto", right: "0" }}>
                         {successResponse}
                       </div>
 
                     )}
 
                     <CardHeader className='position-relative'>
                       <h5 className="card-title mb-0">Upload Documents below</h5>
                     </CardHeader>
                     <CardBody>
                       <Row>
                         {b.length > 0 && b.map((projectdoc, index) => (
                           <Col md={4} lg={4} sm={12} xs={12} key={index}>
                             {() => testArr.push({ document: {}, document_id: "", user_id: "" })}
 
                             <div className=" mb-3">
                               <Label className="ValidationInput text-capitalize fs-15 " for="validationFormCheck3">{projectdoc.name}</Label>
                               <Input type="file"
                                 className="form-control"
                                 id="name"
                                 name="document"
                                 placeholder='Enter document '
                                 values={image|| " "}
                                 onChange={(e) => {
                                 
                                   handleActualChange(projectdoc.id, e, index)
                                 }}
                                 required />
 
                               <div className="invalid-feedback">upload file</div>
                             </div>
 
                           </Col>
                         )
 
                         )}
 
                       </Row>
                       {isLoading ===false &&(
                           <div className="text-right position-absolute  end-0 m-2">
                           <div className="m-1 position-relative">
                         <button type="button" onClick={(e)=>
                       handleSubmitbb(saveData,token)
                       
                         } className="button button--input">Submit</button>
                         </div>
                         </div>
                       )}
                       {/* {isLoading ===true &&(
                            <div className="text-right position-absolute  end-0 m-2">
                            <div className="m-1 position-relative">
                              <Button color="primary" className="btn-load" outline disabled>
                                <span className="d-flex align-items-center">
                                  <span className="flex-grow-1 me-2">
                                    Loading...
                                  </span>
                                  <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                                </span>
                              </Button>
                            </div>
                          </div>
                       )} */}
 
                     </CardBody>
                  
                   </Form>
                 )}
               </Formik>
             </Card>
           )}
         </Col>
         </Row>
         <div class="investment-table">
         <div class="table-wrapper ">
      <Row className='d-flex'>
      
      {newcreatedprojectDocuments&& 
          newcreatedprojectDocuments.map((projectdoc, index) => (
        <div className='col-lg-4 col-md-4 col-sm-6 col-xs-6 '>
          
            <div className="investment-table-row" key={index}>
            {projectdoc.documents.type === "pdf" && (
                    <div><AiOutlineFilePdf style={{ height: "85%", width: "85%", color: "red" }} /></div>
                  )}
                  {projectdoc.documents.type === "jpg" && (
                    <div><AiOutlineFileJpg style={{ height: "100px", width: "100px", color: "" }} /></div>
                  )}
                  {projectdoc.documents.type === "jpeg" && (
                    <div><img src={myimg} alt="jpeg" style={{ height: "85%", width: "85%" }} /></div>
                  )}
                  {projectdoc.documents.type === "png" && (
                    <div><img src={pngimg} alt="jpeg" style={{ height: "45%", width: "45%" }} /></div>
                  )}
                  {projectdoc.documents.type === "avif" && (
                    <div><img src={avif} alt="avif" style={{ height: "45%", width: "45%" }} /></div>
                  )}
                  {projectdoc.documents.type === "webp" && (
                    <div><img src={webpimg} alt="webp" style={{ height: "45%", width: "45%" }} /></div>
                  )}
               <div className='d-flex flex-row position-relative m-4 ' >
                    <p class="position-absolute start-0 text-muted">{projectdoc.documents.name}</p>
                    <Dropdown className="position-absolute end-0 " isOpen={dropdownOpen} toggle={toggle}>
                      <a href="#" caret><AiOutlineMore /></a>
                      <DropdownMenu>
                        <DropdownItem onClick={(e) => {
                          tog_center2(e)
                          setDoc(projectdoc.id)

                        }}><i className="ri-eye-fill me-2 align-middle text-muted" />View</DropdownItem>
                        { }
                        <Modal
                          isOpen={modal}
                          toggle={() => {
                            tog_center2();
                          }}
                          centered
                        >{
                            projectdoc.documents.type === "pdf" ?
                              <ViewSingle handleClose={tog_center2} documentId={doc} /> :
                              <ViewDocument  handleClose={tog_center2} documentId={doc} />
                          }

                        </Modal>
                        {/* delete */}
                        <DropdownItem className='remove-item-btn' onClick={() =>{
                          tog_center()
                          setDoc(projectdoc.id)
                        }}
                        >
                          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
                        <Modal
                          isOpen={modal_center}
                          toggle={() => {
                            tog_center();
                          }}
                          centered
                        >
                          <DeleteInvestorDocument handleClose={tog_center} documentId={doc} handleDelete={handleDelete()} />
                        </Modal>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
            </div>
          
        </div>
        ))}
      </Row>
       
    </div>

         </div>
    </div>
  )
}
