import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchSummary } from "../../../redux/actions/summary/fetchSummary";

function Numbers() {
    const dispatch = useDispatch();
    const summary = useSelector(state => state.summary.summary);
    useEffect(() => {
        dispatch(fetchSummary())
    }, [])
    
  return (
    <div>
    <section class="numbers section__space bg__img" data-background="assets/images/globe.png">
        <div class="container">
            <div class="numbers__area wow fadeInUp">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-6">
                        <div class="content column__space">
                            <h5 class="neutral-top">With Revest anyone can invest!</h5>
                            <h2>Numbers Said
                                More Than Words</h2>
                            <p>our low minimums give you the flexibility to invest the right amount, at the right time,
                                to meet your goals.</p>
                            <Link to="/projects" class="button button--effect" style={{color: 'white'}}>Start Exploring</Link>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row d-flex align-items-start align-items-lg-center">
                            <div class="col-sm-6">
                                <div class="numbers__single shadow__effect">
                                    <img src="assets/images/platforms.png" alt="platform" />
                                    <h3><span class="counter">{summary.total_investors}</span>+</h3>
                                    <p class="neutral-bottom">Investors Using Platform</p>
                                </div>
                                <div class="numbers__single shadow__effect__secondary">
                                    <img src="assets/images/returns.png" alt="Returns" />
                                    <h3><span class="counter">{summary.total_projects}</span>+</h3>
                                    <p class="neutral-bottom">Total Projects</p>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="numbers__single alt shadow__effect__secondary">
                                    <img src="assets/images/experience.png" alt="Experience" />
                                    <h3 class="counter">{summary.total_invested_amount}</h3>
                                    <p class="neutral-bottom">Total Invested Amount</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Numbers;
