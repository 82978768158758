// import "./styles.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 }
];

const COLORS = ["#5927e3", "#4BB543","#9C27B0", "#ED6C02",  "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
export default function ProjectPieChart() {
  return (
    <Box style={{display:"flex",justifyContent:"center",alignItem:"center"}}>
      <div>
       <Typography variant="h6" style={{marginLeft:{sm:"24px",xs:"34px"}}} >Raised Amount Disribution</Typography>
    <PieChart width={400} height={400} style={{margin:"5px"}} >
    {/* <Typography variant="h6" >Raised Amount Disribution</Typography> */}
      <Pie
        data={data}
        cx={200}
        cy={200}
       
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
    </div>
    </Box>
    
  );
}
