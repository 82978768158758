import React, { useEffect, useRef, useState } from 'react';
import MuiAlert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Divider, Stack, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { Check } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import PropTypes from 'prop-types'

import image from "../../assets/images/company.jpg"
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Payment from '../Onboard/Payment';
import Company from '../Company/Index';
import NewDirectors from '../Onboard/NewDirectors';
import GeneralProjectInfo from './generalInfo';
import AdditionalInfo from './AdditionalInfo';
import FinancialDetails from './FinancialDetails';
import { OnboardProjectContext } from './ProjectContext';
// import GeneralInfo from '../OnBoardCompany/GeneralInfo';



const steps = ['General Information', 'Additional Details', "Finanicial Information "];

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const ActiveStepContent = ({ step, loading }) => {
    // const { handleSubmit } = props;
    switch (step) {
        case 0:
            return <GeneralProjectInfo step={step} loading={loading} />
        case 1:
            return <AdditionalInfo step={step} loading={loading} />
        case 2:
            // returDirectorn <Director step={step} loading={loading} />
            return <FinancialDetails step={step} loading={loading} />
        default:
            throw new Error('Unknown step');
    }
}

const OnBoardProject = () => {

    const [activeStep, setActiveStep] = useState(0);
    const previousStep = useRef(null);
    const [open, setOpen] = useState(false);
    const [loading, isLoading] = useState(true);
    const [disabled, setDisabled] = React.useState(false)
    const [projectName, setProjectName] = useState("");
    const [openingDate, setOpeningDate] = useState(new Date());
    const [closingDate, setClosingDate] = useState(new Date());
    const [sector, setSector] = useState("");
    const [address, setAddress] = useState("");
    const [projectHeader, setProjectHeader] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [whyInvest, setWhyInvest] = useState("");
    const [projectFinance, setProjectFinance] = useState({});
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [checked, setChecked] = useState(1);

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#5927e3',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#5927e3',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    }));

    const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#5927e3',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#5927e3',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }));


    const handleValidation = ({ result }) => {
        setDisabled(!result)
    }

    function QontoStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    <Check className="QontoStepIcon-completedIcon" />
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </QontoStepIconRoot>
        );
    }

    QontoStepIcon.propTypes = {
        /**
         * Whether this step is active.
         * @default false
         */
        active: PropTypes.bool,
        className: PropTypes.string,
        /**
         * Mark the step as completed. Is passed to child components.
         * @default false
         */
        completed: PropTypes.bool,
    };


    const handleNext = () => {


        setActiveStep(activeStep + 1);
        let prev = previousStep.current;
        let cur = activeStep + 1;

        if (prev == 0 && cur == 1) {
            console.log('Updating supplier profile');
        } else if (prev == 3 && cur == 4) {
            console.log('Updating supplier competency');
        } else {

        }


        console.log('prev', prev);
        console.log('cur', cur);
        setOpen(true);
    };


    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        previousStep.current = activeStep;
    }, [activeStep]);


    const theme = createTheme(
        {
            components: {
                MuiTypography: {
                    styleOverrides: {
                        root: {
                            marginBottom: '1rem',
                        }
                    }
                }
            }
        }
    );

    return (
        <OnboardProjectContext.Provider value={{
            projectName, 
            setProjectName,
            openingDate,
            setOpeningDate,
            closingDate,
            setClosingDate,
            sector,
            setSector,
            address,
            setAddress,
            projectHeader,
            setProjectHeader,
            projectDescription,
            setProjectDescription,
            whyInvest,
            setWhyInvest,
            projectFinance,
            setProjectFinance, 
            lat,
            setLat,
            lng,
            setLng,
            checked,
            setChecked,
            }}>
            <div class="" style={{
                minHeight: "1000vh !important",
                backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), #5e5ee2),url(${image})`, paddingX:{ lg:"60px",xs:"15px",paddingTop:"70px"}
            }}>
                <ThemeProvider theme={theme} class="mb-2" >
                    <CssBaseline />
                    <Container component="main" maxWidth="xl"
                        minHeight="100vh"
                        // sx={{ width: '75%', mb: 2  ,height: '100%' }}
                        sx={{ mb: 0, minHeight: "100vh" }} style={{marginTop:"90px"}}>

                        <Paper elevation={0} style={{minWidth:{xs:"100% !important"},marginTop:{xs:"80px"}}}>

                            <Box sx={{ pt: 1, borderRadius: '5px 5px 0 0', marginTop:{ lg:"7%",md:"7%",xs:"1%"}, marginX:{ lg:"auto"}, maxWidth: {lg:"75%",xs:"100%" ,minWidth:{xs:"100%"}} }}>
                                <Typography variant={"p"} sx={{ p: 2.25, fontSize: '16px', }}>
                                    Fill all fields to go to next step
                                </Typography>
                                <Divider sx={{ my: 1, color: '#eaeaf0' }} />
                            </Box>

                            <Stack sx={{ width: '75%', marginX: "auto" }} spacing={8} class=" m-6">
                                <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />} sx={{ p: 2 }}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Stack>

                            <Paper
                                sx={{ my: { xs: 0, md: 0 }, p: { xs: 2, md: 2 } }}

                            // sx={{ maxWidth:"75%",marginX:"auto",my: { xs: 0, md: 0 }, p: { xs: 2, md: 2 } }}
                            >
                                <React.Fragment>
                                    {activeStep === steps.length ? (
                                        <React.Fragment>
                                            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                                {() => alert('test')}
                                            </Snackbar>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Box className='preq-steps'>
                                                <ActiveStepContent step={activeStep} loading={loading} />
                                                {/* {getStepContent(activeStep)} */}
                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                                {activeStep !== 0 && (
                                                    <Button onClick={handleBack}
                                                        style={{
                                                            color: "#5927e3"
                                                        }}
                                                        variant="outlined"
                                                        sx={{ mt: 3, ml: 1, mr: 1, textTransform: 'Capitalize' }}>
                                                        Previous
                                                    </Button>
                                                )}

                                                <Button
                                                    style={{
                                                        backgroundColor: "#5927e3"
                                                    }}
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 3, ml: 1, textTransform: 'Capitalize' }}
                                                >
                                                    {activeStep === steps.length - 1 ? '' : 'Next Step'}
                                                </Button>
                                            </Box>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </Paper>

                        </Paper>

                    </Container>
                </ThemeProvider>
            </div>
        </OnboardProjectContext.Provider>
    );
}
export default OnBoardProject;
