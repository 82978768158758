import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Payment from './Payment';
import NewDirectors from './NewDirectors';
import { OnboardCompanyContext } from './OnboardContext';
import Company from './Company';
import image from "../../assets/images/company.jpg"
import "./style.css"
import { Card } from '@mui/material';
import Swal from 'sweetalert2';

const steps = [
    {
        label: 'Payment',

    },
    {
        label: 'Company Search',

    },
    {
        label: 'Director Invitation',

    },
];

export default function OnBoardMobile() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [loading, isLoading] = React.useState(true);

    const registrationN = localStorage.getItem("brs-company");
    const registration = JSON.parse(registrationN)?.id;

    const ActiveStepContent = ({ step, loading }) => {
        // const { handleSubmit } = props;
        switch (step) {
            case 0:
                return <Payment step={step} loading={loading} />
            case 1:
                return <Company step={step} loading={loading} />
            case 2:
                // returDirectorn <Director step={step} loading={loading} />
                return <NewDirectors step={step} loading={loading} />
            default:
                throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <OnboardCompanyContext.Provider value={{ activeStep, setActiveStep }}>
              <div class="" style={{
                minHeight: "1000vh !important",
                display:"flex",
                justifyContent:"center",
                marginTop:"50px",
                paddingTop:"10%",
                backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), #5e5ee2),url(${image})`, padding: "60px"
            }}>
            <Card style={{minWidth:"90vw",padding:"30px"}}>  
            <Typography variant='h6' style={{fontWeight:400,color:"#5927e3"}}>Onboard Company</Typography>
            <Box sx={{  marginTop: "70px" }}>
                
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel style={{color:"#5927e3"}}
                                optional={
                                    index === 2 ? (
                                        <Typography variant="caption">Last step</Typography>
                                    ) : null
                                }
                            >
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                {/* <Typography>{step.description}</Typography> */}
                                {/* <p>uuuu</p> */}
                                {/* <ActiveStepContent step={activeStep} loading={loading} /> */}

                                <React.Fragment>
                                {activeStep === steps.length ? (
                                        <React.Fragment>
                                            {
                                                Swal.fire({
                                                    icon: 'success',
                                                    title: `Company profile created successfully`,
                                                    showClass: {
                                                        popup: 'animate__animated animate__fadeInDown'
                                                    },
                                                    hideClass: {
                                                        popup: 'animate__animated animate__fadeOutUp'
                                                    },
                                                    showConfirmButton: true,
                                                }).then((result) => {
                                                    window.location.href = `/company-profile/${registration}`
                                                })
                                            }
                                        </React.Fragment>
                                    ) :(
                                        <React.Fragment>
                                            <ActiveStepContent step={activeStep} loading={loading} />

                                            <Box sx={{ mb: 2 }}>
                                    <div>
                                    {activeStep !== 0 && (
                                                    <Button onClick={handleBack}
                                                        style={{
                                                            color: "#5927e3"
                                                        }}
                                                        variant="outlined"
                                                        sx={{ mt: 3, ml: 1, mr: 1, textTransform: 'Capitalize' }}>
                                                        Previous
                                                    </Button>
                                                )}

                                                <Button
                                                    visible={activeStep === steps.length - 1}
                                                    style={{
                                                        backgroundColor: "#5927e3"
                                                    }}
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 3, ml: 1, textTransform: 'Capitalize' }}
                                                >
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next Step'}
                                                </Button>
                                    </div>
                                </Box>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                                {/* <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Box> */}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Reset
                        </Button>
                    </Paper>
                )}
            </Box>
            </Card>  
           
            </div>
        </OnboardCompanyContext.Provider>
    );
}
