import { FETCH_SINGLE_DOCUMENT_FAILURE, FETCH_SINGLE_DOCUMENT_REQUEST, FETCH_SINGLE_DOCUMENT_SUCCESS } from "../../../actions/Documents/projectDocuments/DocumentsTypes"

const initialState = {
    loading: false,
    document: {},
    error: ''
}

const singleDocumentReducer = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_SINGLE_DOCUMENT_REQUEST:
            return {
                loading: true,
                document: {},
                error: ''
            }
        case FETCH_SINGLE_DOCUMENT_SUCCESS:
            return {
                loading: false,
                document: action.payload,
                error: ''
            }
        case FETCH_SINGLE_DOCUMENT_FAILURE:
            return {
                loading: false,
                document: {},
                error: action.payload
            }
        default: return state
    }
}

export default singleDocumentReducer