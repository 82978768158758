import Details from "./details";

function ProjectDetails() {
    return (
      <div>
        <Details />
      </div>
    );
  }
  
export default ProjectDetails;
  