import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
// import { TabPanel } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Modal,
  Typography,
} from "@mui/material";
import ProjectAdditonal from "./ProjectAdditional";
import Milestone from "./Milestone";
import AddMilestone from "../project-details/AddMiletone";
import Risk from "./Risk";
import NoRisk from "./NoRisk";
import MainGallery from "./Gallery/Index";
import PropTypes from "prop-types";
import Analytics from "./Analytics/Index";
import { getToken, getUser } from "../../services/UseTokens";
import { useParams } from "react-router-dom";
import { requests } from "../../services/Api";
import image from "../../assets/images/NoProjects.png";
import MyAnalytics from "./MyAnalytics";
import { ProjectContext } from './ProjectContext';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProjectTab2({ project, financing }) {
  const token = getToken();
  const user = getUser();
  const { project_id } = useParams();
  const [value, setValue] = React.useState(0);
  const [risks, setRisks] = React.useState([]);
  const [rLoading, setRLoading] = React.useState(false);
  const [milestones, setMilestones] = React.useState([]);
  const [mloading, setMLoading] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchProjectRisks = async () => {
    setRLoading(true);
    await requests
      .get(`projects/get-project-risks/${project_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setRLoading(false);
        setRisks(response.data);
      })
      .catch((error) => {
        setRLoading(false);
        console.log(error);
      });
  };

  const fetchProjectMilestones = async () => {
    setMLoading(true);
    await requests
      .get(`projects/get-project-milestones/${project_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setMLoading(false);
        setMilestones(response.data);
      })
      .catch((error) => {
        setMLoading(false);
        console.log(error);
      });
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    fetchProjectMilestones();
    fetchProjectRisks();
  }, []);

  return (
    <ProjectContext.Provider value={{ project, milestones, risks, setMilestones, setRisks }}>
    <Box
      sx={{
        maxWidth: { xs: 320, sm: 480 },
        minWidth: "100%",
        bgcolor: "background.paper",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable auto tabs example"
      >
        <Tab label="OverView" {...a11yProps(0)} />
        <Tab label={`Milestones`} {...a11yProps(1)} />
        <Tab label={`Risks`} {...a11yProps(2)} />
        <Tab label="Gallery" {...a11yProps(3)} />
        <Tab label="Analytics" {...a11yProps(4)} />
        <Tab label="My Analytics" {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ProjectAdditonal project={project} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {milestones.length > 0 ? (
          <Milestone milestones={milestones} />
        ) : (
          <Card
            sx={{ maxWidth: 345, marginX: "auto", marginY: "9%" }}
            style={{ zIndex: "auto" }}
          >
            <CardMedia
              component="img"
              height="140"
              image={image}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                No milestones created
              </Typography>
              {project && project.created_by === user?.id && (
                <Button variant="contained" onClick={handleClickOpen}>
                  Add Milestone
                </Button>
              )}

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <AddMilestone handleClose={handleClose} />
                </Box>
              </Modal>
            </CardContent>
          </Card>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {risks.length > 0 ? <Risk risks={risks} /> : <NoRisk project={project}/>}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <MainGallery project={project}/>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Analytics financing={financing} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <MyAnalytics financing={financing} />
      </TabPanel>
    </Box>
    </ProjectContext.Provider>
  );
}
