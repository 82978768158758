import React, { useState, useEffect, useMemo } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, Table, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { requests } from '../../services/Api';
import { getToken } from '../../services/UseTokens';

// import { getSectorList  } from '../../../store/actions';
const SectorList = (props) => {
    const [sectors, setSectors] = useState([]);
    const [modal,setModal]=useState(false)
    const [docId,setDocId]=useState("")
    const token=getToken()
    console.log("props",props.parentCallBack)

    function tog_center2() {
        if (modal == false) {
            setModal(true)
        } else {
            setModal(false)
        }
    }
    const getSectorList=async()=>{
        await requests.get("/sectors",{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response)=>{            
            setSectors(response.data)
          console.log("response",response.data)
           
        }).catch((error)=>{
            console.log("company sectors",error)
        })
    }
    const handleUpdate=(childData)=>{
        setSectors(oldArray=>[...oldArray,childData])
    }
    useEffect(() => {
        getSectorList()
        
    }, [])
    

    return (
        <Table className="table-borderless align-middle mb-0">
                    <thead className="table-light">
                        <tr>
                            <th scope="col"> #</th>
                            <th scope="col"> Name</th>
                            <th scope="col">Description</th>
                            
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(sectors ).map((item, key) => (
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>
                                    <div className="d-flex align-items-center">
                                       
                                          
                                        <div className="ms-3 flex-grow-1">
                                            
                                            <h6 className="fs-16 mb-0">{item.name}
                                            </h6>
                                        </div>
                                    </div>
                                </td>
                                <td>{item.description}</td>
                                <td>
                                    <UncontrolledDropdown direction='start'>
                                        <DropdownToggle tag="a" className="btn btn-light btn-icon" id="dropdownMenuLink15" role="button">
                                            <i className="ri-equalizer-fill"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                       
                                            <DropdownItem><i className="ri-edit-line me-2 align-middle text-muted" />Edit</DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle text-muted" />Delete</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
    );
};





export {  SectorList };