import React, { Component } from "react";
import { Collapse } from "react-collapse";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button, Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import "./style.css";
import styled from "styled-components";
import UpdateMilestone from "./UpdateMilestone";
import DeleteMilestone from "./DeleteMilestone";

const Main = styled("div")`
  font-family: sans-serif;
  background: #f0f0f0;
  height: 100vh;
`;

 const DropDownContainer = styled("div")`
  width: 10.5em;
  margin: 0 auto;
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
  background: #ffffff;
`;

 const DropDownListContainer = styled("div")``;

 const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

 const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
`;



export default  class DropdownItem extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpened: this.props.isOpen,
      dropdownOpen: false,
      isOpen:false,
      modal:false,
      modal2:false
    };
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
  }
  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
 
  toggle2() {
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle3() {
    this.setState({
      modal2: !this.state.modal2
    });
  }

  onItemClick = id => {
    this.setState({ isOpened: !this.state.isOpened });
  };
  onButtonClick = id => {
    this.setState({ isOpen: !this.state.isOpen})
    
  }

  render() {
    const { isOpened,isOpen } = this.state;
   
    const { title, startDate, endDate,Description,milestones } = this.props;
   
    return (
      <div>
        <ListGroup>
        <ListGroupItem  className=" listg d-flex justify-content-space-between">
        <div onClick={this.onItemClick} className="title">
          <span>{title}</span>
        </div>
        <div class="mx-4  shadow-lg"  onClick={this.onButtonClick} >
          <BsThreeDotsVertical/>
        </div>
        
     
       
        </ListGroupItem>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              <ListItem onClick={this.toggle2}>edit</ListItem>
              
              <ListItem  onClick={this.toggle3}>Delete</ListItem>
              
            </DropDownList>
          </DropDownListContainer>
        )}
        {/* edit */}
         <Modal isOpen={this.state.modal} toggle={this.toggle2} className="">
         <UpdateMilestone milestones={milestones}/>
         
         
        </Modal>
        {/* delete */}
        <Modal isOpen={this.state.modal2} toggle={this.toggle3} className="">
          <ModalHeader toggle={this.toggle3}>Modal title</ModalHeader>
          <DeleteMilestone milestones={milestones}/>
        </Modal>
        <Collapse isOpened={isOpened} className="content">
          {isOpened && (
            <div >
            <div>
              <h5> Start Date : <span class="textl">{startDate}</span></h5>
              <h5> End Date : <span class="textl">{endDate}</span></h5>
                <h5> Description: <span class="textl">{Description}</span></h5>
              
            </div>
           
            </div>
          )}
        </Collapse>
        
        </ListGroup>
      </div>
    );
  }
}



 