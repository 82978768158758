import { combineReducers } from "redux";
import { LOGOUT_REQUEST } from "../actions/auth/logoutTypes";
import buysharesReducer from "./buy-shares/buysharesReducer";
import companyReducer from "./company/companyReducer";
import DeleteCompanyReducer from "./company/DeleteCompanyreducer";
import fetchDirectors from "./company/fetchDirectorsReducers";
import fetchexecutives from "./company/fetchEcecutivesReducers";
import singleCompanyReducer from "./company/SingleCompanyREducer";
import userCompanyReducer from "./company/UserCompanyReducer";
import fetchDocumentType from "./documents/documentTypes/fetchDocumentypesReducers";
import fetchInvestorDocreducer from "./documents/investorDocument/fetchInvestorDoc";
import PostInvestorDocumentInvestorReducer from "./documents/investorDocument/PostInvestorDocument";
import updateIvestorDocReducer from "./documents/investorDocument/updateDocument";
import fetchprojectDocuments from "./documents/projectDocuments/fetchprojectDocuments";
import createprojectdicument from "./documents/projectDocuments/postprojectDocuments";
import singleDocumentReducer from "./documents/projectDocuments/singleDocumentReducer";
import projectDocumentReducer from "./documents/projectDocuments/updateProject";
import fetchOwnerDocReducer from "./documents/projectOwner/fetchOwnerDoc.reducer";
import createOwnerDocumentReducer from "./documents/projectOwner/PostOwnerDoc.reducer";
import projectOwnerReducer from "./documents/projectOwner/UpdateProjectOwner";
import forgotPassReducer from "./forgotPassword/ForgotPasswodReducer";
import DeleteMilestoneReducer from "./mileStone/DeleteMilestone";
import fetchSinglemilestoneReducer from "./mileStone/getSinglemilestone";
import postMilestoneReducer from "./mileStone/PostMilestones";
import updateMilestoneReducer from "./mileStone/updateMileStoneReducer";
import summaryReducer from "./numbers/summaryReducer";
import updateProfileReducer from "./Profile/UpdateProfile";
import projectReducer from "./project-details/projectReducer";
import updateCompany from "./project-details/UpdateCompany";
import updateProjectReducer from "./project-details/updateProject";
import CreateprojectsReducer from "./projects/CreateProjectReducer";
import DeleteProjectReducer from "./projects/DeleteProjects";
import projectsReducer from "./projects/projectsReducer";
import sectorsReducer from "./sectors/sectorsReducer";
import sellSharesReducer from "./sellShares/sellShare.reducer";
import userTransactionsReducer from "./transactions/transactionReducer";
import usersharesReducer from "./user-shares/usersharesReducer";
import DeleteExecutiveReducer from "./company/DeleteExecutive";
import updateExecutiveReducer from "./company/updateExecutiveReducer";
import UpdateDirectorReducer from "./company/UpdateDirectors";
import DeleteDirectorsReducer from "./company/DeleteDirector";
import DeleteOwnerDocReducer from "./documents/projectOwner/DeleteDocument";
import DeleteInvestorDocreducer from "./documents/investorDocument/DeleteInvestorDoc";
import OtpReducer from "./OtpSubmission/OtpSubmission";
import PaymentReducer from "./Payment";
import companySearchreducer from "./company/companySearchreducer";
import ResendLinkReducer from "./OtpSubmission/ResendLinkReducer";
import { fetchProjectMilestones } from "../actions/milestones/projectMilestone";
import addRiskReducer from "./risk/addRiskReducer";
import fetchRiskReducer from "./risk/fetchRiskReducer";
import CompanyProjects from "./company/CompanyProjects/CompanyProjects";

const appReducer = combineReducers({
    projects: projectsReducer,
    single: projectReducer,
    summary: summaryReducer,
    sectors: sectorsReducer,
    buyshares: buysharesReducer,
    companies: companyReducer,
    userCompanies:userCompanyReducer,
    singleCompany: singleCompanyReducer,
    sellshares:sellSharesReducer,
    projectdocumentss:createprojectdicument,
    allprojectdocuments:fetchprojectDocuments,
    documenttype:fetchDocumentType,
    updateprofile:updateProfileReducer,
    ownerDocuments:fetchOwnerDocReducer,
    investorDocuments:fetchInvestorDocreducer,
    createOwnerDocument:createOwnerDocumentReducer,
    createInvestorDocument:PostInvestorDocumentInvestorReducer,
    postMilestoneReducer:postMilestoneReducer,
    usershares: usersharesReducer,
    singledocument:singleDocumentReducer,
    forgotpassword:forgotPassReducer,
    updateCompany:updateCompany,
    updateProject:updateProjectReducer,
    updateMilestone:updateMilestoneReducer,
    updateInvestorDocument:updateIvestorDocReducer,
    updateProjectDocument:projectDocumentReducer,
    updateownerDocument:projectOwnerReducer,
    directors:fetchDirectors,
    executives:fetchexecutives,
    transactions: userTransactionsReducer,
    deletedcompanies:DeleteCompanyReducer,
    deletedmilestone:DeleteMilestoneReducer,
    singlemilestone:fetchSinglemilestoneReducer,
    deletedProject:DeleteProjectReducer,
    deletetedExecutive:DeleteExecutiveReducer,
    updatedExecutive:updateExecutiveReducer,
    deletedDirector:DeleteDirectorsReducer,
    updateDirector:UpdateDirectorReducer,
    deletedOwnerDoc:DeleteOwnerDocReducer,
    deletedInvestorDoc:DeleteInvestorDocreducer,
    otp:OtpReducer,
    payment:PaymentReducer,
    companySearch:companySearchreducer,
    resendLink:ResendLinkReducer,
    projectMilestones: fetchProjectMilestones,
    addRisk: addRiskReducer,
    risks: fetchRiskReducer,
    CompanyProjects: CompanyProjects
   
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_REQUEST) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;