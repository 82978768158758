import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  createTheme,
  Stack,
  styled,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// import companySearch from "../../redux/actions/companySearch/CompanySearchAct";
import { requests } from "../../services/Api";
import { getToken, getUser } from "../../services/UseTokens";
// import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { OnboardCompanyContext } from "./OnboardContext";
import { Item } from "better-react-carousel";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#5927e3",
    },
  },
});
const StyledButton = styled(Button)`
  ${({ theme }) => `
cursor: pointer;
transition: ${theme.transitions.create(["transform"], {
    duration: theme.transitions.duration.standard,
  })};
&:hover {
color:"fff";
background-color:#8d6bec;
transform: scale(1.3);
}
`}
`;

function Company() {
  const [companyNumber, setCompanyNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const token = getToken();
  const user = getUser();
  const { activeStep, setActiveStep, companyComplete, setCompanyComplete } = useContext(OnboardCompanyContext);

  const dispatch = useDispatch();
  const brsCompany = useSelector((state) => state.companySearch.company);

  // const handleSearch = () => {
  //   const data = {
  //     company_number: companyNumber,
  //     user_id: 2
  //   }
  //   dispatch(companySearch(data, token));
  // }

  const handleBRS = async (values) => {
    setIsLoading(true);
    await requests
      .post(
        "/brs-search",
        {
          company_number: companyNumber,
          user_id: user.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const { data } = response;
        //save directors from data in localstorage
        // localStorage.setItem("directors", JSON.stringify(data.directors));
        localStorage.setItem("brs-company", JSON.stringify(data));

        Swal.fire({
          icon: "success",
          // title: 'Company '.$data.company_name+' found proceed to directors',
          title: `Company ${data.company_name} found proceed to directors`,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${error.response.data.message}`,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showConfirmButton: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Stack justifyContent="center" alignItems="center">
        <Item>
          <Typography variant="h4" gutterBottom>
            <Alert severity="info">
              Please enter your company registration number to check if your
              company is registered with the Government. You can find it on your
              certificate of incorporation and any official documentation
              received from Companies House. Your CRN is also displayed on the
              public register of companies, which can be accessed online via
              Companies House Service.
            </Alert>
          </Typography>
        </Item>
        <Item>
          <div class="d-flex">
            <TextField
              variant="outlined"
              label="CR Number"
              sx={{ width: "60%" }}
              onChange={(e) => setCompanyNumber(e.target.value)}
            />
            <ThemeProvider theme={customTheme}>
              {!isLoading && (
                <StyledButton
                  variant="outlined"
                  style={{
                    color: "#5927e3",
                    marginLeft: "15px",
                    marginBottom: "0px",
                  }}
                  onClick={() => handleBRS()}
                >
                  search
                </StyledButton>
              )}
              {isLoading && (
                <LoadingButton
                  loading={isLoading}
                  loadingIndicator="Loading…"
                  variant="outlined"
                >
                  Fetch data
                </LoadingButton>
              )}
            </ThemeProvider>
          </div>
        </Item>
      </Stack>
    </Box>
  );
}

export default Company;
