import { FETCH_DOCUMENT_FAILURE, FETCH_DOCUMENT_REQUEST, FETCH_DOCUMENT_SUCCESS } from "../../../actions/Documents/documentTypes/documentTypes";


const initialState={
    loading:false,
    error:"",
    documents:[],
}
export default function fetchDocumentType(state=initialState,action){
  
    switch (action.type) {
        case FETCH_DOCUMENT_REQUEST:
            return{
                loading:true,
                documents:[],
                error:""
            }
            
            break;
        case FETCH_DOCUMENT_SUCCESS:
           
            return{
                loading:false,
                documents:action.payload,
                error:""
            }
            break;
        case FETCH_DOCUMENT_FAILURE:
            return{
                loading:false,
                documents:[],
                error:action.payload
            }
    
        default:
            return state;
            break;
    }
}