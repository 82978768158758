import { FETCH_SINGLE_MILESTONE_REQUEST,FETCH_SINGLE_MILESTONE_SUCCESS,FETCH_SINGLE_MILESTONE_FAILURE } from "../../actions/milestones/MilestoneType";

const initialState={
    loading:false,
    milestones:[],
    error:""
}

export default function fetchSinglemilestoneReducer(state=initialState, action){
    switch (action.type) {
        case FETCH_SINGLE_MILESTONE_REQUEST:
            return{
                ...state,
                loading:true
            }
            
            break;
        case FETCH_SINGLE_MILESTONE_SUCCESS:
            return{
                ...state,
                milestone:action.payload
            }
        case FETCH_SINGLE_MILESTONE_FAILURE:
            return{
                ...state,
                error:action.payload
            }
    
        default:
            return state
            break;
    }
}