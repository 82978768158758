import Swal from "sweetalert2";
import { requests } from "../../../../services/Api";
import { UPDATE_SINGLE_DOCUMENT_REQUEST } from "../../Documents/projectDocuments/DocumentsTypes";
import { UPDATE_DIRECTORS_REQUEST,UPDATE_DIRECTORS_SUCCESS,UPDATE_DIRECTORS_FAILURE } from "./directorTypes";

const updateDirectorAct=(id,data,token)=>async(dispatch) => {
    try {
        dispatch({type:UPDATE_DIRECTORS_REQUEST})
        const response=await requests.put(`company/update-director/${id}`,data,{
            headers:{
                Authorization: `Bearer ${token}` 
            }
        })
        dispatch({type:UPDATE_DIRECTORS_SUCCESS,
        payload:response.data})
        Swal.fire({
            icon:"success",
            title:"Success",
            text:"Director updated suucessfully",
            confirmButtonText:"Ok"
        })
        
    } catch (error) {
        dispatch({type:UPDATE_DIRECTORS_FAILURE,
        payload:error.response.message})
        Swal.fire({
            icon:"error",
            title:"Error",
            text:error.response.data,
            confirmButtonText:"Ok"
        })
    }
}
export default updateDirectorAct