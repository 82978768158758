import { CREATE_INVESTOR_FAILURE,CREATE_INVESTOR_SUCCESS,CREATE_INVESTOR_REQUEST } from "../../../actions/Documents/InvestorDocs/InvestorTypes";

const initialState = {
    loading: false,
    data: [],
    error: ""
}

export default function PostInvestorDocumentInvestorReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_INVESTOR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_INVESTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case CREATE_INVESTOR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}