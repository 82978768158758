import { POST_SUCCESS,POST_REQUEST,POST_FAILURE } from "../../actions/milestones/MilestoneType";

const initialState={
    loading:false,
    error:"",
    data:[]
}
const postMilestoneReducer=(state=initialState,action)=>{
    switch(action.type){
        case POST_REQUEST:
            return{
                ...state,
                loading:true
            }
        case POST_SUCCESS:
            return{
                ...state,
                loading:false,
                data:action.payload
            }
        case POST_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.payload
            }
        default:
            return state
    }
}
export default postMilestoneReducer;