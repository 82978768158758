import React from 'react'
import Swal from 'sweetalert2'
import { requests } from '../../../../services/Api'
import { CREATE_PROJECTS_FAILURE } from '../../projects/projectTypes'
import { CREATE_PROJECTDOCUMENT_REQUEST, CREATE_PROJECTDOCUMENT_SUCCESS } from './DocumentsTypes'

export const postprojectdocuments = (data, token) => async (dispatch) =>{
    console.log("recieved Data",data)
   
try {
    dispatch({type: CREATE_PROJECTDOCUMENT_REQUEST})

    const response = await requests.post("documents/projects-bulk",data,{
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    dispatch({type:CREATE_PROJECTDOCUMENT_SUCCESS,
    payload:response.data})
    Swal.fire(
        'Good job!',
        'Project Document Created Successfully',
        'success'
    )
    console.log("responseee",response.data)
} catch (error) {
    console.log("error",error)
    dispatch({
        type:CREATE_PROJECTS_FAILURE,
        Payload:error
    })
    Swal.fire({
        icon:"error",
        title:"Oops!...",
        text:error.response.data.message,
        cancelButton:"ok"
    })
    
}
}