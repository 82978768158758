import { useLayoutEffect } from "react";
import Banner from "./banner";
import FormPage from "./form";
import Overview from "./overview";

function Contact () {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div>
            <Banner />
            <Overview />
            <FormPage />
        </div>
    )
}

export default Contact;