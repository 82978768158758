import { requests } from "../../../../services/Api";
import { getToken } from "../../../../services/UseTokens";
import { FETCH_INVESTOR_FAILURE, FETCH_INVESTOR_REQUEST,FETCH_INVESTOR_SUCCESS} from "./InvestorTypes";

const fetchInvestorAction=()=>async(dispatch)=>{
    const token=getToken();
    try {
        dispatch({type:FETCH_INVESTOR_REQUEST})
        const response = await requests.get("documents/investors",{
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        dispatch({type:FETCH_INVESTOR_SUCCESS,
        payload:response.data})
        
    } catch (error) {
        dispatch({
            type:FETCH_INVESTOR_FAILURE,
            payload:error
        })
        
        
    }
}
export default fetchInvestorAction;