import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchProjects } from '../../redux/actions/projects/fetchProjects'
import { getUser } from '../../services/UseTokens'
import * as _ from 'lodash'
import { Button } from 'react-bootstrap'
import { Badge } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { imageurl } from '../../services/Api'
import NoProjects from './NoProjects'

const user = getUser()

export default function LastIncome() {
    const dispatch = useDispatch()
    const projects = useSelector(state => state.projects)
    const loading = useSelector(state => state.projects.loading)
    //get all projects where the user is the owner using lodash
    const userProjects = _.filter(projects?.projects, { 'created_by': user?.id })

    //randomly get 3 projects using lodash
    const randomProjects = _.sampleSize(userProjects, 3)

    useEffect(() => {
        dispatch(fetchProjects(user?.id))
    }, [])
    return (
        <div>
            <div class="dashboard-single__box">
                {randomProjects.length > 0 && (
                     <div class="intro">
                     <h5 style={{ fontSize: "20px" }}>
                         My Projects{' '}
                         <Badge color='success' pill>
                             {userProjects.length}
                         </Badge>
                     </h5>
                     <Link style={{ fontSize: "20px" }} to="/my-projects">Show All<i
                         class="fa-solid fa-arrow-right-long"></i></Link>
 
 
                 </div>
                )}
               
                {loading && (
                    <div>
                        <Skeleton />
                        <Skeleton count={5} />
                    </div>
                )}
                {!loading && (
                    <div>
                        {
                            randomProjects.length > 0 && randomProjects.map((project, index) => (
                                <div class="last-income" key={index}>
                                    <div class="group">
                                        <img src={`${imageurl}${project?.project_header_image}`} alt="Last Income" />
                                        <div>
                                            <h6 style={{ fontSize: "15px" }}>{project?.project_name}</h6>
                                            <p class="project__has"><span
                                                class="project__has__investors">{`${project?.no_of_investors} Investors`}</span> | <span
                                                    class="project__has__investors__amount">
                                                    {project?.financing?.project_available_shares}
                                                </span> <span
                                                    class="project__has__investors__percent">{`(${parseInt(((project?.financing?.project_share_volume - project?.financing?.project_available_shares) / project?.financing?.project_share_volume) * 100)}%)`}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <Link to={`/my-projects/${project?.id}`}><Button>view</Button></Link>
                                </div>
                            ))

                        }
                        <div>

                            { randomProjects.length === 0 && (
                                <NoProjects />
                            )}
                        </div>
                    </div>



                )}

            </div>
        </div>
    )
}
