import { OTP_SUBMISSION_REQUEST , OTP_SUBMISSION_SUCCESS, OTP_SUBMISSION_FAILURE} from "../../actions/auth/Twofactor/TwofactorAct"

const initialState={
    loading:false,
    otp:"",
    error:""
}

export default function  OtpReducer(state=initialState,action){
    switch(action.type){
        case OTP_SUBMISSION_REQUEST:
            return{
                ...state,
                loading:true
            }
        case OTP_SUBMISSION_SUCCESS:
            return{
                ...state,
                otp:action.payload
            }
        case OTP_SUBMISSION_FAILURE:
            return{
                ...state,
                error:action.payload
            }
        default:
            return state;

    }
}