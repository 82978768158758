import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, TextField, Typography } from '@mui/material';
import { requests } from '../../../services/Api';
import Swal from 'sweetalert2';
import { getToken } from '../../../services/UseTokens';


const customTheme = createTheme({
  palette: {
    primary: {
      main: "#5927e3",
    },
  },
});
const StyledButton = styled(Button)`
    ${({ theme }) => `
    cursor: pointer;
    transition: ${theme.transitions.create(['transform'], {
  duration: theme.transitions.duration.standard,
})};
    &:hover {
        color:"fff";
      background-color:#8d6bec;
      transform: scale(1.3);
    }
    `}
  `;
export default function UserSearch() {
  const [idNumber, setIdNumber] = useState("");
  const [name, setName] = useState("");
  const token = getToken()

  const handleIPRS = async () => {
    const data = {
      identification_number: idNumber,
    }
    await requests.post(`iprs-search`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      const { data } = response;
      Swal.fire({
        icon: 'success',
        title: `User ${data.first_name} ${data.middle_name} ${data.last_name} found proceed to investment.`,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        showConfirmButton: true,
      })
    }).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${error.response.data.message}`,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        showConfirmButton: true,
      })
    })
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h6">Enter  User Details</Typography>
      </Box>
      <Grid container spacing={2}>

        <Grid item xs={12} md={6} lg={4} xl={4}>
          <TextField 
            id="outlined-basic" 
            label="Full Name" 
            variant="outlined" 
            onChange={(e) => setName(e.target.value)}
            value={name}
            helperText="Enter your name as written in the ID" />

        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <TextField 
          id="outlined-basic" 
          label="Id Number" 
          onChange={(e) => setIdNumber(e.target.value)}
          value={idNumber}
          variant="outlined" />

        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <ThemeProvider theme={customTheme}>

            <StyledButton
              variant="outlined"
              style={{ color: "#5927e3" }}
            onClick={() => handleIPRS()}
            >Verify</StyledButton>
          </ThemeProvider>
        </Grid>

      </Grid>
    </Box>
  );
}
