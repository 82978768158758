import {useEffect, useState} from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { Button, Dropdown, DropdownItem, DropdownMenu, Modal } from 'reactstrap';
import { fetchUserShares } from '../../redux/actions/user-shares/fetchUserShares'
import SellShares from './SellShares';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { chain } from 'lodash';
const user = JSON.parse(localStorage.getItem('authuser'));

export default function InvestmentTable(projectId,props) {
    const dispatch = useDispatch()
    const [response,setResponse]=useState({})
    const usershare = useSelector(state => state.usershares)
    const [modal,setModal]=useState(false)
    const [doc,setDoc]=useState()
    const [shares1 ,setShares1]=useState()
    const [dropdownOpen,setDropdownOpen]=useState(false)
    const [sellShareState,setSellShareState]=useState(false)
    const [opensellShareState,setopensellshares]=useState(false)
    const toggle2=()=>setopensellshares(sellShareState=>!sellShareState)
    const toggle = () => setDropdownOpen(prevState => !prevState);

   console.log("investmentTable",projectId)
   let loading=usershare.loading

    const toggle_2=(e)=>{
        if (modal===false){
            setModal(true)
        }else{
            setModal(false)
        }
    }

    const getData=(childData)=>{
        setResponse(childData)
        console.log("childData",childData)
    }

    useEffect(() => {
        dispatch(fetchUserShares(user?.id, user?.token))
    }, [])
  return (
    <div>
         <div class="investment-table">
            
            {loading &&(
                <div>
                <Skeleton  height={"55px"} baseColor="#fff"/>
                <Skeleton  height={"55px"}/>
                <Skeleton  height={"55px"}count={5} /> 
                </div>
            )}
           { !loading &&(<div class="table-wrapper">
           <div class="intro">
                <h5>Transactions</h5>
            </div>
                <table>
                    <tr>
                        <th>#</th>
                        <th>Project</th>
                        <th>Company</th>
                        <th>Share Value</th>
                        <th>Shares Invested</th>
                        <th>Amount Invested</th>
                        <th>Actions</th>
                    </tr>
                    {
                       usershare && usershare.usershares.map((share, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <img src="assets/images/table-img.png" alt="Investment" />
                                    {share?.project?.project_name}
                                </td>
                                <td>{share?.company}</td>
                                <td>{share?.project?.financing?.project_share_value}</td>
                                <td>{share?.shares}</td>
                                <td>{share?.project?.financing?.project_share_value * share?.shares}</td>
                                <td>
                                <div className='d-flex flex-row '>
                                   
                                  
                                   {/* <div className="position-absolute end-0 rounded-circle bg-light text-dark"><BsThreeDotsVertical className='text-center m-1 try' style={{ height: "1.8em", width: "1.8em" }} /></div> */}
                                   <Dropdown className="  mb-4 rounded-circle bg-light text-dark" isOpen={dropdownOpen} toggle={toggle} style={{zIndex:5}}>
                                       <div  onClick={()=>toggle()}><BsThreeDotsVertical className='text-center m-1 try' style={{ height: "1.8em", width: "1.8em" }} /></div>
                                       <DropdownMenu style={{zIndex: 1, position: "absolute", top: 0, left: 0}} >
                                           <Link to={`/projects/${share?.project?.id}`}>
                                               <DropdownItem >  Invest</DropdownItem>
                                               </Link>
                                           {/* <Link to ={`/update-company/${projectId}`}><DropdownItem >
                                               <i className="ri-edit-fill  me-2 align-middle text-muted" />edit</DropdownItem>
                                           </Link> */}
                                           
                                           
                                         
                                           {/* delete */}
                                           <DropdownItem className='remove-item-btn' 
                                           onClick={() => {
                                            toggle_2()
                                            // sellShareState===false?setSellShareState(true):sellShareState
                                            // props.handleState(sellShareState)
                                               toggle_2(index)
                                               setDoc(share?.project?.id)
                                               setShares1(share?.shares)
                                           }}
                                           >
                                              Sell Shares </DropdownItem>
                                           <Modal
                                               isOpen={modal}
                                               toggle={() => {
                                                   toggle_2();
                                               }}
                                               centered
                                           >
                                               <SellShares handleClose={toggle_2  }
                                               getData={getData} projectId={doc} companyId={share?.company_id}  
                                               shareValue={parseInt(share?.project?.financing?.project_share_value)}
                                                shares={shares1} amount={parseInt(share?.project?.financing?.project_share_value * share?.shares)} />
                                           </Modal>
                                       </DropdownMenu>
                                   </Dropdown>
                               </div>
                                </td>
                            </tr>
                        ))
                    }
                </table>
            </div>)}

        </div>
    </div>
  )
}
