import { DELETE_PROJECTS_REQUEST,DELETE_PROJECTS_SUCCESS,DELETE_PROJECTS_FAILURE } from "../../actions/projects/projectTypes";

const initialState={
    loading:false,
    project:{},
    statue:"",
    error:""
}

export default function DeleteProjectReducer(state=initialState,action){
    switch (action.type) {
        case DELETE_PROJECTS_REQUEST:
            return{
                ...state,
                loading:true,
            }
            
            break;
        case DELETE_PROJECTS_SUCCESS:
            return{
                ...state,
                projects:action.payload,
                status:action.status,
            }
    case DELETE_PROJECTS_FAILURE:
        return{
            ...state,
            error:action.payload,
        }
        default:
            return state;
            break;
    }
}