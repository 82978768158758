import { POST_REQUEST,POST_SUCCESS,POST_FAILURE } from "../../actions/milestones/MilestoneType";

const initialState={
    loading:false,
    milestone:{},
    error:""

}
const updateMilestoneReducer=(state=initialState,action)=>{
    switch(action.type){
        case POST_REQUEST:
            return{
                ...state,
                loading:true

            }
            break;
        case POST_SUCCESS:
            return{
                ...state,
                loading:false,
                milestone:action.payload

            }
            break;
        case POST_FAILURE:
            return{
                ...state,
                loading:false,
                error:action.payload
            }
            break;
        default:
            return state;
            
    }
}
export default updateMilestoneReducer;