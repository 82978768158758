import { useEffect, useState } from 'react'
import Banner from './images/details.png'
import Check from './images/check.png'
import Close from "./images/close.png"
import Security from './images/home.png'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchProject } from '../../../redux/actions/project-details/fetchProject'
import _ from 'lodash'
import { buyShares } from '../../../redux/actions/buy-shares/buyShares';
import { getUser, getUserId } from '../../../services/UseTokens';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Tooltip } from 'reactstrap';
import Dropdown from '../../../components/dropdown/dropdown.component';
import { sellShares } from '../../../redux/actions/sell-shares/SellShares.actions';
import { AiOutlinePlus, AiOutlinePlusSquare } from 'react-icons/ai';
import { BsEyeFill, BsPlusSquareFill } from 'react-icons/bs';
import AddMilestone from '../AddMilestone';
import LLink from "./Button";
import "./style.css"
import ProjectDocuments from '../../Documents/ProjectDocuments'
import ProjDocDropDown from '../../Documents/ProjectDocuments/ProjDocDropdown'
import fetchdocumentstypes from '../../../redux/actions/Documents/documentTypes/fetchdocumentstypes'
import fetchprojectDocumentAction from '../../../redux/actions/Documents/projectDocuments/FetchDocuments'
import DisplayProjectDoc from '../../Documents/ProjectDocuments/DisplayProjectDoc'
import DropdownItem from './DropdownItem'


function SingleProjectAdmin(props) {
    const location = useLocation()
    const [amount, setAmount] = useState(0)
    const [payment, SetPayment] = useState("")
    const [shareQuantity, setShareQuantity] = useState(0)
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const [tooltipOpen2, setTooltipOpen2] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpen2, setModalOpen2] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [projectDoc,setProjectDoc]=useState([])
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const buyshare = useSelector(state => state.buyshares)
    const sellshare = useSelector(state => state.sellshares)
    const project = useSelector(state => state.single.project);
    const boughtShares = useSelector(state => state.buyshares.buyshare)
    const investment_opportunity = project?.project_investment_opportunities;
    const risks = project?.project_risks;
    const milestonesAll = project.project_milestones
    const milestones = _.filter(milestonesAll, { status: "complete" })
    const company = project?.company;
    const financing = project?.financing;
    const user = getUser();
    const token = user && user.token;
    const userId = getUserId()
   
    const projectid=id.id
  
    const options = [
        { name: "Cash", value: "cash" },
        { name: "Bank Transfer", value: "bank_transfer" },
        { name: "PayPal", value: "paypal" },
        { name: "Credit Card", value: "credit_card" },
        { name: "Debit Card", value: "debit_card" },
    ]

    function createMarkup(value) {
        return { __html: value };
    }
    //get minutes to project end date
    const minutesToEnd = (closing) => {
        const today = moment();
        const end = moment(closing);
        const diff = end.diff(today, 'minutes');
        return diff;
    }

    //check validity of amount against maximum and minimum amount
    const checkAmount = (amount) => {
        if (amount > project.maximum_investment_amount) {
            return false;
        }
        if (amount < project.minimum_investment_amount) {
            return false;
        }
        return true;
    }
    function toggle() {
        if (tooltipOpen === false) {
            setTooltipOpen(true)
        } else {
            setTooltipOpen(false)
        }
    }

    function toggle3() {
        if (tooltipOpen2 === false) {
            setTooltipOpen2(true)
        } else {
            setTooltipOpen2(false)
        }
    }
   
    const toggleModal = () => {
        if (modalOpen === false) {
            setModalOpen(true)
        } else {
            setModalOpen(false)
        }
    }
    const toggleModal2 = () => {
        if (modalOpen === false) {
            setModalOpen2(true)
        } else {
            setModalOpen2(false)
        }
    }
    const getData = (child) => {
        milestones.push(child)
    }
    
    useEffect(() => {
        dispatch(fetchProject(id))
        dispatch(fetchdocumentstypes(token))
        // ProjectDocumentsToPost()
        // x()
        dispatch(fetchprojectDocumentAction(token))

    }, [])
    return (
        <div>
            <div class="propSingleProject__banner bg__img clear__top"
                style={{ backgroundImage: `url(${Banner})` }}>
            </div>
            <section class="p__details faq section__space__bottom">
                <div class="container">
                    <div class="p__details__area">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="p__details__content">
                                    
                                    <div class="intro">
                                        <h3>{project.project_name}</h3>
                                        <p><i class="fa-solid fa-location-dot"></i>{project.location}
                                        </p>
                                    </div>
                                    <div class="group__one">
                                        <h4>Project Description</h4>
                                        <p>{project.project_description}</p>
                                    </div>
                                    {project.project_risk && (<div class="group__one">
                                        <h4>Project Risks</h4>
                                        <p>{project.project_risks}</p>
                                    </div>)}
                                    {project.why_invest != null &&
                                        (
                                            <div class="group__two">
                                                <h5>Why Invest:</h5>
                                                <p>{project.why_invest}</p>
                                            </div>
                                        )
                                    }
                                    {
                                        investment_opportunity?.length > 0 && (
                                            <div class="group__two">
                                                <h5>Project Investment Opportunity:</h5>
                                                <ul>
                                                    {
                                                        investment_opportunity.map((opportunity, index) => (
                                                            <li key={index}>{opportunity}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    }

                                    <div class="owner">
                                        {
                                            company?.company_logo && (
                                                <img src={company?.company_logo} alt="Owner" />
                                            )
                                        }
                                        <div>
                                            <h5>The project owner (borrower)</h5>
                                            <p>{company?.company_description}</p>
                                        </div>
                                    </div>

                                  <div>
                                  <h5>Project Documents</h5>
                                    <DisplayProjectDoc id={id}/>
                                  </div>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="p__details__sidebar">
                                    <div class="intro">
                                        <div class="countdown__wrapper">
                                            <p class="secondary"><i class="fa-solid fa-clock"></i> Left to invest
                                            </p>
                                            <div class="countdown">
                                                <h5>
                                                    <span class="days">{parseInt(minutesToEnd(project.closing_date) / (60 * 24))}</span><span class="ref">d</span>
                                                    <span class="seperator">:</span>
                                                </h5>
                                                <h5>
                                                    <span class="hours">{parseInt(parseInt(minutesToEnd(project.closing_date) % (60 * 24)) / 60)}</span><span class="ref">h</span>
                                                    <span class="seperator">:</span>
                                                </h5>
                                                <h5>
                                                    <span class="minutes">{parseInt(parseInt(parseInt(minutesToEnd(project.closing_date) % (60 * 24)) % 60))}</span><span class="ref">m</span>
                                                    <span class="seperator"></span>
                                                </h5>
                                            </div>
                                        </div>
                                        <h5>Available for funding: <span>{`SHARES ${project && project.financing && project.financing.project_available_shares}`}</span></h5>
                                        <div class="progress__type progress__type--two">
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="25"
                                                    aria-valuemin="0" aria-valuemax="100"
                                                    style={{
                                                        width: `${parseInt(((financing?.project_share_volume - financing?.project_available_shares) / financing?.project_share_volume) * 100)}%`
                                                    }}>
                                                </div>
                                            </div>
                                            <div class="project__info">
                                                
                                                <p class="project__has"><span class="project__has__investors">{`${project.no_of_investors} Investors`}</span> | <span class="project__has__investors__amount">{` ${parseInt(financing?.project_share_volume - financing?.project_available_shares)} Shares`}</span></p>
                                                <p class="project__goal">
                                                    {`SHARES ${financing?.project_share_volume} Goal`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group brin">
                                        <div class="acus__content">
                                            {/* <form> */}
                                            <div class="input input--secondary">
                                                <label for="anNumIn">Maximum Investment</label>
                                                <input
                                                    type="number"
                                                    name="maximum_investment"
                                                    id="anNumIn"
                                                    placeholder="KES 500"
                                                    required="required"
                                                    value={financing?.maximum_investment}
                                                    disabled
                                                />
                                            </div>
                                            <div class="input input--secondary">
                                                <label for="anNumInTwo">Minimum Investment</label>
                                                <input
                                                    type="number"
                                                    name="minimum_investment"
                                                    id="anNumInTwo"
                                                    placeholder="KES 35.00"
                                                    required="required"
                                                    value={financing?.minimum_investment}
                                                    disabled
                                                />
                                            </div>
                                            <div class="input input--secondary">
                                                <label for="anNumInTwo">Share Value</label>
                                                <input
                                                    type="number"
                                                    name="minimum_investment"
                                                    id="anNumInTwo"
                                                    placeholder="KES 35.00"
                                                    required="required"
                                                    value={financing?.project_share_value}
                                                    disabled
                                                />
                                            </div>
                                            
                                                <div>
                                                    <div class="input input--secondary">
                                                        <label for="anNum">Payment Method:</label>
                                                        <br />
                                                        
                                                        <Dropdown
                                                            options={
                                                                options
                                                            }
                                                            prompt="Select a payment method"
                                                            value={payment}
                                                            onChange={(val) => SetPayment(val)}
                                                        />
                                                    </div>
                                                    <div class="input input--secondary">
                                                        <label for="anNumInTwo">Amount of Investment in KES</label>
                                                        <input
                                                            
                                                            type="number"
                                                            name="an__num_in_two"
                                                            id="anNumInTwo"
                                                            placeholder="KES 35.00"
                                                            required="required"
                                                            onChange={(e) => setAmount(e.target.value)}
                                                        />
                                                    </div>
                                                    {
                                                        buyshare && buyshare.error && (
                                                            <div class="alert alert-danger" role="alert">
                                                                <p>{buyshare.error}</p>
                                                            </div>
                                                        )
                                                    }
                                                    <div class="suby">
                                                        <h5>{`Shares ${amount / financing?.project_share_value}`}</h5>
                                                        {
                                                            buyshare && buyshare.loading && (
                                                                <Button color="secondary" className="btn-load w-100" outline disabled>
                                                                    <span className="d-flex align-items-center">
                                                                        <span className="flex-grow-1 me-2">
                                                                            Loading...
                                                                        </span>
                                                                        <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                                                                    </span>
                                                                </Button>
                                                            )
                                                        }
                                                        {
                                                            buyshare && !buyshare.loading &&
                                                                payment !== '' && amount !== 0 ?
                                                                (
                                                                    <button
                                                                        class="button button--effect"
                                                                        onClick={() => {
                                                                            //check if user is logged in
                                                                            if (!token) {
                                                                                navigate('/login', { state: { from: location.pathname } });
                                                                            } else {
                                                                                dispatch(buyShares({ project_id: project.id, company_id: project.company_id, finance_method: 'equity', amount_of_investment: amount, payment_method: payment.value }, token))
                                                                                .then(()=>{
                                                                                    if(boughtShares.length !==0){
                                                                                        navigate("/my-investments")
                                                                                    }

                                                                                })

                                                                            }
                                                                        }}
                                                                    >
                                                                        Invest Now
                                                                    </button>
                                                                ) : (
                                                                    <Button
                                                                        color="primary"
                                                                        outline
                                                                        disabled
                                                                    >
                                                                        Invest Now
                                                                    </Button>
                                                                )
                                                        }


                                                    </div>
                                                </div>
                                            

                                        </div>
                                    </div>
                                    <div class="group birinit">
                                        <h6>Share via Social </h6>
                                        <div class="social text-start">
                                            <a href="javascript:void(0)">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <i class="fab fa-instagram"></i>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <i class="fab fa-linkedin-in"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="group alt__brin ">
                                        <div className='position-relative d-flex'>
                                            <h5>Key Milestones <i class="fa-solid fa-bell"></i></h5>
                                            <button className='bg-primary position-absolute end-0' id="TooltipExample" onClick={() => toggleModal()}><BsPlusSquareFill style={{ width: "150%", height: "150%", backgroundColor: "white", color: "#13216e", fontWeight: "900" }} /></button>
                                            <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
                                                Add a milestone
                                            </Tooltip>

                                            {/* modal */}
                                            <Modal isOpen={modalOpen} toggle={toggleModal} >
                                                <ModalHeader toggle={toggleModal}>Add MileStone</ModalHeader>
                                                <AddMilestone getData={getData} projectId={id} />
                                            </Modal>
                                        </div>
                                        <hr />

                                        <div class="singl__wrapper ">
                                            
                                                {
                                                milestonesAll && milestonesAll.map((milestone, index) => (
                                                    
                                                    <div class="singl" key={index}>
                                                        {milestones.length !== 0 &&(
                                                             <img src={Check} alt="Check" />
                                                )}
                                                {milestones.length===0 && (
                                                    <img src={Close} style={{width:"32px",height:"32px"}} alt="Close" />
                                                )}
                                            
                                                       
                                                     <div>
                                                            <DropdownItem
                                                                key={index}
                                                                isOpen={isOpen}
                                                                title={<p dangerouslySetInnerHTML={createMarkup(milestone.milestone_name)} />}
                                                                startDate={milestone.milestone_start_date}
                                                                endDate={milestone.milestone_end_date}
                                                                Description={<p class="textl" dangerouslySetInnerHTML={createMarkup(milestone.milestone_description)} />}
                                                                functionconv={createMarkup}
                                                            />

                                                            <a></a>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SingleProjectAdmin;
