import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../../components/dropdown/dropdown.component";
import { fetchSectors } from "../../../redux/actions/sector/fetchSectors";

function Filter({allProjects}) {
    const dispatch = useDispatch();
    const sectors = useSelector(state => state.sectors);
    const [sec, setSec] = useState("");
    const [search, setSearch] = useState('');
    const filteredData = () => {
        return allProjects.filter(project => {
            //check if the project name contains the search text
            return project?.project_name.toLowerCase().includes(search.toLowerCase());
        })
    }
    console.log('search', filteredData())
    useEffect(() => {
        dispatch(fetchSectors())
    }, [search])
    
    return (
      <div>
        <div class="property__filter">
            <div class="container">
                <div class="property__filter__area">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-12 col-xl-6">
                            <div class="property__search__wrapper">
                                <form>
                                    <div class="input">
                                        <input 
                                            type="search" 
                                            name="property__search" 
                                            id="propertySearch"
                                            placeholder="Search for properties" 
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                    {/* <button type="submit" class="button button--effect">Search</button> */}
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-3">
                            <div class="property__select__wrapper">
                                {/* <select class="property__select">
                                    <option data-display="Property">Project Sectors</option>
                                    {sectors && sectors.map((sector, index) => (
                                        <>
                                        <option key={index} value={sector.id}>{sector.name}</option>
                                        </>
                                    ))}
                                </select> */}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Filter;
  