import { requests } from "../../../../services/Api";
import { FETCH_PROJECTDOCUMENT_FAILURE, FETCH_PROJECTDOCUMENT_REQUEST, FETCH_PROJECTDOCUMENT_SUCCESS } from "./DocumentsTypes";

 const fetchprojectDocumentAction=(token)=>async(dispatch)=>{
    try {
        dispatch({type:FETCH_PROJECTDOCUMENT_REQUEST})
        const response = await requests.get("/documents/projects",{
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        
        dispatch({type:FETCH_PROJECTDOCUMENT_SUCCESS,
        payload:response.data})
        
    } catch (error) {
       dispatch({
        type:FETCH_PROJECTDOCUMENT_FAILURE,
        payload:error
       }) 
    }
}
export default fetchprojectDocumentAction;