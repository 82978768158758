import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TopSummaries from "./TopSummaries";
import ProjectInvestment from "./ProjectsInvestment";
import InvestorsChart from "./Investors";
import DoughnutChart from "./Doughnut";
import ProjectPieChart from "./PieChart";
import { Card, CardHeader, Tooltip, Typography } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Analytics({ company }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopSummaries
            totalProjects={company?.total_projects}
            totalInvestors={company?.total_investors}
            remainingInvestment={company?.remaining_investment_amount}
            totalInvested={company?.total_amount_invested}
            percentageChange={company?.monthly_percentage_change}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ProjectInvestment
            projects={company?.projects}
            totalInvested={company?.total_amount_invested}
          />
          <Card style={{margin: "15px"}}>
            <CardHeader title="Investment per projects per month"/>
            <InvestorsChart data={company?.monthlyProjectInvestments} projects = {company?.projects}/>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={12} lg={4}>
          <Item style={{ display: "flex", justifyContent: "center" }}>
            <>
              <Tooltip title="Project  percentages" placement="top-start">
                <div>
                  <ProjectPieChart />
                </div>
              </Tooltip>
            </>
          </Item>

          <DoughnutChart />
        </Grid> */}
      </Grid>
    </Box>
  );
}
