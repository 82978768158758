import { useEffect, useState } from 'react'
import Banner from './details/images/details.png'
import Check from './details/images/check.png'
import Security from './details/images/home.png'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Avatar, { ConfigProvider } from 'react-avatar';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchProject } from '../../../redux/actions/project-details/fetchProject'
import _ from 'lodash'
import { buyShares } from '../../../redux/actions/buy-shares/buyShares';
import { getUser } from '../../../services/UseTokens';
import { Button, Input, Modal, ModalHeader, Spinner, Tooltip } from 'reactstrap';
import Dropdown from '../../../components/dropdown/dropdown.component';
import { imageurl } from '../../../services/Api';
import { fetchCompany } from '../../../redux/actions/company/fetchCompany';
import { fetchcompany } from '../../../redux/actions/company/companydetails/fetchcompany';
import CreateExecutives from '../../Company/CreateExcecutives';
import CreateDirectors from '../../Company/CreateDirectors';
import "./style.css"
// import fetchexecutives from '../../../redux/actions/company/CompanyExecutives/fetchexecutives';
import fetchDirectors from '../../../redux/actions/company/companyDirectors/fetchDirectors';
import fetchexecutivesAction from '../../../redux/actions/company/CompanyExecutives/fetchexecutives';
import "./style.css"
import { BsThreeDotsVertical } from 'react-icons/bs';
import { BiEditAlt } from 'react-icons/bi';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import UpdateExecutives from '../UpdateExecutive';
import DeleteExecutive from '../DeleteExecutive';
import DeleteDirector from '../DeleteDirector';
import UpdateDirectors from '../UpdateDirector';
import image from "../../../assets/images/companybanner.jpg"


function CompanyDetails() {
    const location = useLocation()
    const [amount, setAmount] = useState(0)
    const [payment, SetPayment] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPage2, setCurrentPage2] = useState(1)
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const buyshare = useSelector(state => state.buyshares)
    const company = useSelector(state => state.singleCompany.company);
    const allexecutives = useSelector(state => state.executives.executives)
    const alldirectors = useSelector(state => state.directors.directors)
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const [tooltipOpen2, setTooltipOpen2] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalOpen2, setModalOpen2] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [modalDelExecutive, setModalDelExecutive] = useState(false)
    const [modalDelDirector, setModalDelDirector] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [ModalExecutive, setModalExecutive] = useState({})
    const user = getUser();
    console.log("company",company)
    const token = user && user.token;

    const numPages = Math.ceil(allexecutives.length / 3);
    console.log(numPages, "numPages")
    const paginate = (array, pageSize, pageNumber) => {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }
    const executives = paginate(allexecutives, 3, currentPage);

    const numPagesDirectors = Math.ceil(alldirectors.length / 3);
    console.log(numPagesDirectors, "numPages")
    const paginate2 = (array, pageSize, pageNumber) => {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }
    const directors = paginate2(alldirectors, 3, currentPage2);
    console.log("currentPage", currentPage)
    console.log("executives", executives)
    const options = [
        { name: "Cash", value: "cash" },
        { name: "Bank Transfer", value: "bank_transfer" },
        { name: "PayPal", value: "paypal" },
        { name: "Credit Card", value: "credit_card" },
        { name: "Debit Card", value: "debit_card" },
    ]
    function toggle() {
        if (tooltipOpen === false) {
            setTooltipOpen(true)
        } else {
            setTooltipOpen(false)
        }
    }
    function toggle2() {
        if (tooltipOpen2 === false) {
            setTooltipOpen2(true)
        } else {
            setTooltipOpen2(false)
        }
    }

    const toggleModal = () => {
        if (modalOpen === false) {
            setModalOpen(true)
        } else {
            setModalOpen(false)
        }
    }
    const toggleModal2 = () => {
        if (modalOpen2 === false) {
            setModalOpen2(true)
        } else {
            setModalOpen2(false)
        }
    }
    const getData = (child) => {
        console.log("child", child)
        // milestones.push(child)
        setModalExecutive(oldarr => [...oldarr, child])
    }

    //get minutes to project end date
    const minutesToEnd = (closing) => {
        const today = moment();
        const end = moment(closing);
        const diff = end.diff(today, 'minutes');
        return diff;
    }
    function toggle_3() {
        if (modal2 === false) {
            setModal2(true)
        } else {
            setModal2(false)
        }
    }
    function DeleteExecutive_toggle() {
        if (modal2 === false) {
            setModalDelExecutive(true)
        } else {
            setModalDelExecutive(false)
        }
    }
    function DeleteDirector_toggle() {
        if (modal2 === false) {
            setModalDelDirector(true)
        } else {
            setModalDelDirector(false)
        }
    }


    function createMarkup(value) {
        return { __html: value };
    }

    useEffect(() => {
        dispatch(fetchcompany(id, token))
        dispatch(fetchexecutivesAction(id, token))
        dispatch(fetchDirectors(id, token))
    }, [currentPage])

    return (
        <div>
            <div class="property__details__banner bg__img clear__top"
            >
                <img src={company.company_logo?imageurl + company.company_logo:image} alt="banner" style={{ height: "90vh", width: "97vw" }} />
            </div>
            <section class="p__details faq section__space__bottom">
                <div class="container">
                    <div class="p__details__area">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="p__details__content">
                                    <div class="intro">
                                        <h3>{company.company_name?company.company_name:"Elfantasia"}</h3>
                                        <p><i class="fa-solid fa-location-dot"></i>{company.physical_addresss?`${company.physical_addresss},${company.company_nationality}`:`Nairobi ,Kenya`}</p>

                                    </div>
                                    {company.company_description &&(
                                          <div class="group__one">
                                          <h4>Project Description</h4>
                                          <p dangerouslySetInnerHTML={createMarkup(company.company_description)} />
                                      </div>
                                    )}
                                  

                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="p__details__sidebar">
                                    <div class="intro">
                                        <div class="countdown__wrapper">
                                            <h5 class="secondary"><i class="fa fa-globe" aria-hidden="true"></i> Company website
                                            </h5>
                                            <div class="countdown">
                                                <p>
                                                    <span class="days">{"www.elfantasia.com"}</span>

                                                </p>

                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div class="group brin">
                                        <div class="acus__content">

                                            <div class="input input--secondary">
                                                <label for="anNumIn">Email</label>
                                                <input
                                                    type="text"
                                                    name="maximum_investment"
                                                    id="anNumIn"
                                                    required="required"
                                                    value="info@elfantasia.com"
                                                    disabled
                                                />
                                            </div>
                                            <div class="input input--secondary">
                                                <label for="anNumIn">Phone Number</label>
                                                <input
                                                    type="number"
                                                    name="share_volume"
                                                    id="anNumInThree"
                                                    placeholder="0712345678"
                                                    required="required"
                                                    value={company.company_phone_number?company.company_phone_number:"024234312"}
                                                    disabled
                                                />
                                            </div>

                                            <div class="input input--secondary">
                                                <label for="anNumInTwo">Company Registration Number</label>
                                                <input
                                                    type="text"
                                                    name="minimum_investment"
                                                    id="anNumInTwo"
                                                    required="required"
                                                    value="TX-0986"
                                                    disabled
                                                />
                                            </div>
                                          
                                        </div>
                                    </div>
                                    <div class="group birinit">
                                        <h6>Share via Social </h6>
                                        <div class="social text-start">
                                            <a href="javascript:void(0)">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <i class="fab fa-instagram"></i>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <i class="fab fa-linkedin-in"></i>
                                            </a>
                                        </div>
                                    </div>
                                    
                                    {directors.length>0&&(
                                        <div class="group alt__brin">
                                        <h5>Company Directors
                                            <i class="fa fa-plus" aria-hidden="true" id="TooltipExample2" onClick={() => toggleModal()}></i>
                                            <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipExample2" toggle={toggle}>
                                               Show all
                                            </Tooltip>
                                           
                                        </h5>
                                        <hr />

                                        <div class="singl__wrapper" style={{padding:"1px  !important"}}>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    {directors && directors.map((director, index) => {
                                                        return (
                                                            <div class="articles ">

                                                                <div class="card-body no-padding border-right-0 hover-shadow position-relative">
                                                                    <div class="item d-flex align-items-center ">
                                                                        <div class="image" >
                                                                            <ConfigProvider colors={['#6E2413 ', '#81003a', '#00815c', "#815500"]} >
                                                                                <Avatar name={director.first_name + " " + director.last_name} size="35" round={true} textSizeRatio={1.75} style={{ paddingRight: "auto" }} />
                                                                            </ConfigProvider>
                                                                        </div>
                                                                        <div class="text"><a href="#">
                                                                            <h3 class="h5">{director.first_name + " " + director.last_name}</h3></a><small>{director.citizenship}  </small>
                                                                        </div>
                                                                        <div class="btn-group col-4 ">
                                                                                <button class="btn btn-white btn-sm dropdown-toggle try" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <BsThreeDotsVertical />

                                                                                </button>
                                                                                <ul class="dropdown-menu">
                                                                                    <li><div className='border-bottom text-center' onClick={toggle_3} ><span class="mx-1"><BiEditAlt /></span>edit</div></li>
                                                                                    <Modal isOpen={modal2} toggle={toggle_3} className="">
                                                                                       
                                                                                       
                                                                                        <UpdateDirectors Director={director.id} director={director} />
                                                                                    </Modal>
                                                                                    <li><div className="border-bottom text-center" onClick={DeleteDirector_toggle}><span class="mx-1"><MdOutlineDeleteOutline /></span>Delete</div></li>
                                                                                    <Modal isOpen={modalDelDirector} toggle={DeleteDirector_toggle} className="">
                                                                                        <DeleteDirector directorId={director.id} director={director} handleClose={DeleteDirector_toggle}/>
                                                                                    </Modal>
                                                                                </ul>
                                                                            </div>
                                                                    </div>
                                                                    <div>

                                                                    </div>


                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                {directors.length>0 &&(
                                                    <div>
                                                         {

currentPage2 === 1 && numPagesDirectors === 1 ? null :
    currentPage2 === 1 ? (
        <div class="cta__btn">
            <button
                class="button button--effect m-4"
                onClick={() => {
                    setCurrentPage2(currentPage2 + 1)
                }}>Load More</button>
        </div>

    ) : numPagesDirectors === currentPage2 || numPagesDirectors < currentPage2 ? (
        <div class="cta__btn">
            <button
                class="button button--effect m-4"
                onClick={() => {
                    setCurrentPage2(currentPage2 - 1)
                }}>Load Previous</button>
        </div>
    ) : (
        <div class="cta__btn">
            <button
                class="button button--effect m-4"
                onClick={() => {
                    setCurrentPage2(currentPage2 - 1)
                }}>Load Previous</button>
            <button
                class="button button--effect m-4"
                onClick={() => {
                    setCurrentPage2(currentPage2 + 1)
                }}>Load More</button>
        </div>

    )
}
                                                    </div>
                                                )}

                                               

                                            </div>
                                        </div>
                                    </div>
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CompanyDetails;
