import { BUYSHARES_FAILURE, BUYSHARES_REQUEST, BUYSHARES_SUCCESS } from "../../actions/buy-shares/buysharesTypes"

const initialState = {
    loading: false,
    buyshare: [],
    error: ''
}

const buysharesReducer = (state=initialState, action) => {
    switch (action.type) {
        case BUYSHARES_REQUEST:
            return {
                loading: true,
                buyshare: [],
                error: ''
            }
        case BUYSHARES_SUCCESS:
            return {
                loading: false,
                buyshare: action.payload,
                error: ''
            }
        case BUYSHARES_FAILURE:
            return {
                loading: false,
                buyshare: [],
                error: action.payload
            }
        default: return state
    }
}

export default buysharesReducer