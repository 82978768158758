import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ModalBody, ModalHeader } from 'reactstrap';
import { requests } from '../../../services/Api';
import { getToken } from '../../../services/UseTokens';


export default function DeleteProjectOwner(props) {
    console.log("props deleteee",props.documentId)
    const [modal_center, setmodal_center] = useState(false);
    const [documents ,setDocuments]=useState([])
    const token=getToken()

const newdocs=[]  
console.log("newDocs",props.newDocs)

const DeleteDocument=async()=>{
    await requests.delete(`documents/owners/${props.documentId}`,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then((response)=>{ 
        
        console.log("role delete",response)           
        setDocuments(response.data)
        props.handleDelete(props.documentId)
        // milestones.splice(milestones.findIndex(milestones),1)
        // ParentCallback(milestones)
   props.handleClose()
        
    }).catch((error)=>{
        console.log("roles delete error",error)
    })
}
  
   


  
  
  return (
    <div>
  
                <ModalBody className="text-center p-5">
                  
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure you want to delete the document?</h4>
                         <div className="hstack gap-2 justify-content-center">
                            <Link to="#" className="btn btn-link link-success fw-medium" onClick={(e)=>props.handleClose(e)}><i className="ri-close-line me-1 align-middle"></i> Close</Link>
                            <Link to="#" className="btn btn-danger" onClick={(e)=>{DeleteDocument()
                            }}>delete</Link>
                        </div>
                    </div>
                </ModalBody>
    </div>
  )
}
