import React from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader,  Input, Label, Spinner } from 'reactstrap'
import {Form, Formik } from "formik"
import * as Yup from "yup"
import { requests } from '../../services/Api'
import { getToken } from '../../services/UseTokens'


export default function AddSector(props) {
    const [loading,setLoading]=React.useState(false)
    const [successResponse,setSuccessResponse]=React.useState("")
    const [serverError,setServerError]=React.useState("")
    const [modal,setModal]=React.useState(false)
    const [sectors,setSectors]=React.useState([])
    const token = getToken()
    console.log("sectors",token)
    const AddSector = async (values) => {
        
  
        try {
          setLoading(true);
          const response = await requests.post(`/sectors`,values, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          setLoading(false);
          setSuccessResponse("sector has been successfully added")
    
          console.log("Document tab response", response.data)
          setSectors(response.data)
    
          // props.parentCallBack(response.data)
          props.toggle()
          console.log("Document tab respons yte",props.toggle)
    
          setTimeout(() => {
            setSuccessResponse("")
            // handleCloseModal()
          }, 3000);
          //   navigate(`/Document-tab/${projectId}/${response.data.id}`)
        } catch (error) {
          setLoading(false);
          console.log('error', error)
          setServerError(error.response.data.message)
          setTimeout(() => {
            setServerError("")
          }, 5000)
          props.toggle()
        }
    console.log("token",token)

      }
  
   
    return (
        <div>
            <Card >
            <Formik
                  initialValues={{
                  "name": "",
                  "description": "",
                }}
              
                  onSubmit={(values,{resetForm}) => {
                    AddSector(values)
                    // console.log('project', dispatch(createSector(values)));
                //    handleSubmit(values)
                   resetForm({values:""})
                    console.log("submit values",values)
                  }}
            >
                 {({values, isSubmitting, errors, handleSubmit, handleChange,dirty,isValid}) => (

            <Form className="was-validated">
                <CardHeader className='position-relative text-capitalize text-center'>
                    <h5 className='card-title text-center m-auto'>Add a Sector</h5>
                    </CardHeader>
                <CardBody>
            

                <div className=" mb-3">
                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">sector Name</Label>
                    <Input type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder='Enter sector name'
                        values={values.name}
                        onChange={handleChange}
                        required />

                    <div className="invalid-feedback m-4">Enter sector name</div>
                </div>
                <div className="mb-3">
                    <Label htmlFor="validationTextarea" className="form-label">Description</Label>
                    <textarea className="form-control "
                        rows="5"
                        id="description"
                        name="description"
                         placeholder="enter sector description"
                        values={values.description}
                        onChange={handleChange}
                        required/>
                    <div className="invalid-feedback m-4">
                      Enter description
                    </div>
                </div>
            
            </CardBody>
            <CardFooter>
            {!loading && (
                  <div className='position-relative'>

                    <button className=" button button--effect m-1" type="submit">Submit form</button>
                    <button  className="button  btn-danger  m-1" type="close" style={{backgroundColor:"#d9534f"}} onClick={()=>props.toggle()}>close</button>
                  </div>
                )}
                {
                  loading && (
                    <div className="mt-4">

                      <Button color="secondary" className="btn-load " outline disabled>
                        <span className="d-flex align-items-center">
                          <span className="flex-grow-1 me-2">
                            Loading...
                          </span>
                          <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                        </span>
                      </Button>
                    </div>

                  )
                }
                </CardFooter>
            </Form>
                 )}
                 </Formik>
            </Card>
        </div>
    )
}
