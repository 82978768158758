import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ModalBody, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import { requests } from '../../../services/Api';
import { getToken } from '../../../services/UseTokens';


export default function DeleteProjectDocument(props) {
    console.log("props deleteee",props.documentId)
    const [modal_center, setmodal_center] = useState(false);
    const [documents ,setDocuments]=useState([])
    const token=getToken()



const DeleteDocument=async()=>{
    await requests.delete(`documents/projects/${props.documentId}`,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    .then((response)=>{ 
        Swal.fire({
            icon:"success",
            title:"success",
            text:"you have successfully deleted the project document",
            confirmButton:"Ok"

        })
        console.log("role delete",response)           
        setDocuments(response.data)
        props.handleDelete(props.documentId)
       
        
    }).catch((error)=>{
        console.log("roles delete error",error)

        Swal.fire({
            icon:"error",
            title:"error",
            text:error.response.data.message,
            confirmButton:"ok"
        })
    })
}
  
   


  
  
  return (
    <div>
  
                <ModalBody className="text-center p-5">
                  
                    <div className="mt-4">
                        <h4 className="mb-3">Are you sure you want to delete the document?</h4>
                         <div className="hstack gap-2 justify-content-center">
                            <button to="#" className="btn btn-link link-success fw-medium" onClick={(e)=>props.handleClose(e)}>
                                <i className="ri-close-line me-1 align-middle"></i> Close</button>
                            <button type='button' className="btn btn-danger" onClick={(e)=>{DeleteDocument()
                            }}>delete</button>
                        </div>
                    </div>
                </ModalBody>
    </div>
  )
}
