import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ModalBody } from 'reactstrap'
import {AiFillCloseSquare } from 'react-icons/ai';
import { requests } from '../../../services/Api';

export default function ViewDocument({documentId,handleClose}) {
    console.log("docid",documentId)
 const [ImageStr,setImageStr]=useState("")  
 const [loading,setLoading]=useState(true)
const [document ,setDocument]=useState({})
const token=JSON.parse(localStorage.getItem('authuser')).token
const url=`http://192.168.8.168:8000/api/`
    const getSingleDocument = async (url) => {
        setLoading(true)
        await requests.get(`documents/owners/${documentId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "image/png"
            },
           
        }).then((response) => {
            setDocument(JSON.parse(JSON.stringify(response.data).replace("\/","/")))
           console.log(document)
            // document && setImageStr(`http://192.168.8.168:8000${document.document}`)
            setLoading(false)
            console.log("tyouuutyusdfgvht",ImageStr)
          
        })
            
            
        .catch((error) => {
            setLoading(false)
            console.log("projec documents error", error)
        })
    }

  
    useEffect(() => {
        getSingleDocument()
      
    }, [])
    
  return (
    <div>
        {document &&(
            console.log("DOCC",`http://18.116.46.98:8001${document.document}`),
  <ModalBody className="text-center p-5">
  {!loading? (
   <div className="card">
    <div className='position-relative m-1 d-flex flex-row'>
    {/* <h4 className="mb-3 card-title">image</h4> */}
    <div className='position-absolute end-0 mb-3'><button className="btn-close " onClick={(e)=>handleClose(e)}></button></div>
    </div>
      
        <div className="hstack gap-2 justify-content-center">
          <img src={`http://18.116.46.98:8001${document.document}`} alt="" 
         
          className="img-fluid"/>
           {console.log("imageeee",`http://18.116.46.98:8001${document.document}`)}
       </div>
   </div>
  ):(<div>loading...</div>)}
</ModalBody>
        )}

    </div>
  )
}
