import React, { useEffect, useState } from "react";
import { requests } from "../../../services/Api";
import ViewDocument from "./ViewDocument";
import Viewpdf from "./ViewPdf";
import _ from "lodash";


export default function ViewSingle({documentId}) {
    console.log("documentId",documentId)
    const [ImageStr,setImageStr]=useState("")  
 const [loading,setLoading]=useState(true)
const [document ,setDocument]=useState({})
const token=JSON.parse(localStorage.getItem('authuser')).token
    const getSingleDocument = async (url) => {
        setLoading(true)
        await requests.get(`documents/owners/${documentId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "image/png"
            },
           
        }).then((response) => {
            setDocument(JSON.parse(JSON.stringify(response.data).replace("\/","/")))

            document && (document.document && (Object.keys(document).length =! 0 &&(
            setImageStr(`http://18.116.46.98:8001${document.document}`)
            )))
            setLoading(false)
            console.log("youuggg",ImageStr)
          
        })          
            
        .catch((error) => {
            setLoading(false)
            console.log("projec documents error", error)
        })
    }

  
    useEffect(() => {
        getSingleDocument()
      
    }, [])
  return (
    <div className="">
      
     { console.log("documentrtyhftyyh",Object.keys(ImageStr).length)}
     {Object.keys(ImageStr).length === 0 && (
      <div>loading</div>
     )}

     {Object.keys(ImageStr).length !== 0 && (
      <Viewpdf pdf={`http://18.116.46.98:8001${document.document}`} />
     )}

     

     
    </div>
  );
}
