import { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { fetchProjects } from '../../../redux/actions/projects/fetchProjects';
import moment from 'moment';
import Dropdown from '../../home/filter/dropdown/dropdown.component';
import { FetchUserCompany } from '../../../redux/actions/company/FetchUserCompany';
import ReactCardFlip from 'react-card-flip';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import React from 'react'
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import Map from './location';
import { Button } from 'reactstrap';
import Card from './Card';
var _ = require('lodash');

function List({ companies}) {
    const [perPage, setPerPage] = useState(9)
    const [currentPage, setCurrentPage] = useState(1)
    const [deletedCompany,setDeletedCompany]=useState([])
    const [desc,setDesc]=useState("")
    const [flip, setFlip] = useState(false);
    const [flipped, setFlipped] = useState(new Array(companies?.length).fill(false));
    const dispatch = useDispatch();
    // const allProjects = useSelector(state => state.projects.projects);
    // const projects = allProjects.slice(0,perPage.value);
    //check number of pages
    const numPages = Math.ceil(companies.length / perPage.value);
    const paginate = (array, pageSize, pageNumber) => {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }
    const userCompanies = paginate(companies, perPage.value || 9, currentPage);
    console.log("projects",companies)
    //get minutes to project end date
    const minutesToEnd = (closing) => {
        const today = moment();
        const end = moment(closing);
        const diff = end.diff(today, 'minutes');
        return diff;
    }


    // const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    function createMarkup(value) {
        return {__html: value};
      }

    useEffect(() => {
        dispatch(FetchUserCompany())
    }, [currentPage])
    
    const options = [
        { name: '9', value: 9 },
        { name: '18', value: 18 },
        { name: '27', value: 27 },
        { name: '36', value: 36 }
    ]
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    const handleDelete=(childData)=>{
        console.log("childData",childData)
        var index = userCompanies.findIndex(function(o){
          return o.id === childData
     })
        userCompanies.splice(index,1)
        return userCompanies
        setDeletedCompany(userCompanies)
    
        console.log("newcreatedprojectDocuments minushhh one",userCompanies)
        
      }

    return (
      <div>
        <section class="properties__filter section__space__bottom">
        
            <div class="container wow fadeInUp">
                <div class="properties__filter__wrapper">
                    <h6>Showing <span>{userCompanies && userCompanies.length}</span>  companies</h6>
                    <div class="grid__wrapper">
                        <label>Per Page</label>
                        <Dropdown
                            options={
                                options
                            }
                            prompt="Per Page"
                            value={perPage}
                            onChange={(val) => setPerPage(val)}
                            />
                    </div>
                </div>
                
                <div class="row property__grid__area__wrapper">
                    {
                        deletedCompany.length===0 && userCompanies && userCompanies.map((company, index) => (
                            <>
                            <div class="col-xl-4 col-md-6 property__grid__area__wrapper__inner">
                                <Card company={company} />
                            </div>
                            </>
                            
                        ))
                    }
                     {
                        deletedCompany.length>0 && deletedCompany.map((company, index) => (
                            <>
                            <div class="col-xl-4 col-md-6 property__grid__area__wrapper__inner">
                                <Card company={company} handleDelete={handleDelete}/>
                            </div>
                            </>
                            
                        ))
                    }
                </div>   

                    {
                        currentPage === 1 && numPages === 1 ? null :
                        currentPage === 1 ? (
                            <div class="cta__btn">
                                <button 
                                    class="button button--effect m-4" 
                                    onClick={() => {
                                    setCurrentPage(currentPage + 1)
                                    }}>Load More</button>
                            </div>
                            
                        ): numPages === currentPage || numPages < currentPage ? (
                            <div class="cta__btn">
                                <button 
                                    class="button button--effect m-4" 
                                    onClick={() => {
                                    setCurrentPage(currentPage - 1)
                                    }}>Load Previous</button>
                            </div>
                        ): (
                            <div class="cta__btn">
                            <button 
                                    class="button button--effect m-4" 
                                    onClick={() => {
                                    setCurrentPage(currentPage - 1)
                                    }}>Load Previous</button>
                                <button 
                                    class="button button--effect m-4" 
                                    onClick={() => {
                                    setCurrentPage(currentPage + 1)
                                    }}>Load More</button>
                            </div>

                        )
                    }
                    
            </div>

          
        
        </section>
      </div>
    );
  }
  
  export default List;
  