import { requests } from "../../../services/Api"
import { FETCH_PROJECTS_FAILURE, FETCH_PROJECTS_REQUEST, FETCH_PROJECTS_SUCCESS } from "./projectTypes"

export const fetchProjects = () => async (dispatch) =>{
    try {
        dispatch({
            type: FETCH_PROJECTS_REQUEST
        })

        const response = await requests.get("/projects/get-projects")

        dispatch({
            type: FETCH_PROJECTS_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: FETCH_PROJECTS_FAILURE,
            payload: error
        })
    }
}