import { Form, Formik } from 'formik'
import { divide } from 'lodash'
import React, { useState } from 'react'
import { ModalTitle } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Label, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'
import { sellShares } from "../../redux/actions/sell-shares/SellShares.actions"
import { getUserId } from '../../services/UseTokens'
import * as Yup from "yup";

export default function SellShares(props) {
    console.log(props.projectId,"eweew")
    const maxAmount=props.shares*props.shareValue
    const dispatch = useDispatch()
    const sellSharesVal = useSelector(state => state.sellshares)
    const [amount, setAmount] = useState()
    const [shareQuantity, setShareQuantity] = useState()
    const [shareQuantityErr, setShareQuantityErr] = useState("")
    const [amountErr, setAmountErr] = useState(false)
    const [sharesState, setSharesState] = useState(true)
    const [AmountState, setAmountState] = useState(false)
    const [errors, setErrors] = useState({});
    const [response, setResponse] = useState({})
    const userId = getUserId()
    let quantityErr=""
    let ValErr=""
    // let AmountE
    console.log("props", props.shares)
    let loading = sellSharesVal.loading
    
    console.log("sellShares", shareQuantity)

   if (shareQuantity===0){
    quantityErr ="Required"
   } else{
    quantityErr=""
   }
   if (amount===0){
    ValErr="Required"
   }
    
   
  

    return (
        <Card  >

            <Formik
                initialValues={{
                    "project_id": "",
                    "user_id": "",
                    "share_quantity": "",
                    "company_id": "",
                    "share_worth": ""

                }}

                onSubmit={(values, { resetForm }) => {
                    
                    values["user_id"] = userId
                    values["project_id"] = props.projectId
                    values["company_id"] = props.companyId
                    values["share_quantity"] = shareQuantity
                    dispatch(sellShares(values)).then(() => {
                        // setResponse(sellSharesVal.sell_shares)
                        props.getData(sellSharesVal.sell_shares)
                    })
                    resetForm({ values: "" })
                    console.log("submit values", values)
                }}
            >
                {({ values, isSubmitting, errors, handleSubmit, handleChange, dirty, isValid }) => (

                    <Form className="was-validated col-md-12 col-lg-12 card">
                        <ModalHeader className='position-relative text-capitalize text-center' closeButton>
                            <ModalTitle class="position absolute start-0 m-2" >Sell Shares</ModalTitle>

                        </ModalHeader>
                        <ModalBody>
                            <div class="row d-flex">
                             

                            </div>
                            <div className='row'>
                                <div className=" mb-3  col-xlg-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                               
                                             <div class="input input--secondary">
                                            <div>
                                                <label for="anNumInTwo">worth of shares in KSH</label> 
                                                <input

                                                    type="number"
                                                    name="an__num_in_two"
                                                    id="share_quantity"
                                                    placeholder="KES 35.00"
                                                    required="required"
                                                    onChange={(e) => {

                                                        setAmount(e.target.value)
                                                        setShareQuantity(Math.floor(e.target.value / props.shareValue))
                                                        if((e.target.value / props.shareValue >props.shares)){
                                                            setShareQuantityErr("shares exceed your shares")
                                                            console.log("tru")
                                                        }else{
                                                            setShareQuantityErr("")
                                                            console.log("false")
                                                        }
                                                        // console.log("shareQuantityrr", shareQuantityErr.length)
                                                    }}
                                                    value={amount}
                                                />
                                                  <div className="invalid-feedback">{quantityErr}</div>
                                                 {shareQuantityErr.length && <div style={{color: 'red'}}>{shareQuantityErr}</div>}
                                            </div>

                                            <p class="m-4">you are selling <span class="form-check-label">{shareQuantity}</span> shares  </p>
                                            </div>

                                </div>


                            </div>

                        </ModalBody>
                        {!loading && (
                            <ModalFooter class="text-right ">
                                <div className='position-relative'>
                                    <Button variant="secondary" className="  m-1" onClick={() => props.handleClose()}>Close</Button>
                                    <button className="btn btn-success  end-0 m-1" type="submit" >Submit form</button>
                                </div>

                            </ModalFooter>
                        )}

                        {
                            loading && (
                                <ModalFooter class="text-right bg-light ">
                                    <div className="m-1 position-relative">
                                        <Button color="success" className="btn-load" outline disabled>
                                            <span className="d-flex align-items-center">
                                                <span className="flex-grow-1 me-2">
                                                    Loading...
                                                </span>
                                                <Spinner size="sm" type="grow" className="flex-shrink-0" role="status" disabled> Loading... </Spinner>
                                            </span>
                                        </Button>
                                    </div>
                                </ModalFooter>

                            )
                        }

                    </Form>
                )}
            </Formik>
        </Card>
    )
}
