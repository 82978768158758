import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CardBody, CardFooter, CardHeader, Form, Input, Label, Spinner } from 'reactstrap'
import { GetSingleMilestoneAction } from '../../../redux/actions/milestones/getsinglemilestone'
import updateMilestoneAct from '../../../redux/actions/milestones/updateMilestone'
import {getToken} from "../../../services/UseTokens"

export default function UpdateMilestone(props,{milestones}) {
    console.log("props",props.milestones)
    const dispatch= useDispatch()
    const token=getToken()
    const [loading,setLoading]=useState(false)
    const [serverError,setServerError]=useState(false)
    const [successResponse,setSuccessResponse]=useState(false)
    const [description, setDescription] = useState("")
    const [title, setTitle] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [startDate, setStartDate] = useState("")
    const [data, setData] = useState("")
    const milestone=useSelector(state=> state.singlemilestone.milestone.data)
    console.log("milestoneee",milestone)
    function handleSubmit(values) {
        const data={
        "milestone_name": values.milestone_name,
        "milestone_start_date": values.milestone_start_date,
        "milestone_end_date": values.milestone_end_date,                       
        "project_id": milestone.project_id,
        "milestone_description":!description?milestone.project_description:description
        }
        dispatch(updateMilestoneAct(props.milestones.id,data,token))
    }

    useEffect(() => {
      
        dispatch(GetSingleMilestoneAction(props.milestones.id,token))
    }, [])
    
  return (
    <div>
        <Formik
                    initialValues={milestone||{
                        "milestone_name": "",
                        "milestone_start_date": "",
                        "milestone_end_date": "",                       
                        "project_id": "",
                    }}

                    onSubmit={(values, { resetForm }) => {
                       
                        values["project_id"] = props.id
                        values["milestone_description"] = description
                        // PostProjectDocument(values)
                        resetForm({ values: "" })
                        console.log("submit values", values)
                    }}
                    enableReinitialize
                >
                    {({ values, isSubmitting, errors, handleSubmit, handleChange, dirty, isValid }) => (


                        <Form className="was-validated">
                            <CardHeader>
                           
                            </CardHeader>
                            <CardBody>
                           
                            <div className="row">
                                <div className=" mb-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput txt-capitalize" for="validationFormCheck3">milestone Name</Label>
                                    <Input type="text"
                                        className="form-control"
                                        id="milestone_name"
                                        name="milestone_name"
                                        placeholder='Enter milestone name'
                                        value={values.milestone_name}
                                        onChange={handleChange}
                                        required />

                                    <div className="invalid-feedback">Enter milestone name</div>
                                </div>
                            </div>
                            <div className='row'>
                            <div className=" mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">milestone Name</Label>
                                    <Input type="date"
                                        className="form-control"
                                        id="milestone_start_date"
                                        name="milestone_start_date"
                                        placeholder='Enter milestone start date'
                                        value={values.milestone_start_date}
                                        onChange={handleChange}
                                        required />

                                    <div className="invalid-feedback">Enter milestone  start date</div>
                                </div>
                                <div className=" mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">milestone end date</Label>
                                    <Input type="date"
                                        className="form-control"
                                        id="date"
                                        name="milestone_end_date"
                                        placeholder='Enter milestone end date'
                                        value={values.milestone_end_date}
                                        onChange={handleChange}
                                        required />

                                    <div className="invalid-feedback">select milestone closing date</div>
                                </div>
                                </div>
                                <div className=" mb-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">milestone description</Label>
                                <CKEditor
                                            editor={ClassicEditor}
                                            data={milestone.milestone_description}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setDescription(data)
                                                console.log({ event, editor, data });
                                            }}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                        />
                                    <div className="invalid-feedback">Enter milestone description</div>
                                </div>

                            </CardBody>
                            {!loading && (
                                <CardFooter className="text-right position-absolute  end-0">
                                    <div className=''>

                                        <button className="btn btn-success m-1" type="button"
                                         onClick={(e)=>{
                                            const data={
                                                "milestone_name": values.milestone_name,
                                                "milestone_start_date": values.milestone_start_date,
                                                                "milestone_end_date": values.milestone_end_date,                       
                                                                "project_id": milestone.project_id,
                                                                "milestone_description":!description?milestone.project_description:description
                                                }
                                                dispatch(updateMilestoneAct(props.milestones.id,data,token))}
                                         }>Submit form</button>
                                    </div>

                                </CardFooter>
                            )}

                            {
                                loading && (
                                    <CardFooter className="text-right position-absolute  end-0">
                                        <div className="m-1 position-relative">
                                            <Button color="success" className="btn-load" outline disabled>
                                                <span className="d-flex align-items-center">
                                                    <span className="flex-grow-1 me-2">
                                                        Loading...
                                                    </span>
                                                    <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                                                </span>
                                            </Button>
                                        </div>
                                    </CardFooter>

                                )
                            } 
                        </Form>
                    )}
                </Formik>
    </div>
  )
}
