export const POST_REQUEST = "POST_REQUEST";
export const POST_SUCCESS = "POST_SUCCESS";
export const POST_FAILURE = "POST_FAILURE";

export const FETCH_REQUEST = "FETCH_REQUEST";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAILURE = "FETCH_FAILURE";

export const FETCH_SINGLE_MILESTONE_REQUEST = "FETCH_SINGLE_MILESTONE_REQUEST";
export const FETCH_SINGLE_MILESTONE_SUCCESS = "FETCH_SINGLE_MILESTONE_SUCCESS";
export const FETCH_SINGLE_MILESTONE_FAILURE = "FETCH_SINGLE_MILESTONE_FAILURE";

export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAILURE = "UPDATE_FAILURE";

export const DELETE_REQUEST = "DELETE_REQUEST";
export const DELETE_SUCCESS = "DELETE_SUCCESS";
export const DELETE_FAILURE = "DELETE_FAILURE";

export const FETCH_PROJECT_MILESTONE_REQUEST = "FETCH_PROJECT_MILESTONE_REQUEST";
export const FETCH_PROJECT_MILESTONE_SUCCESS = "FETCH_PROJECT_MILESTONE_SUCCESS";
export const FETCH_PROJECT_MILESTONE_FAILURE = "FETCH_PROJECT_MILESTONE_FAILURE";
