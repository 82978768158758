import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CompanyDetails from "./CompanyDetails"
import CompanyTab from './CompanyTab';
import image from "../../assets/table.jpg"
import { Fab } from '@mui/material';
import { AddCircleOutlineRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { fetchcompany } from '../../redux/actions/company/companydetails/fetchcompany';
import { getToken } from '../../services/UseTokens'
import CompanyDirectors from './DirectorIndex';

const style = {
  margin: 0,
  top: 'auto',
  right: 40,
  bottom: 40,
  left: 'auto',
  position: 'fixed',
  zIndex:1000,
  borderRadius:"25px"

};
;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function CompanyProfile2() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const company = useSelector(state => state.singleCompany.company)
  const { id } = useParams();
  const token = getToken()
  useEffect(() => {
    dispatch(fetchcompany(token, id, navigate));
  }, [])
  
  return (
    <div style={{backgroundImage:`linear-gradient(to bottom, rgba(245, 246, 252, 0.52), #5e5ee2),url(${image})`}}>
    <Box sx={{ flexGrow: 1,marginTop:"10%",marginX:"3%" ,paddingBottom:"10%"}}>

      <Box style={style}>
      <Link to={`/onboard-project/${id}`}><Fab style={{backgroundColor:"#5927e3" ,color:"#fff",flex:1}}  variant="extended">
        <AddCircleOutlineRounded sx={{ mr: 1 }} />
        create Project
      </Fab></Link>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} md={4} lg={4} xl={4}>
          <Item><CompanyDetails company={company}/></Item>
          {company?.directors &&(<Item style={{marginTop:"15px"}}><CompanyDirectors directors={company?.directors}/></Item>)}
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={8} xl={8}>
          <Item><CompanyTab company={company} projects={company?.projects}/></Item>
        </Grid>
        
      </Grid>
    </Box>
    </div>
  );
}
