export const FETCH_DIRECTORS_REQUEST = "FETCH_DIRECTORS_REQUEST";
export const FETCH_DIRECTORS_SUCCESS = "FETCH_DIRECTORS_SUCCESS";
export const FETCH_DIRECTORS_FAILURE = "FETCH_DIRECTORS_FAILURE";

export const DELETE_DIRECTORS_REQUEST = "DELETE_DIRECTORS_REQUEST";
export const DELETE_DIRECTORS_SUCCESS = "DELETE_DIRECTORS_SUCCESS";
export const DELETE_DIRECTORS_FAILURE = "DELETE_DIRECTORS_FAILURE";

export const UPDATE_DIRECTORS_REQUEST = "UPDATE_DIRECTORS_REQUEST";
export const UPDATE_DIRECTORS_SUCCESS = "UPDATE_DIRECTORS_SUCCESS";
export const UPDATE_DIRECTORS_FAILURE = "UPDATE_DIRECTORS_FAILURE";

