import React, { useLayoutEffect, useEffect, useState } from "react";
import { requests } from "../../services/Api";
import { getToken, getUser } from "../../services/UseTokens";
import AmountInvested from "./Amount Invested";
import Faq from "./Faq";
import Hero from "./Hero";
import Process from "./Process";

export default function ListofProjects() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const [projects, setProjects] = useState([]);
  const [companies, setCompanies] = useState([])
  const user = getUser();
  const token = getToken()

  const getProjects = async () => {
    await requests
      .get(`projects/get-user-projects/${user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProjects(response.data);
      }).catch((error) => {
        console.log(error);
      });
  };

  const getCompanies = async () => {
    await requests.get(`company/get-user-companies/${user.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      setCompanies(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <div>
      <Hero companies={companies}/>
      <AmountInvested />
      <Process />
      <Faq />
    </div>
  );
}
