import React from 'react'
import {FETCH_COMPANY_PROJECT_REQUEST ,FETCH_COMPANY_PROJECT_FAILURE ,FETCH_COMPANY_PROJECT_SUCCESS } from "../../../actions/company/CompanyProject/ProjectType"

const initialState={
    loading:false,
    projects:[],
    error:""
}
export default function CompanyProjects(state=initialState,action) {
    switch (action.type) {
        case FETCH_COMPANY_PROJECT_REQUEST:
            return{
                ...state,
                loading:true
            }
            
            break;
            case FETCH_COMPANY_PROJECT_FAILURE:
                return{
                    ...state,
                    error:action.payload
                }
                
                break;
                case FETCH_COMPANY_PROJECT_SUCCESS:
                    return{
                        ...state,
                        projects:action.payload
                    }
                    
                    break;
    
        default:
            return state;
            break;
    }
  
}

