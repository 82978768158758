import { Link } from "react-router-dom";
import image2 from "../../assets/images/list-bg.png";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
export default function Hero({ projects, companies }) {
    const navigate = useNavigate();
    const handleRegisterProject = () => {
        if(companies.length > 0) {
            // show a sweet alert to select a company
            Swal.fire({
                title: 'Choose a company',
                text: 'All projects must be registered under a company you own, select a company to proceed',
                input: 'select',
                inputOptions: companies.reduce((acc, company) => {
                    acc[company.system_company_number] = company.company_name
                    return acc
                }, {}),
                inputPlaceholder: 'Select a company',
                confirmButtonText: 'Proceed to register project',
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'You need to select a company'
                    }
                    navigate(`/onboard-project/${value}`)
                }
            })
        }else {
            // show a sweet alert that you have to register your company first
            Swal.fire({
                title: 'Error!',
                text: 'You have to register your company first, do you want to register your company now?',
                icon: 'info',
                confirmButtonText: 'Proceed to register company',
                showCancelButton: true,
            }).then((result) => {
                if(result.isConfirmed) {
                    window.location.href = '/onboard-company'
                }
            })
        }
    }
  return (
    <section
      class="hero hero--two hero--three hero__list clear__top pos__rel over__hi bg__img"
      style={{ backgroundImage: `url(${image2})` }}
    >
      <div class="container">
        <div class="hero__area">
          <div class="row">
            <div class="col-lg-8">
              <div class="hero__content">
                <h5 class="neutral-top">
                  <span style={{ color: "#26DD77" }}>
                    Register your company to list your projects
                  </span>
                </h5>
                <h1>Fund your project, Online & hassle-free</h1>
                <p class="primary neutral-bottom">
                  Having a project and want to get quick funding instantly? List
                  your project and get funding from our pot of investors on
                  Habanahaba.
                </p>
                <div class="hero__cta__group">
                  {/* <Link to="/onboard-company">
                    <Button
                      variant="contained"
                      class="button button--secondary button--effect"
                    >
                      Register your project
                    </Button>
                  </Link> */}
                  <Button
                      variant="contained"
                      class="button button--secondary button--effect"
                      onClick={handleRegisterProject}
                    >
                      Fund your project
                    </Button>
                  {companies.length > 0 && (
                    <Link to="/my-company">
                      <Button
                        variant="outlined"
                        class="button button--secondary button--effect"
                      >
                        Go to my companies
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
