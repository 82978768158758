import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import FileUpload from "react-mui-fileuploader";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { requests } from "../../../services/Api";
import { getToken, getUser } from "../../../services/UseTokens";
import Gallery from "./Gallery";
import Video from "./Video";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #5927e3",
  boxShadow: 24,
  p: 4,
};

export default function MainGallery({ project }) {
  const user = getUser();
  const [imageState, setImageState] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleFileUploadError = (error) => {
    // Do something...
  };

  const handleFilesChange = (files) => {
    // Do something...
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [images, setImages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const { project_id } = useParams();
  const token = getToken();

  const fetchImages = async () => {
    setLoading(true);
    await requests
      .get(`/projects/get-project-images/${project_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setImages(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const addImage = async () => {
    const data = new FormData();
    data.append("image", image);
    data.append("media_type", "image");
    data.append("project_id", project_id);


    setLoading(true);
    await requests
      .post(`/projects/create-project-image`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        Swal.fire({
          title: "Success",
          text: `${imageState ? "Video" : "Image"} added successfully`,
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const addVideo = async () => {
    const data = {
      image,
      media_type: 'video',
      project_id
    }



    setLoading(true);
    await requests
      .post(`/projects/create-project-image`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        Swal.fire({
          title: "Success",
          text: `${imageState ? "Video" : "Image"} added successfully`,
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {project && project?.created_by === user?.id ? (
            <Button
              variant="outlined"
              style={{ border: "0.5px solid #5927e3" }}
              onClick={handleOpen}
            >
              Upload
            </Button>
          ) : null}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <Typography variant="h6">Upload Files </Typography>
              </Box>

              {imageState && (
                <TextField
                  type="text"
                  id="outlined-name"
                  label="Video Link"
                  value={image}
                  onChange={(e) => {
                    console.log("video", e.target.value);
                    setImage(e.target.value);
                  }}
                />
              )}
              {!imageState && (
                <input
                  accept="image/*"
                  style={{ border: "2px solid #D3D3D3" }}
                  className="form-input"
                  // style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={(e) => {
                    console.log('image', e.target.files[0]);
                    setImage(e.target.files[0]);
                  }}
                />
              )}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "3px",
                  padding: "3px",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    borderColor: "#ba000d",
                    color: "#ba000d",
                    margin: "3px",
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                {loading ? (
                  <CircularProgress />
                ) : imageState === false ? (<Button
                  variant="contained"
                  style={{ backgroundColor: "#5927e3", margin: "3px" }}
                  onClick={() => addImage()}
                >
                  Upload Image
                </Button>
                ) : (<Button
                  variant="contained"
                  style={{ backgroundColor: "#5927e3", margin: "3px" }}
                  onClick={() => addVideo()}
                >
                  Upload Video
                </Button>
                )}
              </Box>
            </Box>
          </Modal>
        </Grid>
        <Grid xs={6}>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>image</Typography>
              <Android12Switch
                onChange={() =>
                  imageState === false
                    ? setImageState(true)
                    : setImageState(false)
                }
              />
              <Typography>Video</Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      {!imageState && <Gallery data={images} />}
      {imageState && <Video data={images} />}
    </Box>
  );
}
