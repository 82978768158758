import { Box } from "@mui/material";
import Carousel from "better-react-carousel";
import React from "react";
import "video-react/dist/video-react.css";
import { Player } from "video-react";

export default function Video({ data }) {
  // get where media_type is video
  const videos = data.filter((item) => item.media_type === "video");

  return (
    <Box>
      <Carousel cols={1} rows={1} gap={10} loop autoPlay={100}>
        {/* {videos && videos.length > 0 && (
          <Carousel.Item>
            <div>
              <Player
                playsInline
                poster="/assets/poster.png"
                autoPlay={true}
                src={videos && videos.length > 0 && videos[0].image_path}
              />
            </div>
          </Carousel.Item>
        )} */}

        <Carousel.Item>
          <Player
            playsInline
            poster="/assets/poster.png"
            autoPlay={true}
            src="https://www.youtube.com/watch?v=eVu99Mr45H8"
          />
        </Carousel.Item>

        <Carousel.Item>
          {/* anything you want to show in the grid */}
        </Carousel.Item>
        {/* ... */}
      </Carousel>
    </Box>
  );
}
