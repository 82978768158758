import { FETCH_TRANSACTIONS_FAILURE, FETCH_TRANSACTIONS_REQUEST, FETCH_TRANSACTIONS_SUCCESS } from "../../actions/transactions/transactionTypes";

const initialState = {
    loading: false,
    transactions: [],
    error: ""
}

const userTransactionsReducer = (state=initialState, action) => {
    switch (action.type) {
        case FETCH_TRANSACTIONS_REQUEST:
            return {
                loading: true,
                transactions: [],
                error: ""
            }

        case FETCH_TRANSACTIONS_SUCCESS:
            return {
                loading: false,
                transactions: action.payload,
                error: ""
            }
        
        case FETCH_TRANSACTIONS_FAILURE:
            return {
                loading: false,
                transactions: [],
                error: action.payload
            }    
        default: return state
    }
}
export default userTransactionsReducer