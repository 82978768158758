import { Link } from 'react-router-dom'
import Logo from './images/logo.png'
import Logo2 from './images/logo.jpg'
function Footer() {
  return (
    <div>
    <footer class="footer pos__rel over__hi">
        <div class="container">
            <div class="footer__newsletter">        
            </div>
            <div class="footer__area section__space">
                <div class="row">
                    <div class="col-md-12 col-lg-4 col-xl-4">
                        <div class="footer__intro">
                            <Link to="/">
                                <img src={Logo} alt="Revest" class="logo" />
                            </Link>
                            <p>Revest is a platform offering anyone the ability to invest and potentially earn money
                                from property at the click of a button</p>
                            <div class="social">
                                <a href="javascript:void(0)">
                                    <i class="fa-brands fa-facebook-f"></i>
                                </a>
                                <a href="javascript:void(0)">
                                    <i class="fa-brands fa-twitter"></i>
                                </a>
                                <a href="javascript:void(0)">
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
                                <a href="javascript:void(0)">
                                    <i class="fa-brands fa-linkedin-in"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                        <div class="footer__links footer__links--alt">
                            <h5>Company</h5>
                            <ul>
                                <li>
                                    <a href='#'>About Us</a>
                                </li>
                                <li>
                                    <a href='#'>Careers</a>
                                </li>
                                <li>
                                    <a href='#'>Blog</a>
                                </li>
                                <li>
                                    <Link to='/contact'>Contact Us</Link>
                                </li>
                                <li class="neutral-bottom">
                                    <a href="affiliate-program.html">Affiliate</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                        <div class="footer__links footer__links--alt">
                            <h5>Invest</h5>
                            <ul>
                                <li>
                                    <Link to='/projects'>Browse Projects</Link>
                                </li>
                                <li>
                                    <a href='#'>How it works</a>
                                </li>
                                <li>
                                    <a href='#'>Loan Application </a>
                                </li>
                                <li>
                                    <a href='#'>Property Alerts</a>
                                </li>
                                <li class="neutral-bottom">
                                    <a href='#'>FAQs</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                        <div class="footer__links footer__links--alt--two">
                            <h5>Insights</h5>
                            <ul>
                                <li>
                                    <a href='#'>Help Center</a>
                                </li>
                                <li>
                                    <Link to='/list-your-project' >List Your Project</Link>
                                </li>
                                <li class="neutral-bottom">
                                    <a href='#'>Loyality program </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                        <div class="footer__links">
                            <h5>Legal</h5>
                            <ul>
                                <li>
                                    <a href='#'>Privacy Policy</a>
                                </li>
                                <li>
                                    <a href='#'>Term & Conditions</a>
                                </li>
                                <li>
                                    <a href='#'>Cookie Policy</a>
                                </li>
                                <li class="neutral-bottom">
                                    <a href='#'>Key Risks</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer__credit">
                <div class="row d-flex align-items-center">
                    <div class="col-sm-9 order-1 order-sm-0">
                        <div class="footer__copyright">
                            <p>Copyright &copy; Oaknet | Designed by <a
                                    href="http://oaknetbusiness.com">Oaknet</a></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="footer__animation">
            <img src="assets/images/footer/footer__left__circle.png" alt="Circle" class="left__circle" />
            <img src="assets/images/footer/footer__right__circle.png" alt="Circle" class="right__circle" />
            <img src="assets/images/footer/footer__home___illustration.png" alt="Home" class="home__illustration" />
        </div>
    </footer>
    </div>
  );
}

export default Footer;