import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FetchUserCompany } from "../../../../redux/actions/company/FetchUserCompany";
import Companies from '../banner';
import List from '.'
import { useNavigate } from 'react-router-dom';

function MyNewCompany() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const allCompanies = useSelector(state => state.userCompanies.company);
    const [search, setSearch] = useState('');

    const filteredData = () => {
        return allCompanies.filter(company => {
            //check if the company name contains the search text
            return company?.company_name.toLowerCase().includes(search.toLowerCase());
        })
    }
    const companies = filteredData() || allCompanies;
    useEffect(() => {
        dispatch(FetchUserCompany(navigate))
    }, [])


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <Companies />
            <div class="property__filter">
                <div class="container">
                    <div class="property__filter__area">
                        <div class="row d-flex align-items-center">
                            {companies.length > 0 && (<div class="col-lg-12 col-xl-11">
                                <div class="property__search__wrapper">
                                    <form>
                                        <div class="input m-auto" style={{ margin: "auto" }}>
                                            <input
                                                className='m-auto'
                                                type="search"
                                                name="property__search"
                                                id="propertySearch"
                                                placeholder="Search for company"
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                        </div>
                                        <button type="submit" class="button button--effect">Search</button>
                                    </form>
                                </div>
                            </div>)}

                        </div>
                    </div>
                </div>
            </div>
            <List allCompanies={companies} />
        </>
    );
}

export default MyNewCompany;
