 import { FORGOT_PASSWORD_FAILURE,FORGOT_PASSWORD_REQUEST,FORGOT_PASSWORD_SUCCESS } from "../../actions/auth/forgotPassword/forgotPassTypes";

 const initialState = {
    loading:false,
    error:"",
    data:{}
 }
    const forgotPassReducer = (state = initialState, action) => {
        switch (action.type) {
            case FORGOT_PASSWORD_REQUEST:
                return {
                    ...state,
                    loading:true,
                    error:""
                }
                break;
            case FORGOT_PASSWORD_SUCCESS:
                return {
                    ...state,
                    loading:false,
                    error:"",
                    data:action.payload
                }
                break;
            case FORGOT_PASSWORD_FAILURE:
                return {
                    ...state,
                    loading:false,
                    error:action.payload
                }
                break;
            default:
                return state;
                break;

    }
}
export default forgotPassReducer;