import { ADD_RISK_FAILURE, ADD_RISK_REQUEST, ADD_RISK_SUCCESS } from "../../actions/risks/riskTypes"

const initialState = {
    loading: false, 
    risk: {},
    error: ""
}

const addRiskReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_RISK_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADD_RISK_SUCCESS:
            return {
                ...state,
                risk: action.payload
            }
        case ADD_RISK_FAILURE:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state
    }
}

export default addRiskReducer