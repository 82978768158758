import { CREATE_PROJECTDOCUMENT_FAILURE, CREATE_PROJECTDOCUMENT_REQUEST, CREATE_PROJECTDOCUMENT_SUCCESS, FETCH_PROJECTDOCUMENT_FAILURE, FETCH_PROJECTDOCUMENT_REQUEST, FETCH_PROJECTDOCUMENT_SUCCESS } from "../../../actions/Documents/projectDocuments/DocumentsTypes";

const initialState={
    loading:false,
    error:"",
    documents:[],
}


 function fetchprojectDocuments(state=initialState,action){
    switch (action.type) {
        case FETCH_PROJECTDOCUMENT_REQUEST:
            return {
                loading:true,
                documents:[],
                error:""
            }
            
            break;
        case FETCH_PROJECTDOCUMENT_SUCCESS:
            return {
                loading:false,
                documents:action.payload,
                error:""
            }
            break;
        case FETCH_PROJECTDOCUMENT_FAILURE:
            return{
                loading:false,
                documents:[],
                error:action.payload
            }
            break;
    
        default:
            return state;
            break;
    }
}

export  default fetchprojectDocuments;