import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import DoughnutChart from './Donught';
import FinancialDetails from "../Financial"
import ProjectedChart from '../../../components/Charts/ComposedChart';
import Interested from './Interested';
import Investors from '../../../components/Charts/ProjectInvestors';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function FinanceAnalytics({financing, analytics}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <DoughnutChart analytics={analytics?.raised_vs_remaining}/>
        </Grid>
        <Grid item xs={12} sm={12} md={6}> 
         <FinancialDetails financing={financing} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Investors data={analytics?.monthly_investments}/>
        </Grid>

        
      </Grid>
    </Box>
  );
}
