import { Formik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { BsEyeFill, BsThreeDotsVertical } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, Form, Input, Label, Modal, Row, Spinner, Tooltip } from 'reactstrap'
import fetchdocumentstypes from '../../../redux/actions/Documents/documentTypes/fetchdocumentstypes'
import fetchprojectDocumentAction from '../../../redux/actions/Documents/projectDocuments/FetchDocuments'
import { postprojectdocuments } from '../../../redux/actions/Documents/projectDocuments/Postdocuments'
import { getToken } from '../../../services/UseTokens'
import myimg from './image/image/image.png'
import avif from "./image/image/avif.webp"
import jpgimg from "./image/image/jpg.png"
import webpimg from "./image/image/webp.png"
import pngimg from "./image/image/png.png"
import DeleteProjectDocument from './DeleteProjectDocument'
import ViewSingle from './ViewSingle'
import ViewDocument from './ViewDocument'
import { AiOutlineFileJpg, AiOutlineFilePdf, AiOutlineMore } from 'react-icons/ai'
import UpdateDocument from './UpdateDocument'

export default function DisplayProjectDoc({ id }) {
  const [tooltipOpen2, setTooltipOpen2] = useState(false)
  const [loading, setLoading] = useState(false)
  const [saveData, setSaveData] = useState([])
  const [successResponse, setSuccessResponse] = useState()
  const [serverError, setServerError] = useState()
  const [image, setImage] = useState(null)
  const [doc, setDoc] = useState()
  const [modal_center, setmodal_center] = useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [modal, setModal] = useState(false)
  const [editModal,setEditModal]=useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [newDoc, setNewDoc] = useState([])
  const [deleted, setDeleted] = useState([])
  const [deletedDocument, setDeletedDocument] = useState([])
  const testArr = []

  const dispatch = useDispatch()
  const token = getToken()
  const documents = useSelector(state => state.documenttype.documents);
  const createdprojectDocuments = useSelector(state => state.allprojectdocuments.documents)
  const isLoading = useSelector(state => state.allprojectdocuments.loading)
  const response = useSelector(state => state.projectdocumentss.documents)
  const projectDocuments = _.filter(documents, { isProject: 1 })


  function toggle2() {
    if (tooltipOpen2 === false) {
      setTooltipOpen2(true)
    } else {
      setTooltipOpen2(false)
    }
  }

  function tog_center2() {
    if (modal == false) {
      setModal(true)
    } else {
      setModal(false)
    }
  }
  function tog_center() {
    if (modal_center == false) {
      setmodal_center(true)
    } else {
      setmodal_center(false)
    }
  }
  function EditToggle() {
    if (editModal == false) {
      setEditModal(true)
      console.log("true")
    } else {
      setEditModal(false)
    }
  }
  

  const newcreatedprojectDocuments = _.filter(createdprojectDocuments, { project_id: parseInt(id) })
  const b = projectDocuments && projectDocuments.filter(function (o1) {
    return !newcreatedprojectDocuments.some(function (o2) {
      return o1.id === o2.document_id;
    })
  })

  const handleActualChange = (docid, e, index) => {
    setImage(e.target.files[0])
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      let temp = [...saveData]
      temp[index][e.target.name] = reader.result
      temp[index]["document_id"] = docid
      temp[index]["project_id"] = id
      setSaveData(temp)
    }
  }

  // const handleSubmitbb = (e, saveData, token) => {
  //   e.preventDefault()
  //   setLoading(true)
  //   dispatch(postprojectdocuments(saveData, token))
  //     .then(() => {


  //       setSuccessResponse(response.data.message)
  //       setLoading(false)
  //       var index = b.findIndex(function (o) {
  //         return o.id === response.data.document_id;
  //       })
  //       b.splice(index, 1)
  //       return b;
  //     })
  // }

  const submitDocuments = () => {
    dispatch(postprojectdocuments(saveData, token))
  }
  const handleDelete = (childData) => {
    let arr = []
    arr.push(childData)
    setDeleted(arr)
    var index = newcreatedprojectDocuments.findIndex(function (o) {
      return o.id === childData
    })

    newcreatedprojectDocuments.splice(index, 1)
    setDeletedDocument(newcreatedprojectDocuments)
    return deletedDocument;
  }

  const appendDocument = (index) => {
    //if object does not exist in array then add it
    if (!saveData.includes(index)) {
      setSaveData((prevState) => [...prevState, {document: "", document_id: "", project_id: id}])
    }
    //append at a particular index
    let temp = [...saveData]
    temp.splice(index, 0, {
      document: "",
      document_id: "",
      project_id: ""
    })
  }

  console.log('saveData', newcreatedprojectDocuments )

  useEffect(() => {
    dispatch(fetchdocumentstypes(token))
    
    dispatch(fetchprojectDocumentAction(token))
  }, [])

  return (
    <div>
      <Row>

        <Col md={12} lg={12} sm={12} xs={12}>
          {b && b.length > 0 && (
            <Card className="p-2" >
              <Formik
                initialValues={{
                  "document": "",
                  "document_id": "",
                  "project_id": "",
                }}
                onSubmit={(values, { resetForm }) => {
                  //   PostProjectOwnerDocument()
                  dispatch(postprojectdocuments(saveData, token))
                  resetForm({ values: "" })
                }}
              >
                {({ values, handleSubmit, handleChange }) => (

                  <Form>
                    {serverError && (
                      <div style={{ color: "red", fontSize: "15px" }}>{serverError && (
                        <div>{serverError}</div>
                      )}
                      </div>
                    )}

                    {successResponse && (
                      <div
                        style={{ color: "green", fontSize: "15px", height: "30px", width: "auto", right: "0" }}>
                        {successResponse}
                      </div>

                    )}

                    <CardHeader className='position-relative'>
                      <h5 className="card-title mb-0">Upload Documents below</h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        {b.length > 0 && b.map((projectdoc, index) => (
                          <Col xl={6} md={6} lg={6} sm={12} xs={12} xxl={4} key={index}>
                            <div className=" mb-3">
                              <Label className="ValidationInput text-capitalize fs-15 " for="validationFormCheck3">{projectdoc.name}</Label>
                              <Input type="file"
                                className="form-control"
                                id="name"
                                name="document"
                                placeholder='Enter document '
                                values={image || " "}
                                onFocus={() => appendDocument(index)}
                                onChange={(e) => {
                                  handleActualChange(projectdoc.id, e, index)
                                }}
                                required />

                              <div className="invalid-feedback">upload file</div>
                            </div>

                          </Col>
                        )

                        )}

                      </Row>
                      {isLoading === false && (
                        <div className="text-right end-0 m-2">
                          <div className="m-1">
                            <button type="button" onClick={() =>
                              submitDocuments()} className="button button--input">Submit</button>
                          </div>
                        </div>
                      )}
                      {/* {isLoading ===true &&(
                           <div className="text-right position-absolute  end-0 m-2">
                           <div className="m-1 position-relative">
                             <Button color="primary" className="btn-load" outline disabled>
                               <span className="d-flex align-items-center">
                                 <span className="flex-grow-1 me-2">
                                   Loading...
                                 </span>
                                 <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                               </span>
                             </Button>
                           </div>
                         </div>
                      )} */}

                    </CardBody>

                  </Form>
                )}
              </Formik>
            </Card>
          )}
        </Col>
      </Row>
      {newcreatedprojectDocuments.length > 0 && (
        <div class="  group alt__brin  card m-2 p-4 " style={{ boxShadow: "0px 4px 24px 0px rgb(19 33 110 / 25%)" }}>
          <div className='position-relative d-flex'>
            <h5>Project Documents <i class="fa-solid fa-bell"></i></h5>



          </div>
          <hr />

          <div class="singl__wrapper row">


            <div class="">
              <div class="">
                <h5>My Documents</h5>
              </div>

              <div class="table-wrapper row">
                {

                  deleted.length > 0 && deletedDocument &&
                  deletedDocument.map((projectdoc, index) => (
                    <div className="col-6 mb-1 mt-1 card shadow  mr-1 d-flex flex-row   justify-content-between " key={index}>
                      {projectdoc.documents.type === "pdf" && (
                        <div><AiOutlineFilePdf style={{ height: "4em", width: "4em", color: "#b33234" }} /></div>
                      )}
                      {projectdoc.documents.type === "jpg" && (
                        <div><AiOutlineFileJpg style={{ height: "100px", width: "100px", color: "" }} /></div>
                      )}
                      {projectdoc.documents.type === "jpeg" && (
                        <div><img src={myimg} alt="jpeg" style={{ height: "30%", width: "30%" }} /></div>
                      )}
                      {projectdoc.documents.type === "png" && (
                        <div><img src={pngimg} alt="jpeg" style={{ height: "30%", width: "30%" }} /></div>
                      )}
                      {projectdoc.documents.type === "avif" && (
                        <div><img src={avif} alt="avif" style={{ height: "30%", width: "30%" }} /></div>
                      )}
                      {projectdoc.documents.type === "webp" && (
                        <div><img src={webpimg} alt="webp" style={{ height: "30%", width: "30%" }} /></div>
                      )}
                      {/* <div className=' position-relative mb-4 ' style={{backgroundColor:"gray"} }> */}
                      <div style={{ maxWidth: "50%" }}><p class="  text-dark fw-bold text-center  text-muted m-auto" style={{ fontsize: "1.2em" }}>{projectdoc.documents.name}</p></div>
                      {/* <div class="position-absolute end-0">ree</div> */}
                      <div class=" end-0  " style={{ marginRight: "35px" }}>
                        <Dropdown className="text-center rounded-circle bg-light text-dark" isOpen={dropdownOpen} toggle={toggle} style={{ zIndex: 5, marginBottom: "25px" }}>

                          <a href="" caret><BsThreeDotsVertical className='text-center  try' style={{ height: "1.2em", width: "1.2em" }} /></a>
                          <DropdownMenu>
                            {/* <DropdownItem onClick={() => {
                              tog_center2()
                              setDoc(projectdoc.id)

                            }}><i className="ri-eye-fill  align-middle text-muted" />View</DropdownItem>
                            { }
                            <Modal
                              isOpen={modal}
                              toggle={() => {
                                tog_center2();
                              }}
                              centered
                            >
                              {
                                projectdoc.documents.type === "pdf" ?
                                  <ViewSingle handleClose={tog_center2} documentId={doc} /> 
                                  :
                                  <ViewDocument handleClose={tog_center2} documentId={doc} />
                              }

                            </Modal> */}

                            <DropdownItem onClick={()=>EditToggle()}>Edit</DropdownItem>
                            <Modal
                              isOpen={editModal}
                              toggle={() => {
                                EditToggle();
                              }}
                              centered
                            >
                              <UpdateDocument/>
                            </Modal>


                            <DropdownItem className='remove-item-btn' onClick={() => {
                              tog_center()
                              setDoc(projectdoc.id)
                            }}
                            >
                              <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
                            <Modal
                              isOpen={modal_center}
                              toggle={() => {
                                tog_center();
                              }}
                              centered
                            >
                              <DeleteProjectDocument handleClose={tog_center} documentId={doc} handleDelete={handleDelete} />
                            </Modal>
                          </DropdownMenu>
                        </Dropdown>

                      </div>
                    </div>
                    // </div>
                  ))
                }

                {

                  deleted.length === 0 && newcreatedprojectDocuments &&
                  newcreatedprojectDocuments.map((projectdoc, index) => (
                    <div className="col-6 mb-1 mt-1 card shadow  mr-1 d-flex flex-row   justify-content-between " key={index}>
                      {projectdoc.documents.type === "pdf" && (
                        <div><AiOutlineFilePdf style={{ height: "4em", width: "4em", color: "#b33234" }} /></div>
                      )}
                      {projectdoc.documents.type === "jpg" && (
                        <div><AiOutlineFileJpg style={{ height: "100px", width: "100px", color: "" }} /></div>
                      )}
                      {projectdoc.documents.type === "jpeg" && (
                        <div><img src={myimg} alt="jpeg" style={{ height: "30%", width: "30%" }} /></div>
                      )}
                      {projectdoc.documents.type === "png" && (
                        <div><img src={pngimg} alt="jpeg" style={{ height: "30%", width: "30%" }} /></div>
                      )}
                      {projectdoc.documents.type === "avif" && (
                        <div><img src={avif} alt="avif" style={{ height: "30%", width: "30%" }} /></div>
                      )}
                      {projectdoc.documents.type === "webp" && (
                        <div><img src={webpimg} alt="webp" style={{ height: "30%", width: "30%" }} /></div>
                      )}
                      {/* <div className=' position-relative mb-4 ' style={{backgroundColor:"gray"} }> */}
                      <div style={{ maxWidth: "50%" }}><p class="  text-dark fw-bold text-center  text-muted m-auto" style={{ fontsize: "1.2em" }}>{projectdoc.documents.name}</p></div>
                      {/* <div class="position-absolute end-0">ree</div> */}
                      <div class=" end-0  " style={{ marginRight: "35px" }}>
                        <Dropdown className="text-center rounded-circle bg-light text-dark" isOpen={dropdownOpen} toggle={toggle} style={{ zIndex: 15000, marginBottom: "25px" }}>

                          <a href="" caret><BsThreeDotsVertical className='text-center  try' style={{ height: "1.2em", width: "1.2em" }} /></a>
                          <DropdownMenu>
                            {/* <DropdownItem onClick={(e)=>{
                              tog_center2(e)
                              setDoc(projectdoc.id)

                            }}><i className="ri-eye-fill  align-middle text-muted" />View</DropdownItem>
                            { }
                            <Modal
                              isOpen={modal}
                              toggle={() => {
                                tog_center2();
                              }}
                              centered
                            >
                             {
                                projectdoc.documents.type === "pdf" ?
                                  <ViewSingle handleClose={tog_center2} documentId={doc} />
                                    :
                                  <ViewDocument handleClose={tog_center2} documentId={doc} />
                             }

                            </Modal> */}
                            <DropdownItem className='remove-item-btn' 
                            onClick={()=>EditToggle()}> <i className="ri-edit-2-fill align-bottom me-2 text-muted"></i>Edit</DropdownItem>
                            <Modal
                              isOpen={editModal}
                              toggle={() => {
                                EditToggle();
                                setDoc(projectdoc.id)
                              }}
                              centered
                            >
                              <UpdateDocument id={doc} projectDoc={projectdoc}/>
                            </Modal>
                            <DropdownItem className='remove-item-btn' onClick={() => {
                              tog_center()
                              setDoc(projectdoc.id)
                            }}
                            >
                              <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
                            <Modal
                              isOpen={modal_center}
                              toggle={() => {
                                tog_center();
                              }}
                              centered
                            >
                              <DeleteProjectDocument handleClose={tog_center} documentId={doc} handleDelete={handleDelete} />
                            </Modal>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>

                  ))
                }
              </div>
            </div>


          </div>
        </div>
      )}
    </div>

  )
}
