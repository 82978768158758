import React from 'react'

export default function Statistics() {
  return (
    <div>
        <div class="investment-sidebar">
                                        <div class="statistics">
                                            <h5>Statistics</h5>
                                            <hr />
                                            <div class="group">
                                                <img src="assets/images/icons/wallet.png" alt="Wallet" />
                                                <div>
                                                    <h4>€537,00</h4>
                                                    <p>Monthly Income</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="explore">
                                            <img src="assets/images/icons/explore.png" alt="Explore" />
                                            <div class="group">
                                                <h6>Explore More</h6>
                                                <p class="secondary">Investment opportunities</p>
                                                <a href="registration.html"
                                                    class="button button--effect">Explore</a>
                                            </div>
                                        </div>
                                    </div>
    </div>
  )
}
