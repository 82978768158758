import React, { useEffect } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Label, Spinner } from 'reactstrap'
import { Form, Formik } from "formik"
import { useState } from 'react'
import Select from 'react-select'
import { requests } from '../../services/Api'
import { getToken } from '../../services/UseTokens'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import updateDirectorAct from '../../redux/actions/company/companyDirectors/UpdateDirectors'


export default function UpdateDirectors(props) {
    console.log("props",props)
    const [documentId, setDocumentId] = useState("")
    const [identityDocumentType,setIdentityDocumentType]=useState()
    const [documents, setDocuments] = useState([])
    const [loading, setLoading] = useState(false)
    const [companies, setCompanies] = useState([])
    const [image,setImage]=useState(null)  
    const [successResponse, setSuccessResponse] = useState()
    const [serverError, setServerError] = useState("")
    const user = localStorage.getItem("authuser")
    const token = getToken()
    const dispatch=useDispatch()
    // Modal open state
    const [modal, setModal] = React.useState(false);

    // Toggle for Modal
    const toggle = () => setModal(!modal);

    const options=[
        { value: "national_id_card", label: "National Identity Card" },
        {value:"passport",label: "Passport"}
    ]    


    const PostProjectDocument = async (values) => {
        const data = {
            company_id: props.director.company_id,
            first_name:values.first_name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            citizenship:values.citizenship,
            identification_document_type:values.identification_document_type,
            identification_number:values.identification_number,
            identification_document:values.identification_document,
        }
     
        // ParentCallback(data.company_id)
        try {
            setLoading(true);
           dispatch(updateDirectorAct(props.director.id,data,token))
            
            setLoading(false);
         
            
        } catch (error) {
            setLoading(false);
           
        }
    }



    return (
        <div>
            <Card  >
                <Formik
                    initialValues={props.director||{
                        "company_id": "",
                        "first_name": "",
                        "middle_name": "",
                        "last_name": "",
                        "citizenship":"",
                        "identification_document_type":"",
                        "identification_number":"",
                        "identification_document":"",
                    }}

                    onSubmit={(values, { resetForm }) => {
                        values["identification_document_type"] = identityDocumentType
                        values["company_id"] = props.companyId
                        PostProjectDocument(values)
                        resetForm({ values: "" })
                        console.log("submit values", values)
                    }}
                    enableReinitialize
                >
                    {({ values, isSubmitting, errors, handleSubmit, handleChange, dirty, isValid }) => (

                        <Form className="was-validated col-md-12 col-lg-12">
                            <CardHeader className='position-relative text-capitalize text-center'>
                                <strong> Update director `${props.director.first_name}`</strong>
                            </CardHeader>
                            <CardBody>
                                {serverError && (
                                    <div style={{ color: "red", fontSize: "15px" }}>{serverError && (
                                        <div>{serverError}</div>
                                    )}
                                    </div>
                                )}

                                {successResponse && (
                                    <div
                                        style={{ color: "green", fontSize: "15px", height: "30px", width: "auto", right: "0" }}>
                                        {successResponse}
                                    </div>

                                )}
                            <div className='row'>
                                <div className=" mb-3  col-xlg-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">first name</Label>
                                    <Input type="text"
                                        className="form-control"
                                        id="first_name"
                                        name="first_name"
                                        placeholder='Enter first name'
                                        value={values.first_name}
                                        onChange={handleChange}
                                        required />

                                    <div className="invalid-feedback">Enter first name</div>
                                </div>
                                <div className=" mb-3  col-xlg-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-md-">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">middle Name</Label>
                                    <Input type="text"
                                        className="form-control"
                                        id="middle_name"
                                        name="middle_name"
                                        placeholder='Enter middle name'
                                        value={values.middle_name}
                                        onChange={handleChange}
                                        required />

                                    <div className="invalid-feedback">Enter middle name</div>
                                </div>
                                <div className=" mb-3  col-xlg-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">last Name</Label>
                                    <Input type="text"
                                        className="form-control"
                                        id="last_name"
                                        name="last_name"
                                        placeholder='Enter last name'
                                        value={values.last_name}
                                        onChange={handleChange}
                                        required />

                                    <div className="invalid-feedback">Enter last name</div>
                                </div>
                                <div className=" mb-3  col xlg-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">citizenship</Label>
                                    <Input type="text"
                                        className="form-control"
                                        id="citizenship"
                                        name="citizenship"
                                        placeholder='Enter citizenship'
                                        value={values.citizenship}
                                        onChange={handleChange}
                                        required />

                                    <div className="invalid-feedback">Enter your citizenship</div>
                                </div>
                                <div className="mb-3  col-xlg-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <Label className="form-label text-capitalize " htmlFor="project-title-input">Identification Document Type </Label>
                                    <Select onChange={(e) => setIdentityDocumentType(e.value)} options={options} />
                                    <div className="invalid-feedback">select identification document type</div>
                                </div>
                                <div className=" mb-3  col xlg-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">document Number</Label>
                                    <Input type="number"
                                        className="form-control"
                                        id="identification_number"
                                        name="identification_number"
                                        placeholder='Enter document number'
                                        value={values.identification_number}
                                        onChange={handleChange}
                                        required />

                                    <div className="invalid-feedback">Enter document number</div>
                                </div>
                                <div className=" mb-3  col-xlg-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">identification document </Label>
                                    <Input type="file"
                                        className="form-control"
                                        id="identification_document"
                                        name="identification_document"
                                        placeholder='select identification document'
                                        value={values.identification_document}
                                        onChange={handleChange}
                                        required />

                                    <div className="invalid-feedback">choose a document</div>
                                </div>
                                
                            </div>

                            </CardBody>
                            {!loading && (
                                <CardFooter class="text-right position-absolute  end-0">
                                    <div className='position-relative'>

                                        <button className="btn btn-success m-1" type="submit" onClick={(e)=>{PostProjectDocument()}}>Submit form</button>
                                    </div>

                                </CardFooter>
                            )}

                            {
                                loading && (
                                    <CardFooter class="text-right position-absolute  end-0">
                                        <div className="m-1 position-relative">
                                            <Button color="success" className="btn-load" outline disabled>
                                                <span className="d-flex align-items-center">
                                                    <span className="flex-grow-1 me-2">
                                                        Loading...
                                                    </span>
                                                    <Spinner size="sm" type="grow" className="flex-shrink-0" role="status" disabled> Loading... </Spinner>
                                                </span>
                                            </Button>
                                        </div>
                                    </CardFooter>

                                )
                            }
                        </Form>
                    )}
                </Formik>
            </Card>
        </div>
    )
}
