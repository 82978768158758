import React, { useState } from 'react'
// import CreateCompany from '../Company/CreateCompany';
// import CreateProject from '../ListProperty/CreateProject'
import SideBar from './SideBar'
import SideBar2 from './SideBar2';

export default function CreateCompanyL() {
    const [sidebarIsOpen, setSidebarOpen] = useState(true);
    const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
     const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);

  return (
    <div>
        <div class="dashboard section__space__bottom">
        <div class="container"></div>
     <div class="dashboard__area">
                <div class="row">
                    <div class="col-xxl-3">
                        <div class="sidebar">
                            
                           <SideBar />
                           
                        </div>
                    </div>
                    <div class="col-xxl-9">
                        <div class="main__content">
                            <div class="collapse__sidebar">
                                <h4>Dashboard</h4>
                                <a href="javascript:void(0)" class="collapse__sidebar__btn">
                                    <i class="fa-solid fa-bars-staggered"></i>
                                </a>
                            </div>
                            <div class="main__content-dashboard">
                                <div class="row">
                                  {/* <CreateCompany/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
    
  )
}
