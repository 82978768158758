import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import AvatarGroup from '@mui/material/AvatarGroup'
import image from "../../../assets/images/companyon.jpg"
import { Badge, Grid } from '@mui/material'
import image1 from "../../../assets/avatars/1.png"
import { Link } from 'react-router-dom'


const Companycard = ({company}) => {
  return (
    <div>
    <Card sx={{ position: 'relative' }}>
      <CardMedia sx={{ height: '12.625rem' }} image={image} />
      <Avatar
        alt={company?.name}
        src='/images/avatars/1.png'
        sx={{
          width: 75,
          height: 75,
          left: '1.313rem',
          top: '10.28125rem',
          position: 'absolute',
          border: theme => `0.25rem solid ${theme.palette.common.white}`
        }}
      />
      <CardContent>
        <Box
          sx={{
            mt: 2.75,
            mb: 4.75,
            
          }}
        >
        
         <Grid container spacing={2}>
          <Grid item xs={8} md={9}>
          {/* <Box sx={{ mr: 2, mb: 1, display: 'flex', flexDirection: 'column' }}> */}
            <Typography variant='h6' sx={{mx:1}} style={{display:"flex",justifyContent:"flex-start",fontSize:"24px",color:"#5927e3", fontWeigth:450}}>{company?.company_name}</Typography>
            <Typography variant='caption' sx={{mx:1}} style={{display:"flex",justifyContent:"flex-start",fontSize:"18px"}}>{company?.company_location}</Typography>
            <Typography variant='caption' sx={{mx:1}} style={{display:"flex",justifyContent:"flex-start",fontSize:"18px"}}>{company?.company_email}</Typography>
           
          {/* </Box> */}
          </Grid>
          <Grid item xs={4} md={3} style={{display:"flex",justifyContent:"flex-end"}}>
          <Badge badgeContent={company?.status}  sx={{marginX:"15px",fontSize:"20px"}} color={company?.status === 'inactive' ? "error" : "success"}></Badge>

            </Grid>

         </Grid>
         
        </Box>
        
        <Box sx={{ gap: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}   sm={6} style={{display:"flex",justifyContent:"flex-start"}}>
              <Typography variant='subtitle2' sx={{ whiteSpace: 'nowrap', color: 'text.primary',fontSize:"22px" }}>
           {`${company?.directors.length} directors`}
          </Typography>
              </Grid>

              <Grid item xs={6}   sm={6} style={{display:"flex",justifyContent:"flex-end"}}>
              <AvatarGroup max={4}>
            {
              company?.directors.map((director, index) => (
                <Avatar src={image1} alt={`${director?.name}`} />
              ))
            }
          
          </AvatarGroup>
                </Grid>
            </Grid>
      
        </Box>
        <Box style={{display:"flex",justifyContent:"flex-end",color:"#5927e3",marginTop:"10px"}}>
          <Link to={`/company-profile/${company?.system_company_number}`}> <Button variant="outlined" >View</Button></Link>
        </Box>
      </CardContent>
    </Card>
    </div>
  )
}

export default Companycard

