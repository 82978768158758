import {
  Alert,
  Box,
  Button,
  createTheme,
  Grid,
  Paper,
  Stack,
  styled,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { requests } from "../../services/Api";
import { getToken } from "../../services/UseTokens";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#5927e3",
    },
  },
});
const StyledButton = styled(Button)`
  ${({ theme }) => `
cursor: pointer;
transition: ${theme.transitions.create(["transform"], {
    duration: theme.transitions.duration.standard,
  })};
&:hover {
color:"fff";
background-color:#8d6bec;
transform: scale(1.1);
width:300px !important;
}
`}
`;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Director() {
  //fetch directors from localstorage
  const directors = JSON.parse(localStorage.getItem("brs-company"))?.directors;
  const company = JSON.parse(localStorage.getItem("brs-company"));
  const token = getToken();
  const [savedDirectors, setSavedDirectors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [directorId, setDirectorId] = useState("");
  const [directorName, setDirectorName] = useState("");
  const [directorEmail, setDirectorEmail] = useState("");
  const [directorPhone, setDirectorPhone] = useState("");
  const [isDirector, setIsDirector] = useState(false);

  const inviteDirector = async (name, id) => {
    setIsLoading(true);
    await requests
      .post(
        "/director-invites",
        {
          director_id: id,
          name: name,
          email: directorEmail,
          phone_number: directorPhone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const { data } = response;
        setIsLoading(false);
        setDirectorId("");
        setDirectorName("");
        setDirectorEmail("");
        setDirectorPhone("");
        setSavedDirectors((prevState) => [...prevState, id]);
        Swal.fire({
          icon: "success",
          title: `Director ${data.name} successfully invited`,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showConfirmButton: true,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error getting inviting director",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //check if director has been invited
  const isInvited = (index) => {
    const isSaved = savedDirectors.includes(index);
    if (isSaved) {
      return true;
    }
    return false;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Stack spacing={2}>
        <Item>
          <Typography variant="h4" gutterBottom>
            <Alert severity="info">
              This section is for inviting directors of your company to verify
              they accept the company being registered on the platform. If your
              company has more than one director, you need at least two
              directors to verify the company to activate the company account.
              If you are the only director, you can verify the company yourself.
            </Alert>
          </Typography>
        </Item>
        <Item>
          <span>
            <span>Company Name: </span>
            {company?.company_name}
          </span>
          <br />
          <span>
            <span>Company Number: </span>
            {company?.company_no}
          </span>
        </Item>
      </Stack>
      <Grid container spacing={2} sx={{ margin: "2px" }}>
        {directors &&
          directors.filter((director) => director.id) &&
          directors.map((director, index) => (
            <Grid item xs={4} key={index}>
              <Item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <TextField
                  variant="outlined"
                  label="Name"
                  size="small"
                  sx={{ m: 1 }}
                  value={director?.name}
                  onChange={(e) => setDirectorName(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  label="id Type"
                  size="small"
                  sx={{ m: 1 }}
                  value={director?.identification_document_type}
                />
                <TextField
                  variant="outlined"
                  label="id No"
                  size="small"
                  sx={{ m: 1 }}
                  value={director.identification_number}
                />
                <TextField
                  variant="outlined"
                  label="email"
                  size="small"
                  sx={{ m: 1 }}
                  onChange={(e) => setDirectorEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  label="PhoneNumber"
                  size="small"
                  sx={{ m: 1 }}
                  onChange={(e) => setDirectorPhone(e.target.value)}
                />
                {
                  //check if director has been invited
                  isInvited(director.id) ? (
                    <StyledButton
                      variant="outlined"
                      size="small"
                      sx={{ m: 1 }}
                      disabled
                    >
                      Director Saved
                    </StyledButton>
                  ) : (
                    <StyledButton
                      variant="outlined"
                      size="small"
                      sx={{ m: 1 }}
                      onClick={() =>
                        inviteDirector(director?.name, director?.id)
                      }
                    >
                      Submit
                    </StyledButton>
                  )
                }
              </Item>
            </Grid>
          ))}
      </Grid>
      <ThemeProvider theme={customTheme}></ThemeProvider>
    </Box>
  );
}

export default Director;
