import React from 'react'
import { POST_COMPANY_SEARCH_REQUEST,POST_COMPANY_SEARCH_SUCCESS,POST_COMPANY_SEARCH_FAILURE } from '../../actions/companySearch/CompanySearchTypes'

const initialState = {
    loading: false,
    company:{},
    error:""
}

export default function companySearchreducer(state=initialState, action) {
    switch (action.type) {
        case POST_COMPANY_SEARCH_FAILURE:
            return{
                ...state,
                error: action.payload
            }
            
            break;
        case POST_COMPANY_SEARCH_SUCCESS:
            return{
                ...state,
                company: action.payload
            }
            break;
        case POST_COMPANY_SEARCH_REQUEST:
            return{
                ...state,
                loading:true
            }
            break;
        default:
            return state;
            break;
    }
  
}
