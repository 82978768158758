import Swal from "sweetalert2";
import { requests } from "../../../services/Api";
import { UPDATE_REQUEST,UPDATE_SUCCESS,UPDATE_FAILURE } from "./MilestoneType";

const updateMilestoneAct =(id,milestone,token)=>async(dispatch)=>{
    console.log("iddddddddd act",id)
try {
    dispatch({type: UPDATE_REQUEST})
    const response = await requests.put(`projects/update-project-milestone/${id}`, milestone, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
    dispatch({type: UPDATE_SUCCESS,
    payload:response.data})
    Swal.fire({
        icon:"success",
        title:"success",
        text:"milestone updated successfully",
        confirmButtonText:"Ok"
    })

    
} catch (error) {
    dispatch({type: UPDATE_FAILURE,
    payload:error.response.message})
    Swal.fire({
        icon:"error",
        title:"Error",
        text:error.response.message,
        confirmButtonText:"Ok"
    })

    
}
}
export default updateMilestoneAct