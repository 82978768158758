import { Formik } from 'formik'
import React, { useState } from 'react'
import { Button, CardBody, CardFooter, CardHeader, Form, Input, Label, Spinner } from 'reactstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch } from 'react-redux';
import { postMilestone } from '../../redux/actions/milestones/PostMilestone';

export default function AddMilestone(props) {
    const dispatch = useDispatch()
    console.log("props", props)
    const [loading, setLoading] = useState(false)
    const [serverError, setServerError] = useState(false)
    const [successResponse, setSuccessResponse] = useState(false)
    const [description, setDescription] = useState("")
    const [title, setTitle] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [startDate, setStartDate] = useState("")
    const [data, setData] = useState("")


    const createMilestone = async () => {
        const formData = {
            project_id: props.projectId,
            milestone_name: title,
            milestone_description: description,
            milestone_start_date: startDate,
            milestone_end_date: dueDate,

        }

        setData(formData)
        dispatch(postMilestone(formData))
        alert("Milestone Created Successfully")
        props.getData(formData)
        console.log("submited Data", data)
    }
    return (
        <div className='card'>
            <Formik
                initialValues={{
                    "milestone_name": "",
                    "milestone_start_date": "",
                    "milestone_end_date": "",
                    "project_id": "",
                }}

                onSubmit={(values, { resetForm }) => {

                    values["project_id"] = props.id
                    values["milestone_description"] = description
                    // PostProjectDocument(values)
                    resetForm({ values: "" })
                    console.log("submit values", values)
                }}
            >
                {({ values, isSubmitting, errors, handleSubmit, handleChange, dirty, isValid }) => (


                    <Form className="was-validated">

                        <CardBody>
                            {serverError && (
                                <div style={{ color: "red", fontSize: "15px" }}>{serverError && (
                                    <div>{serverError}</div>
                                )}
                                </div>
                            )}

                            {successResponse && (
                                <div
                                    style={{ color: "green", fontSize: "15px", height: "30px", width: "auto", right: "0" }}>
                                    {successResponse}
                                </div>

                            )}
                            <div className="row">
                                <div className=" mb-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput txt-capitalize" for="validationFormCheck3">milestone Name</Label>
                                    <Input type="text"
                                        className="form-control"
                                        id="milestone_name"
                                        name="milestone_name"
                                        placeholder='Enter milestone name'
                                        values={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required />

                                    <div className="invalid-feedback">Enter milestone name</div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className=" mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">milestone Name</Label>
                                    <Input type="date"
                                        className="form-control"
                                        id="milestone_start_date"
                                        name="milestone_start_date"
                                        placeholder='Enter milestone start date'
                                        values={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        required />

                                    <div className="invalid-feedback">Enter milestone  start date</div>
                                </div>
                                <div className=" mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">milestone end date</Label>
                                    <Input type="date"
                                        className="form-control"
                                        id="date"
                                        name="milestone_end_date"
                                        placeholder='Enter milestone end date'
                                        values={dueDate}
                                        onChange={(e) => {
                                            setDueDate(e.target.value)
                                            console.log("due date", dueDate)
                                        }}
                                        required />

                                    <div className="invalid-feedback">select milestone closing date</div>
                                </div>
                            </div>
                            <div className=" mb-3 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Label className="ValidationInput text-capitalize" for="validationFormCheck3">milestone description</Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data=""
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setDescription(data)
                                        console.log({ event, editor, data });
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}
                                />
                                <div className="invalid-feedback">Enter milestone description</div>
                            </div>

                        </CardBody>
                        {!loading && (
                            <CardFooter className="text-right position-absolute  end-0">
                                <div className='position-relative'>

                                    <button className="btn btn-success m-1" type="button" onClick={(e) => { createMilestone(data) }}>Submit form</button>
                                </div>

                            </CardFooter>
                        )}

                        {
                            loading && (
                                <CardFooter className="text-right position-absolute  end-0">
                                    <div className="m-1 position-relative">
                                        <Button color="success" className="btn-load" outline disabled>
                                            <span className="d-flex align-items-center">
                                                <span className="flex-grow-1 me-2">
                                                    Loading...
                                                </span>
                                                <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                                            </span>
                                        </Button>
                                    </div>
                                </CardFooter>

                            )
                        }
                    </Form>
                )}
            </Formik>
        </div>
    )
}
