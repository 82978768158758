import Swal from "sweetalert2";
import { requests } from "../../../../services/Api";
import { getToken } from "../../../../services/UseTokens";
import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE } from "./forgotPassTypes";

const ForgotPasswordAct = (data) => async (dispatch) => {
    const token = getToken()

    try {
        dispatch({ type: FORGOT_PASSWORD_REQUEST })
        const response = await requests.post("auth/password/email", data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        

        dispatch({ 
            type: FORGOT_PASSWORD_SUCCESS, payload: response.data 
        })
        
        Swal.fire({
            title: 'Success',
            text: 'Check your email for reset link',
            icon: 'success',
            confirmButtonText: 'Ok'
        })
        

    } catch (error) {
        Swal.fire(
            "error",
            `${error.response.data.message}`,
            "ok"
        )
        dispatch({
            type: FORGOT_PASSWORD_FAILURE,
            payload: error.response.data.message
        })
    }
}
export default ForgotPasswordAct;