import { requests } from "../../../services/Api";
import { SELLSHARES_FAILURE, SELLSHARES_REQUEST, SELLSHARES_SUCCESS } from "./sellSharesTypes";
import Swal from 'sweetalert2'
import { getToken } from "../../../services/UseTokens";

export const sellShares = (data) => async (dispatch) => {
    console.log("data",data)
   const token=getToken()
    dispatch({ 
        type: SELLSHARES_REQUEST 
    });
    await requests.post(`/shares/sell`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }).then((response) => {
        dispatch({ 
            type: SELLSHARES_SUCCESS, 
            payload: response.data 
        });
        Swal.fire(
            'Good job!',
            'Shares Ssold Successfully',
            'success'
        )
    }).catch((error) => {
        dispatch({ 
            type: SELLSHARES_FAILURE, 
            payload: error.response.data.message 
        });
    })
}