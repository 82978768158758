import { useState, useContext } from 'react';
import { Box, Card, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { Button, Typography } from '@mui/material'
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../services/UseTokens'
// import { addRisk } from '../../redux/actions/risks/addRisk';
import { ProjectContext } from './ProjectContext';
import { requests } from '../../services/Api';
import Swal from 'sweetalert2';


export default function AddRisk(props) {
  const { risks, setRisks } = useContext(ProjectContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken()
  const { project_id } = useParams();
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [mitigation, setMitigation] = useState("")
  const [loading, setLoading] = useState(false)

  // const createRisk = () => {
  //   const risk = {
  //     project_id: project_id,
  //     risk_title: title,
  //     risk_description: description,
  //     mitigation_description: mitigation,
  //   }
  //   dispatch(addRisk(risk, token))
  // }

  const addRisk = async () => {
    setLoading(true)
    const risk = {
      project_id: project_id,
      risk_title: title,
      risk_description: description,
      mitigation_description: mitigation,
    }
    await requests.post(`projects/risks`, risk, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then((response) => {
      setLoading(false)
      setRisks((prevState) => [...prevState, response.data])
      props.handleClose()
      Swal.fire({
        title: 'Success',
        text: 'Risk added successfully',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
      // props.handleClose()
    }).catch((error) => {
      setLoading(false)
      console.log(error);
      Swal.fire({
        title: 'Error',
        text: error.response.data.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (error.response.status === 401) {
          navigate('/login')
          props.handleClose()
        }
      })
    })
  }

  return (
    <div style={{ zIndex: 2 }}>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h6" style={{ color: "#5927e3" }}>Add Risk</Typography>
      </Box>

      <form style={{ display: "flex", flexDirection: "column" }} >
        <TextField
          variant="outlined"
          label="Risk Title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          style={{ margin: "15px" }} />
        <TextField
          id="outlined-multiline-static"
          variant="outlined"
          label="Risk Description"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          style={{ margin: "15px" }} multiline
          rows={4} />
        <TextField
          variant="outlined"
          label="Risk Mitigation"
          style={{ margin: "15px" }}
          multiline
          onChange={(e) => setMitigation(e.target.value)}
          value={mitigation}
          id="outlined-multiline-static"
          rows={4} />
        {/* <TextField variant="outlined " label="Risk Descripton"/> */}
        {/* <TextField variant="outlined " label="Risk Mitigation" /> */}
        {/* <Button variant="contained " sx={{backgroundColor:"#5927e3"}}>Submit</Button> */}
      </form>

      <DialogActions>
        <Button onClick={() => props.handleClose()} variant="outlined" color="error">Cancel</Button>
        <Button onClick={() => addRisk()} variant="outlined" style={{ color: "#5927e3" }}>Submit</Button>
      </DialogActions>
    </div>
  )
}
