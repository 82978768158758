export const FETCH_PROJECTS_REQUEST = "FETCH_PROJECTS_REQUEST";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE";

export const CREATE_PROJECTS_REQUEST = "CREATE_PROJECTS_REQUEST";
export const CREATE_PROJECTS_SUCCESS = "CREATE_PROJECTS_SUCCESS";
export const CREATE_PROJECTS_FAILURE = "CREATE_PROJECTS_FAILURE";


export const DELETE_PROJECTS_REQUEST = "DELETE_PROJECTS_REQUEST";
export const DELETE_PROJECTS_SUCCESS = "DELETE_PROJECTS_SUCCESS";
export const DELETE_PROJECTS_FAILURE = "DELETE_PROJECTS_FAILURE";