import React, { useState, useEffect } from 'react'
import "./style.css"
import { useDispatch, useSelector } from 'react-redux'
import { getToken, setUser } from '../../services/UseTokens'
import { useNavigate } from 'react-router-dom'
import { requests } from '../../services/Api'
import Swal from 'sweetalert2'
// import { Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save';
import {Input, InputGroup, Button} from "reactstrap";

function TwoFactor() {
  const [code, setCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [resend, setResend] = useState(false)
  const [seconds, setSeconds] = useState(0)
  const [countDownDate, setCountDownDate] = useState(new Date().getTime() + 60000)

  const token = getToken()
  const navigate = useNavigate()

  const handleCodeChange=(value)=>{
    setCode(value)
  }

  // function to count down every 60 seconds to resend code using interval
  
  const countDown = () => {
    const now = new Date().getTime();
    const distance = countDownDate - now;
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    setSeconds(seconds)
    if (distance < 0) {
      setResend(true)
    }
  }
  
  const twoFactor = async () => {
    setLoading(true)
    const data = {
      "two_factor_code": code
    }
    await requests.post('/two-factor', data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      setLoading(false)

      setUser(response.data);
      Swal.fire({
        icon: 'success',
        title: 'Verified successfully',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        showConfirmButton: true,
        timer: 2000
      })
      navigate('/my-dashboard')
    }).catch((err) => {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.response.data.message,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        showConfirmButton: true,
        timer: 2000
      })
      if (err.response.status === 401) {
        navigate("/login")
      }
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      countDown()
    }, 1000);

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  return (
    <div style={{marginTop: "50px"}}>
      <div class="container">
        <br />
        <div class="row">
          <div class="col-lg-5 col-md-7 mx-auto my-auto">
            <div class="card" style={{display:"flex",justifyContent:"center"}}>
              <div class="card-body px-lg-5 py-lg-5 text-center">
                <img src="https://bootdey.com/img/Content/avatar/avatar7.png" class="rounded-circle avatar-lg img-thumbnail mb-4" alt="profile-image" />
                <h4 class="" style={{ color: "#5927e3 !important" }}>2FA Security</h4>
                <p class="mb-4" style={{ color: "#5927e3" }}>Enter the 6-digits code sent to your  phone number</p>
                <form>
                  <div class="row mb-4">
                    <div class="col-lg-12 col-md-12 ps-0 ps-md-2 d-flex" style={{display:"flex",justifyContent:"center"}}>
                      <InputGroup>
                        {/*<input type="text" className="form-control text-lg text-center" placeholder="_ _ _ _ _ _"*/}
                        {/*       aria-label="2fa" value={code} onChange={(e) => handleCodeChange(e.target.value)}/>*/}
                        <Input type="text"
                               className="form-control text-lg text-center"
                               placeholder="_ _ _ _ _ _"
                               id="password-input"
                               required
                               name="password"
                               onChange={(e) => handleCodeChange(e.target.value)}
                               value={code} />
                        <Button disabled={!resend}>{!resend ? `Resend in ${seconds}` : `Resend Code`}</Button>
                      </InputGroup>
                    </div>

                  </div>
                  <div class="text-center">
                    {!loading && (
                      <Button variant="contained" type="button" onClick={() => twoFactor()} class="btn btn-lg my-4" style={{ backgroundColor: "#5927e3", color: "#fff" }}>Continue</Button>

                    )}
                    {loading && (
                      <LoadingButton
                        loading
                        loadingPosition="start"
                        style={{ color: "5927e3", border: "1px #5927e3" }}
                        startIcon={<SaveIcon />}
                        variant="outlined"
                      >
                        Loading
                      </LoadingButton>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwoFactor