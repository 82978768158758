import { FETCH_OWNERDOCS_REQUEST,FETCH_OWNERDOCS_SUCCESS,FETCH_OWNERDOCS_FAILURE } from "./OwnerTypes";
import { getToken } from "../../../../services/UseTokens";
import { requests } from "../../../../services/Api";

const fetchprojectOwnerAction=(saveData)=>async(dispatch)=>{
    const token=getToken();
    try {
        console.log("token",token)
        dispatch({type:FETCH_OWNERDOCS_REQUEST})
        const response = await requests.get("documents/owners",{
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        dispatch({type:FETCH_OWNERDOCS_SUCCESS,
        payload:response.data})
        
    } catch (error) {
        dispatch({
            type:FETCH_OWNERDOCS_FAILURE,
            payload:error
        })
        
    }
}
export default fetchprojectOwnerAction;