import { SELLSHARES_FAILURE, SELLSHARES_REQUEST, SELLSHARES_SUCCESS } from "../../actions/sell-shares/sellSharesTypes";

const initialState = {
    loading: false,
    sell_shares: {},
    error: ""
}

const sellSharesReducer = (state=initialState, action) => {
    switch (action.type) {
        
        case SELLSHARES_REQUEST:
            return {
                ...state,
                loading: true,
                sell_shares: {},
                error: ""

            }
            
            break;
        case SELLSHARES_SUCCESS:
            return {
                loading:false,
                sell_shares: action.payload,
                error: ""
            }
            break;
        case SELLSHARES_FAILURE:
            return {
                loading:false,
                sell_shares: {},
                error: action.payload
            }
            break;
        default:
            return state;
            break;
    }
}
export default sellSharesReducer