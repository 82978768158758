import React, { useEffect } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Input, Label, Spinner } from 'reactstrap'

import { useState } from 'react'
import Select from "react-select"
import { getToken, getUser } from '../../../services/UseTokens'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import UpdateProjectDocumentAct from '../../../redux/actions/Documents/projectDocuments/UpdateProjecttDocument'

export default function UpdateDocument(props,{ParentCallback}) {
    console.log("props up",props)
    const [projectId, setProjectId] = useState("")
    const [documentId, setDocumentId] = useState("")
    const [documents, setDocuments] = useState([])
    const [loading, setLoading] = useState(false)
    const [projects, setProjects] = useState([])
    const [successResponse, setSuccessResponse] = useState()
    const [serverError, setServerError] = useState("")
    const [image,setImage]=useState(null)
    const user = getUser()
    const token = getToken()
    const dispatch=useDispatch()
    // Modal open state
    const [modal, setModal] = React.useState(false);

    // Toggle for Modal
    const toggle = () => setModal(!modal);

  const handleUpdate=(values)=>{
    const data={
        document_id:props.projectDoc.documents.id,
        project_id:values.project_id,
        document:values.document
    }
    dispatch(UpdateProjectDocumentAct(values.document_id,data,token))
  }


 




    return (
        <div>
            <Card >
                <Formik
                    initialValues={props.projectDoc||{
                        "document": null,
                        "document_id": "",
                        "project_id": "",
                        "document_id":""
                    }}

                    onSubmit={(values, { resetForm }) => {
                        values["document_id"] = props.projectDoc.id
                               handleUpdate(values)    
                        // PostProjectDocument(values)
                        resetForm({ values: "" })
                        console.log("submit values", values)
                    }}
                    enableReinitialize
                >
                    {({ values, isSubmitting, errors, handleSubmit, handleChange, dirty, isValid }) => (

                        <Form className="was-validated">
                            <CardHeader className='position-relative text-capitalize text-center'>
                                <strong>update {props.projectDoc?.documents.name}</strong>
                            </CardHeader>
                            <CardBody>
                                {serverError && (
                                    <div style={{ color: "red", fontSize: "15px" }}>{serverError && (
                                        <div>{serverError}</div>
                                    )}
                                    </div>
                                )}

                                {successResponse && (
                                    <div
                                        style={{ color: "green", fontSize: "15px", height: "30px", width: "auto", right: "0" }}>
                                        {successResponse}
                                    </div>

                                )}

                                <div className=" mb-3">
                                    <Label className="ValidationInput text-capitalize" for="validationFormCheck3">Upload Document</Label>
                                    <Input type="file"
                                        className="form-control"
                                        id="name"
                                        name="document"
                                        placeholder='Enter document '
                                        values={image}
                                        onChange={(e) =>{ setImage(e.target.files[0])
                                            console.log("image",image)}}
                                        required />

                                    
                                </div>
                               
                                

                            </CardBody>
                            {!loading && (
                                <CardFooter class="text-right position-absolute  end-0">
                                    <div className='position-relative'>

                                        <button className="btn btn-success m-1" type="submit">Submit form</button>
                                    </div>

                                </CardFooter>
                            )}

                            {
                                loading && (
                                    <CardFooter class="text-right position-absolute  end-0">
                                        <div className="m-1 position-relative">
                                            <Button color="success" className="btn-load" outline disabled>
                                                <span className="d-flex align-items-center">
                                                    <span className="flex-grow-1 me-2">
                                                        Loading...
                                                    </span>
                                                    <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                                                </span>
                                            </Button>
                                        </div>
                                    </CardFooter>

                                )
                            }
                        </Form>
                    )}
                </Formik>
            </Card>
        </div>
    )
}

