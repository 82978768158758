import Swal from "sweetalert2";
import { requests } from "../../../services/Api";
import { getToken } from "../../../services/UseTokens";
import { POST_REQUEST, POST_SUCCESS, POST_FAILURE } from "./MilestoneType";

export const postMilestone = (milestone) => async dispatch => {
    const token = getToken()

    try {
        dispatch({ type: POST_REQUEST })
        const response = await requests.post(`projects/create-project-milestone`, milestone, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        dispatch({ type: POST_SUCCESS, payload: response.data })
        Swal.fire(
            'Good job!',
            'Milestone Created Successfully',
            'success'
        )

    } catch (error) {
        console.log("error", error)
        dispatch({ type: POST_FAILURE, payload: error })
        Swal.fire(
            'error',
            error.response.data.message,
            "ok"

        )

    }
}
