import Main from "./layout/main";
import { Routes, Route } from "react-router-dom";
import CreateProject from "./components/modals/CreateProject";
import ListOfProjectsLayout from "./layout/listofproject/Index";
import CompanyLayout from "./layout/company/Index";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Home from "./pages/home";
import ProjectDetails from "./pages/project-details";
import Projects from "./pages/projects";
import Settings from "./pages/Settings/content";
import Contact from "./pages/contact";
import CreateCompanyL from "./pages/configurables/Company2";
import ListYourProject from "./layout/listofproject/ListYourProject";
import Dash from "./pages/configurables/Dash";
import Test2 from "./pages/configurables/Test2";
import MyCompany from "./pages/ViewUserCompany";
import CompanyDetails from "./pages/ViewSingleCompany/details";
import Dashboard from "./pages/Dashboard";
import MyProjects from "./pages/myProjects";
import MyInvestment from "./pages/Investment";
import LastIncome from "./pages/last Income/Index";
import ProjectDocuments from "./pages/Documents/ProjectDocuments";
import ProjectOwnerDocument from "./pages/Documents/ProjectOwner/ProjectOwnerDocument";
import ProjectOwnerDocuments from "./pages/Documents/ProjectOwner";
import InvestorDocument from "./pages/Documents/InvestorDocuments/index.js";
import UserProfile from "./pages/UserProfile/UserProfile";
import ForgotPass from "./pages/Auth/ForgotPass";
import ResetPass from "./pages/Auth/ResetPass";
import UpdateCompany from "./pages/Company/UpdateCompany";
import UpdateProject from "./pages/configurables/UpdateProject";
import SingleProjectAdmin from "./pages/project-details/details/SingleProjects";
// import OboardCompany from "./pages/OnBoardCompany";
import OnBoardCompany from "./pages/OnBoardCompany";
import VerifyEmail from "./pages/components/VerifyEmail";
import NominateUser from "./pages/OnBoardCompany/NominateUser";
import NoProjects from "./pages/myProjects/NoProjects";
import OnboardCompany2 from "./OnBoard new/Index";
import TwoFactor from "./pages/Auth/TwoFactor";
import OnBoard from "./pages/Onboard";
import { PrivateRoute } from "./utils/PrivateRoute";
import Directors from "./pages/ViewSingleCompany/Directors";
import CompanyProfile from "./pages/Auth/CompanyProfile/Index";
import CompanyProfile2 from "./pages/Companies/Index.jsx";
import OnBoardProject from "./pages/OnBoardProject/index.js";
import ProjectProfile from "./pages/ProjectProfile.jsx/Index";
import MyNewCompany from "./pages/Auth/CompanyProfile/companies/MainCompany";
import WelcomeDirector from "./pages/Companies/WelcomeDirector";
import ChangePassword from "./pages/Auth/DirectorsChangePassword";
import OnBoardMobile from "./pages/Onboard/OnboardMobile";
import OnboardCompanyCombine from "./pages/Onboard/OnboardCompanyCombine";
import OnboardProjectCombine from "./pages/OnBoardProject/CombineProjectOnboard";
import Learn from "./pages/learn/Index";

// import SettingsLayout from "./layout/Settings/SettingsLayout";

function App() {
  return (
    <div>
      {/* <Main /> */}
      <Routes>
        <Route exact path="/company" element={<CompanyLayout />} />
        <Route
          exact
          path="/login"
          element={
            <Main>
              <Login />
            </Main>
          }
        />
        <Route
          exact
          path="/register"
          element={
            <Main>
              <Register />
            </Main>
          }
        />
        <Route
          exact
          path="/forgot-password"
          element={
            <Main>
              <ForgotPass />
            </Main>
          }
        />
        <Route
          path="/resetpassword/:token/:email"
          element={
            <Main>
              <ResetPass />
            </Main>
          }
        />
        <Route exact path="/settings" element={<Settings />} />
        <Route
          exact
          path="/learn"
          element={
            <Main>
              <Learn />
            </Main>
          }
        />
        <Route
          exact
          path="/list-your-project"
          element={
            <PrivateRoute>
              <Main>
                <ListYourProject />
              </Main>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/"
          element={
            <Main>
              <Home />
            </Main>
          }
        />
        <Route
          exact
          path="/projects"
          element={
            <Main>
              <Projects />
            </Main>
          }
        />
        <Route
          exact
          path="/projects/:id"
          element={
            <Main>
              <ProjectDetails />
            </Main>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <Main>
              <Contact />
            </Main>
          }
        />
        <Route
          exact
          path="/`configurables`"
          element={
            <Main>
              <Dash />
            </Main>
          }
        />
        <Route
          exact
          path="/update-company/:id"
          element={
            <Main>
              <UpdateCompany />
            </Main>
          }
        />
        <Route
          exact
          path="/create-project-configurable"
          element={
            <Main>
              <Test2 />
            </Main>
          }
        />
        <Route
          exact
          path="/company-layout"
          element={
            <Main>
              <CreateCompanyL />
            </Main>
          }
        />
        <Route
          exact
          path="/sectors"
          element={
            <Main>
              <Settings />
            </Main>
          }
        />
        <Route exact path="/dashboard" element={<Dash />} />
        <Route exact path="/verify-email" element={<VerifyEmail />} />
        <Route exact path="/nominate-user/:id" element={<NominateUser />} />
        <Route
          exact
          path="/change-password/:token/:email"
          element={<ChangePassword />}
        />
        <Route
          exact
          path="/successful-director-verification"
          element={<WelcomeDirector />}
        />
        <Route
          exact
          path="/onboard-company"
          element={
            <PrivateRoute>
              <Main>
                <OnboardCompanyCombine />
              </Main>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/test"
          element={
            <Main>
              <Test2 />
            </Main>
          }
        />
        <Route
          exact
          path="/my-companies"
          element={
            <Main>
              <MyCompany />
            </Main>
          }
        />
        <Route
          exact
          path="/company/:id"
          element={
            <Main>
              <CompanyDetails />
            </Main>
          }
        />
        <Route
          exact
          path="/my-dashboard"
          element={
            <PrivateRoute>
              <Main>
                <Dashboard />
              </Main>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/my-projects"
          element={
            <Main>
              <MyProjects />
            </Main>
          }
        />
        <Route
          exact
          path="/my-investments"
          element={
            <Main>
              <MyInvestment />
            </Main>
          }
        />
        <Route
          exact
          path="/sell-shares"
          element={
            <Main>
              <LastIncome />
            </Main>
          }
        />
        <Route
          exact
          path="/user-profile"
          element={
            <Main>
              <UserProfile />
            </Main>
          }
        />
        <Route
          exact
          path="/project-documents/:id"
          element={
            <Main>
              <ProjectDocuments />
            </Main>
          }
        />
        <Route
          exact
          path="/project-owner-documents/:id"
          element={
            <Main>
              <ProjectOwnerDocuments />
            </Main>
          }
        />
        <Route
          exact
          path="/investor-documents/:id"
          element={
            <Main>
              <InvestorDocument />
            </Main>
          }
        />
        <Route
          exact
          path="/update-project/:id"
          element={
            <Main>
              <UpdateProject />
            </Main>
          }
        />
        <Route
          exact
          path="/update-company/:id"
          element={
            <PrivateRoute>
              <Main>
                <UpdateCompany />
              </Main>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/my-projects2"
          element={
            <Main>
              <NoProjects />
            </Main>
          }
        />
        <Route
          exact
          path="/my-projects/:id"
          element={
            <Main>
              <SingleProjectAdmin />
            </Main>
          }
        />
        <Route
          exact
          path="/two-factor"
          element={
            <Main>
              <TwoFactor />
            </Main>
          }
        />
        <Route
          exact
          path="/directors"
          element={
            <Main>
              <Directors />
            </Main>
          }
        />
        <Route
          exact
          path="/my-company"
          element={
            <PrivateRoute>
              <Main>
                <MyNewCompany />
              </Main>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/company-profile/:id"
          element={
            <PrivateRoute>
              <Main>
                <CompanyProfile2 />
              </Main>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/project-profile/:project_id"
          element={
            <PrivateRoute>
              <Main>
                <ProjectProfile />
              </Main>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/onboard-project/:company_id"
          element={
            <PrivateRoute>
              <Main>
                <OnboardProjectCombine />
              </Main>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/onboard-mobile"
          element={
            <Main>
              <OnBoardMobile />
            </Main>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
