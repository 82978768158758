import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import image from "../../assets/images/form3.jpg"
import { createTheme, makeStyles } from '@material-ui/core';
import { Box, Button, Grid, TextField } from '@mui/material';


const useStyles = makeStyles(theme => ({
    CardContent: {
        top: "auto",
        bottom: 0,
        textAlign: "center"
    },
    footer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    cardHeader:{
        color:"#fff"
    }
}));
export default function NominateUser() {
    const classes = useStyles();
    return (
        <div style={{ margin: "auto", minHeight: "100vh", padding: "3%",backgroundImage: `url(${image})`  }}>
            <Card className={classes.CardContent}
                sx={{ padding: "3%", boxShadow: 3, maxWidth: "75% ", marginX: "auto", marginY: "10%", width: "45%", height: "70%", padding: "auto" }}>
                      {/* <CardHeader  className={classes.cardHeader}style={{ color:"#5927e3 !important",justifyContent:"center !important"}} align="center"

        title="Nominate User"
        content="Thank you for verifying your account.Fill in the form below to nominate a user to monitor yor progress"
       
      /> */}
      <Box style={{backgroundColor:"" ,color:"#5927e3" ,height:"100px"}} className={classes.footer}>
        <Typography variant="h6" color="#5927e3" align="center">Nominate your company user to monitor your company's progress</Typography>
    

      </Box>
             <Box
   
   component="form"
   justifyContent="center"
   sx={{ flexGrow: 1 ,padding:"15px"}}
   
   Validate
   autoComplete="off"
 >
     <Grid container spacing={2} style={{padding:"15px"}}>
   
   <Grid item xs={6}>
   <TextField fullWidth id="outlined-basic" label="First Name" variant="outlined" />
   </Grid>

   <Grid item xs={6}>
   <TextField fullWidth id="outlined-basic" label="Middle Name" variant="outlined" />
   </Grid>
   <Grid item xs={6}>
  
     <TextField fullWidth sx={6} id="outlined-basic" label="Last Name" variant="outlined" />
     </Grid>
     <Grid item xs={6}>
  
     <TextField fullWidth sx={6} id="outlined-basic" label="Phone Number" variant="outlined" />
     </Grid>
     <Grid item xs={6}>
  
     <TextField fullWidth sx={6} id="outlined-basic" label="Email" variant="outlined" />
     </Grid>

     <Grid item xs={6}>
  
     <TextField fullWidth sx={6} id="outlined-basic" label="Id Number" variant="outlined" />
     </Grid>
     </Grid>
    
    <Button variant="contained" style={{backgroundColor:"#5927e3",color:"#fff" ,margin:"15px"}}>Submit</Button>
  
 </Box>

            </Card>
        </div>

    );
}
