import { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../../components/dropdown/dropdown.component";
import { fetchProjects } from "../../redux/actions/projects/fetchProjects";
import { fetchSectors } from "../../redux/actions/sector/fetchSectors";
import Banner from "./banner";
import Filter from "./filter";
import List from "./projects";
import PropagateLoader from "react-spinners/PropagateLoader";

function Projects() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.projects);
  const allProjects = data.projects;
  const sectors = useSelector((state) => state.sectors);
  const [search, setSearch] = useState("");
  const [sec, setSec] = useState("");
  const [filterSector, setFilterSector] = useState("");
  const filteredData = () => {
    return allProjects.filter((project) => {
      if (sec !== "") {
        console.log("sec", sec);
        return project.sector_id === sec.id;
      }
      return project?.project_name.toLowerCase().includes(search.toLowerCase());
    });
  };
  const projects = filteredData() || allProjects;
  useEffect(() => {
    dispatch(fetchSectors());
    dispatch(fetchProjects());
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Banner />

      <div class="property__filter">
        <div class="container">
          <div class="property__filter__area">
            <div class="row d-flex align-items-center">
              <div class="col-lg-12 col-xl-6">
                <div class="property__search__wrapper">
                  <form>
                    <div class="input">
                      <input
                        type="search"
                        name="property__search"
                        id="propertySearch"
                        placeholder="Search for properties"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-6 col-xl-3">
                <div class="property__select__wrapper">
                  <Dropdown
                    options={
                      sectors.loading
                        ? []
                        : sectors.sectors.length === 0
                        ? []
                        : sectors.sectors
                    }
                    prompt="Choose a Sector"
                    value={sec}
                    onChange={(val) => setSec(val)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data.loading === true ? (
        <section class="properties__filter section__space__bottom">
        <div class="container wow fadeInUp">
          <div class="properties__filter__wrapper">
          <div style={{justifyContent: 'center', alignItems: 'center'}}>
          <PropagateLoader color="#36d7b7"/>
          </div>
          
          </div>
        </div>
        </section>
        
      ) : (
        <List allProjects={projects} />
      )}
    </div>
  );
}

export default Projects;
