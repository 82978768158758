// ** MUI Imports
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'
import image from "../../assets/images/chart.png"
import trianglelight from "../../assets/images/triangle-light.png"
import triangleDark from "../../assets/images/triangle-dark.png"
import { getUser } from '../../services/UseTokens'

// Styled component for the triangle shaped background image
const TriangleImg = styled('img')({
  right: 0,
  bottom: 0,
  height: 170,
  position: 'absolute'
})

// Styled component for the trophy image
const TrophyImg = styled('img')({
  right: 36,
  bottom: 20,
  height: 98,
  position: 'absolute'
})

const InvestmentSummary= ({data}) => {
  const user = getUser();
  // ** Hook
  const theme = useTheme()
  const imageSrc = theme.palette.mode === 'light' ? trianglelight : triangleDark

  return (
    <Card sx={{ position: 'relative' }}>
      <CardContent>
        <Typography variant='h6'>{`Hey ${user?.first_name}`} 🥳</Typography>
        <Typography variant='body2' sx={{ letterSpacing: '0.25px' }}>
          Total monthly investment
        </Typography>
        <Typography variant='h5' sx={{ my: 4, color: '#5927e3' }}>
          {data?.totalInvestedThisMonth}
        </Typography>
        {/* <Button style={{backgroundColor:"#5927e3"}} size='small' variant='contained'>
          View Sales
        </Button> */}
        <TriangleImg alt='triangle background' src={`${imageSrc}`} />
        <TrophyImg alt='trophy' src={image }/>
      </CardContent>
    </Card>
  )
}

export default InvestmentSummary
